export const handleAxiosError = (error) => {
  if (error.response) {
    const statusCode = error.response.status;
    const responseData = error.response.data;
    const responseMessage = error?.response?.data?.message;
    console.log("Error Status: ", statusCode);
    console.log("Error Data: ", responseData);

    if (statusCode === 401) {
      console.error("Unauthorized:", responseData);
      localStorage.setItem("error", statusCode);
      localStorage.setItem("errCode", responseMessage);
    } else if (statusCode === 403) {
      console.error("403:", responseData);
      localStorage.setItem("error", statusCode);
    } else if (statusCode === 500) {
      console.error("Server:", responseData);
      localStorage.setItem("error", statusCode);
    }
  }
};
