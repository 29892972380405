import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import loginBg from "../../../../assets/images/Background/Global/loginRegBg.jpg";
import CustomContainer from "../../../../layouts/Container/CustomContainer";
import CustomMessage from "../../../../components/Form/ErrorMessage";
import CustomButton from "../../../../components/UI/CustomButton";
import Config from "../../../../Config/config";
import CustomInput from "../../../../components/Form/CustomInput";
import SpecificSyspar from "../../../../Utilities/Reusable/SpecificSyspar";
import { fetchSysparValue } from "../../../../Utilities/Fetch/FetchSysparValue";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomModal from "../../../../components/UI/Modal";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
const EnterEmail = () => {
  const [errorMessage, setErrorMsg] = useState("");
  const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState("");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [siteKey, setSiteKey] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const schema = z
    .object({
      email: z.string().nonempty("Email is required").email(),
      confirmEmail: z.string().nonempty("Confirm Email is required").email(),
    })
    .refine((data) => data.email === data.confirmEmail, {
      message: "Emails do not match",
      path: ["confirmEmail"], // Set the path to confirmPassword field
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  function onChange(value) {
    setRecaptcha(value);
    setRecaptchaErrorMessage("");
  }

  useEffect(() => {
    fetchSysparValue("GOOGLE_CAPTCHA_SITE_KEY", setSiteKey);
  }, []);

  const onSubmitWithReCAPTCHA = async (data) => {
    setRecaptchaErrorMessage("");
    setIsBtnLoading(true);
    const { email, ...requestData } = data;
    if (data && recaptcha) {
      try {
        const sendEmail = await axios.post(
          `${Config.nxtApi}/api/user/forgot/password`,
          {
            email,
          }
        );

        setModalShow(true);
        setIsBtnLoading(false);
      } catch (error) {
        setIsBtnLoading(false);
        setErrorMsg(error.response.data.message);
        setModalErrorShow(true);
      }
    } else {
      setIsBtnLoading(false);
      setRecaptchaErrorMessage("Check captcha before submitting");
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    navigate("/");
  };

  const handleModalErrorClose = () => {
    setModalErrorShow(false);
  };
  return (
    <>
      <CustomContainer
        backgroundImage={loginBg}
        className="customContainerLogin"
      >
        <div className="containerIndex">
          <div className="content">
            <form onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}>
              <div className="loginTitleLeft">
                <h1>Enter Your Email</h1>
              </div>
              <CustomInput
                errors={errors}
                label="Please enter your email address"
                type="email"
                {...register("email")}
                placeholder="Email Address"
                className="formInputGroup"
              />
              <CustomInput
                errors={errors}
                label="Confirm email address"
                type="email"
                {...register("confirmEmail")}
                placeholder="Confirm Email Address"
                className="formInputGroup"
              />

              {siteKey && (
                <div className="captcha">
                  <label>Captcha:</label>
                  <ReCAPTCHA
                    sitekey={siteKey}
                    onChange={onChange}
                    onClick={() => setRecaptchaErrorMessage("")}
                  />
                  {recaptcha === "" ||
                    (recaptcha === null && (
                      <CustomMessage label={"Please verify captcha"} />
                    ))}

                  {recaptchaErrorMessage && (
                    <CustomMessage label={recaptchaErrorMessage} />
                  )}
                </div>
              )}

              <div className="btnContainerMrgn">
                <CustomButton
                  type="submit"
                  name="Submit"
                  className="customLRButton"
                  isLoading={isBtnLoading}
                />
              </div>
            </form>
          </div>
        </div>
      </CustomContainer>
      <CustomModal
        buttonName="Close"
        show={modalShow}
        modalMessage="To set up your password, please check your email and click the following link"
        modalType="Success"
        onClick={() => handleModalClose()}
      />
      <CustomModal
        show={modalErrorShow} // Show the modal when needed
        modalMessage={errorMessage} // Error message
        modalType="Warning" // Type of modal (you can customize this)
        buttonName="Close" // Button label
        onClick={() => handleModalErrorClose()}
      />
    </>
  );
};

export default EnterEmail;
