import React from "react";
import AdminSidebar from "../../../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../../../layouts/Navbar";
import WhiteContainer from "../../../../../../layouts/Container/WhiteContainer";
import AddOnSelect from "./../../AddOn/AddOnStep/AddOnSelect";
import { useLocation, useNavigate } from "react-router-dom";
import SubsConfig from "../../../../../../components/LayoutClient/Subscription/SubsConfig";
import SubsCheckout from "../../../../../../components/LayoutClient/Subscription/SubsCheckout";
import SubsLastPage from "../../../../../../components/LayoutClient/Subscription/SubsLastPage";

const AddOnSubscribe = () => {
  const location = useLocation();

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer subscription-card__ats">
          <WhiteContainer>
            <div className="content-container">
              <SubsLastPage />
            </div>
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default AddOnSubscribe;
