import React from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
// import Footer from "../../layouts/Footer";
import Banner from "../../components/Banner";
import ApplicantList from "../../components/LayoutClient/ApplicantList/ApplicantList";
import FilterList from "../../components/LayoutClient/ApplicantList/FilterList";
import "../../../src/assets/scss/Client/Page/ApplicantList.scss";
import SidebarViewApplicant from "../../layouts/Client/SidebarViewApplicant";
import SidebarJoblistStatistics from "../../layouts/Client/SidebarJoblistStatistics";
// import FooterClient from "../../layouts/FooterClient";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import Footer from "../LandingPage/Components/Footer";
const ApplicantLists = () => {
  TabTitle("NXT | Applicant List");
  return (
    <div className="applicant-background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="applicant-banner">
        <Banner />
      </div>

      <Container className="applicant-body">
        <div>
          <div className="sidebar-container">
            <SidebarViewApplicant />
            <SidebarJoblistStatistics />
          </div>
        </div>

        <div className="content-container">
          <FilterList />
          <ApplicantList />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default ApplicantLists;
