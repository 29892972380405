import React, { useEffect, useState } from "react";
import loginBg from "../../../../assets/images/Background/Global/adminLogin.png";
// import ContainerIndex from "../User/Container";
import CustomContainer from "../../../../layouts/Container/CustomContainer";
import ContainerIndex from "./Container";
// import ContainerIndex from "./Container";
// import CustomContainer from "../../../layouts/Container/CustomContainer";

// 5 mins idle

const AdminLogin = () => {
  return (
    <>
      <CustomContainer
        backgroundImage={loginBg}
        className="customContainerAdminLogin"
      >
        <ContainerIndex />
      </CustomContainer>
    </>
  );
};

export default AdminLogin;
