// const Config = {
//   nxtApi: process.env.REACT_APP_NXT_API,
//   admin: process.env.REACT_APP_ADMIN_API,
//   ats: process.env.REACT_APP_ATS_API,
//   subs: process.env.REACT_APP_SUBSCRIPTION,
//   ai: process.env.REACT_APP_AI,
// };

// export default Config;

// const Config = {
//   nxtApi: "http://localhost:8080",
//   // nxtApi: process.env.REACT_APP_NXT_API,
//   admin: process.env.REACT_APP_ADMIN_API,
//   ats: "http://localhost:8000",

//   subs: "http://localhost:8002",
//   ai: process.env.REACT_APP_AI,
// };

// export default Config;

// export default Config;

export const isLocalhost = window.location.hostname === "localhost";

const Config = isLocalhost
  ? {
      nxtApi: "http://localhost:8080",
      // nxtApi: process.env.REACT_APP_NXT_API,
      admin: process.env.REACT_APP_ADMIN_API,
      ats: "http://localhost:8000",
      subs: "http://localhost:8002",
      ai: process.env.REACT_APP_AI,
    }
  : {
      nxtApi: process.env.REACT_APP_NXT_API,
      admin: process.env.REACT_APP_ADMIN_API,
      ats: process.env.REACT_APP_ATS_API,
      subs: process.env.REACT_APP_SUBSCRIPTION,
      ai: process.env.REACT_APP_AI,
    };

export default Config;
