import React from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/BasicInformation.scss";
import { List } from "react-content-loader";
import { Label } from "reactstrap";
import { Alert } from "react-bootstrap";

const BasicInformation = (data) => {
  const applicantData = data.data;

  return (
    <div className="st-basic-information-container">
      <h6>BASIC INFORMATION</h6>
      <hr className="line-separator" />
      {applicantData < 0 ? (
        <Alert color="secondary">No data available.</Alert>
      ) : (
        <div className="user-information">
          <div className="personal-info">
            <div className="label-container">
              <Label>Last Name:</Label>
              <p>{applicantData.lastName}</p>
            </div>
            <div className="label-container">
              <Label>First Name:</Label>
              <p>{applicantData.firstName}</p>
            </div>
            <div className="label-container">
              <Label>Middle Name:</Label>
              <p>{applicantData.middleName}</p>
            </div>
            <div className="label-container">
              <Label>Email:</Label>
              <p>{applicantData.email}</p>
            </div>
            <div className="label-container">
              <Label>Contact:</Label>
              <p>{applicantData.contactNumber}</p>
            </div>
          </div>
          <div className="other-info">
            <div className="label-container">
              <Label>Birthday:</Label>
              <p>{applicantData.birthday}</p>
            </div>
            <div className="label-container">
              <Label>Address:</Label>
              <p>{applicantData.address}</p>
            </div>
            <div className="label-container">
              <Label>Municipality:</Label>
              <p>{applicantData.municipality}</p>
            </div>
            <div className="label-container">
              <Label>Country:</Label>
              <p>{applicantData.country}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicInformation;
