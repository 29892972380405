import axios from "axios";
import Config from "../../Config/config";

// export const GetData = async (url, token) => {
//   try {
//     const response = await axios.get(`${Config.nxtApi}/${url}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     // console.log(response.data);
//     return response.data;
//   } catch (error) {
//     console.log(error.response.data);
//     return error.response.data;
//   }
// };

export const GetData = async (url, token) => {
  try {
    const response = await axios.get(`${Config.nxtApi}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response.data);
    return error;
  }
};
