import React, { useEffect } from "react";
// import Header from "../layouts/Header";
// import Footer from "../layouts/Footer";
// import "../../src/assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import CompanyProfileForm from "./Form/CompanyProfileForm";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import ClientAnalytics from "../../../Utilities/Analytics/ClientAnalytics";
import Header from "../../../layouts/Header";
import Banner from "../../../components/Banner";
import Sidebar from "../../../layouts/Sidebar";
import FooterClient from "../../../layouts/FooterClient";
import { useSelector } from "react-redux";
import { List } from "react-content-loader";
import axios from "axios";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import { localDevBase } from "../../../services/API/localDevBase";
import Footer from "../../LandingPage/Components/Footer";
const CompanyProfile = () => {
  TabTitle("NXT | Company Profile");

  return (
    <div className="background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <Banner />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>

        <div className="company-profile-container">
          <CompanyProfileForm />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default CompanyProfile;
