import React from "react";

const MultiUser = () => {
  return (
    <div>
      <h2>What is ‘Multi-user’?</h2>
      <p>
        A feature in NXT that will allow multiple users, including moderators
        and recruiters, to access and interact with the system simultaneously,
        improving collaboration, security, and overall system efficiency.
      </p>
      <br />
      <h2>Who can access Multi-user?</h2>
      <p>
        NXT client accounts with Premium + or Elite subscription can have an
        option to subscribe to the Multi-user Add On subscription
      </p>
      <br />
      <h2>Who are the feature users?</h2>
      <ul>
        <li>
          Parent account holder (Client with Premium + or Elite subscription)
        </li>
        <li>Moderator</li>
        <li>Recruiter</li>
      </ul>
      <br />
      <h2>Who can be added as a user?</h2>
      <p>Only NXT client accounts can be added as moderators or recruiters.</p>
      <br />
      <h2>How to subscribe to the Multi-user?</h2>
      <ol>
        <li>Login the client with the Premium + or Elite subscription</li>
        <li>On the side navigation, click Manage Job</li>
        <li>Click Subscription</li>
        <li>Click Add Ons</li>
        <li>Select the Multi-user and click SUBSCRIBE NOW</li>
        <li>
          Continue until you reach the Checkout page, tick the Terms and
          Conditions checkbox then click Continue
        </li>
        <li>
          Confirmation page upon successful order will be displayed and an email
          notification will be sent to your registered email address
        </li>
        <li>
          Check your email for the instructions on How to pay the Add On
          subscription
        </li>
      </ol>
      <br />
      <h2>How to access Multi-user? (Parent account)</h2>
      <ol>
        <li>Login the client account subscribed to the Multi-user</li>
        <li>On the side navigation, click Manage Job</li>
        <li>Click Members</li>
      </ol>
      <br />
      <h2>How to add a user?</h2>
      <ol>
        <li>Login the client account subscribed to the Multi-user</li>
        <li>On the side navigation, click Manage Job</li>
        <li>Click Members</li>
        <li>Click the Add User button</li>
        <li>
          A modal will be displayed, select an employer account you want to add
        </li>
        <li>Select a role (Moderator or Recruiter)</li>
        <li>Click Add</li>
        <li>A modal will be displayed, click Yes</li>
      </ol>
      <br />
      <h2>How to edit the role of a user?</h2>
      <ol>
        <li>Login the client account subscribed to the Multi-user</li>
        <li>On the side navigation, click Manage Job</li>
        <li>Click Members</li>
        <li>Select a user and click the edit button (Pencil icon)</li>
        <li>A modal will be displayed, change the role of the user</li>
        <li>Change to Moderator or Recruiter (vice versa)</li>
        <li>Click Save</li>
      </ol>
      <br />
      <h2>How to access your Moderated account? (Moderator)</h2>
      <ol>
        <li>Login the Moderator account</li>
        <li>On the upper right corner, click the dropdown button</li>
        <li>Click the company name of the moderated account</li>
      </ol>
      <br />
      <h2>How to access your Moderated account? (Recruiter)</h2>
      <ol>
        <li>Login the Recruiter account</li>
        <li>On the upper right corner, click the dropdown button</li>
        <li>Click the company name of the moderated account</li>
      </ol>
      <br />
      <h2>How many users can be added?</h2>
      <p>
        The maximum number of users that can be added is limited to 5,
        regardless of whether they are Moderators or Recruiters.
      </p>
    </div>
  );
};

export default MultiUser;
