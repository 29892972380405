import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="hero position-relative">
      <div className="position-absolute top-50 start-50 translate-middle w-75">
        <div className="row">
          <div className="">
            <h5 className="d-flex mb-5">Revolutionize Your Hiring Process</h5>
            <p className="mb-5">
              Experience the future of talent acquisition with NXT's
              <br />
              groundbreaking features, tailored to propel your hiring
              <br /> process to new heights!
            </p>
            <Button
              className="btn px-4"
              onClick={() => navigate("/contact-us")}
            >
              Request a demo
            </Button>
            <img
              className="image-one__working position-absolute"
              src={require("../../../assets/images/LandingPage/TrackingSystem/working-one.png")}
            />
            <img
              className="image-two__working position-absolute"
              src={require("../../../assets/images/LandingPage/TrackingSystem/working-two.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
