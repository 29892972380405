import React from "react";
import privacyText from "../../../../Data/about/PrivacyPolicy.json";
import "../../../../assets/scss/Page/Outer/PrivacyPolicy/_privacyPolicy.scss";
import { useNavigate } from "react-router-dom";

const Text = () => {
  const navigate = useNavigate();
  return (
    <div className="privacyPolicy">
      <h1>Privacy Policy</h1>
      <div>
        <h2>Our commitment to maintaining your privacy</h2>
        <p>
          Our mission is to create new and meaningful experiences today by
          connecting talented individuals with the right opportunities and
          helping organizations succeed. It is important to us that you have a
          clear understanding of how your information is collected, utilized,
          and protected when you engage with our services.
          <br />
          <br />
          Upon registration and account creation, utilization of our products
          and services, visits to our Platforms, or any form of engagement,
          whether through phone, email, in-person, or otherwise, the terms
          outlined in this Privacy Policy will be applicable to you.
          <br />
          <br />
          Our Privacy Policy explains all of this in detail below.
        </p>
        <br />
        <h2>1. Who are we?</h2>
        <p>
          <strong>NXT - New eXperiences Today</strong> by
          <strong> Acquiro Solutions and Tech Inc. </strong>
          aims to streamline job hunting among seekers and the search for
          talents among recruiters and companies.
          <br />
          <br />
          A spin-off from Asticom’s staffing solutions business, this new
          addition to the Asticom Group aims to assist a diverse range of job
          seekers including fresh graduates, career shifters, interns, and
          gigsters to find suitable careers. At the same time, it seeks to help
          businesses of all sizes adapt to the ever-changing modern workplace.
          <br />
          <br />
          NXT is a job search platform connecting job seekers and businesses,
          making the job search process seamless with innovative technology and
          a user-friendly interface.
          <br />
          <br />
          As a company based in the Philippines, Acquiro Solutions and Tech
          Inc., its affiliates, and its parent, Asticom have established this
          Data Privacy Policy to underscore our acknowledgment and respect for
          your privacy rights. We are committed to adhering to the provisions
          outlined in the Data Privacy Act of 2012 and its implementing rules
          and regulations.
        </p>
        <br />
        <h2>2.How do we handle your Personal Information?</h2>
        <p>
          If you're a Talent and you apply for a role advertised on NXT, we
          handle your job application (including any Personal Information it
          contains) on behalf of the Employer you're applying to. In this
          process, we act as the data processor, while the Employer serves as
          the data controller.
          <br />
          <br />
          <strong>Talent:</strong> Candidate / Job Seeker / Applicant
          <br />
          <strong>Employer:</strong> Recruiter / Hiring Manager / Job Advertiser
          / Client / Company
          <br />
          <br />
          In all other cases in which we collect your Personal Information, we
          do so as a data controller of such information.
        </p>
        <br />
        <h2>3. What type of Personal Information do we gather?</h2>
        <p>
          We gather different types of information about you based on your
          interactions with us and your usage of our products and services.
          <br />
          <br />
          The main types of information (including Personal Information) we may
          collect include but are not limited to
          <br />
          <br />
          a. If you are a Registered User:
          <br />
          <br />
          Categories of Personal Data
          <br />
          <br />
          <ul>
            <li>General Data</li>
            <ul>
              <li>For all Registered Users:</li>
              <ul>
                <li>name, email address and password.</li>
              </ul>
              <li>
                For Talents setting up a Profile, you may elect to disclose to
                us the following information: country of residence (including
                Home Location, preferred work locations), resumes, and
                certifications, and any other Personal Information that a Talent
                may provide in their resume uploaded to our Platforms or
                otherwise included in the Talent’s Profile on our Platforms.
              </li>
              <li>
                For Talents applying for a job, depending on the Employer, we
                may collect additional Personal Information from you on behalf
                of the Employer, such as any Personal Information that you may
                include within a resume, cover letters, or in response to role
                requirement questions or selection criteria.
              </li>
            </ul>
            <br />
            <li>Contact Data</li>
            <ul>
              <li>
                Email address, mobile phone number, other contact number, home
                address, contact address, company details (if applicable).
              </li>
              <li>Google or Facebook logins.</li>
              <li>
                If you are an Employer: company name, business address, and
                billing address; name, email address, and phone number of a
                primary contact and name, email address, and phone number of any
                additional users of the Employer account.
              </li>
              <li>Other contact data that a User may share with us.</li>
            </ul>
            <br />
            <li>Background Data</li>
            <ul>
              <li>
                For Talents setting up a Profile, you may elect to disclose to
                us the following information: personal summary, work experience,
                educational background, language skills, other skills,
                professional qualifications, preferred job classifications, and
                locations.
              </li>
              <li>Usage and Device Data</li>
              <ul>
                <li>
                  Information on access and visits to a Platform, usage
                  patterns, Cookies, pages which you have visited, search and
                  click history on a Platform, personalization of content on a
                  Platform, IP Address, browser types and forms, referrer page,
                  date, and time stamps. This data is automatically collected
                  through the use of Cookies that are part of or placed on your
                  browser or your devices.
                </li>
              </ul>
            </ul>
            <br />
            <li>
              Third-Party Data (including Personal Information of Related
              Person)
            </li>
            <ul>
              <li>
                Name, email address, mobile phone number, other contact number,
                contact address, workplace, position, relationship with the
                Registered User (please ensure you have the consent of the
                relevant third party when disclosing their Personal Information
                to us).
              </li>
            </ul>
            <br />
            <li>Location Data</li>
            <ul>
              <li>
                Name, email address, mobile phone number, other contact number,
                contact address, workplace, position, relationship with the
                Registered User (please ensure you have the consent of the
                relevant third party when disclosing their Personal Information
                to us).
              </li>
            </ul>
          </ul>
          <br />
          b. If you are an Unregistered User:
          <br />
          <br />
          We collect data about your visit solely for analytics purposes,
          ensuring that no personal information is gathered during this process.
          <br />
          <br />
          c. Children
          <br />
          <br />
          The minimum working age in the Philippines is 15 years old. Therefore
          an applicant should be below 15 years old unless he works directly
          under the sole responsibility of his parents or guardian and his
          employment does not in any way interfere with his schooling.
          Applicants below 18 years old cannot apply for apply for any hazardous
          or deleterious jobs.
        </p>
        <br />
        <h2>4. In what manner do we gather Personal Information?</h2>
        <p>
          We collect your Personal Information in a few different ways.
          <br />
          <br />
          a. Directly from you
          <br />
          <br />
          We gather your Personal Information directly from you, including but
          not limited to instances when you register and create an account with
          us, utilize any of our products and services, or simply visit our
          Platforms.
          <br />
          <br />
          b. Indirectly
          <br />
          <br />
          We may also collect your Personal Information via third parties, for
          example:
          <br />
          <br />
          <ul>
            <li>
              When you register and sign in to your account with us via Google
              or Facebook.
            </li>
            <br />
            When you register or sign in using your Google or Facebook login
            details, you give Google, and Facebook permission to share your data
            with us, such as your name and email address.
            <li>
              When we work with third parties, for example, our Service
              Providers and Partners.
            </li>
            <li>
              If you are a Talent, we may collect additional Personal
              Information about you via Employers if this information is
              captured in our Platforms during a job application process.
            </li>
            <li>
              We may also collect your Personal Information from government, law
              enforcement and regulatory authorities where necessary and as
              required. This may include, but is not limited to, police,
              immigration, customs, and border security agencies.
            </li>
          </ul>
          d. From your online activities,
          <br />
          <br />
          We use Cookies to collect your Usage and Device Data when you use our
          and when you interact with our advertisements on third-party websites.
          <br />
          <br />
          <ul>
            <li>
              When you register and sign in to your account with us via Google
              or Facebook.
            </li>
            <br />
            When you register or sign in using your Google or Facebook login
            details, you give Google, and Facebook permission to share your data
            with us, such as your name and email address. If you already have an
            account registered with
            <br />
            the same email address, your existing account will be linked with
            the login details of your Linked Accounts.
            <li>
              When we work with third parties, for example, our Service
              Providers and Partners.
              <br />
              If you are a Talent, we may collect additional Personal
              Information about you via Employers if this information is
              captured in our Platforms during a job application process.
            </li>
            <li>
              We may also collect your Personal Information from government, law
              enforcement, and regulatory authorities where necessary and as
              required. This may include but is not limited to, police,
              immigration, customs, and other law enforcement agencies.
            </li>
          </ul>
        </p>
        <br />
        <h2>5. How do we safeguard your information?</h2>
        <p>
          Ensuring the security of your Personal Information is our top
          priority. We take diligent measures to protect it from misuse,
          interference, loss, and unauthorized access, modification, or
          disclosure. Your data is stored on secure servers within controlled
          facilities, adhering to industry standards. However, it's important to
          note that no internet transmission or electronic storage method is
          entirely foolproof. Third parties, including Employers, must adhere to
          relevant privacy laws. If you directly provide information to third
          parties, their privacy policies govern its usage, not ours.
          <br />
          <br />
          Your active involvement is crucial in maintaining the security of your
          information by safeguarding passwords and accounts. Please notify us
          immediately of any unauthorized account use or security breaches.
        </p>
        <br />
        <h2>6. What is the purpose of using your personal information?</h2>
        <p>
          We collect Personal Information from you which we have a legal basis
          to collect and is necessary for the purposes set out below:
          <br />
          <br />
          a. To provide Users with products and services.
          <br />
          <br />
          <ul>
            <li>
              We use your information to provide our products and services,
              including but not limited to assisting and enabling Talents in
              searching and applying for jobs; assisting Employers in recruiting
              for roles; processing transactions with you; verifying you as a
              Registered User, and for data profiling.
            </li>
            <ul>
              <li>Categories of Personal</li>
              <ul>
                <li>Information</li>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
                <li>Usage and Device Data</li>
                <li>Third-Party Data</li>
                <li>Location Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Contractual Obligations</li>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws)
                </li>
              </ul>
              <br />
            </ul>
            <li>
              To customize and improve products and services. We use your
              information to customize and improve our products and services.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
                <li>Usage and Device Data</li>
                <li>Location Data</li>
              </ul>
            </ul>
            <ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws)
                </li>
              </ul>
            </ul>
            <br />
            <li>
              To communicate with you about your use of our products and
              services and to provide you with technical and other support. We
              may contact you and provide information relevant to your use of
              the Platforms, products, or services and provide you with
              technical and other support. When we speak to you over the phone,
              we may record the calls for training, verification, and quality
              assurance purposes. We may also send Registered Users SMS
              communications to verify their identity and to keep accounts
              secure.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Usage and Device Data</li>
                <li>Location Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>Legal Obligation</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws)
                </li>
              </ul>
            </ul>
          </ul>
          b. Marketing and communication purposes.
          <ul>
            <li>
              To market and promote our products and services, we use your
              information to send you marketing and promotional materials for
              direct marketing purposes including information about our products
              and features, job placement and related career information,
              newsletters, survey requests, and events we think may be of
              interest to you.
            </li>
          </ul>
          We may communicate with you via several different channels (including
          email/online/social media).
          <ul>
            <li>Categories of Personal Information</li>
            <ul>
              <li>General Data</li>
              <li>Contact Data</li>
              <li>Background Data</li>
              <li>Usage and Device Data</li>
              <li>Location Data</li>
            </ul>
            <li>Legal Bases</li>
            <ul>
              <li>Legitimate Interest</li>
              <li>
                Notification and/or Consent (where required by applicable
                Privacy Laws)
              </li>
            </ul>
            <li>
              Usage and Device Data, we track and monitor your Usage and Device
              Data to personalize, manage, and target display advertisements
              that may be visible to you.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Usage and Device Data</li>
                <li>Location Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws).
                </li>
              </ul>
            </ul>
            <br />
            <li>To conduct market surveys, trials, and customer research.</li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Usage and Device Datat Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws).
                </li>
              </ul>
            </ul>
            <br />
            <li>
              To send you information regarding the products and services
              offered by businesses within Acquiro, Employers, and Partners.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws)
                </li>
              </ul>
            </ul>
          </ul>
          c. As part of Acquiro’s business operations.
          <ul>
            <li>
              For research and development of products and services within the
              Acquiro. We use your information to obtain insights about our
              Users and to help businesses within Acquiro to improve, tailor,
              and develop their products and services.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
                <li>Usage and Device Data</li>
                <li>Location Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws)
                </li>
              </ul>
            </ul>
            <li>
              To manage and administer our products and services, verify your
              identity, and to ensure the safety and security of our operations
            </li>
          </ul>
          We use your information to verify your identity (and any other
          information you may provide to us), your accounts and activities, to
          detect, prevent and respond to potential or actual security incidents
          and to monitor and protect against other malicious, deceptive,
          fraudulent or illegal activities.
          <ul>
            <li>Categories of Personal Information</li>
            <ul>
              <li>General Data</li>
              <li>Contact Data</li>
              <li>Background Data</li>
              <li>Usage and Device Data</li>
            </ul>
            <li>Legal Bases</li>
            <ul>
              <li>Legitimate Interest</li>
              <li>Legal Obligation</li>
              <li>
                Notification and/or Consent (where required by applicable
                Privacy Laws)
              </li>
            </ul>
            <br />
            <li>
              To protect rights of all entities within Acquiro, its assets,
              other rights and interests and personnel.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
                <li>Usage and Device Data</li>
                <li>Third Party Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legitimate Interest</li>
                <li>Legal Obligation</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws).
                </li>
              </ul>
            </ul>
          </ul>
          <br />
          <br />
          d. To meet our regulatory, compliance, and legal obligations.
          <ul>
            <li>
              This includes, without limitation, proceeding with a request
              relevant to your rights regarding our use and handling of your
              Personal Information and to respond to potential requests for
              information from different regulatory or law enforcement
              authorities.
            </li>
            <ul>
              <li>Categories of Personal Information</li>
              <ul>
                <li>General Data</li>
                <li>Contact Data</li>
                <li>Background Data</li>
                <li>Third-Party Data</li>
                <li>Usage and Device Data</li>
              </ul>
              <li>Legal Bases</li>
              <ul>
                <li>Legal Obligation</li>
                <li>
                  Notification and/or Consent (where required by applicable
                  Privacy Laws).
                </li>
              </ul>
            </ul>
          </ul>
        </p>
        <br />
        <h2>
          7. What happens if we're unable to obtain your personal information?
        </h2>
        <p>
          We need your Personal Information to provide products and services. If
          you don't want your data processed as outlined in our Privacy Policy,
          you may need to stop using our Platforms, products, or services. You
          have the option not to share your Personal Information, but without
          it, we may not be able to provide some or all of our products or
          services. Additionally, we require your information to meet legal
          obligations. Failure to provide certain Personal Information may
          result in breaches of legal obligations for both you and us.
        </p>
        <br />
        <h2>8. How do we use Cookies and Usage and Device Data?</h2>
        <p>
          a. IP Addresses
          <br />
          Our web servers collect your Internet Protocol (IP) address for
          diagnosing issues or providing support with our services.
          Additionally, we monitor the usage of our Platforms, including user
          locations.
          <br />
          <br />
          b. Cookies
          <br />
          We use Cookies to enhance Users' browsing experience by collecting
          preferences and click activity. We utilize this data to customize
          services, improve website performance, and analyze and develop new
          functionalities. Information may be shared within the Asticom Group,
          Service Providers, and Partners to enhance products and services.
          Cookies also tailor display advertisements on our Platforms and
          third-party sites to users' interests and behaviors. Partners and
          Service Providers may place Cookies to analyze users' interests and
          deliver relevant display ads. Users can limit targeted advertising by
          adjusting browser or mobile device settings, but this may restrict
          access to certain features and services.
          <br />
          <br />
          c. Third-Party Measurement
          <br />
          We employ third-party Service Providers to gauge traffic on our
          Platforms, using Cookies to collect the following information:
          <br />
          <ul>
            <li>Number of page views (or impressions)</li>
            <li>Number of unique visitors</li>
            <li>Average time spent by visitors on the Platforms</li>
            <li>Common entry and exit points</li>
          </ul>
          <br />
          For Unregistered Users, this data is aggregated and analyzed to
          understand Platform usage. Registered Users' information is connected
          across sessions and devices to provide an accurate user count. Media
          organizations and research firms may access this data for industry
          comparisons.
          <br />
          <br />
          Visit our Cookie Policy:{" "}
          <a href="/cookie-policy"> https://nxt.com.ph/cookie-policy</a>
        </p>
        <br />
        <h2>9. With whom do we share your Personal Information?</h2>
        <p>
          We observe data sharing if you consent to the same and we have data
          sharing agreements established to adequately safeguard data privacy
          and security and uphold of rights of all persons using this platform.
          <br />
          Further, we share your Personal Information in several different ways.
          <br />
          <br />
          a. Acquiro and Asticom Group
          <br />
          We may share your Personal Information with any entity or business
          within the Asticom Group for the purposes set out in this Privacy
          Policy.
          <br />
          <br />
          b. Employers
          <br />
          As a Talent, you agree to share your Personal Information with
          Employers when applying to job advertisements on our Platforms. By
          applying, you consent to sharing your Profile and application
          information with the relevant Employer.
          <br />
          <br />
          We cannot guarantee the security of your Personal Information stored
          by the Employer outside our Platforms. Direct inquiries about job
          applications to the Employer. Depending on your Profile visibility
          settings, we may share it with Employers via Talent Search. Your
          Profile and application details may be shared with Employers, even if
          you've set visibility preferences. Some Employers may request answers
          to specific questions, which we'll provide to them and may incorporate
          into your Profile for Talent Search.
          <br />
          <br />
          We'll notify you when your Profile is part of your application and
          specify which parts are sent to the Employer. Employers might share
          your information with third parties, and their actions are beyond our
          control. Contact Employers directly for access to or deletion of your
          Personal Information provided during job applications or via Talent
          Search. Employers are obligated to adhere to relevant privacy laws and
          regulations, including those governing the use and disclosure of
          Personal Information.
          <br />
          <br />
          c. Third parties
          <br />
          <br />
          Service Providers and Partners
          <br />
          We occasionally share your Personal Information with third parties,
          including Service Providers, contractors, and Partners, solely for the
          following purposes:
          <br />
          <br />
          <ul>
            <li>
              Assisting us in delivering the products and services we provide to
              you.
            </li>
            <li>
              Facilitating your access to the products or services they offer.
            </li>
            <li>Conducting surveys, trials, and customer research.</li>
          </ul>
          <br />
          Government authorities
          <br />
          We may share your Personal Information with authorized governmental
          authorities, law enforcement agencies, regulatory bodies, or competent
          officers as required by law, including compliance with court orders or
          other legal obligations.
          <br />
          <br />
          Mergers, acquisitions, or restructures
          <br />
          In mergers, acquisitions, or restructures, your Personal Information
          may be shared with the transferee, successor, or prospective purchaser
          of any entity within Acquiro and the Asticom Group.
          <br />
          <br />
          d. Links to third-party sites
          <br />
          We provide links to Third Party Websites on some Platforms, but we do
          not own or control them. We are not responsible for their privacy
          practices. Users should review the privacy policies of Third Party
          Websites before using them.
          <br />
          <br />
          e. Overseas
          <br />
          We may share your Personal Information outside of your Home Location
          under the following circumstances:
          <br />
          <br />
          <ol type="a">
            <li>
              Processing of personal information is done abroad when outsourced
              or subcontracted
            </li>
            <li>
              With any entity or business within the Asticom Group for the
              purposes outlined in this Privacy Policy.;
            </li>
            <li>
              With Employers outside your Home Location. Employers abroad may
              access your Personal Information through job applications or
              Talent Search. They can view your information when searching for
              Talents in your Home Location or preferred areas.
            </li>
            <li>When necessary comply with legal or regulatory obligations.</li>
          </ol>
        </p>
        <h2>10. Retention of your Personal Information</h2>
        <p>
          We retain your Personal Information as necessary to fulfill the
          purposes for which the data was obtained or for the establishment,
          exercise, or defense of legal claims or legitimate business purposes,
          or as provided by law or until you request its deletion. Upon your
          deletion request, we will take reasonable steps to remove your
          Personal Information, as required by applicable Privacy Laws.
          <br />
          If you've applied for a job on our Platforms, you must contact
          Employers directly to request deletion of your information from their
          systems.{" "}
          <a href="/data-deletion"> https://nxt.com.ph/data-deletion</a>
          <br />
          <br />
          We may retain your Personal Information beyond your deletion request
          if necessary for legitimate interests, legal obligations, dispute
          resolution, fraud prevention, testing, or backup purposes. Testing
          environments are secure and used solely for internal software testing,
          while backup systems are maintained to restore data in case of loss.
        </p>
        <h2>11. Personal Information Request</h2>
        <p>
          <strong>a. General</strong>
          <br />
          You have the option to make specific requests regarding the Personal
          Information we hold about you. Please note that certain requests apply
          only under specific circumstances, as explained further below.
          <br />
          <br />
          <ul>
            <li>
              Access: You have the right to ascertain whether we hold Personal
              Information about you and request access to such Personal
              Information. Any User can obtain access to their Personal
              Information by contacting us.
            </li>
            <li>
              Correction/Rectification: You have the right to correct any
              Personal Information held about you that is inaccurate or
              incomplete. If you are a Talent, you can change or update your
              Profile anytime by logging in to your account and accessing the
              ‘Profile’ section. For Employers, you may access your information
              anytime by logging in to your account and accessing the ‘Profile’
              section. Alternatively, any User can correct or update their
              Personal Information by contacting us.
            </li>
            <li>
              Deletion: Any User can contact us to request that we limit the
              Processing of or delete their Personal Information, or to withdraw
              their consent to our Processing of their Personal Information.
            </li>
          </ul>
          <br />
          When contacting us directly and making a request under this section,
          we may require identity verification before proceeding. Depending on
          circumstances and applicable Privacy Laws, fulfilling specific
          requests may not be feasible, but we will engage with you in such
          cases. Additionally, you can reach out to us to request access to any
          additional Personal Information rights under applicable Privacy Laws.
          We aim to respond promptly and in compliance with these laws.
          <br />
          <br />
          You are responsible for providing accurate and current information
          about yourself and others whose Personal Information you provide. For
          Talents, we hold the discretion to disable or delete your Profile,
          including stored resumes or cover letters, if the information is
          deemed untrue or stored for improper purposes.
        </p>
        <h2>12. Updates to this Privacy Policy</h2>
        <p>
          We may amend this Privacy Policy at any time, and it's advisable to
          review it periodically to stay informed of any updates. Depending on
          the nature of the changes, you may be notified in advance if required
          by applicable Privacy Laws.
          <br />
          <br />
          Acquiro Solutions and Tech Inc. retain the right to modify or withdraw
          any part of this Data Privacy Policy by posting updated content on our
          websites. Your ongoing use of our websites and services implies your
          consent to these changes. By continuing to utilize our Platforms,
          products, or services, you acknowledge and agree to any modifications
          made to this Privacy Policy.
        </p>
        <h2>13. Definitions of Terms</h2>
        <p>
          <strong>Employer:</strong> An individual or entity, including
          employers and recruiters, posting job ads on our Platforms or using
          our services to find potential Talents for job vacancies.
          <br />
          <br />
          <strong>Talent / Applicant:</strong> An individual or entity,
          including employers and recruiters, posting job ads on our Platforms
          or using our services to find potential Talents for job vacancies.
          <br />
          <br />
          <strong>Cookies:</strong> Files of information generated and sent to a
          web browser including tags, applets, scripts, and other similar
          technologies.
          <br />
          <br />
          <strong>Home Location:</strong> Your primary place of residence (if
          you are a Talent, your primary place of residence as selected in your
          Profile).
          <br />
          <br />
          <strong>Job Alerts:</strong> NXT Job Alerts (where applicable) where
          Job Alerts are email notifications of job advertisements that a Talent
          will receive based on a Talent’s saved searches.
          <br />
          <br />
          <strong>Linked Accounts:</strong> Google and Facebook accounts.
          <br />
          <br />
          <strong>Partners:</strong> Third parties who work with us to provide
          us or our Users with services, for example, marketing partners that we
          work with to advertise and market our products and services,
          recruitment software providers that integrate with us to provide our
          services to Employers or strategic business partners that we work with
          to provide ancillary services.
          <br />
          <br />
          <strong>Personal Information:</strong> Any information whether
          recorded in a material form or not, from which the identity of an
          individual is apparent or can be reasonably and directly ascertained
          by the entity holding the information, or when put together with other
          information would directly and certainly identify an individual.
          <br />
          <br />
          <strong>Platforms:</strong>
          Includes our online websites, mobile websites, applications, products,
          services, features and software.
          <br />
          <br />
          <strong>Privacy Law:</strong> Data Privacy Act of 2012 (R.A. 10173)
          and its implementing rules and regulations which regulate the
          collection, use, disclosure, storage of, and granting of access rights
          to Personal Information.
          <br />
          <br />
          <strong>Processing:</strong> Any operation or any set of operations
          performed upon personal information including, but not limited to, the
          collection, recording, organization, storage, updating or
          modification, retrieval, consultation, use, consolidation, blocking,
          erasure, or destruction of data.
          <br />
          <br />
          <strong>Profile:</strong> A personal profile created on our Platforms
          by a Talent, which contains Personal Information of that Talent.
          <br />
          <br />
          <strong>Registered User:</strong> Users who register their details
          with us via our Platforms to obtain or receive access to our products
          and/or services.
          <br />
          <br />
          <strong>Related Person:</strong> A person or persons who are related
          to the Registered Users such as referees and emergency contacts.
          <br />
          <br />
          Sensitive Information:
          <br />
          <p className="text-indent">Refers to Personal Information:</p>
          <br />
          <p className="text-indent">
            (1) About an individual's race, ethnic origin, marital status, age,
            color, and religious, philosophical, or political affiliations;
          </p>
          <br />
          <p className="text-indent">
            (2) About an individual's health, education, the genetic or sexual
            life of a person, or to any proceeding for any offense committed or
            alleged to have been committed by such person, the disposal of such
            proceedings, or the sentence of any court in such proceedings;
          </p>
          <br />
          <p className="text-indent">
            (3) Issued by government agencies peculiar to an individual which
            includes, but is not limited to, social security numbers, previous
            or current health records, licenses or its denials, suspension or
            revocation, and tax returns; and
          </p>
          <p className="text-indent">
            (4) Specifically established by executive order or an act of
            Congress to be kept classified.
          </p>
          <br />
          <strong>Service Providers:</strong> Third-party providers essential
          for delivering our products and services, including analytics, cloud
          storage, IT, marketing, advertising, market research, accounting,
          legal, compliance, event management, electronic payment services, and
          other professional advisors.
          <br />
          <br />
          <strong>Shared Profile:</strong> A feature that allows Talents to
          share a summary version of their Profile via a shareable link.
          <br />
          <br />
          <strong>Talent Search:</strong> Our talent database allows Employers
          to search, refine, and connect with Talents who maintain a Profile and
          have set their Profile visibility settings to enable their Profile to
          be searchable by Employers.
          <br />
          <br />
          <strong>Talent Search Connection Method:</strong> A method by which an
          Employer using Talent Search can contact a Talent by sending a Talent
          a copy of a job opportunity for which the Employer is recruiting or by
          sending a Talent a customized email about a job opportunity.
          <br />
          <br />
          <strong>Third-Party Websites:</strong> These are online platforms not
          owned or operated by the referenced entity, offering distinct
          services, content, or products.
          <br />
          <br />
          <strong>Unregistered User:</strong>
          <br />
          <br />
          <p className="text-indent">
            (1) Use and visit our Platforms but do not register their details
            with us via the Platforms; or
          </p>
          <br />
          <p className="text-indent">
            (2) Engage with us via other channels external to our Platforms, for
            example, by phone or in person, by direct job application process,
            as an investor, or by participating in any other events, programs,
            or promotions.
          </p>
          <br />
          <strong>Users, you, your:</strong> Talents, Employers, Registered
          Users, Unregistered Users, and other users, as relevant, who engage
          with us or use our products, services, or information, unless
          specified otherwise.
        </p>
        <br />
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          wish to reach out to us regarding the use or handling of your Personal
          Information, please don't hesitate to contact us. For comments or
          issues concerning our data privacy practices, you may reach our Data
          Protection Officer at privacy@acquiro.com.ph, or send your
          correspondence to 15F ASTICOM, W5th Building, 5th Ave, Bonifacio
          Global City, Taguig, Philippines.
        </p>
        <br />
        <br />
        <br />
        <div className="d-flex">
          <img
            className="mx-auto img-fluid policy--img"
            src={require("../../../../assets/images/LandingPage/PrivacyPolicy/lock.png")}
          />
        </div>
      </div>
    </div>
  );
};

export default Text;
