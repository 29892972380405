import { useEffect, useState } from "react";
import { useAuthToken, useRoleKey } from "../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../Config/config";
import { handleAxiosError } from "../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

export const useAtsTalent = () => {
  const token = useAuthToken();
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [talentList, setTalentList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getTalentList = async () => {
    setIsLoading(true);

    try {
      const res = await axios.get(
        `${Config.ats}/api/ats/candidate-list/talent`,
        {
          headers,
        }
      );

      setTalentList(res.data.result?.data);
      console.log(res);
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [talentInfo, setTalentInfo] = useState(null);

  const getSpecificTalent = async (talentInfo) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${Config.ats}/api/ats/candidate-list/${talentInfo.talentId}`,
        {
          headers,
          params: {
            jobId: talentInfo.jobId,
          },
        }
      );

      setTalentInfo(res.data.result);
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [note, setNote] = useState(null);
  const getNote = async (talentInfo) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${Config.ats}/api/ats/candidate-list/notes`,
        {
          headers,
          params: {
            talentId: talentInfo.talentId,
            jobId: talentInfo.jobId,
          },
        }
      );

      setNote(res.data.result);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [candidateListError, setCandidateListError] = useState(null);
  const [candidateList, setCandidateList] = useState(null);
  const getCandidateList = async (jobId) => {
    // console.log(clientData);
    try {
      const res = await axios.get(`${Config.ats}/api/ats/candidate-list`, {
        headers,
        params: {
          jobId: jobId,
        },
        // withCredentials: true,
      });

      console.log(res.data.result.data);
      setCandidateList(res.data.result.data);
    } catch (error) {
      setCandidateListError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getTalentList();
  }, [token]);

  return {
    getTalentList,
    talentList,
    isLoading,
    getSpecificTalent,
    talentInfo,
    getNote,
    note,
    getCandidateList,
    candidateList,
    candidateListError,
  };
};
