import { useNavigate } from "react-router";
import CustomModal from "../../../components/UI/Modal";
import { useDispatch } from "react-redux";
import { LogoutAndClearProfile } from "../../../services/Actions/logoutAction";
import { errorMessages } from "../Message/errorMessage";

export const CustomModalError = ({ isOpen, errorNumber, errCode, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(LogoutAndClearProfile());
    localStorage.clear();
    sessionStorage.clear();
    console.log("Logout ...");
    navigate("/");
    onClose();
  };

  const getModalMessage = () => {
    console.log(errorMessages, errorNumber, errCode);
    console.log(errorMessages[errorNumber]);
    if (errorNumber === "401" || errorNumber === 401) {
      return errorMessages[errCode] || "You have been automatically logged out";
    }
    if (errorNumber === "403" || errorNumber === 403) {
      return "You'll be logged out due to inactivity or because you've signed in from another device";
    }
    if (errorNumber === "500" || errorNumber === 500) {
      return "Something went wrong";
    }
    if (errorNumber === "404" || errorNumber === 404) {
      return "API Not found";
    }
    return "";
  };

  return (
    <div>
      <CustomModal
        show={isOpen}
        onClick={handleLogout}
        buttonName="Logout"
        modalType="Error"
        modalMessage={getModalMessage()} // Call the function to get the message
      />
    </div>
  );
};
