import React, { useState } from "react";
import DropdownFilter from "../../../Dropdown/DropdownFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import MultiRange from "../../../Range/MultiRange";

const SalaryRange = ({ salaryRangeFilter }) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => setShowFilter(!showFilter);

  const handleFilter = (event) => {
    console.log(event);
    salaryRangeFilter(event);
  };
  return (
    <div className="filter--container">
      <div className="filter--container--title">
        <label>Salary Range</label>
        <FontAwesomeIcon
          icon={showFilter ? faChevronDown : faChevronRight}
          className="filter--container--icon"
          onClick={handleShowFilter}
        />
      </div>
      {showFilter && <MultiRange filter={handleFilter} />}
    </div>
  );
};

export default SalaryRange;
