import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import "../../src/assets/scss/Client/Page/ManageJob.scss";
import { Container } from "reactstrap";
import Banner from "../components/Banner";
import Sidebar from "../layouts/Sidebar";
import { TabTitle } from "../Utilities/FunctionTitle";
import FormManageJob from "../components/FormClient/FormManageJob";
import ClientAnalytics from "../Utilities/Analytics/ClientAnalytics";
import CheckIsSubscribe from "../Utilities/Reusable/Validation/Validator/CheckIsSubscribe.ats";
import { localDevBase } from "../services/API/localDevBase";
import { useAuthToken } from "../Utilities/Auth/Token";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import Config from "../Config/config";
import { useSelector } from "react-redux";
import Footer from "./LandingPage/Components/Footer";
const JobPosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState("");
  const devBase = localDevBase();
  const token = useAuthToken();
  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withATS
  );

  // console.log(feature);
  useEffect(() => {
    checkIfSubscribe();
  }, [feature]);

  const checkIfSubscribe = async () => {
    if (feature) {
      navigate("/admin/ats/managing-job");
    } else {
      navigate("/client/manage-job");
    }
  };

  TabTitle("NXT | Manage Job");
  return (
    <div className="background-imagePost-managejob">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <Banner />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>

        <div className="company-profile-container">
          <FormManageJob />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default JobPosting;
