import React from "react";
import Company from "./SpecificFilter/Company";
import JobLevel from "./SpecificFilter/JobLevel";
import JobSpecialization from "./SpecificFilter/JobSpecialization";
import Location from "./SpecificFilter/Location";
import WorkType from "./SpecificFilter/WorkType";
import SalaryRange from "./SpecificFilter/SalaryRange";

const FilterJob = ({
  jsFilter,
  companyFilter,
  locationFilter,
  salaryRangeFilter,
  workTypeFilter,
  jobLevelFilter,
}) => {
  const handleJsFilter = (event) => {
    console.log(event);
    jsFilter(event);
  };

  const handleCompanyFilter = (event) => {
    console.log(event);
    companyFilter(event);
  };

  const handleLocationFilter = (event) => {
    console.log(event);
    locationFilter(event);
  };

  const handleSalaryRangeFilter = (event) => {
    console.log(event);
    salaryRangeFilter(event);
  };

  const handleWorkTypeFilter = (event) => {
    console.log(event);
    workTypeFilter(event);
  };

  const handleJobLevel = (event) => {
    console.log(event);
    jobLevelFilter(event);
  };

  return (
    <div className="filter__job">
      {/* <JobSpecialization jsFilter={handleJsFilter} />
      <JobLevel jobLevelFilter={handleJobLevel} /> */}
      <Company companyFilter={handleCompanyFilter} />
      <SalaryRange salaryRangeFilter={handleSalaryRangeFilter} />
      <Location locationFilter={handleLocationFilter} />
      <WorkType workTypeFilter={handleWorkTypeFilter} />
    </div>
  );
};

export default FilterJob;
