import React, { useEffect, useState } from "react";
import Text from "./Content/Text";
import Footer from "../../LandingPage/Components/Footer";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import Image from "../../../components/UI/Image";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import ScrollButton from "../../../components/UI/Button/ScrollToTop";

const PrivacyPolicy = () => {
  TabTitle("NXT | Privacy Policy");

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  return (
    <div>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require("../../../assets/images/Background/Outside/privacyPolicy.png")}
      />
      <CustomContainer className="containerWhiteBg">
        <Text />
      </CustomContainer>
      <Footer />
      {showScrollButton && <ScrollButton />}
    </div>
  );
};

export default PrivacyPolicy;
