import React, { useState } from "react";
import TextPerChoice from "./TextPerChoice";
import "../../../assets/scss/Page/Outer/FAQ/_faq.scss";

const Choices = ({ link, handleLinkClick, selectedLink }) => {
  const [clickedLink, setClickedLink] = useState(null);
  const handleClick = (value) => {
    handleLinkClick(value);
    setClickedLink(value.key);
  };

  return (
    <div className="faqLink">
      <div className="links">
        {link.map((value) => (
          <p
            key={value.key}
            className={`link ${clickedLink === value.key ? "clicked" : ""}`}
            onClick={() => handleClick(value)}
          >
            {value.key} {value.value}
          </p>
        ))}
      </div>
      <div className="messageContainer">
        <TextPerChoice selectedLink={selectedLink} />
      </div>
    </div>
  );
};

export default Choices;
