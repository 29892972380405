import React from "react";
import LandingNavbar from "../LandingPage/Components/LandingNavbar";
import Banner from "./Banner";
import Content from "./Content";
import Footer from "../LandingPage/Components/Footer";
import Apply from "./Apply";
import { TabTitle } from "../../Utilities/FunctionTitle";

const About = () => {
  TabTitle("NXT | About");
  return (
    <>
      <LandingNavbar />
      <Banner />
      <Content />
      <Apply />
      <Footer />
    </>
  );
};

export default About;
