import React from "react";
import LandingNavbar from "../../../LandingPage/Components/LandingNavbar";
import Footer from "../../../LandingPage/Components/Footer";
import { TabTitle } from "../../../../Utilities/FunctionTitle";
import { Container } from "reactstrap";
import BannerJobListDetails from "./Content/BannerJobListDetails";
import JobListSidebar from "./Content/JobListSidebar";
import JobListContent from "./Content/JobListContent";
import JobTitle from "../../../../Utilities/Analytics/JobTitle";

const JobDetails = () => {
  TabTitle("NXT | Job List Details");

  return (
    <>
      <div className="background-imagePost">
        <div className="header-fixed">
          <LandingNavbar />
        </div>
        <div className="jobposting-banner">
          <BannerJobListDetails />
        </div>

        <Container className="jobposting-body">
          <div>
            <div className="sidebar-container">
              <JobListSidebar />
            </div>
          </div>

          <div className="company-profile-container">
            <JobListContent />
          </div>
        </Container>

        <Footer />
      </div>
    </>
  );
};

export default JobDetails;
