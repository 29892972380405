import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
// import Footer from "../../layouts/Footer";
import Banner from "../../components/Banner";
import ApplicantList from "../../components/LayoutClient/ApplicantList/ApplicantList";
import FilterList from "../../components/LayoutClient/ApplicantList/FilterList";
import BasicInformation from "../../components/LayoutClient/ApplicantList/ViewResume/BasicInformation";
import KeySkills from "../../components/LayoutClient/ApplicantList/ViewResume/KeySkills";
import WorkHistory from "../../components/LayoutClient/ApplicantList/ViewResume/WorkHistory";
import DocumentsCert from "../../components/LayoutClient/ApplicantList/ViewResume/DocumentsCert";
import DocumentsCV from "../../components/LayoutClient/ApplicantList/ViewResume/DocumentsCV";
import SidebarViewResume from "../../layouts/Client/SidebarViewResume";
// import FooterClient from "../../layouts/FooterClient";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchApplicant,
  fetchApplicantData,
} from "../../components/LayoutClient/ApplicantList/ViewResume/fetchData";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";
import { Parser } from "html-to-react";
import { useClient } from "../../hook/useClient";
import CustomModal from "../../components/UI/Modal";
import { Message } from "../../Utilities/Reusable/Message/Message";

const ViewResume = () => {
  const [modalInfo, setModalInfo] = useState({ show: false });
  const navigate = useNavigate();
  TabTitle("NXT | Talent List");
  const { viewJobDetails, viewJobData, errorViewJobData } = useClient();
  const location = useLocation();
  const jobId = location.state?.jobId;
  const ref_job_id = location.state.ref_job_id;
  console.log(jobId, ref_job_id);
  const talentId = location.state?.talentId;

  const token = useAuthToken();
  const [applicantData, setApplicantData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchApplicant(talentId, token);
        if (data) {
          await viewJobDetails(jobId, ref_job_id);
        }
        setApplicantData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [talentId, ref_job_id, token]);

  const workHistoryData = (applicantData && applicantData[0]?.data) || [];
  const certificateData = (applicantData && applicantData[1]?.data) || [];
  const applicantInfo = (applicantData && applicantData[2]?.data) || {};

  const previewJob = (id, jobId) => {
    console.log(jobId);
    const obj = {
      id: jobId,
    };

    localStorage.setItem("previewJob", JSON.stringify(obj));

    navigate(`/client/view-job-details/${id}`);
  };

  useEffect(() => {
    if (errorViewJobData) {
      setModalInfo({
        show: true,
        type: "Error",
        message: Message.client.viewJob[400],
        func: "jobNotOpen",
        bName: "Go back to Talent List",
      });
    }
  }, [errorViewJobData]);
  return (
    <div className="background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <Banner />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <SidebarViewResume data={applicantInfo} jobDetails={viewJobData} />
            <div className="sidebar-va-container mt-3 w-100">
              <div className="sva-header">
                <h5>{viewJobData.jobTitle}</h5>
                <h6>{viewJobData.companyName}</h6>
              </div>
              <p>{Parser().parse(viewJobData.jobOverview)}</p>
              <div className="view__details">
                <p onClick={() => previewJob(ref_job_id, jobId)}>
                  View Details...
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="company-profile-container">
          {isLoading ? (
            <List />
          ) : (
            <>
              <BasicInformation data={applicantInfo} />
              <KeySkills data={applicantInfo} />
              <WorkHistory data={workHistoryData} />
              <DocumentsCV data={applicantInfo} />
              <DocumentsCert data={certificateData[0]} />
            </>
          )}
        </div>
      </Container>

      <Footer />
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        onClick={() => {
          if (modalInfo.func === "jobNotOpen") {
            navigate("/client/talent-list");
          }
        }}
      />
    </div>
  );
};

export default ViewResume;
