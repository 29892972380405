import React from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../assets/scss/Client/Layout/BannerBreadcrumb.scss";
import { useNavigate } from "react-router-dom";
const BannerJobList = () => {
  const navigate = useNavigate();

  const clickProfile = () => {
    navigate("/talent/profile");
  };

  const clickJobList = () => {
    navigate("/talent/job-list");
  };

  // const clickJobListDetails = () => {
  //   navigate("/talentjoblistdetails");
  // };
  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>Job List</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickProfile} tag="a">
                Profile
              </BreadcrumbItem>
              <BreadcrumbItem onClick={clickJobList} tag="a">
                Job List
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerJobList;
