import React, { useState } from "react";
import DropdownFilter from "../../../Dropdown/DropdownFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import workArrangement from "../../../../../Data/company/workArrangement.json";
const WorkType = ({ workTypeFilter }) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => setShowFilter(!showFilter);

  const handleFilter = (event) => {
    console.log(event);
    workTypeFilter(event);
  };

  return (
    <div className="filter--container">
      <div className="filter--container--title">
        <label>Work Type</label>
        <FontAwesomeIcon
          icon={showFilter ? faChevronDown : faChevronRight}
          className="filter--container--icon"
          onClick={handleShowFilter}
        />
      </div>
      {showFilter && (
        <DropdownFilter filter={handleFilter} data={workArrangement} />
      )}
    </div>
  );
};

export default WorkType;
