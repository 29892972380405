import React, { useState } from "react";
import cities from "../../../assets/global/cities.json";

const DropdownFilter = ({ data, filter, sampleValue }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    filter(event.target.value);
  };

  return (
    <div>
      <select value={selectedValue} onChange={handleChange}>
        <option value="">Select Option</option>
        {data &&
          data.map((val, key) => (
            <option key={key} value={val}>
              {val}
            </option>
          ))}
      </select>
    </div>
  );
};

export default DropdownFilter;
