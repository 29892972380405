import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomNavigate } from "../../../../../Utilities/Navigation/CustomNavigation";
import {
  loginStart,
  loginSuccess,
  loginFailure,
} from "../../../../../services/Actions/userSlice";
import Config from "../../../../../Config/config";
import axios from "axios";
import { useForm } from "react-hook-form";
import { z, ZodType } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import CustomModal from "../../../../../components/UI/Modal";
import CustomButton from "../../../../../components/UI/CustomButton";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import CustomPassword from "../../../../../components/Form/CustomPassword";
import BottomButton from "../../../../../components/UI/Button/BottomButton";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";
import { getBasicInformation } from "../../../../../services/Actions/basicInformationSlice";
import { getProfile } from "../../../../../services/Actions/profileSlice";
import { getCompanyProfile } from "../../../../../services/Actions/companyProfileSlice";
import {
  setfeatures,
  updatefeatures,
} from "../../../../../services/Actions/featuresAction";
import { getCurrentUser } from "../../../../../services/Actions/currentUser";
import { useRole } from "../../../../../hook/role/useRole";
import { getCurrentLoginUser } from "../../../../../services/Actions/getAllUser";

const Step1 = () => {
  const { role, isLoading, getRoleKey, errorRole } = useRole();
  const { goToForgotPassword } = CustomNavigate();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState([]);
  // const { show, message, color, icon, type, showModal } = useCustomModal();
  const schema = z.object({
    email: z.string().nonempty("Email is required").email(),
    password: z.string().nonempty("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const submitData = async (data) => {
    setLoginError("");
    setIsBtnLoading(true);
    dispatch(loginStart());

    try {
      // const response = await axios.post(`${Config.nxtApi}/api/user/login`, {
      //   ...data,
      // });

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/login",
        { ...data },
        null,
        null
      );

      console.log(response);
      if (response.status === 200 && response.data.userType === "client") {
        console.log(response);

        fetchClientProfile(response.data.token, response.data);
        // setUserInfo(response.data);
        // setRole(response.data.token);
      } else if (
        response.status === 200 &&
        (response.data.userType === "applicant" ||
          response.data.userType === "talent")
      ) {
        fetchTalentProfile(response.data.token, response.data);
        // setUserInfo(response.data);
      }

      // dispatch(loginSuccess(response.data));
    } catch (error) {
      setIsBtnLoading(false);
      dispatch(loginFailure());
      console.log(error);
      console.log(error.data?.message?.message);
      if (
        error.data?.message?.message === "Invalid Password" ||
        error.data?.message?.message === "Invalid Username or Password" ||
        error.data?.message?.message === "Invalid Username or password" ||
        error.data?.message?.message === "Invalid username or password"
      ) {
        const remainingAttempts = error.data?.message.remainingInvalidAttempts;
        setLoginError(
          `Invalid Username or Password \n Remaining Attempts: ${remainingAttempts}`
        );
      } else if (error.data?.message.message === "Account Locked") {
        const remainingSeconds = error.data?.message.remainingSeconds;
        setLoginError(
          `${error.data?.message.message} \n Remaining Seconds: ${remainingSeconds}`
        );
      } else {
        setLoginError(error.data?.message.message || error.data?.message);
      }
    }
  };

  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const getPubId = localStorage.getItem("pub_id");

  const handleModalClose = () => {
    console.log(userInfo);

    setModalShow(false);
    dispatch(loginSuccess(userInfo));
  };

  // const token = useAuthToken();
  // const devBase = localDevBase();
  // console.log(token);
  const devBase = localDevBase();
  // const setRole = async (token) => {
  //   console.log(userInfo);
  //   try {
  //     const response = await axios.get(`${devBase}/api/ats/user/setRole`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       // withCredentials: true,
  //     });
  //     console.log(response);
  //     readCookie(token);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const readCookie = async (token) => {
  //   try {
  //     const response = await axios.get(`${devBase}/api/ats/user/cookie`, {
  //       headers: {
  //         // "Content-Type": "application/json;charset=UTF-8",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       // withCredentials: true,
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // Get Talent Profile
  const fetchTalentProfile = async (token, data) => {
    try {
      const response = await RequestMethod(
        "get",
        "nxtApi",
        "/api/talent/myprofile",
        null,
        token
      );
      setIsBtnLoading(false);
      setModalShow(true);
      setUserInfo(data);
      if (response.status === 200) {
        dispatch(getProfile(response.data.data[0]));
      }
      console.log("Data:", response.data.data[0]);
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  //

  const [clientId, setClientId] = useState(null);
  const fetchClientProfile = async (token, data) => {
    try {
      const result = await RequestMethod(
        "get",
        "nxtApi",
        "/api/client/myprofile",
        null,
        token
      );

      setClientId(result.data.data[0].clientId);
      if (result.status === 200) {
        dispatch(getCurrentUser(result.data.data[0]));
        dispatch(getCompanyProfile(result.data.data[0]));
        dispatch(setfeatures(result.data.subscription));
        await getRoleKey(token);
        setIsBtnLoading(false);
        setModalShow(true);
        setUserInfo(data);

        // dispatch(
        //   setfeatures({
        //     isSubscribed: true,
        //     withATS: true,
        //     withAI: false,
        //   })
        // );
      }

      // console.log("Data:", result.data.data[0]);
      // dispatch(getProfile(result.data.data[0]));
      // dispatch(loginSuccess(data));
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  useEffect(() => {
    if (role && role.length > 0) {
      const selectedCompany = role.find(
        (company) => company.ownerId === clientId
      );
      if (selectedCompany) {
        dispatch(
          getCurrentLoginUser({
            company: { ...selectedCompany },
            features: {
              isSubscribed: selectedCompany.isSubscribed,
              withATS: selectedCompany.withATS,
              withAI: selectedCompany.withAI,
              subscription: {
                daysOfValidity: selectedCompany.daysOfValidity,
                endDate: selectedCompany.endDate,
                jobLimit: selectedCompany.jobLimit,
              },
            },
          })
        );

        console.log("im dispatched", selectedCompany);
        dispatch(getCurrentUser(selectedCompany));
        dispatch(
          updatefeatures({
            isSubscribed: selectedCompany.isSubscribed,
            withATS: selectedCompany.withATS,
            withAI: selectedCompany.withAI,
            subscription: {
              daysOfValidity: selectedCompany.daysOfValidity,
              endDate: selectedCompany.endDate,
              jobLimit: selectedCompany.jobLimit,
            },
          })
        );
      }
    }
  }, [role]);

  return (
    <div className="loginForm">
      <BottomButton />
      <div className="loginTitle">
        <h1>Sign In / Create Account</h1>
        <p>Login using social networks</p>
      </div>
      <form onSubmit={handleSubmit(submitData)}>
        <CustomInput
          errors={errors}
          label="Email Address"
          type="email"
          {...register("email")}
          placeholder="Email Address"
          className="formInputGroup"
        />
        <CustomPassword
          errors={errors}
          label="Password"
          {...register("password")}
          placeholder="Password"
          className="formInputGroup"
        />

        {loginError && <CustomMessage label={loginError} />}

        <div className="forgotPassButton">
          <p onClick={goToForgotPassword}>Forgot Password</p>
          <CustomButton
            name="Log In"
            type="submit"
            className="customLRButton"
            isLoading={isBtnLoading}
          />
        </div>
      </form>
      <CustomModal
        buttonName="Ok"
        show={modalShow}
        onHide={() => handleModalClose()}
        color="green"
        icon={faCheck}
        modalMessage="You are successfully logged in"
        onClick={() => handleModalClose()}
        modalType="Success"
      />
    </div>
  );
};

export default Step1;
