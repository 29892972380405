import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../../assets/scss/Components/UI/_navbar.scss";
import Image from "../../components/UI/Image";
function LandingNav() {
  // const { goToLandingPage, goToAboutPage, goToContactUs, goToFAQ } =
  //   CustomNavigate();

  return (
    <>
      <Navbar
        expand="md"
        className="bg-body-tertiary"
        style={{ background: "#ffffff" }}
      >
        <Navbar.Brand className="nxtLogo">
          <Image
            height={54}
            width={150}
            src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <Image
                height={54}
                width={150}
                src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3 landingNavMenu">
              <Nav.Link>Home</Nav.Link>
              <Nav.Link>About NXT</Nav.Link>

              <NavDropdown
                title="Help"
                id={`offcanvasNavbarDropdown-expand-md`}
              >
                <NavDropdown.Item>FAQ</NavDropdown.Item>
                <NavDropdown.Item>Contact Us</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>Career Advise</Nav.Link>
              <Nav.Link>
                <Image
                  height={20}
                  width={80}
                  src={require("../../assets/images/Logo/Brand/gigster.png")}
                />
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}

export default LandingNav;
