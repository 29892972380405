import React, { useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import Stepper from "react-stepper-horizontal";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import CustomCheckbox from "../../../../components/Form/CustomCheckbox";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../../Config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthToken, useRoleKey } from "../../../../Utilities/Auth/Token";

const SubsCheckOut = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const clientInfo = useSelector(
    (state) => state.reducer.companyProfile.currentCompanyProfile
  );
  console.log("client info", clientInfo);

  const { subs, checkSubsDaysLeft } = location.state || {};
  console.log(subs, checkSubsDaysLeft);

  const getSubscriptionValue = (subs) => {
    switch (subs) {
      case "premium+":
        return "2,000";
      case "elite":
        return "3,000";
      default:
        return "2,000";
    }
  };

  const getSubscriptionProrated = () => {
    const subscriptionRates = {
      "premium+": 2000,
      elite: 3000,
    };

    if (subs in subscriptionRates) {
      const baseAmount = subscriptionRates[subs];
      const proratedAmount = Math.round((baseAmount / 30) * checkSubsDaysLeft);
      const vat = Math.round(proratedAmount * 0.12);
      const totalWithVAT = proratedAmount + vat;

      return {
        proratedAmount: proratedAmount.toLocaleString(),
        vat: vat.toLocaleString(),
        totalWithVAT: totalWithVAT.toLocaleString(),
      };
    }
  };

  const subscriptionDetails = getSubscriptionProrated();

  const handleGoBack = () => {
    window.history.back(); // Navigates back in the browser's history
  };

  const handlePlaceHolderActivate = async () => {
    console.log(clientInfo);
    const data = {
      customerId: clientInfo.clientId,
      emailInfo: {
        clientName: `${
          clientInfo.firstName +
          " " +
          (clientInfo.middleName ? clientInfo.middleName : "") +
          " " +
          clientInfo.lastName
        }`,
        companyName: clientInfo.companyName,
        contactNumber: clientInfo.contactNumber,
        clientEmail: clientInfo.email,
      },
      items: [
        {
          planId:
            subs === "premium+"
              ? "nxt-multi0002"
              : subs === "elite"
              ? "nxt-multi0003"
              : "",
          // count: ["ai", "ats+ai", "ats"].includes(selectedPlan.planName)
          //   ? 1
          //   : Number(count),
          count: 1,
        },
      ],
    };

    try {
      const response = await axios.post(
        `${Config.subs}/api/v1/subscription/order`,
        data,
        { headers }
      );

      console.log(response);
      navigate("/admin/ats/subscription/subscribed");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="admin">
        <div className="sidebar">
          <AdminSidebar />
        </div>
        <div className="navbarContent">
          <Navbar layout="admin" />
          <div className="pageContainer subsciption-container border-0 shadow-none">
            <WhiteContainer>
              <div className="mb-5 stepper">
                <Stepper
                  activeColor="#8046FA"
                  defaultColor="#EFF0F6"
                  completeColor="#8046FA"
                  defaultBarColor="#EFF0F6"
                  steps={[
                    { title: "Select Add On" },
                    { title: "Configure Add On" },
                    { title: "Checkout" },
                  ]}
                  activeStep={2}
                />
              </div>
              <Row>
                <Col>
                  <div className="checkout-page bg-white p-4">
                    <h2 className="border-bottom pb-4 mb-4">Order Summary</h2>
                    <div className="checkout-premium__border p-3">
                      <Row className="">
                        <Col md={4}>
                          <h2>Multi-user</h2>
                          {/* <div className="subs-features mt-3">Multi-user</div> */}
                        </Col>
                        <Col md={4}>
                          <div className="">
                            <div className="d-flex">
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                className="me-2"
                                disabled
                              >
                                <i class="fa-solid fa-trash"></i> Remove
                              </Button>{" "}
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                disabled
                              >
                                <i class="fa-solid fa-ticket"></i> Apply Coupon
                              </Button>{" "}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex justify-content-end">
                            ₱{getSubscriptionValue(subs)}{" "}
                            {/* {subsData[0].activeSubscription.current !==
                            "freemium"
                              ? (proratedPrice * billing).toLocaleString()
                              : (
                                  selectedPlan.amount * billing
                                ).toLocaleString()} */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Col md={12}>
                      {/* <div className="d-flex mt-4">
                        <div className="ms-auto text-end fw-bold">
                          <p className="mb-0">
                            SubTotal (
                            {selectedPlan.planName === "ats+ai"
                              ? "3"
                              : ["ai", "ats"].includes(selectedPlan.planName)
                              ? "2"
                              : "1"}{" "}
                            1 Item):<span className="me-3"></span> ₱2,000
                            {subsData[0].activeSubscription.current !==
                            "freemium"
                              ? (proratedPrice * billing).toLocaleString()
                              : (
                                  selectedPlan.amount * billing
                                ).toLocaleString()}
                          </p>
                          <p>
                            VAT (12%):<span className="me-4"></span>&nbsp; ₱240
                            {" ₱ " + vatValue.toLocaleString()}
                          </p>
                        </div>
                      </div> */}
                      <Table
                        style={{ width: "40%" }}
                        className="mt-4 ms-auto text-end fw-bold"
                      >
                        {/* <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead> */}
                        <tr>
                          <td colspan="3">SubTotal (1 Item):</td>
                          <td>
                            ₱
                            {subscriptionDetails ? (
                              <>{getSubscriptionValue(subs)}</>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">Prorated:</td>
                          <td>
                            ₱
                            {subscriptionDetails ? (
                              <>{subscriptionDetails.proratedAmount}</>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">VAT (12%):</td>
                          <td>
                            ₱
                            {subscriptionDetails ? (
                              <>{subscriptionDetails.vat}</>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </Table>
                    </Col>
                  </div>
                  <Col md={12}>
                    <div className="checkout-page__subtotal d-flex p-4">
                      <div className="ms-auto text-end fw-bold">
                        <p className="mb-0">
                          Total:
                          <span className="checkout-text__violet ms-5 h5">
                            {/* {" "}
                            {typeOfSub === "freemTrialSubs"
                              ? "FREE"
                              : `₱ ${formattedTotalAmount}`} */}
                            ₱
                            {subscriptionDetails ? (
                              <>{subscriptionDetails.totalWithVAT}</>
                            ) : (
                              ""
                            )}
                            {/* {subs === "premium+"
                              ? "240"
                              : subs === "elite"
                              ? "360"
                              : "240"} */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="checkout-page checkout-page__mod bg-white p-4 mt-5">
                      <h2 className="border-bottom pb-4 mb-4">
                        Choose Your Payment Method
                      </h2>
                      <Form>
                        <div className="d-flex checkout-page__pay">
                          <Form.Check
                            className="me-2"
                            type="radio"
                            aria-label="radio 1"
                            checked
                          />
                          Pay with Bank Deposit
                        </div>
                        <div className="text-center">
                          <small>
                            <CustomCheckbox
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                              link="/terms-and-condition"
                              linkName="Terms and Conditions"
                              label="Accept"
                              // labelAfter="and Terms"
                              linkClassName="noUnderline"
                              className="mb-5"
                            />
                          </small>
                          <div className="d-flex justify-content-center gap-3">
                            <Button
                              className="btn btn-light py-3 px-5 border-0"
                              onClick={handleGoBack}
                            >
                              BACK
                            </Button>
                            <Button
                              className="btn btn-green py-3 px-5 border-0 me-2"
                              disabled={!isChecked || isLoading}
                              onClick={handlePlaceHolderActivate}
                            >
                              {/* {isLoading ? <Loader /> : "PLACE ORDER"} */}
                              Continue
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Col>
              </Row>
            </WhiteContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubsCheckOut;
