import React, { useEffect, useState } from "react";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import { Image } from "react-bootstrap";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import Footer from "../../LandingPage/Components/Footer";
import ScrollButton from "../../../components/UI/Button/ScrollToTop";

const CookiePolicy = () => {
  TabTitle("NXT | Cookie Policy");
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  return (
    <div>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require("../../../assets/images/Background/Outside/cookiePolicy.png")}
      />
      <CustomContainer className="containerWhiteBg">
        <div className="container">
          <div className="privacyPolicy">
            <h1>Cookie Policy</h1>
            <br />
            <p>
              Acquiro Solutions and Tech Inc. prioritizes your privacy and is
              committed to complying with the data privacy regulations set forth
              by the Philippine Data Privacy Commission (PDPC). This Cookie
              Policy outlines how we use cookies and adhere to PDPC guidelines.
            </p>
            <br />
            <h2>What Are Cookies?</h2>
            <p>
              Cookies are small text files that your web browser stores on your
              computer's hard drive. They have various purposes, including
              improving website performance, storing preferences, and analyzing
              user interactions. It's important to note that cookies do not
              personally identify you but are associated with your device.
            </p>
            <br />
            <h3>Types of Cookies We Use</h3>
            <br />
            <h2>1. First-Party Cookies:</h2>
            <p>
              We employ first-party cookies to enhance your experience on our
              websites. These cookies help us store your preferences, record
              session information, analyze your interactions, and evaluate the
              effectiveness of our email communications, website features, and
              advertisements.
            </p>
            <br />
            <h2>2. Third-Party Cookies:</h2>
            <p>
              In partnership with third-party advertising companies, we may
              allow the placement of their cookies on your device while you
              browse our websites. These cookies are mainly used for delivering
              advertisements and may collect data about your online activities.
              The use of third-party cookies is subject to the privacy policies
              of the respective third-party companies.
            </p>
            <br />
            <h2>Cookie Declaration</h2>
            <p>
              By using our website, you grant consent to the use of cookies as
              outlined in this Cookie Policy. Cookies enable us to personalize
              content and ads, provide social media features, and analyze
              website traffic. We may also share information about your website
              usage with our social media, advertising, and analytics partners.
              These partners may combine this data with other information you've
              provided or that they've collected from your use of their
              services.
            </p>
            <br />
            <p>
              Please note that the utilization of cookies by our partners and
              service providers is not governed by our privacy policy. You
              maintain the ability to modify or withdraw your consent for
              cookies at any time. To manage your preferences, please refer to
              the "Cookie Declaration" section on our website.
            </p>
            <br />
            <h2>Cookie Consent</h2>
            <p>
              In accordance with applicable laws, we may store cookies on your
              device only if they are strictly necessary for our site's
              operation. For all other types of cookies, we require your
              explicit consent. You can provide or withdraw your consent through
              your settings in the "Cookie Declaration" section on our website.
            </p>
            <br />
            <h2>Types of Cookies Used on Our Website</h2>
            <p>
              Essential Cookies: These cookies are vital for the proper
              functioning of our website. They enable core functionalities that
              are necessary for a seamless and secure user experience. Essential
              cookies are responsible for tasks such as user authentication,
              maintaining session information, and facilitating essential
              features like job searches and account management.
            </p>
            <br />
            <h2>How to Manage Cookies</h2>
            <p>
              You can manage and control cookies by adjusting your browser
              settings. Please consult your browser's help documentation for
              instructions on how to do this. Keep in mind that disabling or
              blocking certain cookies may impact your ability to use some
              features of our website.
            </p>
            <br />
            <h2>Changes to This Cookie Policy</h2>
            <p>
              This Cookie Policy may undergo updates periodically to reflect
              changes in our practices, as well as for operational, legal, or
              regulatory reasons. We encourage you to review this policy
              periodically to stay informed about our cookie usage practices.
              The date of the last update can be found at the top of this page.
            </p>
            <br />
            <h2>Data Protection Officer</h2>
            <p>
              If you have any comments or issues regarding our data privacy
              practices, you may contact our Data Protection Officer at this
              email address: privacy@acquiro.com.ph, or send your comments or
              issues in writing at 15F ASTICOM, W5th Building, 5th Ave.
              Bonifacio Global City, Taguig, Philippines.
            </p>
          </div>
        </div>
      </CustomContainer>
      <Footer />
      {showScrollButton && <ScrollButton />}
    </div>
  );
};

export default CookiePolicy;
