import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resume: null,
  loading: false,
  error: false,
};

const basicInformationSlice = createSlice({
  name: "basicInformation",
  initialState,
  reducers: {
    startBasicInformation: (state) => {
      state.loading = true;
    },
    updateBasicInformation: (state, action) => {
      state.loading = false;
      state.resume = {
        ...state.resume,
        ...action.payload,
      };
    },
    getBasicInformation: (state, action) => {
      state.loading = false;
      state.error = false;
      state.resume = action.payload;
    },
    failBasicInformation: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearBasicInformation: (state) => {
      state.resume = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  startBasicInformation,
  updateBasicInformation,
  getBasicInformation,
  failBasicInformation,
  clearBasicInformation,
} = basicInformationSlice.actions;

export default basicInformationSlice.reducer;
