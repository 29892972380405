import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/Icon/iconBuilding.png";
import "../../assets/scss/layout/Container/_imageTextContainer.scss";
import React, { useState } from "react";
import AllInformation from "../../pages/Admin/ATS/AllInformation/AllInformation";
import CustomOffcanvas from "../../components/UI/Offcanvas/CustomOffcanvas";
import moment from "moment";

const ImageTextContainer = ({ data, hideView, hiredCandidates }) => {
  const [isOCShowing, setisOCShowing] = useState(false);

  const clickName = (id) => {
    console.log("id", id);
    setisOCShowing(true);
  };

  const handleClose = () => setisOCShowing(false);

  return (
    <div className="hired_container_ats">
      {hiredCandidates?.map((val, key) => {
        return (
          <div className="hired_container" key={key}>
            <div className="hired_container_information">
              <div className="hired_img_section">
                <img
                  crossOrigin="anonymous"
                  // src={`${
                  //   Config.nxtApi
                  // }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
                  //   list.clientId
                  // )}&thumbNail=yes`}
                  src="sample.img"
                  alt="logo"
                  onError={(e) => {
                    e.target.src = logo;
                    e.target.classList.add("errorImg");
                  }}
                />
              </div>
              <div className={`hired_txt_section`}>
                <h1>{val.firstName + " " + val.lastName} </h1>
                <div className="hired_txt_section_date">
                  {!hideView && (
                    <FontAwesomeIcon icon={faClock} className="hired_icon" />
                  )}
                  <p>{moment(val.dateApplied).format("MM-DD-YYYY")}</p>
                </div>
                {hideView && (
                  <p className="hired_txt_sentence mt-3">{val.sentence}</p>
                )}
              </div>
            </div>
            {!hideView && (
              <div className="hired_view_section">
                <FontAwesomeIcon
                  icon={faEye}
                  className="icon"
                  onClick={() => clickName(val.name)}
                />
              </div>
            )}
          </div>
        );
      })}

      <CustomOffcanvas
        show={isOCShowing}
        handleClose={handleClose}
        placement="end"
        title="View Candidate"
      >
        <AllInformation />
      </CustomOffcanvas>
    </div>
  );
};

export default ImageTextContainer;
