import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import TitleLine from "../../../../components/UI/Title/TitleLine";
import CustomAtsTable from "../../../../components/UI/Table/ATS/CustomAtsTable";
import InputSearch from "../../../../components/UI/Search/InputSearch";
import "../../../../assets/scss/Page/Admin/ATS/GlobalAtsStyle/_atsList.scss";
import ATS from "../MainPage";
import { hiredListTC } from "../../../../Utilities/Reusable/TableHead/AtsTHead";
import atsList from "../../../../Data/ATS/atsList.json";
import axios from "axios";
import Config from "../../../../Config/config";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import { useHired } from "../../../../hook/ats/useHired";

const HiredList = () => {
  const { hiredList } = useHired();
  const token = useAuthToken();
  // const [hiredList, setHiredList] = useState([]);
  const [nameOrder, setNameOrder] = useState(""); // "" for default, "asc", or "desc"
  const [dateApplied, setDateApplied] = useState("");
  const [keyword, setKeyword] = useState("");

  const handleNameOrderChange = (e) => {
    setNameOrder(e.target.value);
  };

  const handleDateAppliedChange = (e) => {
    setDateApplied(e.target.value);
  };

  const handleSearch = (keyword) => {
    setKeyword(keyword);
  };

  // useEffect(() => {
  //   getTalentList();
  // }, []);

  // const getTalentList = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${Config.ats}/api/ats/candidate-list/hired`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     // Uncoment setTalentList if api is ok
  //     setHiredList(response.data.result?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <WhiteContainer>
            <TitleLine
              title="Active Talents"
              className="ats_title_color"
            ></TitleLine>
            <div className="ats_filter_container">
              <div className="ats_filter_section">
                <h5>Name</h5>
                <select onChange={handleNameOrderChange}>
                  <option value="">Default</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <div className="ats_filter_section">
                <h5>Date Applied</h5>
                <input
                  type="date"
                  value={dateApplied}
                  onChange={handleDateAppliedChange}
                />
              </div>
            </div>
          </WhiteContainer>
          <WhiteContainer className="ats_filter_table_cont">
            <div className="keyword_right_container mb-3">
              <InputSearch
                placeholder="Enter Keyword"
                onSearch={handleSearch}
              />
            </div>
            <div className="ats_filter_table">
              <CustomAtsTable
                columns={hiredListTC}
                // data={atsList}
                // Uncomment data if api is ok
                data={hiredList}
                nameOrder={nameOrder}
                dateApplied={dateApplied}
                keyword={keyword}
              />
            </div>
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default HiredList;
