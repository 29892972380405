import React from "react";
import "../../assets/scss/TrackingSystem.scss";
import LandingNavbar from "../LandingPage/Components/LandingNavbar";

import Footer from "../LandingPage/Components/Footer";
import Hero from "./Components/Hero";
import Sticker from "./Components/Sticker";
import UnlockWorld from "./Components/UnlockWorld";
import ManageJobFeature from "./Components/ManageJobFeature";
import SourcingSelection from "./Components/SourcingSelection";
import Empowering from "./Components/Empowering";

const TrackingSystem = () => {
  return (
    <>
      <LandingNavbar />
      <Hero />
      <Sticker />
      <UnlockWorld />
      <ManageJobFeature />
      <SourcingSelection />
      <Empowering />
      <Footer />
    </>
  );
};

export default TrackingSystem;
