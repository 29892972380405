import React, { useEffect, useState } from "react";

import axios from "axios";
import Config from "../../../../Config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faUsers,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/scss/Page/Admin/Dashboard/_cardWithIcon.scss";
import { useAuthToken, useRoleKey } from "../../../../Utilities/Auth/Token";

const CardWithIcon = () => {
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [stats, setStats] = useState({
    totalJobsPosted: 0,
    totalTalentRegistered: 0,
    totalCompanyRegisterd: 0,
    totalRegistered: 0,
  });

  useEffect(() => {
    getStats();
  }, []);

  const getStats = async () => {
    try {
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`,
      // };

      const response = await axios.get(
        `${Config.admin}/api/v1/admin/dashboard/stats`,
        { headers }
      );

      setStats(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="customCardWithIcon">
      <div className="cardWithIcon">
        <div className="cardIconContainer totalJobPosted">
          <FontAwesomeIcon icon={faDollarSign} className="icon" />
        </div>
        <div className="cardTextContainer">
          <h1>{stats.totalJobsPosted}</h1>
          <p>Total Jobs Posted</p>
        </div>
      </div>
      <div className="cardWithIcon">
        <div className="cardIconContainer talentRegistered">
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
        <div className="cardTextContainer">
          <h1>{stats.totalTalentRegistered}</h1>
          <p>Total Talent Registered</p>
        </div>
      </div>
      <div className="cardWithIcon">
        <div className="cardIconContainer companyRegistered">
          <FontAwesomeIcon icon={faBuilding} className="icon" />
        </div>
        <div className="cardTextContainer">
          <h1>{stats.totalCompanyRegisterd}</h1>
          <p>Total Company Registered</p>
        </div>
      </div>
      <div className="cardWithIcon">
        <div className="cardIconContainer totalRegistered">
          {/* You can choose an appropriate icon for totalRegistered */}
          <FontAwesomeIcon icon={faUsers} className="icon" />
        </div>
        <div className="cardTextContainer">
          <h1>{stats.totalRegistered}</h1>
          <p>Total Registered</p>
        </div>
      </div>
    </div>
  );
};

export default CardWithIcon;
