import { useEffect, useState } from "react";
import { useAuthToken, useRoleKey } from "../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../Config/config";

export const useAtsJob = () => {
  const token = useAuthToken();
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [jobList, setJobList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getJobApi = async () => {
    setIsLoading(true);

    try {
      const res = await axios.get(`${Config.ats}/api/ats/manage-job`, {
        headers,
      });

      setJobList(res?.data?.result);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [jobCreated, setJobCreated] = useState(null);
  const createJobApi = async (payload) => {
    setIsLoading(true);
    try {
      const res = await axios.put(`${Config.ats}/api/ats/manage-job`, payload, {
        headers,
      });
      console.log("API Response:", res);
      setJobCreated(res);

      return res;
    } catch (error) {
      console.error("Error creating job:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [prevUpdatedAt, setPrevUpdatedAt] = useState(null);
  const [specificJob, setSpecificJob] = useState(null);
  const [jobError, setJobError] = useState(null);
  const getSpecificJob = async (jobId) => {
    try {
      const res = await axios.get(
        `${Config.ats}/api/ats/manage-job/job/${jobId}`,
        {
          headers,
        }
      );

      console.log(res.data.job.data[0]);
      setSpecificJob(res.data.job.data[0]);
      setPrevUpdatedAt(res.data.job.prevUpdatedAt);
    } catch (error) {
      console.log(error);
      setJobError(error);
    }
  };

  // useEffect(() => {
  //   getJobApi();
  // }, [token, code]);

  return {
    getJobApi,
    jobList,
    isLoading,
    createJobApi,
    jobCreated,
    getSpecificJob,
    specificJob,
    jobError,
    prevUpdatedAt,
  };
};
