import React from "react";

const ApplyForJob = () => {
  return (
    <div>
      <ol>
        <li>Search for your preferred job</li>
        <li>
          Job summary will be shown once filtered jobs are loaded. The following
          job summary details are available:
        </li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Job title</li>
          <li>Hiring company</li>
          <li>Job location</li>
          <li>Work set up (Hybrid, Return to Site, Job posted date)</li>
        </ol>
        <li>Click on the job to see full job details</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Hiring company</li>
          <li>Location </li>
          <li>Salary</li>
          <li>Job Type </li>
          <li>Job qualification</li>
          <li>Required skill sets </li>
          <li>Required dialect</li>
        </ol>
      </ol>
    </div>
  );
};

export default ApplyForJob;
