import axios from "axios";
import Config from "../../Config/config";

const SpecificSyspar = async (code) => {
  try {
    const response = await axios.get(`${Config.nxtApi}/api/syspar/${code}`);
    const data = response.data;
    console.log(data.value);
    return data.value;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default SpecificSyspar;
