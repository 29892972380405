import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../assets/scss/Client/Layout/BannerBreadcrumb.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthToken } from "../../Utilities/Auth/Token";
import Config from "../../Config/config";
const BannerJobListDetails = ({ jobDetails }) => {
  const navigate = useNavigate();
  const clickProfile = () => {
    navigate("/talent/profile");
  };

  const clickJobList = () => {
    navigate("/talent/job-list");
  };

  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>{jobDetails?.jobDetails?.jobTitle}</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickProfile} tag="a">
                Profile
              </BreadcrumbItem>
              <BreadcrumbItem onClick={clickJobList} tag="a">
                Job List
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerJobListDetails;
