import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../../../assets/scss/Talent/Layout/JobList/JobListContent.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SuccessModal from "../../../SuccessModal";
import WarningModal from "../../../WarningModal";
import SuccessIcon from "../../../../assets/images/SuccessIcon.png";
import QuestionIcon from "../../../../assets/images/QuestionIcon.png";
import WarningIcon from "../../../../assets/images/WarningIcon.png";
import QuestionModal from "../../../QuestionModal";
import { Parser } from "html-to-react";
import Loader from "../../../../layouts/loader/Loader";
import ShareBtn from "../../../UI/SharePop/ShareBtn";
import he from "he";
import SocialMediaShare from "../../../UI/SharePop/SocialMediaShare";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import Config from "../../../../Config/config";
import CustomModal from "../../../UI/Modal";
import { useDispatch } from "react-redux";
import moment from "moment";

const JobListContent = ({ jobDetails }) => {
  const token = useAuthToken();
  const navigate = useNavigate();
  let { id } = useParams();
  console.log(jobDetails);

  const [benefit, setbenefit] = useState(jobDetails.jobDetails.benefits);
  const [dialect, setdialect] = useState(jobDetails.jobDetails.dialect);
  const [skill, setskill] = useState(jobDetails.jobDetails.skillSet);

  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalLoading, setIsSuccessModalLoading] = useState(false);
  const [jobId, setJobId] = useState("");
  const [warningDetails, setWarningDetails] = useState(false);
  const [warningResume, setWarningResume] = useState(false);
  const [isApplied, setIsApplied] = useState(jobDetails?.isApplied?.isApplied); // New state variable

  console.log(jobDetails.jobDetails.id);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const isAppliedResponse = await axios.get(
  //         `${Config.nxtApi}/api/talent/jobs/${jobDetails.id}/is-applied`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       setIsApplied(isAppliedResponse.data.isApplied);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchData();
  // }, [id, successModalOpen]);

  const clickApplyJob = async () => {
    console.log(jobDetails);
    setIsLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        jobId: jobDetails.jobDetails.id,
      };

      // console.log(payload);

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/applyjob`,
        payload,
        {
          headers,
        }
      );
      //
      setIsApplied(true);
      console.log("this is payload", payload);
      toggleSuccessModal();
      setIsLoading(false);
      // navigate("/talent/job-list-details/ai-questionare/:id", {
      //   state: payload,
      // });
    } catch (err) {
      console.log(err);
      if (
        err.response.data.message ===
        "profile_missing_field: Please complete your profile before attempting to apply for a job."
      ) {
        toggleWarningDetails();
      } else if (err.response.data.message === "JobApplicationService") {
        toggleWarningResume();
      } else if (err.response.data.message === "Already applied") {
        toggleWarningModal();
      } else {
      }

      setIsLoading(false);
    }
  };

  const getProceedQuestion = async () => {
    setIsSuccessModalLoading(true);
    try {
      const response = await axios.get(`${Config.ats}/api/ats/ai/screening`, {
        params: {
          jobId: jobDetails.jobDetails.id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("done");
      console.log("show question", response.data.result.data);
      console.log(response);
      if (response.status === 200 && response.data.result.data.length > 0) {
        console.log("should navigate");
        navigate("/talent/job-list-details/ai-questionare/:id", {
          state: {
            listQuestion: response?.data.result.data,
            jobId: jobDetails.jobDetails.id,
          },
        });
      } else {
        navigate("/talent/job-list");
      }
      // navigate("/talent/job-list-details/ai-questionare", {
      //   state: response?.data?.result?.data,
      // });
      setIsSuccessModalLoading(false);
    } catch (error) {
      console.log(error);
      setIsSuccessModalLoading(false);
    }
  };

  const handleConfirm = () => {
    navigate("/talent/profile");
  };
  const handleResume = () => {
    navigate("/talent/my-resume");
    window.location.reload();
  };
  const handleSuccess = () => {
    getProceedQuestion();

    // setsuccessModalOpen(false);
    // window.location.reload();
  };

  const toggleSuccessModal = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const toggleWarningModal = () => {
    setwarningModalOpen(!warningModalOpen);
  };
  const toggleWarningDetails = () => {
    setWarningDetails(!warningDetails);
  };
  const toggleWarningResume = () => {
    setWarningResume(!warningResume);
  };
  function isNxtClient() {
    return window.location.hostname === "www.nxt.com.ph";
  }

  function isDevClient() {
    return window.location.hostname === "dev.nxt.com.ph";
  }

  function isStagClient() {
    return window.location.hostname === "stage.nxt.com.ph";
  }

  const baseUrl = isNxtClient()
    ? "https://www.nxt.com.ph"
    : isDevClient()
    ? "https://dev.nxt.com.ph"
    : isStagClient()
    ? "https://stage.nxt.com.ph"
    : "http://localhost:3000";

  // const decodedJobDescription = he.decode(jobDetails?.jobDescription);
  // const textWithoutTags = decodedJobDescription
  //   .toString()
  //   .replace(/<[^>]*>/g, "");

  const endDate = moment(jobDetails?.jobDetails?.postingDate?.endDate);
  const isPast = endDate.isBefore(moment(), "day");
  console.log(isPast);
  return (
    <div className="joblistcontent-container">
      <div className="header">
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ marginTop: "10px" }}>
              <h1>{jobDetails.jobDetails.jobTitle} </h1>
            </div>
          </div>
          {jobDetails.jobDetails.jobPostStatus === "open" && (
            <div className="socialMediaContainer">
              <SocialMediaShare
                url={`${baseUrl}/share/jobs/jd/${jobDetails.jobDetails.job_id}`}
                subject={jobDetails.jobDetails.jobTitle}
              />
            </div>
          )}
          <h3>{jobDetails.jobDetails.companyName}</h3>
          <h3>
            {jobDetails.jobDetails.officeAddress} |{" "}
            {jobDetails.jobDetails.jobType} |{" "}
            {jobDetails.jobDetails.workArrangement}{" "}
          </h3>
          <h3>JOB ID : {jobDetails.jobDetails.job_id}</h3>
        </div>
        <div>
          <Button
            onClick={clickApplyJob}
            disabled={isApplied || isPast}
            className={isPast ? "applyIsPassJobBtn" : ""}
          >
            {isLoading ? (
              <Loader />
            ) : isApplied && !isPast ? (
              "Applied"
            ) : isPast ? (
              "This job is no longer available"
            ) : (
              "Apply"
            )}
          </Button>
        </div>
      </div>
      <div className="body">
        <div className="job-details">
          <h1>Job Description</h1>
          <hr />
          <p>{Parser().parse(jobDetails.jobDetails.jobDescription)} </p>
        </div>
        <div className="job-details">
          <h1>Job Overview</h1>
          <hr />
          <p>{Parser().parse(jobDetails.jobDetails.jobOverview)} </p>
        </div>
        <div className="job-details">
          <h1>Job Qualification</h1>
          <hr />
          <p>{Parser().parse(jobDetails.jobDetails.qualification)} </p>
        </div>
        <div className="job-details">
          <h1>Benefits</h1>
          <hr />
          {benefit &&
            benefit.map((benefit, index) => <li key={index}>{benefit}</li>)}
        </div>
        <div className="job-details">
          <h1>Required Skill Set</h1>
          <hr />
          {skill && skill.map((skill, index) => <li key={index}>{skill}</li>)}
        </div>
        <div className="job-details">
          <h1>Dialect</h1>
          <hr />
          {dialect &&
            dialect.map((dialect, index) => <li key={index}>{dialect}</li>)}
        </div>
      </div>

      <SuccessModal
        isLoading={isSuccessModalLoading}
        modalOpen={successModalOpen}
        toggleModal={toggleSuccessModal}
        iconImage={SuccessIcon}
        modalLabel="Success"
        modalSublabel="You successfully applied to this job."
        confirmButtonText="Ok"
        onConfirm={handleSuccess}
      />

      <WarningModal
        modalOpen={warningModalOpen}
        toggleModal={toggleWarningModal}
        iconImage={QuestionIcon}
        modalLabel="Warning"
        modalSublabel="Already Applied"
        confirmButtonText="Confirm"
        onConfirm={toggleWarningModal}
      />

      <WarningModal
        modalOpen={warningDetails}
        toggleModal={toggleWarningDetails}
        iconImage={WarningIcon}
        modalLabel="Warning"
        modalSublabel="Please complete your profile before attempting to apply for a job."
        confirmButtonText="Confirm"
        onConfirm={handleConfirm}
      />
      <WarningModal
        modalOpen={warningResume}
        toggleModal={toggleWarningResume}
        iconImage={WarningIcon}
        modalLabel="Warning"
        modalSublabel="Please complete your documents (CV) before attempting to apply for a job."
        confirmButtonText="Confirm"
        onConfirm={handleResume}
      />

      {/* <CustomModal
        show={true}
        buttonName="OK"
        modalType="error"
        modalMessage="Something went wrong"
        onClick={() => handleLogout()}
      /> */}
    </div>
  );
};

export default JobListContent;
