import React, { useEffect, useState } from "react";
import "../../../assets/scss/Talent/Layout/MyResume/DocumentsCV.scss";
import {
  Alert,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import DocumentsCVModal from "../../FormTalent/MyResume/DocumentCVModal";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import QuestionModal from "../../QuestionModal";
import Loader from "../../../layouts/loader/Loader";
import { json } from "react-router-dom";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { useSelector } from "react-redux";

const DocumentsCV = () => {
  const talentInfo = useSelector(
    (state) => state.reducer.profile?.currentProfile
  );
  const token = useAuthToken();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const apiKey = process.env.REACT_APP_TALENT_API;
  const endpointKey = process.env.REACT_APP_KEYRESUME_API;

  const [cvData, setCVData] = useState([talentInfo]);
  const [cvName, setCvName] = useState(talentInfo.resume);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const endpointLink = `${endpointKey}/?fileName=${cvName}`;
  const [modalOpen, setModalOpen] = useState(false);
  //State variables for modal
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const handleManageClick = () => {
    window.location.reload();
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  // const getCV = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${Config.nxtApi}/api/talent/myprofile`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     setCVData(response.data.data);
  //     setCvName(response.data.data[0].resume);
  //   } catch (error) {
  //     console.log("Error ", error);
  //   }
  // };

  const [showDocumentsCVModal, setShowDocumentsCVModal] = useState("");
  const clickDocumentsCVBtn = () => {
    setShowDocumentsCVModal(true);
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const downloadCV = () => {
    const downloadLink = endpointLink;
    window.open(downloadLink);
  };
  const viewImage = () => {
    const downloadLink = endpointLink;
    window.open(downloadLink);
  };

  return (
    <div className="documents-cv-talent">
      <div className="header-container">
        <h6>DOCUMENTS (CV)</h6>
        <Button
          onClick={() => {
            clickDocumentsCVBtn();
          }}
        >
          {(cvData.length > 0 && !cvData[0].hasOwnProperty("resume")) ||
          (cvData.length > 0 &&
            cvData[0].hasOwnProperty("resume") &&
            cvData[0].resume === "N/A")
            ? "Add Resume"
            : "Update Resume"}
        </Button>
      </div>
      <hr className="line-seperator" />
      <div className="cv-list">
        {cvData.map((item) => {
          return (
            <React.Fragment key={item.applicantId}>
              <>
                {item.resume === undefined || item.resume === "N/A" ? (
                  <>
                    <Alert color="secondary">No available CV</Alert>
                  </>
                ) : (
                  <>
                    <Label>{item.resume}</Label>
                    <div className="remove-icon-container">
                      {/* <div className="remove-work-container" onClick={toggleSuccess}>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="remove-work"
                        />{" "}
                        <span>Remove</span>
                      </div> */}
                      {item.resume.endsWith(".docx") ? (
                        <Button onClick={downloadCV}>
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="download-attachment-btn"
                          />{" "}
                          Click to Download
                        </Button>
                      ) : (
                        <>
                          {item.resume.endsWith(".png") ||
                          item.resume.endsWith(".jpg") ? (
                            <Button onClick={viewImage}>
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="view-attachment-btn"
                              />{" "}
                              Click to view image
                            </Button>
                          ) : (
                            <Button onClick={toggleModal}>
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="view-attachment-btn"
                              />{" "}
                              View Attachment
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            </React.Fragment>
          );
        })}
      </div>
      {/* <QuestionModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/QuestionIcon.png")}
        modalLabel="Warning!"
        modalSublabel="Are you sure you want to delete this document?"
        confirmButtonText={isLoading ? <Loader /> : "Delete"}
        onConfirm={removeCV}
        onCancel={toggleSuccess}
      /> */}
      <DocumentsCVModal
        isOpen={showDocumentsCVModal}
        close={() => setShowDocumentsCVModal(false)}
      />
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{cvName}</ModalHeader>{" "}
        <ModalBody style={{ background: "#E0E0E0" }}>
          <div
            className="document-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={endpointLink}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <div className="page-wrapper">
                <Page
                  pageNumber={currentPage}
                  width={window.innerWidth * 0.41} // Adjust the width as desired
                />
              </div>
            </Document>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <div className="page-navigation">
            <Button
              onClick={previousPage}
              disabled={currentPage <= 1}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginRight: 10,
                fontFamily: "Lato",
              }}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              onClick={nextPage}
              disabled={currentPage >= numPages}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginLeft: 10,
                fontFamily: "Lato",
              }}
            >
              Next
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentsCV;
