import React, { useEffect, useState } from "react";
import "../../../../assets/scss/Page/Admin/Dashboard/_jobPostChart.scss";
import axios from "axios";
import Config from "../../../../Config/config";
import { handleAxiosError } from "../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
const JobPostChart = () => {
  const token = localStorage.getItem("refreshToken");

  const [jobStats, setJobStats] = useState({
    totalJobPosting: 0,
    newJobPost: 0,
    jobPostingApplied: 0,
    closedPosting: 0,
  });

  useEffect(() => {
    getJobStats();
  }, []);

  const getJobStats = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${Config.admin}/api/v1/admin/dashboard/job/stats`,
        {
          headers: headers,
        }
      );

      setJobStats(response.data.data);
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    }
  };
  return (
    <div className="jobPostChart">
      <div className="title">
        <h1>Job Postings</h1>
      </div>
      <div className="total">
        <h1>Total Job Posting</h1>
        <h2>{jobStats.totalJobPosting}</h2>
      </div>
      <div className="otherJobStats">
        <div>
          <p>New Job Post</p>
          <h4>{jobStats.newJobPost}</h4>
        </div>
        <div>
          <p>Job Posting Applied </p>
          <h4>{jobStats.jobPostingApplied}</h4>
        </div>
        <div>
          <p>Closed Posting</p>
          <h4>{jobStats.closedPosting}</h4>
        </div>
      </div>
    </div>
  );
};

export default JobPostChart;
