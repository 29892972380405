export const atsColumn = {
  "New Applicant": ["New Applicant", "Screening", "Failed", "Withdraw"],
  Screening: [
    "Screening",
    "Exams",
    "Initial Interview",
    "Final Interview",
    "Failed",
    "Withdraw",
  ],
  Exams: [
    "Exams",
    "Initial Interview",
    "Final Interview",
    "Failed",
    "Withdraw",
  ],
  "Initial Interview": [
    "Initial Interview",
    "Exams",
    "Final Interview",
    "Failed",
    "Withdraw",
  ],
  "Final Interview": ["Final Interview", "Offer Letter", "Failed", "Withdraw"],
  "Offer Letter": ["Offer Letter", "BI", "Failed", "Withdraw"],
  BI: ["BI", "PEME", "Failed", "Withdraw"],
  PEME: ["PEME", "Pre Employment Reqs", "Failed", "Withdraw"],
  "Employment Contract": ["Employment Contract", "Failed", "Withdraw", "Hired"],
  "Pre Employment Reqs": [
    "Pre Employment Reqs",
    "Employment Contract",
    "Failed",
    "Withdraw",
  ],
  Hired: ["Hired", "Offer Letter", "Failed", "Withdraw"],
};

export const columnNameMappings = {
  "Background Investigation": "BI",
  "Medical Examination": "PEME",
  "Pre Employment Requirements": "Pre Employment Reqs",
};

export const columnNameMappingsRev = {
  BI: "Background Investigation",
  PEME: "Medical Examination",
  "Pre Employment Reqs": "Pre Employment Requirements",
};
