import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isSubscribe: null,
  loading: false,
  error: false,
};

const subscribe = createSlice({
  name: "isSubscribe",
  initialState,
  reducers: {
    updateSubscribe: (state, action) => {
      state.loading = false;
      state.isSubscribe = {
        ...state.isSubscribe,
        ...action.payload,
      };
    },
    setSubscribe: (state, action) => {
      state.loading = false;
      state.error = false;
      state.isSubscribe = action.payload;
    },
    failSubscribe: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearSubscribe: (state) => {
      state.isSubscribe = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  loadProfile,
  updateSubscribe,
  setSubscribe,
  failSubscribe,
  clearSubscribe,
} = subscribe.actions;

export default subscribe.reducer;
