import React from "react";
import "../../../../assets/scss/Client/Layout/ApplicantList/ViewResume/KeySkills.scss";
import { Label, Alert } from "reactstrap";

const KeySkills = (data) => {
  const skillSet = data?.data?.skillSet || [];

  const renderSkillsByLevel = (level) => {
    const filteredSkills = skillSet.filter((skill) => skill.level === level);

    if (filteredSkills.length === 0) {
      return (
        <Alert color="secondary" className="skills-not-available">
          No available skills for {level} level
        </Alert>
      );
    }

    return filteredSkills.map((skill, index) => (
      <div className="keyskill-container" key={index}>
        <p>
          {skill.skill} - {skill.experience}
        </p>
      </div>
    ));
  };

  return (
    <div className="key-skills-container">
      <h6>KEY SKILLS</h6>
      <hr className="line-seperator" />

      <div className="select-group">
        <Label for="beginner">Beginner</Label>
        <div className="keyskills-list">{renderSkillsByLevel("Beginner")}</div>
      </div>

      <div className="select-group">
        <Label for="intermediate">Intermediate</Label>
        <div className="keyskills-list">
          {renderSkillsByLevel("Intermediate")}
        </div>
      </div>

      <div className="select-group">
        <Label for="advanced">Advanced</Label>
        <div className="keyskills-list">{renderSkillsByLevel("Advance")}</div>
      </div>
    </div>
  );
};

export default KeySkills;
