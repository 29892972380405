import Config from "../Config/config";
import axios from "axios";
import { useAuthToken, useRoleKey } from "../Utilities/Auth/Token";
import { updateProfile } from "../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
import { CVParsing } from "./CVParsing";
import { useState } from "react";
import moment from "moment";
import { localDevBase } from "../services/API/localDevBase";
import { handleAxiosError } from "../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

export const useClient = () => {
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };
  const [viewJobData, setViewJobData] = useState([]);
  const [errorViewJobData, setErrorViewJobData] = useState(null);
  const viewJobDetails = async (id, job_id) => {
    const payload = {
      job_id: id,
    };
    console.log(payload);
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/client/viewjob`,
        payload,
        {
          headers,
        }
      );

      console.log("Job Details: ", response);
      setViewJobData(response.data);
    } catch (error) {
      console.log("viewJob ", error);
      if (error.response.status === 400) {
        setErrorViewJobData(400);
      } else if (error.response.status === 500) {
        setErrorViewJobData(500);
      }
    }
  };
  const devBase = localDevBase();

  const [responseJobId, setResponseJobId] = useState("");
  const [isLoadingCreateJobPublishDraft, setIsLoadingCreateJobPublishDraft] =
    useState(false);
  const [editJobStatus, setEditJobStatus] = useState("");
  const editJobPublishDraft = async (
    jobId,
    payload,
    updatedAt,
    prevUpdatedAtDetails
  ) => {
    console.log(
      "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&",
      prevUpdatedAtDetails
    );
    setEditJobStatus("");
    const editPayLoad = {
      patchOptions: {
        jobId: jobId,
        updatedAt: updatedAt,
      },
      setOptions: payload,
      props: [""],
    };
    setIsLoadingCreateJobPublishDraft(true);
    try {
      const response = await axios.patch(
        `${devBase}/api/ats/manage-job`,
        editPayLoad,
        {
          params: {
            newUpdatedAt: moment().format(),
            prevUpdatedAt: prevUpdatedAtDetails,
          },
          headers,
          // withCredentials: true,
        }
      );

      setResponseJobId(response.data.jobId);
      setIsLoadingCreateJobPublishDraft(false);
      setEditJobStatus("success");
    } catch (err) {
      if (err.response?.status >= 403) {
        handleAxiosError(err);
      }

      setEditJobStatus("error");
      setIsLoadingCreateJobPublishDraft(false);
      console.log(err);
    }
  };

  const [atsJobList, setATSJobList] = useState([]);
  const getATSJobList = async () => {
    try {
      const response = await axios.get(`${Config.ats}/api/ats/manage-job`, {
        headers,
        // withCredentials: true,
      });
      console.log(response);
      setATSJobList(response.data.result.data);
    } catch (err) {
      handleAxiosError(err);
      console.error(err);
    }
  };

  const deleteJob = async (deleteJobPayload, prevUpdatedAt) => {
    console.log(deleteJobPayload);
    try {
      const response = await axios.delete(`${Config.ats}/api/ats/manage-job/`, {
        data: deleteJobPayload,
        headers,
        params: {
          prevUpdatedAt: prevUpdatedAt,
        },
        // withCredentials: true,
      });
      console.log(response);
      // setLoadCloseBtn(false);
      // setModalMode("successDeleted");
    } catch (error) {
      console.log(error);
      handleAxiosError(error);

      // setLoadCloseBtn(false);
      // if (error.response.status == 400) {
      //   setModalShow(true);
      //   setModalMode("cantDelete");
      // }
    }
  };

  return {
    viewJobDetails,
    viewJobData,
    errorViewJobData,
    editJobPublishDraft,
    responseJobId,
    isLoadingCreateJobPublishDraft,
    getATSJobList,
    atsJobList,
    editJobStatus,
    deleteJob,
  };
};
