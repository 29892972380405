import React from "react";
import AdminSidebar from "../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../layouts/Navbar";
import CustomAtsTable from "../../../components/UI/Table/ATS/CustomAtsTable";
import { clientListSA } from "../../../Utilities/Reusable/TableHead/SuperAdminHead";
import WhiteContainer from "../../../layouts/Container/WhiteContainer";
import TitleLine from "../../../components/UI/Title/TitleLine";
import InputSearch from "../../../components/UI/Search/InputSearch";
import CustomButton from "../../../components/UI/CustomButton";

const SuperAdminClientList = () => {
  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <WhiteContainer>
            <TitleLine
              title="Client List"
              className="ats_title_color"
            ></TitleLine>
            <div className="ats_filter_container">
              <div className="ats_filter_section">
                <h5>Client Name</h5>
                <select>
                  <option value="">Default</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
              <div className="ats_filter_section">
                <h5>Status</h5>
                <select>
                  <option value="">Default</option>
                  <option value="pending">Pending</option>
                  <option value="declined">Declined</option>
                  <option value="verified">Verified</option>
                </select>
              </div>
              <div className="ats_filter_section">
                <h5>Date Verified</h5>
                <select>
                  <option value="">Default</option>
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            </div>
          </WhiteContainer>
          <WhiteContainer className="ats_filter_table_cont">
            <div className="keyword_right_container mb-3 mx-3">
              <InputSearch
                placeholder="Client Name"
                // onSearch={handleSearch}
              />
              <CustomButton name="Export to CSV" className="clientList-csv" />
            </div>
            <div className="ats_filter_table">
              <CustomAtsTable
                columns={clientListSA}
                // data={atsList}
                // Uncomment data if api is ok
                // data={hiredList}
                // nameOrder={nameOrder}
                // dateApplied={dateApplied}
                // keyword={keyword}
              />
            </div>{" "}
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminClientList;
