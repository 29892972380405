import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import { Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingTopCompanies.scss";
const TopCompanies = () => {
  const companyList = [
    { img: "asticom.png", key: 1, altText: "agc" },
    { img: "absi.png", key: 2, altText: "absi" },
    { img: "finsi.png", key: 3, altText: "finsi" },
    { img: "globe.png", key: 4, altText: "globe" },
    { img: "acquiro.png", key: 5, altText: "acquiro" },
    { img: "ayala.png", key: 6, altText: "ayala" },
    { img: "ayalaf.png", key: 7, altText: "ayalafoundation" },
    { img: "rush.png", key: 8, altText: "rush" },
  ];

  return (
    <div className="landing-topcompanies">
      <Container>
        <div className="topcompanies-body">
          <h1>Top Hiring Companies</h1>
          <div className="topcompanies-inner">
            {companyList.map((val) => (
              <>
                <div className="topcompanies-img-container">
                  <img
                    alt={val.altText}
                    src={require(`../../../assets/images/LandingPage/TopCompanies/${val.img}`)}
                    className="topcompanies-img"
                  />
                </div>
              </>
            ))}
            <Swiper
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="carousel-container"
              modules={[Autoplay]}
            >
              {companyList.map((val, key) => (
                <>
                  <SwiperSlide className="landing-carousel-item">
                    <img
                      style={{ background: "#ffffff" }}
                      src={require(`../../../assets/images/LandingPage/TopCompanies/${val.img}`)}
                    />
                  </SwiperSlide>
                </>
              ))}
            </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TopCompanies;
