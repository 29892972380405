import { useEffect, useState } from "react";
import { useAuthToken, useRoleKey } from "../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../Config/config";
import { handleAxiosError } from "../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

export const usePooling = () => {
  const token = useAuthToken();
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [poolList, setPoolList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPoolingList = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${Config.ats}/api/ats/candidate-list/pool`, {
        headers,
      });

      setPoolList(res.data.result?.data);
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPoolingList();
  }, [token]);

  return {
    getPoolingList,
    poolList,
    isLoading,
  };
};
