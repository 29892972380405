import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../../assets/scss/Talent/Layout/JobList/JobListSidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import Config from "../../../../../Config/config";
import logo from "../../../../../assets/images/Icon/iconBuilding.png";
const JobListSidebar = () => {
  const apiKey = process.env.REACT_APP_TALENT_API;
  const [jobDetails, setJobDetails] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const inside = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("talentToken")}`,
        };

        const payload = {
          job_id: id,
        };

        const response = await axios.post(
          `${Config.nxtApi}/api/jobs/job/details`,
          { job_id: id }
        );
        // const response = await axios.post(`${apiKey}/viewjob`, payload, {
        //   headers,
        // });
        setJobDetails(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    inside();
  }, [apiKey, id]);

  return (
    <div className="joblistsidebar-container">
      <div className="image-container">
        <img
          crossOrigin="anonymous"
          src={`${
            Config.nxtApi
          }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
            jobDetails.clientId
          )}&thumbNail=yes`}
          alt="logo"
          onError={(e) => {
            e.target.src = logo;
            e.target.classList.add("errorImg");
          }}
        />
      </div>
      <h1>{jobDetails.companyName}</h1>
      {/* <p>
          Asticom Group of Companies is the parent company to four subsidiaries:
          ABSI, HCX, and FINSI that serve the IT-BSO, Digital Solutions and HR,
          Engineering, and Logistics industries, respectively. Through our
          business solutions and services, we have created numerous opportunities
          for businesses and Filipinos.
        </p> */}
      <section>
        <h1>Location</h1>
        <p>
          {jobDetails.officeAddress}
          {/* , {jobDetails.city} */}
        </p>
      </section>
      <section>
        <h1>Salary</h1>
        <p>
          ₱ {jobDetails.minSalary} - ₱ {jobDetails.maxSalary}{" "}
        </p>
      </section>
      <section>
        <h1>Job Type</h1>
        <p>{jobDetails.jobType} </p>
      </section>
      {/* <section>
          <h1>Website</h1>
          <p>
            15/F W5th Building, Bonifacio Global City, Taguig City, Philippines
          </p>
        </section> */}
    </div>
  );
};

export default JobListSidebar;
