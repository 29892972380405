import React, { useState, useEffect } from "react";
import Girl from "../../../assets/images/WhatsNext/img/Employer/Girl.png";
import Check from "../../../assets/images/WhatsNext/img/Employer/Check.png";
import One from "../../../assets/images/WhatsNext/img/Employer/One.png";
import Two from "../../../assets/images/WhatsNext/img/Employer/Two.png";
import Three from "../../../assets/images/WhatsNext/img/Employer/Three.png";
import Four from "../../../assets/images/WhatsNext/img/Employer/Four.png";
import Five from "../../../assets/images/WhatsNext/img/Employer/Five.png";
import NxtLogo from "../../../assets/images/WhatsNext/img/Employer/NxtLogo.png";
import BgVector from "../../../assets/images/WhatsNext/img/Employer/BgVector.png";
import Stars from "../../../assets/images/WhatsNext/img/Employer/Stars.png";
import Shape from "../../../assets/images/WhatsNext/img/Employer/Shape.png";
import BgVector2 from "../../../assets/images/WhatsNext/img/Employer/BgVector2.png";
import Confetti from "../../../assets/images/WhatsNext/img/Employer/Confetti.png";
import Footer from "../../WhatsNext/Footer/Footer";
import "../../../assets/scss/WhatsNext/styles.scss";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import { useLocation, useNavigate } from "react-router";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";
import axios from "axios";

const Index = () => {
  TabTitle("Employer");

  const navigate = useNavigate();
  const handleClickSignUpClient = () => {
    navigate("/client/registration?ref=hO2mFu9V", {
      state: { sendClientState: "client" },
    });
  };

  const api = process.env.REACT_APP_API;
  const [isValidReferral, setIsValidReferral] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("ref");
  const userToken =
    localStorage.getItem("clientToken") || localStorage.getItem("talentToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
          console.log("sysparVal:", sysparVal);

          if (sysparVal === "Y") {
            navigate("/under-maintenance");
          } else if (referralCode) {
            checkReferralValidity(referralCode);
          } else if (userToken) {
            console.log("");
          } else {
            // navigate("/");
            localStorage.clear();
          }
        });
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, [referralCode]);

  const checkReferralValidity = (code) => {
    axios
      .get(
        `${api}/api/referrals/${code}/validity
      `
      )
      .then((response) => {
        if (response.data.isValid) {
          localStorage.setItem("referralCode", code);
          const userType = localStorage.getItem("userType");
          console.log(userType);
          if (userType === "client") {
            navigate(`/client/registration?ref=${code}`);
          } else if (userType === "talent") {
            navigate(`/talent/registration?ref=${code}`);
          }
        } else {
          navigate("/invalidReferral", {
            state: { error: response.data.error },
          });
        }
      })
      .catch((error) => {
        console.error("Error checking referral validity:", error);
        setIsValidReferral(false);
        navigate("/invalidReferral");
      });
  };

  return (
    <div className="bg-container">
      <img className="bg-stars" src={Stars} alt="bg" />
      <img className="bg-vector" src={BgVector} alt="bg" />
      <img className="bg-shape" src={Shape} alt="bg" />
      <img className="bg-vector2" src={BgVector2} alt="bg" />
      <img className="bg-confetti" src={Confetti} alt="bg" />
      <div className="big-container__bg-section-one">
        <img className="bg-girl" src={Girl} alt="girl" />
      </div>
      <div className="bg-cotainer__text-body">
        <p className="bg-cotainer__text-body__para-one">
          Experience the future of talent acquisition with NXT's groundbreaking
          features, tailored to propel your hiring process to new heights!
        </p>
        <div className="bg-cotainer__text-body__header-one">
          <span className="bg-cotainer__text-body__para-two">
            <text>A</text>pplicant <br />
            <text>T</text>racking <br />
            <text>S</text>ystem
          </span>
        </div>
        <section className="bg-container__making-rec">
          <h2 className="bg-cotainer__text-body__para-three">
            Making your recruitment effortless and efficient.
          </h2>
          <h3 className="bg-cotainer__text-body__para-four">
            <img className="check-img" src={Check} alt="check" /> Centralized
            Applicant Management
            <br /> <img className="check-img" src={Check} alt="check" />
            Job Post Progress Tracking
            <br />
            <img className="check-img" src={Check} alt="check" />
            Efficient Workflow
            <br />
            <img className="check-img" src={Check} alt="check" />
            Customizable Status Updates
            <br />
            <img className="check-img" src={Check} alt="check" /> User-Friendly
            Interface
          </h3>
        </section>
        <section className="bg-container__manage-job">
          <h2 className="bg-cotainer__text-body__para-three">Manage Job</h2>
          <p className="bg-cotainer__text-body__para-one">
            The Manage Job feature empowers employers to seamlessly oversee and
            manage job postings.
            <br />
            <br /> Easily create, view, and update job listings, and
            effortlessly enroll them into the ATS (Applicant Tracking System).
          </p>
        </section>
        <section className="bg-container__key-features">
          <h2 className="bg-cotainer__text-body__para-three">Key Features</h2>
          <div className="bg-container__keyfeatures-allside">
            <div className="side-a">
              <span>• Candidate List</span>
              <br />• Talent List
            </div>
            <div className="side-b">
              • Pooling List
              <br />• Hired List
            </div>
          </div>
        </section>
        <section className="bg-container__improve-talent">
          <h2 className="bg-cotainer__text-body__para-three">
            Improve talent recruitment with
          </h2>{" "}
          <img className="nxt-logo" src={NxtLogo} alt="picture" />
          <section className="bg-container__improve-talent__container">
            <div className="bg-container__improve-talent__column">
              <div className="first">
                <img className="one" src={One} alt="picture" />
                <h6>Effortless Talent Connection</h6>
                <p>
                  NXT serves a dynamic bridge connecting employers to a pool of
                  skilled talents.
                </p>
                <br />
              </div>
              <div className="second">
                <img className="two" src={Two} alt="picture" />
                <h6>No Cost Sign-Up for Talents</h6>
                <p>
                  NXT serves a dynamic bridge connecting employers to a pool of
                  skilled talents.
                </p>
                <br />
              </div>
            </div>
            <div className="bg-container__improve-talent__column">
              <div className="first">
                <img className="one" src={Three} alt="picture" />
                <h6>FREE Job Posting</h6>
                <p>
                  We offer free job postings for employers, making the
                  recruitment process hassle-free.
                </p>
                <br />
              </div>
              <div className="second">
                <img className="two" src={Four} alt="picture" />
                <h6>User Friendly Interface</h6>
                <p>
                  Navigating through “NXT” is a smooth experience for both
                  employers and talent.
                </p>
                <br />
              </div>
            </div>
            <div className="bg-container__improve-talent__column">
              <div className="first">
                <img className="three" src={Five} alt="picture" />
                <h6>Streamlined Talent Sourcing</h6>
                <p className="last">
                  Discover and connect with the right candidates who match your
                  requirements.
                </p>
              </div>
            </div>
          </section>
        </section>
        <section className="bg-container__sign-up">
          <p className="bg-cotainer__text-body__para-one">
            Sign up now & enjoy 30-days
          </p>
          <p className="bg-cotainer__text-body__para-five">FREE ACCESS</p>
          <button onClick={handleClickSignUpClient}>Sign Up Now</button>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
