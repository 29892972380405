import React from "react";

const GettingStartedClient = () => {
  return (
    <div>
      <h2>Getting Started</h2>
      <ul>
        <li>Who is NXT?</li>
        <li>Who are your target users?</li>
        <li>How do I reach NXT Support?</li>
      </ul>
      {/* <hr />
      <h2> How It Works</h2>
      <ul>
        <li>Getting Started</li>
        <li>Schedule a Meeting</li>
        <li>Endorsing Talent</li>
      </ul> */}
    </div>
  );
};

export default GettingStartedClient;
