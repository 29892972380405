import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from "../Actions/userSlice";
import companyReducer from "../Actions/companyProfileSlice";
import profileReducer from "../Actions/profileSlice";
import modalReducer from "../Actions/modalAction";
import basicInformationReducer from "../Actions/basicInformationSlice";
import skillSetReducer from "../Actions/skillSetSlice";
import resumeReducer from "../Actions/resumeSlice";
import cvReducer from "../Actions/cvSlice";
import subscribeReducer from "../Actions/subscribe";
import aimlReducer from "../Actions/aiAction";
import featureReducer from "../Actions/featuresAction";
import currentUserReducer from "../Actions/currentUser";
import currentLoginUser from "../Actions/getAllUser";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userReducer,
    companyProfile: companyReducer,
    profile: profileReducer,
    modal: modalReducer,
    basicInformation: basicInformationReducer,
    skillSet: skillSetReducer,
    resume: resumeReducer,
    cv: cvReducer,
    subscribe: subscribeReducer,
    aimlInfo: aimlReducer,
    feature: featureReducer,
    currentUser: currentUserReducer,
    currentLoginUser: currentLoginUser,
  })
);

export default persistedReducer;
