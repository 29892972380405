import React from "react";
import SuperAdminNavbar from "../../layouts/SuperAdmin/Navbar";
import SuperAdminSidebar from "../../layouts/SuperAdmin/Sidebar";
import "../../assets/scss/SuperAdmin/PageScss/SuperAdmin.scss";
import PageContainer from "../../layouts/SuperAdmin/PageContainer";

const AddAdmin = () => {
  return (
    <div className="superAdmin">
      <div className="sidebar">
        <SuperAdminSidebar />
      </div>
      <div className="navbarContent">
        <SuperAdminNavbar />
        <div className="pageContainer">
          <PageContainer layout="addAdmin" />
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
