import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "../../../assets/scss/Client/Layout/Subscription/Subsciption.scss";
import SubsCardPremium from "./SubscriptionCard/SubsCardList";

const SubscriptionCardContainer = ({ subsData }) => {
  return (
    <>
      {subsData?.[0]?.activeSubscription?.current === "freemium" && (
        <div className="subsciption-container">
          <h6>
            {subsData[0].addOn.current !== null
              ? "MONTHLY SUBSCRIPTION"
              : "MONTHLY ADD ONS"}
          </h6>
          <hr className="line-seperator" />
          <div>
            <SubsCardPremium subsData={subsData} />
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionCardContainer;
