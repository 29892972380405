import React from "react";
import "../../assets/scss/UIStyle/Button.scss";
import Loader from "../../layouts/loader/Loader";

const Button = ({
  id,
  loading,
  label,
  classNameLabel,
  onClick,
  type,
  disabled,
}) => {
  return (
    <>
      <button
        id={id}
        className={`customButton ${classNameLabel}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {loading ? <Loader /> : label}
      </button>
    </>
  );
};

export default Button;
