import React, { useEffect, useState } from "react";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import { Image } from "react-bootstrap";
import Footer from "../../LandingPage/Components/Footer";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import ScrollButton from "../../../components/UI/Button/ScrollToTop";

const DataDeletion = () => {
  TabTitle("NXT | Data Deletion");
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  return (
    <div>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require("../../../assets/images/Background/Outside/DataDeletion.png")}
      />
      <CustomContainer className="containerWhiteBg">
        <div className="container">
          <div className="privacyPolicy">
            <h1>Data Deletion Process</h1>
            <br />
            <p>
              The Data Officer at NXT is responsible for overseeing our data
              deletion process, we are committed to safeguarding user privacy
              and ensuring compliance with data protection regulations. Here's
              how we, at NXT, establish and maintain our data deletion process:
            </p>
            <br />
            <h2>1. Data Inventory:</h2>
            <p>
              We meticulously identify and categorize all user data collected,
              ensuring we have a comprehensive understanding of the information
              we handle.
            </p>
            <br />
            <h2>2. Data Mapping:</h2>
            <p>
              Our team maintains a detailed record of where user data is stored,
              whether it's in our databases, backups, or third-party services.
            </p>
            <br />
            <h2>3. Privacy Policy:</h2>
            <p>
              We maintain a transparent and up-to-date privacy policy that
              clearly communicates our data collection, processing purposes, and
              retention policies to our users. You can view our Privacy Policy
              at the NXT Website.
            </p>
            <br />
            <h2>4. User Access:</h2>
            <p>
              We empower our users by providing them with easy access to their
              data through their accounts, allowing them to view, edit, or
              request the deletion of their information.
            </p>
            <br />
            <h2>5. Deletion Request Mechanism:</h2>
            <p>
              We've implemented a straightforward mechanism for users to
              initiate data deletion requests, whether through a dedicated form,
              email, or support ticket system.
            </p>
            <br />
            <h2>6. Verification:</h2>
            <p>
              Our stringent verification process ensures that data deletion
              requests are genuine and authorized, preventing unauthorized
              access.
            </p>
            <br />
            <h2>7. Request Handling:</h2>
            <p>
              We promptly acknowledge and log all data deletion requests,
              carefully reviewing each request for validity and completeness
              before taking action.
            </p>
            <br />
            <h2>8. Data Deletion</h2>
            <p>
              Upon receiving a valid request, we systematically remove the
              requested data from all relevant locations, including databases,
              backups, and third-party platforms.
            </p>
            <br />
            <h2>9. Confirmation:</h2>
            <p>
              We maintain transparent communication with our users, providing
              them with confirmation when their data has been successfully
              deleted, ensuring transparency and trust.
            </p>
            <br />
            <h2>10. Regular Review:</h2>
            <p>
              We conduct periodic reviews of our data deletion process to stay
              in line with evolving regulations and best practices.
            </p>
            <br />
            <h2>11. Team Training:</h2>
            <p>
              Our team members are well-trained and aware of the significance of
              data protection and the proper handling of data deletion requests.
            </p>
            <br />
            <h2>12. Third-Party Agreements:</h2>
            <p>
              When sharing user data with third parties, we ensure these parties
              are bound by agreements to comply with data deletion requests as
              per our policies.
            </p>
            <br />
            <h2>13. Communication:</h2>
            <p>
              We maintain open communication with our users by clearly
              articulating our data deletion policy on our website and privacy
              policy.
            </p>
            <br />
            <h2>14. Testing:</h2>
            <p>
              We regularly test our data deletion process to ensure its
              efficiency and effectiveness in safeguarding user data.
            </p>
            <br />
            <h2>15. Documentation:</h2>
            <p>
              We maintain meticulous records of all data deletion requests,
              actions taken, and communications with users, fostering
              accountability and compliance.
            </p>
            <br />
            <p>
              At NXT, we are dedicated to maintaining a robust data deletion
              process that respects user privacy and demonstrates our commitment
              to ethical data handling and compliance with data protection laws
              and regulations.
            </p>
            <br />
            <p>
              You may contact our Data Protection Officer at this email address:
              privacy@acquiro.com.ph, for NXT concerts our Compliance Officer
              for Privacy at privacy@nxt.com.ph if you have any inquiries or
              feedback on our personal data protection policies and procedures
              or send it in writing at 15F ASTICOM, W5th Building, 5th Ave.
              Bonifacio Global City, Taguig, Philippines.
            </p>
          </div>
        </div>
      </CustomContainer>
      <Footer />
      {showScrollButton && <ScrollButton />}
    </div>
  );
};

export default DataDeletion;
