import axios from "axios";
import React, { useEffect, useState } from "react";
import Config from "../../../Config/config";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";

const Google = ({ onSubmit, registration, onError, isPrivTermsChecked }) => {
  const [clientId, setClientId] = useState("");
  useEffect(() => {
    fetchSysparValue("GOOGLE_CLIENT_ID", setClientId);
  }, [clientId]);

  const onGoogleLogin = async (response) => {
    // console.log("Encoded JWT ID token: " + response.credential);

    const token = response.credential;
    // console.log(token);
    try {
      const googleResponse = await axios.post(
        `${Config.nxtApi}/api/user/sso/signup/google`,
        {
          token,
        }
      );

      onSubmit(
        googleResponse.data.email,
        googleResponse.data.id,
        googleResponse.data.login_type_ref_id,
        googleResponse.data.reg_type
      );
    } catch (error) {
      console.log(error);
      if (error.response.data.message.message === "Account Locked") {
        const remainingSeconds = error.response.data.message.remainingSeconds;
        onError(
          `${error.response.data.message.message} \n Remaining Seconds: ${remainingSeconds}`
        );
      } else {
        onError(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    const loadGoogleSSO = async () => {
      const google = window.google;
      google.accounts.id.initialize({
        client_id: clientId,
        callback: onGoogleLogin,
      });
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        theme: "outline",
        size: "large",
        shape: "pill",
        type: "icon",
      });
    };
    loadGoogleSSO();
  }, [clientId]);

  return (
    <div>
      <div id="buttonDiv"> </div>
    </div>
  );
};

export default Google;
