import React, { useState, useEffect, useRef } from "react";
import { IdleTimerComponent } from "react-idle-timer";
import CustomModal from "../../../components/UI/Modal";

const IdleTimerLogout = ({ logout, modalMessage }) => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    setIsIdle(true);
    // Trigger the logout function when the user is idle for 15 minutes
    logout();
  };

  useEffect(() => {
    // Set the idle timer to 15 minutes (15 * 60 * 1000 milliseconds)
    const timeout = 15 * 60 * 1000;
    idleTimerRef.current.reset();
    idleTimerRef.current.setTimeout(timeout);
  }, []);

  return (
    <div>
      <IdleTimerComponent ref={idleTimerRef} onIdle={onIdle} debounce={250} />

      <CustomModal
        modalMessage={modalMessage}
        modalType="Error"
        show={isIdle}
        onHide={() => setIsIdle(false)}
      />
    </div>
  );
};

export default IdleTimerLogout;
