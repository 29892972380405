import React from "react";
import "../../../assets/scss/Components/UI/Title/_titleLine.scss";

const TitleLine = ({ title, buttons, className }) => {
  return (
    <div className={className ? className : `titleLine`}>
      <div className="titleBtns">
        <h1>{title}</h1>
        <div className="buttonSection">{buttons}</div>
      </div>
      <hr className="lineSeparator" />
    </div>
  );
};

export default TitleLine;
