import React from "react";
import "../../../assets/scss/Page/Admin/_globalAdmin.scss";
import Content from "./Content";
import AdminSidebar from "../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../layouts/Navbar";
const Dashboard = () => {
  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
