import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title, description, url, image }) => {
  // console.log(title, description, url);

  return (
    <Helmet>
      {/* <title>
        Billing & Collection Clerk for Property & Inventory Unit - G.E. Antonino
        Inc. - 13232661 | JobStreet
      </title>
      <meta
        name="description"
        content="Apply for Billing & Collection Clerk for Property & Inventory Unit (13232661) at G.E. Antonino Inc.. Find more Admin/Human Resources, Clerical/Administrative Support-related job vacancies in Philippines, Manila City at JobStreet"
      />

      <meta
        itemprop="name"
        content="Billing & Collection Clerk for Property & Inventory Unit - G.E. Antonino Inc. - 13232661 | JobStreet"
      />
      <meta
        itemprop="description"
        content="Apply for Billing & Collection Clerk for Property & Inventory Unit (13232661) at G.E. Antonino Inc.. Find more Admin/Human Resources, Clerical/Administrative Support-related job vacancies in Philippines, Manila City at JobStreet"
      />
      <meta
        itemprop="image"
        content="http://www.jobstreet.com.ph/assets/logo_jobstreet_1200x1200.png"
      />

      <meta
        property="og:url"
        content="https://www.jobstreet.com.ph/en/job/billing-collection-clerk-for-property-inventory-unit-13232661"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Billing & Collection Clerk for Property & Inventory Unit - G.E. Antonino Inc. - 13232661 | JobStreet"
      />
      <meta
        property="og:description"
        content="Apply for Billing & Collection Clerk for Property & Inventory Unit (13232661) at G.E. Antonino Inc.. Find more Admin/Human Resources, Clerical/Administrative Support-related job vacancies in Philippines, Manila City at JobStreet"
      />
      <meta
        property="og:image"
        content="http://www.jobstreet.com.ph/assets/logo_jobstreet_1200x1200.png"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Billing & Collection Clerk for Property & Inventory Unit - G.E. Antonino Inc. - 13232661 | JobStreet"
      />
      <meta
        name="twitter:description"
        content="Apply for Billing & Collection Clerk for Property & Inventory Unit (13232661) at G.E. Antonino Inc.. Find more Admin/Human Resources, Clerical/Administrative Support-related job vacancies in Philippines, Manila City at JobStreet"
      />
      <meta
        name="twitter:image"
        content="http://www.jobstreet.com.ph/assets/logo_jobstreet_1200x1200.png"
      /> */}
      <meta charset="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="noindex,nofollow" />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={url} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default MetaData;
