import React from "react";
import "../../../assets/scss/WhatsNext/layout/_footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div className="footer--container">
      <div className="footer-container__body">
        Connect with us:
        <div className="logo-container">
          <div className="logo-container__icon">
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
          {/* <div className="logo-container__icon">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </div>
          <div className="logo-container__icon">
            <FontAwesomeIcon className="ig-icon" icon={faInstagram} />
          </div>
          <div className="logo-container__icon">
            <FontAwesomeIcon icon={faTiktok} />
          </div> */}
          <p classname="footer-para">/nextexperiencetoday</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
