import React from "react";
import { Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingSentence.scss";
const Sentence = () => {
  return (
    <div className="landing-sentence">
      <Container>
        <div className="landing-body">
          <h1>
            Embrace a modern recruitment strategy that prioritizes skilled
            professionals over conventional hiring methods.
          </h1>
        </div>
      </Container>
    </div>
  );
};

export default Sentence;
