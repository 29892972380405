import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  cv: null,
  loading: false,
  error: false,
};

const cvSlice = createSlice({
  name: "cv",
  initialState,
  reducers: {
    startCV: (state) => {
      state.loading = true;
    },
    updateCV: (state, action) => {
      state.loading = false;
      state.cv = {
        ...state.cv,
        ...action.payload,
      };
    },
    getCV: (state, action) => {
      state.loading = false;
      state.error = false;
      state.cv = action.payload;
    },
    failCV: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearCV: (state) => {
      state.cv = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { startCV, updateCV, getCV, failCV, clearCV } = cvSlice.actions;

export default cvSlice.reducer;
