import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "../../../../../assets/scss/Page/Outer/Registration/_emailRegistration.scss";
import CustomButton from "../../../../../components/UI/CustomButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";
import { useDispatch } from "react-redux";
import { getProfile } from "../../../../../services/Actions/profileSlice";
import { getCompanyProfile } from "../../../../../services/Actions/companyProfileSlice";
import { loginSuccess } from "../../../../../services/Actions/userSlice";
import { setfeatures } from "../../../../../services/Actions/featuresAction";
import { SubscriptionFunction } from "../../../../../Utilities/Reusable/Function/Subscription/Subscription";

const Step3 = ({ signUpData }) => {
  const dispatch = useDispatch();
  console.log(signUpData);
  const navigate = useNavigate();
  // const userType = useSelector(
  //   (state) => state.reducer.user.currentUser?.userType
  // );

  const { getURLSubsPlanPrice, feature_pricing } = SubscriptionFunction();
  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  const handleSubmitData = async () => {
    if (signUpData.userType === "applicant") {
      console.log("applicant");
      fetchTalentProfile();
    } else if (signUpData.userType === "client") {
      console.log("client");
      fetchClientProfile();
    }
    // if (userType === "applicant") {
    //   navigate("/talent/profile");
    // } else if (userType === "client") {
    //   navigate("/client/company-profile");
    // }
  };

  const fetchTalentProfile = async () => {
    try {
      const response = await RequestMethod(
        "get",
        "nxtApi",
        "/api/talent/myprofile",
        null,
        signUpData.token
      );

      if (response.status === 200) {
        dispatch(getProfile(response.data.data[0]));
      }
      dispatch(loginSuccess(signUpData));
      navigate("/talent/profile");
      console.log("Data:", response.data.data[0]);
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  const fetchClientProfile = async () => {
    console.log(signUpData.token);
    try {
      const result = await RequestMethod(
        "get",
        "nxtApi",
        "/api/client/myprofile",
        null,
        signUpData.token
      );

      if (result.status === 200) {
        dispatch(getCompanyProfile(result.data.data[0]));
        dispatch(setfeatures(result.data.subscription));
      }
      dispatch(loginSuccess(signUpData));

      if (!feature_pricing) {
        navigate("/client/company-profile");
      } else {
        navigate(`/client/company-profile/?feature=${feature_pricing}`);
      }

      // console.log("Data:", result.data.data[0]);
      // dispatch(getProfile(result.data.data[0]));
      // dispatch(loginSuccess(data));
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  return (
    <div className="emailRegStep3">
      <div className="loginTitleLeft">
        <h1>Account Confirm</h1>
        <p>
          Thanks for creating an account. Now you can manage your job alerts,
          save jobs, and more!
        </p>
      </div>
      <CustomButton
        name="Login"
        className="customLRButton"
        onClick={handleSubmitData}
      />
    </div>
  );
};

export default Step3;
