import React from "react";
import "../../assets/scss/About/Banner.scss";
const Banner = () => {
  return (
    <div className="about-banner">
      <img
        alt="about-img"
        src={require(`../../assets/images/About/bannerAbout.png`)}
      />
    </div>
  );
};

export default Banner;
