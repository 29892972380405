import React from "react";
import ChatAssistant from "../../../../../components/LayoutClient/ChatAssistant/ChatAssistant";
import "../../../../../";

const ChatBotBox = () => {
  return (
    <>
      <div className="chat-assistant_container">
        <ChatAssistant />
      </div>
    </>
  );
};

export default ChatBotBox;
