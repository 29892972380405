import React, { useState } from "react";
import ActiveCard from "./Card/ActiveCard";
import SubscriptionTime from "./Card/SubscriptionTime";
import plans from "../../../../../Data/subscription/subscription.json";
import SubsCardList from "../../../../../components/LayoutClient/Subscription/SubscriptionCard/SubsCardList";
import ActiveAddOn from "./Card/ActiveAddOn";

const ActiveSubscription = ({ activePlan }) => {
  console.log(activePlan[0].addOn.current);
  const plan = plans.filter(
    (plan) => plan.planName === activePlan[0].activeSubscription.current
  );

  const [plansOrAddOns, setPlansOrAddOns] = useState(plan[0]);

  console.log(plan);
  return (
    <div className="content-container">
      <div
        className="subsciption-container d-flex w-100 active-subs-tab-content"
        style={{
          border: "none",
          boxShadow: "none",
          gap: "50px",
        }}
      >
        <div>
          <ActiveCard selectedPlan={plansOrAddOns} />
        </div>

        {/* {activePlan[0].addOn.current && (
          <div>
            <ActiveAddOn subsData={activePlan} />
          </div>
        )} */}
        <div className="mt-4">
          <SubscriptionTime activePlan={activePlan} />
        </div>
      </div>
      {/* <div className="m-5">
      
      </div> */}
    </div>
  );
};

export default ActiveSubscription;
