import React from "react";
import AdminSidebar from "../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../layouts/Navbar";
import "../../../assets/scss/SuperAdmin/PageScss/SuperAdmin.scss";
import WhiteContainer from "../../../layouts/Container/WhiteContainer";
import SubscriptionTabs from "./SubscriptionTabs";
import SubscriptionInformationCard from "./SubscriptionInformationCard";
import SubscriberCard from "../../../components/UI/Card/SubscriberCard";

const AdminSubscription = () => {
  const subscriberData = {
    companyName: "Example Company",
    contactNumber: "123-456-7890",
    email: "example@example.com",
    name: "John Doe",
    image: "path-to-image.jpg",
  };
  const additionalComponents = <SubscriptionInformationCard />;

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer fullheight">
          <WhiteContainer>
            <SubscriberCard
              {...subscriberData}
              additionalComponents={additionalComponents}
            />
          </WhiteContainer>
          <WhiteContainer>
            <SubscriptionTabs />
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscription;
