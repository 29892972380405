import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/scss/Components/Form/_customContactNumber.scss";
import CustomMessage from "./ErrorMessage";

const CustomContactNumber = (
  {
    contactNumber,
    name,
    placeholder,
    label,
    labelAfter,
    errors,
    type,
    className,
    linkClassName,
    classNameLabel,
    rules,
    link = "", // Default value for link
    linkName = "", // Default value for linkName
    control,
    required,
    value,
    readOnly,
    maxlength = 15,
    ...props
  },
  ref
) => {
  const errorToShow = errors[name]
    ? errors[name].message === "Required"
      ? "Contact Number is required"
      : errors[name].message
    : null;

  return (
    <div className={`customContactNumber ${className}`}>
      <label className={classNameLabel}>
        {label}
        {required && <span className="required-field">*</span>}
      </label>
      <Controller
        control={control}
        name={name}
        required
        rules={rules}
        {...props}
        ref={ref}
        render={({ field: { ref = { ref }, ...field } }) => (
          <ReactPhoneInput
            {...field}
            inputExtraProps={{
              ref,
              required,
              autoFocus: true,
            }}
            id={name}
            value={field.value}
            defaultCountry="ph"
            country="ph"
            onlyCountries={["ph"]}
            countryCodeEditable={false}
            disabled={readOnly}
            // onFocus={() => setErrors({ ...errors, hiringEmail: "" })}
            inputProps={{ maxLength: maxlength }} // Set maxlength here
          />
        )}
      />

      {errorToShow && <CustomMessage label={errorToShow} />}
    </div>
  );
};

export default CustomContactNumber;
