import React from "react";
import { Tabs, Tab } from "react-bootstrap";

function CustomTabs(tabsData) {
  return (
    <Tabs
      defaultActiveKey={tabsData[0].eventKey}
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      {tabsData.map((tab) => (
        <Tab
          key={tab.eventKey}
          eventKey={tab.eventKey}
          title={tab.title}
          disabled={tab.disabled}
        >
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
}

export default CustomTabs;
