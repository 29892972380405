import React from "react";
import { useNavigate } from "react-router";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../../../../assets/scss/Client/Layout/BannerBreadcrumb.scss";

const BannerJobList = () => {
  const navigate = useNavigate();

  const clickHomepage = () => {
    navigate("/");
  };

  const clickJobList = () => {
    navigate("/jobs");
  };

  const clickJobListDetails = () => {
    navigate("/joblistdetails");
  };
  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>Job List</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickHomepage} tag="a">
                Homepage
              </BreadcrumbItem>
              <BreadcrumbItem onClick={clickJobList} tag="a">
                Job List
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerJobList;
