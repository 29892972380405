import React from "react";

import "../../assets/scss/ChatAssistant.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import ChatAssistant from "../../components/LayoutTalent/ChatAssistant/ChatAssistant";
import BannerTalent from "../../components/LayoutTalent/BannerTalent";
import Footer from "../LandingPage/Components/Footer";

const ChatAssistantPageTalent = () => {
  // Assuming the key you want to remove is "yourKey"
  //   localStorage.removeItem("pub_id");

  TabTitle("NXT | Chat Assistant");
  return (
    <div className="background-imagePost-myresume">
      <TalentAnalytics />
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      <div className="jobposting-banner">
        <BannerTalent />
      </div>

      <Container className="myresume-body">
        <div className="my-resume-body-inner">
          <div>
            <div className="sidebar-container">
              <SidebarTalent />
            </div>
          </div>
          <div className="chat-assistant_container">
            <ChatAssistant />
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default ChatAssistantPageTalent;
