export const hiredListTC = [
  { key: "name", header: "Name" },
  { key: "jobPosting", header: "Job Posting" },
  { key: "dateApplied", header: "Date Applied" },
  { key: "action", header: "Action" },
];

export const hiredCandidatesTC = [
  { key: "name", header: "Name" },
  { key: "dateApplied", header: "Date Applied" },
  { key: "action", header: "Action" },
];

export const failedCandidatesTC = [
  { key: "name", header: "Name" },
  { key: "status", header: "Status" },
  { key: "dateApplied", header: "Date Applied" },
  { key: "action", header: "Action" },
];

export const recommendationsTC = [
  { key: "score", header: "Score" },
  { key: "talentList", header: "Talent List" },
  { key: "skills", header: "Skills" },
  { key: "experience", header: "Experience" },
  { key: "action", header: "Action" },
];
