import React from "react";
import Step1 from "./Form/Step1";
import "../../../../assets/scss/Page/Outer/Login/_login.scss";

import LoginOption from "./Form/Option1";
import AlertWithHide from "../../../../components/UI/Alert/AlertWithHide";

const ContainerIndex = () => {
  return (
    <div className="containerIndex">
      <div className="content">
        <Step1 />
        <LoginOption />
      </div>
    </div>
  );
};

export default ContainerIndex;
