import React from "react";
import Header from "../layouts/Header";
import "../../src/assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import Banner from "../components/Banner";
import Sidebar from "../layouts/Sidebar";
import { TabTitle } from "../Utilities/FunctionTitle";
import FormJobPosting from "../components/FormClient/FormJobPosting";
import { Helmet } from "react-helmet";
import ClientAnalytics from "../Utilities/Analytics/ClientAnalytics";
import Footer from "./LandingPage/Components/Footer";

const JobPosting = () => {
  TabTitle("NXT | Company Profile");
  return (
    <div className="background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <Banner />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>

        <div className="company-profile-container">
          <FormJobPosting />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default JobPosting;
