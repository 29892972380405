import React from "react";
import RecentlyJobPosted from "./Content/RecentlyJobPosted";
import RecentCompany from "./Content/RecentCompany";
import "../../../assets/scss/Page/Admin/Dashboard/_dashboardContent.scss";
import CardWithIcon from "./Content/CardWithIcon";
import JobPostChart from "./Content/JobPostingChart";
const Content = () => {
  return (
    <div className="dashboardContent">
      <CardWithIcon />
      <div className="listAndChart">
        <div className="companyJobPostedList">
          <RecentlyJobPosted />
          <RecentCompany />
        </div>
        <div className="chart">
          <JobPostChart />
        </div>
      </div>
    </div>
  );
};

export default Content;
