import React from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import AddOnSelect from "./AddOn/AddOnStep/AddOnSelect";
import { useLocation, useNavigate } from "react-router-dom";

const AddOn = () => {
  const location = useLocation();

  const selectedPlan = location.state.selectedPlan;
  const subsData = location.state?.subsData;

  console.log("Add on y", subsData);
  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer subscription-card__ats">
          <WhiteContainer>
            <AddOnSelect selectedPlan={selectedPlan} subsData={subsData} />
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default AddOn;
