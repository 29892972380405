import React from "react";
import { Modal, ModalBody, Button, ModalHeader } from "reactstrap";
import "../assets/scss/Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const QuestionModal = ({
  modalOpen,
  toggleModal,
  iconImage,
  modalLabel,
  modalSublabel,
  confirmButtonText,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      keyboard={false}
      className="custom-modal-container"
      centered
    >
      <ModalBody className="custom-modal-body">
        <Button color="link" className="close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} className="close-icon" />
        </Button>
        <div className="icon-container">
          <FontAwesomeIcon icon={faQuestionCircle} className="question-icon" />
        </div>
        <p className="modal-title">{modalLabel}</p>
        <p className="modal-sublabel">{modalSublabel}</p>
        <Button color="primary" onClick={onConfirm} className="question-btn">
          {confirmButtonText}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default QuestionModal;
