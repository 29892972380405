import React, { useEffect, useState } from "react";
import "../../../assets/scss/Product/ProductSubs.scss";
import subs from "../../../Data/subscription/subscriptionPricing.json";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import subsciption from "../../../Data/subscription/subscription.json";
import { useSubscription } from "../../../hook/useSubscription";

const ProductSubscribeBody = () => {
  const navigate = useNavigate();

  const [showSubsBtn, setShowSubsBtn] = useState(false);

  const userType = useSelector(
    (state) => state.reducer.user?.currentUser?.userType
  );

  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.isSubscribed
  );

  const clientId = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.clientId
  );

  console.log(userType, feature, clientId);

  const { checkSubscription, checkSubsData, checkSubsError, isLoading } =
    useSubscription();

  console.log(checkSubsData);

  // const [subsData, setSubsData] = useState(checkSubsData);
  const handleCheckSubscription = async () => {
    await checkSubscription(clientId);
  };

  useEffect(() => {
    if (clientId) {
      handleCheckSubscription();
    }
  }, []);

  const companyProfile = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );

  const [newInitialValues, setInitialValues] = useState({
    companyName:
      companyProfile?.companyName === "N/A" ? "" : companyProfile?.companyName,
    companySize:
      companyProfile?.companySize === "N/A" ? "" : companyProfile?.companySize,
    industry:
      companyProfile?.industry === "N/A" ? "" : companyProfile?.industry,
    address: companyProfile?.address === "N/A" ? "" : companyProfile?.address,
    country:
      companyProfile?.country === "N/A"
        ? "Philippines"
        : companyProfile?.country,
    region:
      companyProfile?.region === undefined || companyProfile?.region === "N/A"
        ? ""
        : companyProfile?.region,
    postal: companyProfile?.postal === "N/A" ? "" : companyProfile?.postal,
    firstName:
      companyProfile?.firstName === "N/A" ? "" : companyProfile?.firstName,
    email: companyProfile?.email === "N/A" ? "" : companyProfile?.email,
    lastName:
      companyProfile?.lastName === "N/A" ? "" : companyProfile?.lastName,
    middleName:
      companyProfile?.middleName === "N/A" ? "" : companyProfile?.middleName,
    contactNumber:
      companyProfile?.contactNumber === "N/A"
        ? "+63"
        : companyProfile?.contactNumber,
  });

  function checkIfProfileHasEmptyProps(profile) {
    if (profile != null && Object.keys(profile).length !== 0) {
      for (const property in profile) {
        if (
          profile[property] == null ||
          profile[property] === "" ||
          profile[property] === "N/A"
        )
          return false;
      }
      return true;
    }
    return false;
  }
  const handleClickClientLogin = (price, planName) => {
    const profileData = newInitialValues;
    delete profileData?.middleName;
    const validated = checkIfProfileHasEmptyProps(profileData);
    console.log(validated);
    let selectedPlan = null;
    console.log(userType === "client" && !feature && validated);
    if (
      userType === "client" &&
      !feature &&
      validated &&
      !checkSubsData?.[0].activeSubscription?.pendingPlan
    ) {
      selectedPlan = subsciption.find((sub) => sub.planName === planName);
      console.log("selectedPln ", selectedPlan);
      navigate("/client/subscription-config", {
        state: {
          selectedPlan: selectedPlan,
          subsData: checkSubsData,
          sendClientState: "client",
        },
      });
    } else if (userType === "client" && feature) {
      navigate("/client/subscription", {
        state: { sendClientState: "client" },
      });
    } else if (
      userType === "client" &&
      !feature &&
      validated &&
      checkSubsData?.[0].activeSubscription?.pendingPlan
    ) {
      navigate("/client/subscription", {
        state: { sendClientState: "client" },
      });
    } else {
      navigate(`/client/company-profile`);
    }
  };

  // const handleShowSubsBtn = () => {
  //   setShowSubsBtn(true);
  // };

  const navLogin = () => {
    navigate(`/client/login/?feature=pricing`, {
      state: { sendClientState: "client" },
    });
  };

  useEffect(() => {
    if (clientId) {
      setShowSubsBtn(true);
    }
  }, [clientId]);

  const activePlan = checkSubsData?.[0]?.activeSubscription?.current;

  return (
    <>
      <div className="pricing-content__pc py-3">
        <h4>Seamless Recruitment Process</h4>
        <h5>Simple and Affordable Pricing </h5>

        {/* <div className="pricing-content__pc">
        <div className="pricing__content--card--list">
          {subs.map((val, key) => {
            return (
              <div
                className={`${
                  val.planName === "premium+"
                    ? "pricing__content--container__white"
                    : `pricing__content--container__normal val.planName ${val.planName}`
                }`}
              >
                {val.planName === "premium+" && (
                  <div className="most__popular">
                    <h1>Most Popular</h1>
                  </div>
                )}

                <div
                  className={`${
                    val.planName === "premium+"
                      ? "pricing__content--card--title mt-5"
                      : "pricing__content--card--title"
                  } `}
                >
                  <h1>{val.planNameTxt}</h1>
                  <div className="pricing__content--card--amount mt-3 mb-4">
                    <h2> {val.amount.toLocaleString()}</h2>
                    <div className="pricing__content--card--amount--details">
                      <label>{val.vat}</label>
                      <p>{val.isMonthly}</p>
                    </div>
                  </div>
                  <div className="d-grid gap-2">
                    <Button
                      className="px-5 mb-3 d-block"
                      onClick={() => navigate(val.link)}
                    >
                      {val.buttonText}
                    </Button>
                  </div>
                </div>
                <div className="pricing__content--card--key__features">
                  <h5 className="mb-3">Key Features</h5>
                  {val.description.map((item, index) =>
                    item.isBullet ? (
                      <div className="px-3">
                        <ul key={index}>
                          <li>{item.text}</li>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <ul key={index}>
                          <li>{item.text}</li>
                        </ul>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

        <div className="container my-3">
          <div className="pricing-body align-center">
            <div className="pricing-body-card col mb-3 mb-sm-3">
              <div className="subs-card py-4 px-4 bg-white position-relative">
                <h5>Basic</h5>
                <ul>
                  <li>✓ Limited Job Postings</li>
                  <li>✓ 30-Day Listing Period</li>
                  <li>✓ Basic Applicant Tracking System (ATS)</li>
                  <li>✓ Downloadable CV</li>
                  <li>✓ Customer Support</li>
                </ul>
                <div className="subs-card__payment position-absolute bottom-0 start-50 translate-middle-x text-center w-100">
                  <h3 className={activePlan !== "freemium" && "mb-4"}>FREE</h3>
                  {showSubsBtn && activePlan === "freemium" && (
                    <div className="subs-card__basic text-center ">
                      Your current subscription
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col mb-3 mb-sm-3 mb-md-0">
              <div className="subs-card py-4 px-4 bg-white">
                <h5>Premium</h5>
                <ul>
                  <li>✓ Everything in Basic PLUS:</li>
                  <li>✓ Unlimited Job Postings</li>
                </ul>
                <div
                  className={
                    activePlan === "premium"
                      ? "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100 mb-5"
                      : "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100"
                  }
                >
                  <div className="d-flex justify-content-center px-3 mx-auto">
                    <h3 className="text-nowrap me-2">₱ 1,000</h3>
                  </div>
                  <div className="d-flex px-4 mx-auto justify-content-center gap-2 ">
                    <strong className="my-auto">
                      <p className="text-nowrap">+VAT EX</p>
                    </strong>
                    <div>
                      <p className="align-self-start">
                        /month/user, billed monthly or billed annually
                      </p>
                    </div>
                  </div>
                  {showSubsBtn && activePlan === "freemium" && (
                    <div class="d-grid col-7 mx-auto">
                      <Button
                        onClick={() => handleClickClientLogin(1000, "premium")}
                      >
                        Subscribe
                      </Button>
                    </div>
                  )}
                </div>
                {activePlan === "premium" && (
                  <div className="subs-card__payment position-absolute bottom-0 start-50 translate-middle-x text-center w-100">
                    <div className="subs-card__basic text-center ">
                      Your current subscription
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col mb-3 mb-sm-3 mb-md-0">
              <div className="subs-card py-4 px-4 bg-white">
                <h5>Premium+</h5>
                <ul>
                  <li>✓ Everything in Premium PLUS:</li>
                  <li>✓ Advance Applicant Tracking System (ATS)</li>
                </ul>
                <div
                  className={
                    activePlan === "premium+"
                      ? "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100 mb-5"
                      : "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100"
                  }
                >
                  <div className="d-flex justify-content-center px-3 mx-auto">
                    <h3 className="text-nowrap me-2">₱ 2,000</h3>
                  </div>
                  <div className="d-flex px-4 mx-auto justify-content-center gap-2 ">
                    <strong className="my-auto">
                      <p className="text-nowrap">+VAT EX</p>
                    </strong>
                    <div>
                      <p className="align-self-start">
                        /month/user, billed monthly or billed annually
                      </p>
                    </div>
                  </div>
                  {showSubsBtn && activePlan === "freemium" && (
                    <div class="d-grid gap-2 col-6 mx-auto">
                      <Button
                        onClick={() => handleClickClientLogin(2000, "premium+")}
                      >
                        Subscribe
                      </Button>
                    </div>
                  )}
                </div>
                {activePlan === "premium+" && (
                  <div className="subs-card__payment position-absolute bottom-0 start-50 translate-middle-x text-center w-100">
                    <div className="subs-card__basic text-center ">
                      Your current subscription
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="subs-card-elite-cont col mb-3 mb-sm-3 mb-md-0">
              <div className="subs-card card-elite py-4 px-4 bg-white">
                {/* <div className="subs-card__elite p-2">
                  <img
                    src={require("../../../assets/images/hiresmarter.png")}
                    className="img-fluid"
                  />
                </div> */}
                <div className="hiresmarter">
                  <img
                    src={require("../../../assets/images/Vector/hiresmarter/hiresmarter-bg.png")}
                    className="img-fluid hiresmarter-bg"
                  />
                  <img
                    src={require("../../../assets/images/Vector/hiresmarter/hiresmarter-left.png")}
                    className="img-fluid hiresmarter-left"
                  />
                  <img
                    src={require("../../../assets/images/Vector/hiresmarter/hiresmarter-right.png")}
                    className="img-fluid hiresmarter-right"
                  />
                  <img
                    src={require("../../../assets/images/hiresmarter.png")}
                    className="img-fluid hiresmarter-label"
                  />
                </div>
                <h5>ELITE</h5>
                <ul>
                  <li>✓ Everything in Premium+ PLUS:</li>
                  <li>✓ AI Chatbot</li>
                  <li>✓ AI Job Description Generator</li>
                  <li>✓ AI Talent Recommendations</li>
                </ul>
                <div
                  className={
                    activePlan === "elite"
                      ? "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100 mb-5"
                      : "subs-card__payment subs-card__payment-mod position-absolute bottom-0 start-50 translate-middle-x text-center w-100"
                  }
                >
                  <div className="d-flex justify-content-center px-3 mx-auto">
                    <h3 className="text-nowrap me-2">₱ 3,000</h3>
                  </div>
                  <div className="d-flex px-3 mx-auto justify-content-center gap-2 ">
                    <strong className="my-auto">
                      <p className="text-nowrap">+VAT EX</p>
                    </strong>
                    <div>
                      <p className="align-self-start">
                        /month/user, billed monthly or billed annually
                      </p>
                    </div>
                  </div>
                  {showSubsBtn && activePlan === "freemium" && (
                    <div class="d-grid gap-2 col-6 mx-auto">
                      <Button
                        onClick={() => handleClickClientLogin(3000, "elite")}
                      >
                        Subscribe
                      </Button>
                    </div>
                  )}
                </div>
                {activePlan === "elite" && (
                  <div className="subs-card__payment position-absolute bottom-0 start-50 translate-middle-x text-center w-100">
                    <div className="subs-card__basic text-center ">
                      Your current subscription
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!clientId && (
          <div className="sign__up__now">
            <Button onClick={() => navLogin()}>Sign Up Now</Button>
          </div>
        )}

        <p className="text-center text-white pt-3">
          Got a question about our pricing?{" "}
          <Link to="/contact-us">Contact us</Link>
        </p>
      </div>
    </>
  );
};

export default ProductSubscribeBody;
