// import React from "react";
// import CustomContainer from "../../../../../layouts/Container/CustomContainer";
// import ContainerIndex from "./Container";
// import regBg from "../../../../../assets/images/Background/Global/loginRegBg.jpg";
// import RegistrationAnalytics from "../../../../../Utilities/Analytics/RegistrationAnalytics";
// const Registration = () => {
//   return (
//     <>
//       <RegistrationAnalytics />
//       <CustomContainer backgroundImage={regBg} className="customContainerLogin">
//         <ContainerIndex />
//       </CustomContainer>
//     </>
//   );
// };

// export default Registration;
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomContainer from "../../../../../layouts/Container/CustomContainer";
import ContainerIndex from "./Container";
import regBg from "../../../../../assets/images/Background/Global/loginRegBg.jpg";
import RegistrationAnalytics from "../../../../../Utilities/Analytics/RegistrationAnalytics";
import { fetchSysparValue } from "../../../../../Utilities/Fetch/FetchSysparValue";
import { Navigate } from "react-router";

const RegistrationTalent = () => {
  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State", sendState);
  const sendClientState = "talent";
  console.log(sendClientState);
  const navigate = useNavigate();
  const [redirectToMaintenance, setRedirectToMaintenance] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
          console.log("New Site Key:", sysparVal);

          if (sysparVal === "Y") {
            setRedirectToMaintenance(true);
          }
        });
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, []);

  if (redirectToMaintenance) {
    return <Navigate to="/under-maintenance" />;
  }

  return (
    <>
      <RegistrationAnalytics />
      <CustomContainer backgroundImage={regBg} className="customContainerLogin">
        <ContainerIndex />
      </CustomContainer>
    </>
  );
};

export default RegistrationTalent;
