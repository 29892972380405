import React, { useEffect, useState, useCallback } from "react";
import {
  Alert,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import DocumentCertModal from "../../FormTalent/MyResume/DocumentCertModal";
import axios from "axios";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import QuestionModal from "../../QuestionModal";
import { List } from "react-content-loader";
import "../../../assets/scss/Talent/Layout/MyResume/DocumentCertificates.scss";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";

const DocumentCertificates = ({ certificates }) => {
  const token = useAuthToken();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const endpointKey = process.env.REACT_APP_KEYCERTIFICATE_API;

  const [cvData, setCVData] = useState([]);
  const apiKey = process.env.REACT_APP_TALENT_API;

  const [isLoading, setIsLoading] = useState(false);
  const [pdfPath, setPdfPath] = useState("");
  const [showDocumentsCertModal, setShowDocumentsCertModal] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);

  const clickDocumentsCertBtn = () => {
    setShowDocumentsCertModal(true);
  };

  // State variables for modal
  const [successModalOpen, setsuccessModalOpen] = useState(false);

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const handleManageClick = () => {
    window.location.reload();
  };

  // const endpointLink = `${endpointKey}/?fileName=${cvName}`;

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    setModalOpen(!modalOpen);
    setPdfPath(downloadLink);
  };

  const deleteCert = (
    id,
    createdAt,
    documentTitle,
    documentName,
    validityDate,
    documentPath
  ) => {
    setSelectedDocument({
      id,
      createdAt,
      documentTitle,
      documentName,
      validityDate,
      documentPath,
    });
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id: selectedDocument.id,
        createdAt: selectedDocument.createdAt,
        documentTitle: selectedDocument.documentTitle,
        documentName: selectedDocument.documentName,
        validityDate: selectedDocument.validityDate,
        filePath: selectedDocument.documentPath,
        isDeleted: 1,
      };

      // await axios
      //   .patch(`${Config.nxtApi}/api/talent/updatecertificate`, payload, {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   })
      //   .then((response) => {
      //     getTalentCert();
      //     setsuccessModalOpen(false);
      //     setIsLoading(false);
      //   })
      //   .catch((err) => {
      //     console.log("Error ", err);
      //   });

      const response = await RequestMethod(
        "patch",
        "nxtApi",
        "/api/talent/updatecertificate",
        payload,
        token,
        null
      );
      // console.log(response);
      getTalentCert();
      setsuccessModalOpen(false);
      setIsLoading(false);
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const getTalentCert = async () => {
    setIsLoading(true);
    try {
      const payload = {
        numRow: 100,
        sortKeys: "desc",
      };
      // const response = await axios.post(
      //   `${Config.nxtApi}/api/talent/certificates`,
      //   payload,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + token,
      //     },
      //   }
      // );

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/talent/certificates",
        payload,
        token,
        null
      );

      // console.log(response);

      setCVData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error.response);
    }
  };

  useEffect(() => {
    setCVData(certificates.data.data);
  }, [certificates]);

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const downloadCV = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    window.open(downloadLink);
  };

  const viewImage = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    window.open(downloadLink);
  };

  return (
    <div className="documents-certificate-talent">
      <div className="header-container">
        <h6>DOCUMENTS (CERTIFICATES, ETC.)</h6>
        <Button
          onClick={() => {
            clickDocumentsCertBtn();
          }}
        >
          Add Certificate
        </Button>
      </div>
      <hr className="line-seperator" />
      <div className="cv-list">
        {isLoading ? (
          <List />
        ) : cvData && cvData.length > 0 ? (
          cvData.map((cvData, index) => (
            <React.Fragment key={cvData.id}>
              <>
                <Label>{cvData.documentPath}</Label>
                <div className="remove-icon-container">
                  <div
                    className="remove-work-container"
                    onClick={() => {
                      setsuccessModalOpen(true);
                      deleteCert(
                        cvData.id,
                        cvData.createdAt,
                        cvData.documentTitle,
                        cvData.documentName,
                        cvData.validityDate,
                        cvData.documentPath
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                    <span>Remove</span>
                  </div>
                  {cvData.documentPath.endsWith(".docx") ? (
                    <Button onClick={() => downloadCV(cvData.documentPath)}>
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="download-attachment-btn"
                      />
                      Click to Download
                    </Button>
                  ) : (
                    <>
                      {cvData.documentPath.endsWith(".png") ||
                      cvData.documentPath.endsWith(".jpg") ? (
                        <Button onClick={() => viewImage(cvData.documentPath)}>
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="view-attachment-btn"
                          />
                          Click to view image
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleModal(cvData.documentPath)}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="view-attachment-btn"
                          />{" "}
                          View Attachment
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </>
            </React.Fragment>
          ))
        ) : (
          <Alert color="secondary">No available certificate</Alert> // Render list component if cvData is empty
        )}
      </div>
      <DocumentCertModal
        isOpen={showDocumentsCertModal}
        close={() => setShowDocumentsCertModal(false)}
      />

      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader
          toggle={toggleModal}
          style={{ fontFamily: "Lato", fontSize: "18px" }}
        ></ModalHeader>
        <ModalBody style={{ background: "#E0E0E0" }}>
          <div
            className="document-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={pdfPath}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <div className="page-wrapper">
                <Page
                  pageNumber={currentPage}
                  width={window.innerWidth * 0.41} // Adjust the width as desired
                />
              </div>
            </Document>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="page-navigation">
            <Button
              onClick={previousPage}
              disabled={currentPage <= 1}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginRight: 10,
                fontFamily: "Lato",
              }}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              onClick={nextPage}
              disabled={currentPage >= numPages}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginLeft: 10,
                fontFamily: "Lato",
              }}
            >
              Next
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <QuestionModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/QuestionIcon.png")}
        modalLabel="Warning!"
        modalSublabel="Are you sure you want to delete this document?"
        confirmButtonText={isLoading ? <Loader /> : "Delete"}
        onConfirm={confirmDelete}
        onCancel={toggleSuccess}
      />
    </div>
  );
};

export default DocumentCertificates;
