import React from "react";
import { useLocation } from "react-router-dom";

const PremiumDetails = ({ details, highlight }) => {
  const location = useLocation();
  const specificPath = ["/client/subscription-select"];
  return (
    <div
      className={`prem__card--details ${
        highlight && !specificPath.includes(location.pathname)
          ? "prem__card--details--recommended"
          : "prem__card--details--default"
      }`}
    >
      {details?.map((item, index) =>
        item.isBullet ? (
          <ul key={index}>
            <li>{item.text}</li>
          </ul>
        ) : (
          <label className="pt-2 d-flex" key={index}>
            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
            {item.text}
          </label>
        )
      )}
    </div>
  );
};

export default PremiumDetails;
