import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Container,
} from "reactstrap";
import "../../assets/scss/Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

function Header(args) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  const navigate = useNavigate();

  const toggleOffcanvas = () => setIsOpen(!isOpen);

  const handleComingsoon = () => {
    navigate("/comingsoonin");
  };

  const handleProfile = () => {
    navigate("/talent/profile");
  };

  const clickJobList = () => {
    navigate("/talent/job-list", { state: { searchVal: "" } });
    setActiveItem("talent/joblist");
  };

  const clickMyResume = () => {
    navigate("/talent/my-resume");
  };
  const handleAppliedJobClick = () => {
    navigate("/talent/applied-job");
  };

  const handleClickLogo = () => {
    navigate("/");
  };

  const isActiveItem = (routeName) => {
    return location.pathname === `/${routeName}`;
  };

  return (
    <div className="header-bg">
      <Container style={{ padding: "0" }}>
        <Navbar expand="lg" bg="light" style={{ padding: 0 }}>
          <NavbarBrand>
            <img
              alt="nxt-logo"
              src={require("../../assets/images/nxt-logo.png")}
              className="nxt-logo"
              onClick={handleClickLogo}
            />
          </NavbarBrand>
          <NavbarToggler onClick={toggleOffcanvas} />

          <Collapse isOpen={isOpen} navbar>
            <Nav
              className="nav-menu"
              navbar
              style={{
                fontSize: "14px",
                textTransform: "uppercase",
                gap: "10px",
                alignItems: "center",
                // marginLeft: "20px",
              }}
            >
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="dropdown-menu">
                  Profile
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={handleProfile}>Profile</DropdownItem>
                  <DropdownItem onClick={clickMyResume}>My Resume</DropdownItem>
                  <DropdownItem onClick={handleAppliedJobClick}>
                    Applied Job
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                  onClick={() => clickJobList()}
                  className="talent-list-btn"
                  active={isActiveItem("talent/joblist")}
                >
                  Job List
                </NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="talent-list-btn">
                  Help
                </DropdownToggle>
                <DropdownMenu end id="dropdown-option">
                  <DropdownItem onClick={() => navigate("/faq")}>
                    FAQs
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/privacy-policy")}>
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => navigate("/terms-and-condition")}
                  >
                    Terms and Conditions
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/data-deletion")}>
                    Data Deletion
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/cookie-policy")}>
                    Cookie Policy
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Button
              onClick={clickMyResume}
              style={{ background: "#efbb20", border: "none" }}
            >
              <FontAwesomeIcon
                icon={faFileLines}
                style={{ fontSize: "15px", marginRight: "10px" }}
              />
              My Resume
            </Button>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Header;
