import {
  faPenSquare,
  faTrashCan,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useState } from "react";
import { Table, Dropdown, Form, Button, InputGroup } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router";
import "../../../../../assets/scss/Page/Admin/ATS/ManageJobTable/_manageJobTable.scss";
import CustomModal from "../../../../../components/UI/Modal";
import FormModal from "../../../../../components/UI/Modal/FormModal";
import RequiredModal from "../../../../../components/RequiredModal";
import Config from "../../../../../Config/config";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import { List } from "react-content-loader";
import { handleAxiosError } from "../../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";
import { useAtsJob } from "../../../../../hook/ats/useAtsJob";
import { useAtsTalent } from "../../../../../hook/ats/useAtsTalent";
import { useMessageHandler } from "../../../../../hook/error/messageHandler";

const ManageJobFormTable = ({
  jobs,
  setSearchKeyword,
  setJobs,
  reloadData,
  loadTable,
  jobNextData,
  dataNextFilter,
  cursor,
}) => {
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    handleResponse,
    handleError,
    handleLogout,
    closeModal,
  } = useMessageHandler();
  console.log("Table =====================", jobs);
  const { getSpecificJob, specificJob, jobError, prevUpdatedAt } = useAtsJob();
  const { getCandidateList, candidateList, candidateListError } =
    useAtsTalent();
  const devBase = localDevBase();
  const token = useAuthToken();
  const [isViewJob, setIsViewJob] = useState(false);
  const [inpKeyword, setInpKeyword] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [doneSubmit, setDoneSubmit] = useState("");
  const [filterValue, setFilterValue] = useState("open");
  const navigate = useNavigate();
  const [checkAll, setCheckAll] = useState(false);

  const [checkboxes, setCheckboxes] = useState(jobs);
  const [checkboxesNextData, setCheckboxesNextData] = useState(
    jobNextData?.data?.result?.data
  );
  const [sortedData, setSortedData] = useState();

  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  console.log(checkAll, jobs);

  const tableHeader = [
    { headerTitle: "Job Title" },
    { headerTitle: "Job Owner" },
    { headerTitle: "Job Created" },
    { headerTitle: "ATS" },
    { headerTitle: "Job Status" },
    { headerTitle: "Action" },
  ];

  console.log("hello!!", jobNextData?.data?.result?.data);
  console.log(filterValue);
  console.log("this is sorted", sortedData?.data?.result?.data);

  useEffect(() => {
    setCheckboxes(jobs);
    setCheckboxesNextData(jobNextData?.data?.result?.data);
  }, [jobs, jobNextData?.data?.result?.data]);

  const handleSearchAndSort = async (e, filter = null) => {
    e?.preventDefault();
    setSearchKeyword(inpKeyword);

    try {
      const params = {};

      if (filter) {
        params.filter = filter;
      }

      if (!filter || inpKeyword) {
        params.keyWord = inpKeyword;
      }

      const response = await axios.get(`${devBase}/api/ats/manage-job`, {
        params,
        headers,
      });

      const jobData = response?.data?.result?.data || [];
      setJobs(jobData);

      setCheckboxesNextData(response);
      cursor(response?.data?.result?.cursor);
      console.log(response?.data?.result?.cursor);

      setSortedData(response?.data?.result?.data);
    } catch (error) {
      console.log(error);
      handleAxiosError(error);
    }
  };

  useEffect(() => {
    handleSearchAndSort();
  }, []);

  const handleSelectChange = (e) => {
    setFilterValue(e.target.value);
    handleSearchAndSort(null, e.target.value);
    dataNextFilter(e.target.value);
  };

  const openJobsCount = jobs.filter(
    (job) => job.jobPostStatus === "open"
  ).length;

  const handleNavCreateJob = async () => {
    navigate("/admin/ats/managing-job", {
      state: { numberOfJobs: openJobsCount },
    });
  };

  const handleEditJob = (val) => {
    navigate("/admin/ats/managing-job", {
      state: { editVal: val, numberOfJobs: jobs.length },
    });
  };

  const handleViewJob = (val) => {
    navigate("/admin/ats/managing-job", {
      state: { editVal: { ...val, isViewJob: true } },
    });
  };

  const [jobVal, setJobVal] = useState([]);
  const handleShowDeleteModal = (val) => {
    setJobVal(val);
    setModalMode("Deleted");
    setModalShow(true);
  };

  const handleDelete = async () => {
    const deleteJobPayload = {
      patchOptions: {
        jobId: jobVal.jobId,
        updatedAt: jobVal.updatedAt,
      },
      setOptions: {
        isDeleted: true,
      },

      props: [jobVal.createdAt],
    };

    try {
      const response = await axios.delete(`${devBase}/api/ats/manage-job/`, {
        data: deleteJobPayload,
        headers,
        // withCredentials: true,
      });

      setJobs((prevJobs) =>
        prevJobs.filter((job) => job.jobId !== jobVal.jobId)
      );
      setModalMode("SuccessDeleted");
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    }
  };

  const handleGoCandidateList = async (val) => {
    console.log(val);
    try {
      await getCandidateList(val.jobId);
      await getSpecificJob(val.jobId);
    } catch (error) {}
    // navigate("/admin/ats", { state: val });
  };

  const [selectedJobs, setSelectedJobs] = useState([]);
  console.log(selectedJobs);
  const handleSelectAll = () => {
    setCheckAll((prevCheckAll) => !prevCheckAll);

    if (!checkAll) {
      const allSelectedJobs = checkboxes
        .filter(
          (job) =>
            job.jobPostStatus === "open" || job.jobPostStatus === "expired"
        )
        .map((job) => ({
          jobId: job.jobId,
          jobTitle: job.jobTitle,
          jobPostStatus: job.jobPostStatus,
          isActive: job?.isActive,
          updatedAt: job.updatedAt,
        }));

      setSelectedJobs(allSelectedJobs);
    } else {
      setSelectedJobs([]);
    }
  };

  useEffect(() => {
    console.log("Selected Jobs:", selectedJobs);
  }, [selectedJobs]);

  const isChecked = (job) => {
    if (checkAll) {
      return job?.jobPostStatus === "open";
    } else {
      return selectedJobs.some(
        (selectedJob) => selectedJob.jobId === job?.jobId
      );
    }
  };

  const handleCheckboxChange = (job) => {
    const isJobSelected = selectedJobs.some(
      (selectedJob) => selectedJob.jobId === job?.jobId
    );

    if (isJobSelected) {
      setSelectedJobs((prevSelectedJobs) =>
        prevSelectedJobs.filter(
          (selectedJob) => selectedJob.jobId !== job?.jobId
        )
      );
    } else {
      setSelectedJobs((prevSelectedJobs) => [
        ...prevSelectedJobs,
        {
          jobId: job?.jobId,
          jobTitle: job?.jobTitle,
          jobPostStatus: job?.jobPostStatus,
          isActive: job?.isActive,
          updatedAt: job.updatedAt,
        },
      ]);
    }
  };

  const areAllJobsActiveTrue =
    selectedJobs.length > 0 &&
    selectedJobs.every((job) => job.isActive === true);

  const areAllJobsActiveFalse =
    selectedJobs.length > 0 && !areAllJobsActiveTrue;

  const conflictStatus =
    areAllJobsActiveTrue === true && areAllJobsActiveFalse === true;

  const handleAddToAtsBtn = () => {
    setModalShow(true);
    setModalMode("Add");
  };

  const handleConfirmAddToAts = async () => {
    const payloadAddToAts = selectedJobs.map((job) => ({
      patchOptions: {
        jobId: job.jobId,
        updatedAt: job.updatedAt,
      },
      setOptions: {
        isActive: !job.isActive,
      },
    }));

    try {
      const response = await axios.patch(
        `${devBase}/api/ats/manage-job/atsJob`,
        payloadAddToAts,
        {
          headers,
        }
      );

      setModalMode("Success");
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
      handleError(error, "client", "deleteUser");
    }
  };

  const handleRemoveToAtsBtn = () => {
    setModalShow(true);
    setModalMode("Remove");
  };

  const handleConfirmRemoveToAts = async () => {
    const payloadRemoveToAts = selectedJobs.map((job) => ({
      patchOptions: {
        jobId: job.jobId,
        updatedAt: job.updatedAt,
      },
      setOptions: {
        isActive: !job.isActive,
      },
    }));

    try {
      const response = await axios.patch(
        `${devBase}/api/ats/manage-job/atsJob`,
        payloadRemoveToAts,
        {
          headers,
          // withCredentials: true,
        }
      );

      console.log(response);
      setModalMode("DeletedAts");
    } catch (error) {
      console.log(error);
      handleAxiosError(error);
      if (error.response.status === 400 && error.response.data.code === "1") {
        console.log("d", error);
        // setModalShow(true);
        setModalMode("hasCandidates");
      }
      handleError(error, "client", "deleteUser");
    }
  };

  const areSelectedJobsActiveSame = (selectedJobs) => {
    if (selectedJobs.length === 0) {
      return false;
    }

    const firstJobIsActive = selectedJobs[0].isActive;

    return selectedJobs.every((job) => job.isActive === firstJobIsActive);
  };

  const areAllSelectedJobsActiveSame = areSelectedJobsActiveSame(selectedJobs);

  const handleCloseModal = () => {
    reloadData();
    setCheckAll(false);
    setSelectedJobs([]);
    setModalShow(false);
  };

  const handleShowNotActiveModal = () => {
    setModalMode("AtsNotActive");
    setModalShow(true);
  };

  const handleGetJobDetails = async ({ jobId, isViewJob }) => {
    console.log(jobId, isViewJob);
    try {
      const res = getSpecificJob(jobId);
      setIsViewJob(isViewJob);
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (specificJob) {
  //     navigate("/admin/ats/managing-job", {
  //       state: { editVal: { ...specificJob, isViewJob: isViewJob } },
  //     });
  //   }
  // }, [specificJob]);

  useEffect(() => {
    if (jobs) {
      setSortedData(jobs);
    }
  }, [jobs]);

  useEffect(() => {
    if (specificJob && candidateList) {
      console.log("navigate", candidateList);
      navigate("/admin/ats", { state: { candidateList, specificJob } });
    } else if (specificJob) {
      navigate("/admin/ats/managing-job", {
        state: {
          editVal: {
            ...specificJob,
            isViewJob: isViewJob,
            prevUpdatedAt: prevUpdatedAt,
          },
        },
      });
    }
  }, [candidateList, specificJob, isViewJob, prevUpdatedAt]);

  useEffect(() => {
    if (jobError) {
      handleError(jobError, "client", "deleteUser");
    }

    if (candidateListError) {
      handleError(candidateListError, "client", "deleteUser");
    }
  }, [jobError, candidateListError]);

  return (
    <>
      <div className="p-3 border rounded d-flex">
        <Form className="d-flex">
          <InputGroup className="w-100 me-2">
            <Form.Control
              placeholder="Enter Keyword"
              aria-label="Enter Keyword"
              aria-describedby="basic-addon2"
              onChange={(e) => setInpKeyword(e.target.value)}
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              type="submit"
              onClick={(e) => handleSearchAndSort(e)}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </Button>
          </InputGroup>
          <Form.Select
            aria-label="Filter by status"
            value={filterValue}
            onChange={(value) => handleSelectChange(value)}
          >
            <option value="open">Open</option>
            <option value="closed">Closed</option>
            <option value="expired">Expired</option>
            <option value="draft">Draft</option>
          </Form.Select>
        </Form>
        <div className="ms-auto">
          <div className="ms-auto">
            {areAllJobsActiveTrue ||
              (areAllSelectedJobsActiveSame && (
                <Button
                  variant="primary"
                  className="text-white me-2"
                  onClick={handleAddToAtsBtn}
                >
                  Add to ATS
                </Button>
              ))}
            {areAllJobsActiveFalse ||
              (areAllSelectedJobsActiveSame && (
                <Button
                  variant="danger"
                  className="text-white me-2"
                  onClick={handleRemoveToAtsBtn}
                >
                  Remove in ATS
                </Button>
              ))}

            <Button
              className="manage-job__bg_blue text-white"
              onClick={handleNavCreateJob}
            >
              Create Job
            </Button>
          </div>
        </div>
      </div>
      {loadTable ? (
        <div>
          <List />
        </div>
      ) : (
        <>
          <Table hover className="manage-job__table" responsive>
            <thead>
              <tr className="table-secondary">
                <th>
                  <input
                    type="checkbox"
                    checked={checkAll}
                    onChange={handleSelectAll}
                  />
                </th>
                {tableHeader.map((val, key) => (
                  <th key={key}>{val.headerTitle}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* {jobs.length === 0 ? "" : ""} */}
              {/* {sortedData?.data?.result?.data.length === 0 ? (
                <tr>
                  <td colSpan={tableHeader.length + 1} className="text-center">
                    No Data Found
                  </td>
                </tr>
              ) : (
                <>
                  {sortedData?.data?.result?.data?.map((val, key) => (
                    <tr key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedJobs.some(
                            (selectedJob) => selectedJob.jobId === val.jobId
                          )}
                          onChange={() => handleCheckboxChange(val)}
                          disabled={
                            val.jobPostStatus !== "expired" &&
                            val.jobPostStatus !== "open"
                          }
                        />
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        <small
                          onClick={() =>
                            val?.isActive
                              ? handleGoCandidateList(val)
                              : handleShowNotActiveModal()
                          }
                        >
                          {val?.jobTitle}
                        </small>
                      </td>
                      <td>
                        <small>{val?.hiringManager?.name}</small>
                      </td>
                      <td>
                        <small>
                          {moment(val?.createdAt).format("MM-DD-YYYY")}
                        </small>
                      </td>
                      <td>
                        <small>{val?.isActive ? "Active" : "Not active"}</small>
                      </td>
                      <td className="text-capitalize">{val?.jobPostStatus}</td>
                      <td>
                        <div className="ats_action_container">
                          {val?.jobPostStatus === "closed" ? (
                            <div
                              className="edit-container"
                              onClick={() => handleViewJob(val)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </div>
                          ) : (
                            <div className="d-flex">
                              <div
                                className="edit-container me-2"
                                onClick={() => handleEditJob(val)}
                              >
                                <FontAwesomeIcon
                                  icon={faPenSquare}
                                  className="edit_icon"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )} */}
              {jobs.length === 0 ? (
                <>
                  {jobNextData === 0 ? (
                    <tr>
                      <td
                        colSpan={tableHeader.length + 1}
                        className="text-center"
                      >
                        No Data Found
                      </td>
                    </tr>
                  ) : (
                    <>
                      {Array.isArray(checkboxesNextData) &&
                      checkboxesNextData.length > 0 ? (
                        checkboxesNextData.map((val, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedJobs.some(
                                  (selectedJob) =>
                                    selectedJob.jobId === val.jobId
                                )}
                                onChange={() => handleCheckboxChange(val)}
                                disabled={
                                  val.jobPostStatus !== "expired" &&
                                  val.jobPostStatus !== "open"
                                }
                              />
                            </td>
                            <td style={{ cursor: "pointer" }}>
                              <small
                                onClick={() =>
                                  val?.isActive
                                    ? handleGoCandidateList(val)
                                    : handleShowNotActiveModal()
                                }
                              >
                                {val?.jobTitle}
                              </small>
                            </td>
                            <td>
                              <small>{val?.hiringManager?.name}</small>
                            </td>
                            <td>
                              <small>
                                {moment(val?.createdAt).format("MM-DD-YYYY")}
                              </small>
                            </td>
                            <td>
                              <small>
                                {val?.isActive ? "Active" : "Not active"}
                              </small>
                            </td>
                            <td className="text-capitalize">
                              {val?.jobPostStatus}
                            </td>
                            <td>
                              <div className="ats_action_container">
                                {val?.jobPostStatus === "closed" ? (
                                  <div
                                    className="edit-container"
                                    onClick={() =>
                                      handleGetJobDetails({
                                        jobId: val.jobId,
                                        isViewJob: true,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEye} />
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <div
                                      className="edit-container me-2"
                                      onClick={() =>
                                        handleGetJobDetails({
                                          jobId: val.jobId,
                                          isViewJob: false,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faPenSquare}
                                        className="edit_icon"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={tableHeader.length + 1}
                            className="text-center"
                          >
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {checkboxes.map((val, key) => (
                    <tr key={key}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedJobs.some(
                            (selectedJob) => selectedJob.jobId === val.jobId
                          )}
                          onChange={() => handleCheckboxChange(val)}
                          disabled={
                            val.jobPostStatus !== "expired" &&
                            val.jobPostStatus !== "open"
                          }
                        />
                      </td>
                      <td style={{ cursor: "pointer" }}>
                        <small
                          onClick={() =>
                            val?.isActive
                              ? handleGoCandidateList(val)
                              : handleShowNotActiveModal()
                          }
                        >
                          {val?.jobTitle}
                        </small>
                      </td>
                      <td>
                        <small>{val?.hiringManager?.name}</small>
                      </td>
                      <td>
                        <small>
                          {moment(val?.createdAt).format("MM-DD-YYYY")}
                        </small>
                      </td>
                      <td>
                        <small>{val?.isActive ? "Active" : "Not active"}</small>
                      </td>
                      <td className="text-capitalize">{val?.jobPostStatus}</td>
                      <td>
                        <div className="ats_action_container">
                          {val?.jobPostStatus === "closed" ? (
                            <div
                              className="edit-container"
                              onClick={() =>
                                handleGetJobDetails({
                                  jobId: val.jobId,
                                  isViewJob: true,
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </div>
                          ) : (
                            <div className="d-flex">
                              <div
                                className="edit-container me-2"
                                onClick={() =>
                                  handleGetJobDetails({
                                    jobId: val.jobId,
                                    isViewJob: false,
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faPenSquare}
                                  className="edit_icon"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </>
      )}

      <CustomModal
        show={modalShow}
        hasOption={true}
        onHide={() => setModalShow(false)}
        modalMessage={
          modalMode === "Add"
            ? "Are you sure to add this job posting to ATS?"
            : modalMode === "Remove"
            ? "Are you sure to remove this job posting from ATS?"
            : modalMode === "Success"
            ? "Job successfully added to ATS. Press 'OK' to proceed."
            : modalMode === "DeletedAts"
            ? "Successfully removed from ATS"
            : modalMode === "Deleted"
            ? "Are you sure to delete this job posting?"
            : modalMode === "SuccessDeleted"
            ? "Successfully Deleted"
            : modalMode === "hasCandidates"
            ? "Applicants processing is ongoing. Job can't be removed in ATS."
            : modalMode === "AtsNotActive"
            ? "Job ATS status not active"
            : ""
            ? modalMode === "exceededJobPosts"
            : "Exceeded to 20 job posts"
        }
        buttonName="OK"
        modalType={
          modalMode === "DeletedAts" ||
          modalMode === "Success" ||
          modalMode === "SuccessDeleted" ||
          modalMode === "exceededJobPosts"
            ? "Success"
            : modalMode === "Remove" ||
              modalMode === "Add" ||
              modalMode === "Deleted"
            ? "Warning"
            : modalMode === "hasCandidates" || modalMode === "AtsNotActive"
            ? "Oops!"
            : "Error"
        }
        onClick={
          modalMode === "Add"
            ? handleConfirmAddToAts
            : modalMode === "Remove"
            ? handleConfirmRemoveToAts
            : modalMode === "Deleted"
            ? handleDelete
            : modalMode === "DeletedAts" ||
              modalMode === "Success" ||
              modalMode === "SuccessDeleted" ||
              modalMode === "hasCandidates" ||
              modalMode === "AtsNotActive"
            ? handleCloseModal
            : () => console.log("No Type")
        }
      />
    </>
  );
};

const DropdownButton = ({ currentValue, data, onStatusChange }) => {
  const [selectedValue, setSelectedValue] = useState(currentValue);

  const handleDropdownChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    onStatusChange(newValue, data);
  };

  return (
    <>
      <select
        className="ats_status_select"
        value={selectedValue}
        onChange={handleDropdownChange}
      >
        <option value="open">Open</option>
        <option value="draft">Draft</option>
        <option value="closed">Closed</option>
        <option value="expired">Expired</option>
      </select>
    </>
  );
};

export default ManageJobFormTable;
