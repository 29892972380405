import React from "react";
import CustomTextArea from "../../../../../components/Form/CustomTextArea";
import CustomButton from "../../../../../components/UI/CustomButton";
import { NoteSchema } from "../../../../../Utilities/Reusable/Validation/Schema/ATS/NoteSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const Note = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(NoteSchema) });

  const submitData = (data) => {
    console.log(data);
  };

  return (
    <div className="mb-5">
      <form onSubmit={handleSubmit(submitData)}>
        <CustomTextArea
          errors={errors}
          label="Notes"
          {...register("notes")}
          inputClassname="note"
          className="noteContainer"
        />
        <div className="container_right_btn m-0 mt-2">
          <CustomButton name="Send" className="customDBlueBtn" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default Note;
