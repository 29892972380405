import React from "react";

const FullName = ({ lastName, firstName, middleName }) => {
  const fullName = () => {
    if (lastName === "N/A" && firstName === "N/A" && middleName === "N/A") {
      return "N/A";
    }
    return `${lastName}, ${firstName}${
      middleName !== "N/A" ? ` ${middleName}` : ""
    }`;
  };

  return <h1>{fullName()}</h1>;
};

export default FullName;
