import Config from "../Config/config";
import axios from "axios";
import { useAuthToken } from "../Utilities/Auth/Token";
import { updateProfile } from "../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
import { CVParsing } from "./CVParsing";
import { useEffect, useState } from "react";
import moment from "moment";

export const useTalent = () => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const { parseCV } = CVParsing();
  const [s3Url, setS3Url] = useState("");
  const [uploadCVLoad, setUploadCVLoad] = useState(false);
  const uploadCV = async (cv, cvParsing) => {
    setUploadCVLoad(true);
    try {
      const formData = new FormData();
      formData.append("file", cv);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      if (cvParsing === "1") {
        config.params = {
          cvParsing: cvParsing,
        };
      }

      const response = await axios.post(
        `${Config.nxtApi}/api/talent/uploadresume`,
        formData,
        config
      );

      console.log(response);
      dispatch(updateProfile({ resume: response.data.fileName }));

      if (response.data.s3Url) {
        console.log(response.data.s3Url);
        // parseCV(response.data.url);
        setS3Url(response.data.s3Url);
      }

      setUploadCVLoad(false);
    } catch (error) {
      setUploadCVLoad(false);
      console.log("uploadCV : ", error);
    }
  };

  const [viewJobData, setViewJobData] = useState([]);
  const [viewJobDetailsLoad, setViewJobDetailsLoad] = useState(false);
  const [viewJobErrorStatus, setViewJobErrorStatus] = useState([]);
  const viewJobDetails = async (id, job_id) => {
    setViewJobDetailsLoad(true);
    console.log(job_id);
    const payload = {
      job_id: JSON.stringify({ jobId: id, job_id: job_id }),
    };

    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/viewjob`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("Job Details: ", response);
      setViewJobData(response.data);
      setViewJobDetailsLoad(false);
    } catch (error) {
      setViewJobDetailsLoad(false);
      setViewJobErrorStatus(error.response.status);
      console.log("viewJob ", error);
    }
  };

  const [workHistoryResponse, setWorkHistoryResponse] = useState(false);

  const addWorkHistory = async (work) => {
    setWorkHistoryResponse(false);
    const today = moment().format();
    const payload = {
      companyName: work.companyName,
      jobLevel: work.jobLevel,
      jobRole: work.jobRole.substring(0, 255),
      employmentType: work.employmentType,
      workSetup: work.workSetup,
      industry: work.industry,
      experienceDate: {
        startDate: work.experienceDate.startDate,
        endDate: work.isPresent === 0 ? work.experienceDate.endDate : today,
      },
      isPresent: work.isPresent,
    };

    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/workhistory`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log(
        "Save operation successful for company:",
        work.companyName,
        response
      );
      setWorkHistoryResponse(true);
    } catch (err) {
      console.log(err);
    }
  };

  const addAllWorkHistories = async (workHistoryArray) => {
    let lastSuccessfulIndex = -1;

    for (let i = 0; i < workHistoryArray.length; i++) {
      const work = workHistoryArray[i];
      if (!workHistoryResponse) {
        try {
          await addWorkHistory(work);
          lastSuccessfulIndex = i;
        } catch (err) {
          console.log(err);
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        i--;
      }
    }

    if (lastSuccessfulIndex === workHistoryArray.length - 1) {
      window.location.reload();
    }
  };

  const [listCompanyData, setListCompanyData] = useState([]);
  const getCompanies = async () => {
    try {
      const response = await axios.post(`${Config.nxtApi}/api/jobs/company`, {
        numRow: 1000,
      });
      console.log(response);
      setListCompanyData(response.data.data);
    } catch (error) {
      console.log(error);
      const data = error.response.data;
      const status = error.response.status;

      if (status === 401) {
        console.error("Unauthorized:", data);
        localStorage.setItem("error", status);
      } else if (status === 403) {
        console.error("403:", data);
        localStorage.setItem("error", status);
      } else if (status === 500) {
        console.error("Server:", data);
        localStorage.setItem("error", status);
      }
    }
  };

  return {
    uploadCV,
    viewJobDetails,
    viewJobData,
    s3Url,
    uploadCVLoad,
    viewJobDetailsLoad,
    addWorkHistory,
    addAllWorkHistories,
    viewJobErrorStatus,
    listCompanyData,
    getCompanies,
  };
};
