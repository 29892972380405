import React from "react";
import "../../../../../assets/scss/Page/Admin/ATS/_keyskills.scss";
import TitleLine from "../../../../../components/UI/Title/TitleLine";

const Keyskills = ({ data }) => {
  if (!data) {
    return (
      <>
        <TitleLine title="Key Skills"></TitleLine>
        <p>No key skill found</p>
      </>
    );
  }

  const levelsOrder = ["Beginner", "Intermediate", "Advance"];

  const groupedSkills = levelsOrder.reduce((acc, level) => {
    acc[level] = [];
    return acc;
  }, {});

  data.forEach((val) => {
    const level =
      (val.level || "").charAt(0).toUpperCase() + (val.level || "").slice(1);
    if (groupedSkills[level]) {
      groupedSkills[level].push(val);
    }
  });

  return (
    <div>
      <TitleLine title="Key Skills"></TitleLine>
      <div className="keyskill_container">
        {levelsOrder.map((level, key) => (
          <div key={key} className="select_group">
            <label htmlFor={level}>{level}</label>
            <div className="keyskills_list">
              {groupedSkills[level].length === 0 ? (
                <p>No {level} skill found</p>
              ) : (
                groupedSkills[level].map((skill, index) => (
                  <div key={index} className="keyskills_container">
                    <p>
                      {skill.skill} - {skill.experience} years
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Keyskills;
