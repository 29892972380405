import React from "react";
import { Helmet } from "react-helmet";

const TalentAnalytics = (jobPostingSEOData) => {
  return (
    <>
      <Helmet>
        <script
          async
          type="testingtype1212121"
          src="https://www.googletagmanager.com/gtag/js?id=G-YJCV0G5Z73"
        ></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-YJCV0G5Z73');
        `}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(jobPostingSEOData.jobPostingSEOData)}
        </script>
      </Helmet>
    </>
  );
};

export default TalentAnalytics;
