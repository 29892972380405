import React, { useEffect, useState } from "react";
import { Table, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import "../../../../../assets/scss/Page/Admin/ATS/UserManagement/_atsUserManagement.scss";
import UserManagementModal from "./UserManagementModal";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import CustomModal from "../../../../../components/UI/Modal";
import { useSelector } from "react-redux";

import { useRole } from "../../../../../hook/role/useRole";
import useErrorHandler from "../../../../../hook/error/useErrorHandler";
const UserManagementTable = ({
  setShowModal,
  setModalMode,
  deleteUser,
  load,
  clickDeleteUser,
  allClientUserList,
  changeClickDelUser,
  err,
  onDeleteSuccess,
}) => {
  const { showErrorModal, errorMessage, handleError, closeErrorModal } =
    useErrorHandler();
  console.log(clickDeleteUser, allClientUserList);
  const code = useRoleKey();
  const { isLoading, delUserAPI, errorRole, delUserData } = useRole();
  console.log(
    "___________________________________________________________",
    errorRole
  );
  // const [manageUserList, setManageUserList] = useState(allClientUserList || []);
  const [show, setShow] = useState(false);
  const [useList, setUserList] = useState(allClientUserList?.roles || []);
  console.log(useList);
  const devBase = localDevBase();
  const token = useAuthToken();
  const [userRole, setUserRole] = useState(allClientUserList?.roleType || "");

  const [roleId, setRoleId] = useState("");
  const [prevUpdatedAt, setPrevUpdatedAt] = useState("");

  const delUser = async () => {
    changeClickDelUser(false);
    console.log(roleId);
    console.log(`  Authorization: Bearer ${token}`);
    try {
      const response = await delUserAPI(roleId, prevUpdatedAt);
    } catch (error) {
      console.log(error);
    }
  };

  const [editData, setEditData] = useState([]);
  const handleEditJob = (email, clientId, roleType, updatedAt) => {
    console.log(clientId, roleType, email);
    setEditData({ email, clientId, roleType, updatedAt });
    setShow(true);
  };

  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.email
  );

  const ownerId = useSelector(
    (state) => state.reducer.currentUser?.currentUser.ownerId
  );
  console.log(companyName);

  const onEditUserSuccess = () => {
    setModalMode("successEdit");
    setShowModal(true); // Show modal on successful edit
  };

  const handleUserDeleteModal = (value, prevUpdatedAt) => {
    console.log(value);
    setRoleId(value);
    setPrevUpdatedAt(prevUpdatedAt);
    setModalMode("deleteUserOption");
    setShowModal(true);
  };

  const handleEditDeleteError = (value) => {
    err(value);
  };

  useEffect(() => {
    if (clickDeleteUser) {
      delUser();
    }
  }, [clickDeleteUser]);

  console.log(
    "{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{",
    clickDeleteUser
  );

  useEffect(() => {
    if (allClientUserList) {
      console.log("allClientUserList updated:", allClientUserList);
      setUserList(allClientUserList.roles || []);
      setUserRole(allClientUserList.roleType || []);
    }

    // getAllClientUsers();
  }, [allClientUserList]);

  useEffect(() => {
    console.log(errorRole);
    if (errorRole) {
      err(errorRole || []);
    }
  }, [errorRole]);

  useEffect(() => {
    if (delUserData && !errorRole) {
      setModalMode("successDelete");
      console.log("Before deletion:", useList);
      setUserList((prevUserList) =>
        prevUserList.filter((user) => user.clientId !== roleId)
      );
      onDeleteSuccess();
    }
  }, [delUserData, errorRole]);

  return (
    <>
      <Table className="">
        <thead className="table-secondary">
          <tr>
            <th className="user-title">Users</th>
            <th className="action-title">Action</th>
          </tr>
        </thead>
        <tbody>
          {load ? (
            <tr>
              <td colSpan="2" className="text-center">
                Loading...
              </td>
            </tr>
          ) : useList.length === 0 ? (
            <tr>
              <td colSpan="2" className="text-center">
                No data found
              </td>
            </tr>
          ) : (
            useList.map((val, key) => (
              <tr key={key}>
                <td className="user-row">
                  <div>{val.email}</div>
                </td>
                <td className="action-buttons">
                  {userRole === "owner" && !val.isOwner && (
                    <div className="d-flex">
                      <div
                        className="ats__btn-edituser me-2"
                        onClick={() =>
                          handleEditJob(
                            val.email,
                            val.clientId,
                            val.roleType,
                            val.updatedAt
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </div>
                      <div
                        className="ats__btn-deleteuser"
                        onClick={() =>
                          handleUserDeleteModal(val.clientId, val.updatedAt)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </div>
                    </div>
                  )}
                  {userRole === "admin" && val.roleType === "recruiter" && (
                    <div className="d-flex">
                      <div
                        className="ats__btn-edituser me-2"
                        onClick={() =>
                          handleEditJob(
                            val.email,
                            val.clientId,
                            val.roleType,
                            val.updatedAt
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </div>
                      <div
                        className="ats__btn-deleteuser"
                        onClick={() =>
                          handleUserDeleteModal(val.clientId, val.updatedAt)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <UserManagementModal
        show={show}
        close={() => setShow(false)}
        editData={editData}
        onEditUserSuccess={onEditUserSuccess}
        addErr={handleEditDeleteError}
        // onAddUserSuccess={onAddUserSuccess}
      />
    </>
  );
};

export default UserManagementTable;
