import axios from "axios";
import React, { useEffect, useState } from "react";
import Config from "../../../../Config/config";

import "../../../../assets/scss/Page/Admin/Dashboard/_recentlyJobPosted.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useAuthToken, useRoleKey } from "../../../../Utilities/Auth/Token";
import { handleAxiosError } from "../../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

const RecentlyJobPosted = () => {
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [jobPostData, setJobPostData] = useState([]);
  useEffect(() => {
    getJobData();
  }, []);

  const getJobData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${Config.admin}/api/v1/admin/dashboard/job/recently`,
        headers
      );

      setJobPostData(response.data.data);
    } catch (error) {
      console.log(error);
      handleAxiosError(error);
    }
  };

  return (
    <div className="jobPostedList">
      <h1>Recently Job Posted</h1>
      {jobPostData.map((value, index) => {
        return (
          <div key={index} className="jobPostedListInner">
            <div className="jobPostedListcontent">
              <div className="jobPostedListImgContainer">
                {!value.companyLogo || value.companyLogo === "N/A" ? (
                  <FontAwesomeIcon icon={faBuilding} className="icon" />
                ) : (
                  <img
                    crossOrigin="anonymous"
                    src={`${
                      Config.nxtApi
                    }/api/client/profile-pic?fileName=${encodeURIComponent(
                      value.companyLogo
                    )}&thumbNail=yes`}
                    alt="logo"
                  ></img>
                )}
              </div>
              <div className="cardTextContainer">
                <h1>{value.jobTitle}</h1>
                <p>{value.companyName}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentlyJobPosted;
