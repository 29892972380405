import React, { useEffect, useState } from "react";
import "../../../../src/assets/scss/Components/Button/_bottomButton.scss";
import NoticeModal from "../../../../src/components/UI/Modal/NoticeModal";
import AlertWithHide from "../Alert/AlertWithHide";

const BottomButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const closeAtMidnight = () => {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(24, 0, 0, 0); // Set to midnight of the next day

      const timeUntilMidnight = midnight - now;

      setTimeout(() => {
        setIsModalOpen(false);
      }, timeUntilMidnight);
    };

    const intervalId = setInterval(() => {
      setIsModalOpen(true);
      closeAtMidnight();
    }, 24 * 60 * 60 * 1000); // Repeat every 24 hours

    closeAtMidnight(); // Call it initially to set up the first close

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <img
        className="tilt-shake"
        style={{ display: isModalOpen ? "none" : "block" }}
        id="bottomButton"
        src={require("../../../../src/assets/images/Vector/megaphone.png")}
        onClick={toggleModal}
        alt="Bottom Button"
      />
      {isModalOpen && <NoticeModal onClose={toggleModal} />}
    </>
  );
};

export default BottomButton;
