import React from "react";

const ViewingApplicants = () => {
  return (
    <div>
      <p>
        Embrace a fresh approach to recruitment by prioritizing qualified
        professionals over traditional hiring practices. Access a vast and
        diverse pool of talented individuals, fostering workplace diversity.
      </p>
      <ol>
        <li
          style={{
            listStyle: "none",
            textDecoration: "underline",
            margin: "20px 0",
          }}
        >
          6.1 Under manage job button
        </li>
        <ol>
          <li>Go to manage job page</li>
          <li>Click on View Applicants’</li>
          <li>Apply desired filters based on the following:</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>Experience</li>
            <ol style={{ listStyleType: "lower-roman" }}>
              <li>Less than 1 year</li>
              <li>1 to 2 years</li>
              <li>3 to 5 years</li>
              <li>6 to 10 years</li>
              <li>More than 10 years</li>
            </ol>
            <li>Location</li>
            <li>Apply from (desired date)</li>
            <li>Applied date (order of application date)</li>
            <ol style={{ listStyleType: "lower-roman" }}>
              <li>ascending</li>
              <li>descending</li>
            </ol>
            <li>Application Statuses</li>
            <ol style={{ listStyleType: "lower-roman" }}>
              <li>For Screening</li>
              <li>Failed Paper Screening</li>
              <li>For Interview</li>
              <li>Failed Interview</li>
              <li>Job Offer</li>
              <li>Hired</li>
              <li>Withdraw application</li>
            </ol>
          </ol>
          <li>The list of filtered applicants will be displayed</li>
          <li>To remove filters, click on ‘Reset’</li>
          <li>
            To view the applicant’s resume from the loaded list of applicants,
            click on ‘View Resume’
          </li>
        </ol>
        <li
          style={{
            listStyle: "none",
            textDecoration: "underline",
            margin: "20px 0",
          }}
        >
          6.2 Under talent list
        </li>
        <ol>
          <li>Type in the desired keywords.</li>
          <li>Click on ‘Search’ button</li>
          <li>
            List of filtered applicants will be displayed with the following
            details:
          </li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>Applicant’s name</li>
            <li>Applicant’s location</li>
            <li>Applicant’s email address</li>
          </ol>
        </ol>
        <p className="mt-5">
          To see specific applicant’s details, click on the the applicant’s name
          The following Applicant’s details will be displayed:
        </p>
        <ol>
          <li>Basic information</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>Last name</li>
            <li>First name</li>
            <li>Middle name</li>
            <li>Email address</li>
            <li>Contact number</li>
            <li>Birthday</li>
            <li>Address</li>
            <li>Municipality</li>
            <li>Country</li>
          </ol>
          <li>Key Skills</li>
          <li>Work History with the following summary</li>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>Role</li>
            <li>Job level</li>
            <li>Time Period</li>
          </ol>
          <li>Region</li>
          <li>Province /District</li>
          <li>Municipality</li>
          <li>Postal Code</li>
          <li>CV</li>
          <li>Certificates</li>
        </ol>
      </ol>
    </div>
  );
};

export default ViewingApplicants;
