import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import SubsConfPremiumReceipt from "./Receipt/SubsConfPremiumReceipt";
import { SubscriptionFunction } from "../../../../Utilities/Reusable/Function/Subscription/Subscription";

const SubsConfDetails = ({ subsData, typeOfSub, selectedPlan }) => {
  // const { existingTransaction, expiredFreeTrial, subscribedToFreeTrial } =
  //   subsData;

  const { calculatePrice } = SubscriptionFunction();

  const [isChecked, setIsChecked] = useState(true);
  const [billing, setBilling] = useState("3");

  const location = useLocation();
  const [proratedPrice, setProratedPrice] = useState(null);
  const getProratedPrice = () => {
    if (subsData[0].activeSubscription.current !== "freemium") {
      setProratedPrice(
        calculatePrice(
          selectedPlan.amount,
          subsData[0].activeSubscription.daysLeft
        )
      );
    }
  };

  useEffect(() => {
    getProratedPrice();
  }, []);
  return (
    <>
      <Row>
        <Col md={8}>
          <div className="bg-white config-subs p-4">
            <h2 className="my-2 fw-bold">{selectedPlan.planNameTxt}</h2>
            <div className="d-flex mx-auto config-subs--amount">
              <h2>
                <span className="config-subs--peso">₱</span>{" "}
                <span className="config-subs--amount__num">
                  {subsData[0]?.activeSubscription?.current !== "freemium"
                    ? proratedPrice?.toLocaleString()
                    : selectedPlan.amount.toLocaleString()}
                </span>
                <span className="config-subs--month"> / month</span>{" "}
              </h2>
            </div>
            <div className="mt-3 mb-3">
              <div className="subs-features">Subscription Features:</div>
              {selectedPlan.description.map((item, index) =>
                item.isBullet ? (
                  <ul key={index}>
                    <li>{item.text}</li>
                  </ul>
                ) : (
                  <label className="pt-2 d-flex" key={index}>
                    {item.text}
                  </label>
                )
              )}
            </div>

            <Form>
              <Form.Label htmlFor="inputPassword5">
                <small>Select Billing Cycle:</small>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                className="mb-4"
                onChange={(e) => setBilling(e.target.value)}
              >
                {/* <option value="3">
                  ₱{" "}
                  {subsData[0].activeSubscription.current !== "freemium"
                    ? proratedPrice?.toLocaleString()
                    : selectedPlan.amount.toLocaleString()}{" "}
                  / {selectedPlan.month} Month
                </option> */}
                <option value={3}>
                  ₱{" "}
                  {subsData[0].activeSubscription.current !== "freemium"
                    ? (proratedPrice * 3)?.toLocaleString()
                    : (selectedPlan.amount * 3).toLocaleString()}{" "}
                  / 3 Months
                </option>
                <option value={6}>
                  ₱{" "}
                  {subsData[0].activeSubscription.current !== "freemium"
                    ? (proratedPrice * 6)?.toLocaleString()
                    : (selectedPlan.amount * 6).toLocaleString()}{" "}
                  / 6 Months
                </option>
                <option value={12}>
                  ₱{" "}
                  {subsData[0].activeSubscription.current !== "freemium"
                    ? (proratedPrice * 12)?.toLocaleString()
                    : (selectedPlan.amount * 12).toLocaleString()}{" "}
                  / 12 Months
                </option>
              </Form.Select>
            </Form>
          </div>
        </Col>
        <SubsConfPremiumReceipt
          subsData={subsData}
          billing={billing}
          typeOfSub={typeOfSub}
          selectedPlan={selectedPlan}
        />
      </Row>
    </>
  );
};

export default SubsConfDetails;
