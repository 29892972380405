import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/Modal/DocumentCVModal.scss";
import axios from "axios";
import WarningModal from "../../WarningModal";
import SuccessModal from "../../SuccessModal";
import Loader from "../../../layouts/loader/Loader";
import QuestionModal from "../../QuestionModal";
import CVModal from "../../CVModal";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import { useSelector } from "react-redux";
import Config from "../../../Config/config";
import { updateProfile } from "../../../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
const DocumentsCVModal = ({ isOpen, close }) => {
  const dispatch = useDispatch();
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const talentInfo = useSelector(
    (state) => state.reducer.profile?.currentProfile
  );

  const [cv, setCV] = useState(talentInfo.resume);
  const [uploadError, setUploadError] = useState("");

  //State variables for modal
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setCV(null);
    }
  }, [isOpen]);

  const toggleWarning = () => {
    setwarningModalOpen(!warningModalOpen);
  };

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const handleManageClick = () => {
    setwarningModalOpen(false);
  };

  const handleManageClickSuccess = () => {
    window.location.reload();
  };

  const cvChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 5000000) {
      setCV(file);
      setUploadError("");
    } else {
      setCV(file);
      setUploadError("File size exceeds the limit of 5MB.");
    }
  };

  const uploadDocumentCert = async (e) => {
    setIsLoading(true);
    try {
      if (cv) {
        // Check if the file size is within the limit (5MB)
        if (cv.size <= 5000000) {
          const formData = new FormData();
          formData.append("file", cv);

          const response = await axios.post(
            `${Config.nxtApi}/api/talent/uploadresume`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );

          if (response.data.code === "0") {
            dispatch(updateProfile({ resume: response.data.fileName }));
            addCV(response.data.fileName);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toggleWarning();
            setUploadError("An error occurred while uploading the CV.");
          }
        } else {
          setIsLoading(false);
          toggleWarning();
          setUploadError("File size exceeds the limit of 5MB.");
        }
      } else {
        setIsLoading(false);
        toggleWarning();
        setUploadError("Please attach your documents (CV) here");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Error ", error);
    }
  };

  const addCV = async (fileName) => {
    try {
      const payload = {
        resume: fileName,
      };
      await axios.post(`${Config.nxtApi}/api/talent/update-resume`, payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      toggleSuccess();
    } catch (err) {
      console.log("Err, ", err);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={close}
        centered
        className="modal-container"
      >
        <ModalHeader className="modal-header-container" toggle={close}>
          Online Document
        </ModalHeader>
        <ModalBody className="modal-body-container">
          <Input
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={cvChange}
          ></Input>
          <label htmlFor="cv-upload" className="cv-label">
            Upload CV (Max size: 5MB)
          </label>
        </ModalBody>
        <ModalFooter className="modal-footer-container">
          <Button className="cancel" onClick={close}>
            Cancel
          </Button>
          <Button className="save" onClick={uploadDocumentCert}>
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>

      <CVModal
        modalOpen={warningModalOpen}
        toggleModal={toggleWarning}
        iconImage={require("../../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel={uploadError}
        confirmButtonText="Confirm"
        onCancel={handleManageClick}
        onConfirm={handleManageClick}
      />

      <SuccessModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Your document (CV) is added"
        confirmButtonText="Ok"
        onConfirm={handleManageClickSuccess}
      />
    </>
  );
};

export default DocumentsCVModal;
