import React from "react";
import "../../assets/scss/Components/UI/_image.scss";

const Image = ({ src, alt, width, height, className, onClick, id }) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
    ></img>
  );
};

export default Image;
