import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

export const ValidatedAtsRoutes = () => {
  const userType = useSelector(
    (state) => state.reducer.user?.currentUser?.userType
  );

  const isSubscribe = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withATS
  );

  console.log(userType);
  console.log(useSelector((state) => state));

  if (userType === "client" && isSubscribe) {
    console.log(isSubscribe);
    console.log("Condition 1");
    return <Outlet />;
  } else if (isSubscribe && userType === "client") {
    console.log(isSubscribe);
    console.log("Condition 2");
    return <Navigate to="/admin/ats/manage-job" />;
  } else {
    console.log(userType);
    console.log(isSubscribe);
    console.log("Condition 3");
    return <Navigate to="/client/manage-job" />;
  }
};
