import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isfeatures: null,
};

const features = createSlice({
  name: "isfeatures",
  initialState,
  reducers: {
    updatefeatures: (state, action) => {
      state.isfeatures = {
        ...state.isfeatures,
        ...action.payload,
      };
    },
    setfeatures: (state, action) => {
      state.isfeatures = action.payload;
    },

    clearfeatures: (state) => {
      state.isfeatures = null;
    },
  },
});

export const { updatefeatures, setfeatures, clearfeatures } = features.actions;

export default features.reducer;
