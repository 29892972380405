import React from "react";
import "../../assets/scss/Components/UI/_button.scss";
import Loader from "../../layouts/loader/Loader";

const CustomButton = ({
  name,
  className,
  onClick,
  type,
  disabled,
  isLoading,
  style,
}) => {
  return (
    <>
      <button
        className={`customButton ${className}`}
        onClick={!isLoading ? onClick : null}
        type={type}
        disabled={disabled ? true : false}
        style={style}
      >
        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <> {name}</>
        )}
      </button>
    </>
  );
};

export default CustomButton;
