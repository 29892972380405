import React from "react";

const ResumeSetup = () => {
  return (
    <div>
      <ol>
        <li>Set up your profile</li>
        <li>Click on ‘My Resume’</li>
        <li>Add your skills</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Click on ‘Add Key Skills’</li>
          <li>Click on ‘+Add Skill’</li>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              Type in the <i>Keywords</i> for your skills Examples: MS Word,
              Google Sheets, Python Programming
            </li>
            <li>
              Select <i>Proficiency</i>: Beginner, Intermediate, Advance
            </li>
            <li>
              Select <i>Years of Experience</i>
            </li>
          </ol>
        </ol>
        <li>Add your work history</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Click on ‘Add Work History’</li>
          <li>Type in your work history details</li>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              Company Name <span className="text-danger">*</span>
            </li>
            <li>Job Level</li>
            <li>
              Role<span className="text-danger">*</span>
            </li>
            <li>Work Setup</li>
            <li>Work Arrangement</li>
            <li>Industry</li>
            <li>
              Start Date<span className="text-danger">*</span>
            </li>
            <li>
              End Date<span className="text-danger">*</span>{" "}
            </li>
            <li>Check the ‘Present’ box if currently employed</li>
          </ol>
        </ol>
        <li>Updating uploaded resume</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            Click on ‘Update Resume’ to upload a copy of your latest resume
          </li>
          <li>Accepted file type: pdf</li>
          <li>Maximum file size: 5mb</li>
          <li>To see contents of your pdf file, click on ‘View Attachment’</li>
        </ol>
        <li>Showcase your certificates for your next employer’s reference</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Click on ‘Add Certificate’</li>
          <li>Type in your certificate’s details</li>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>Certificate Title</li>
            <li>
              Document Name <span className="text-danger">*</span>
            </li>
            <li>
              Validity Date <span className="text-danger">*</span>
            </li>
            <li>Upload a soft copy of your certificate</li>
            <li>Accepted file type: pdf, jpg, png</li>
            <li>Maximum file size: 5mb</li>
            <li>Click on ‘Save’</li>
            <li>Click on ‘Confirm’</li>
            <li>To see contents of your file, click on ‘View Attachment’</li>
          </ol>
        </ol>
      </ol>
    </div>
  );
};

export default ResumeSetup;
