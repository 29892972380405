import React, { useState } from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/KeySkills.scss";
import Select from "react-select";
import { Label } from "reactstrap";
import CreatableSelect from "react-select/creatable";

const KeySkills = () => {
  const [begginer, setBegginer] = useState([]);
  const [intermediate, setIntermediate] = useState([]);
  const [advanced, setAdvanced] = useState([]);

  return (
    <div className="st-key-skills-container">
      <h6>KEY SKILLS</h6>
      <hr className="line-seperator" />
      <div className="select-group">
        <Label>Begginer</Label>
        <CreatableSelect
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          options={[]}
          onChange={(selectedOption) => {
            const selectedValues = selectedOption.map((option) => option.value);
            setBegginer(selectedValues);
          }}
          value={begginer.map((value) => ({ label: value, value }))}
          onCreateOption={(inputValue) => {
            // Handle creating a new option
            const newOption = { label: inputValue, value: inputValue };
            setBegginer([...begginer, newOption.value]);
          }}
        />
      </div>
      <div className="select-group">
        <Label>Intermediate</Label>
        <CreatableSelect
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          options={[]}
          onChange={(selectedOption) => {
            const selectedValues = selectedOption.map((option) => option.value);
            setIntermediate(selectedValues);
          }}
          value={intermediate.map((value) => ({ label: value, value }))}
          onCreateOption={(inputValue) => {
            // Handle creating a new option
            const newOption = { label: inputValue, value: inputValue };
            setIntermediate([...intermediate, newOption.value]);
          }}
        />
      </div>
      <div className="select-group">
        <Label>Advanced</Label>
        <CreatableSelect
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          options={[]}
          onChange={(selectedOption) => {
            const selectedValues = selectedOption.map((option) => option.value);
            setAdvanced(selectedValues);
          }}
          value={advanced.map((value) => ({ label: value, value }))}
          onCreateOption={(inputValue) => {
            // Handle creating a new option
            const newOption = { label: inputValue, value: inputValue };
            setAdvanced([...advanced, newOption.value]);
          }}
        />
      </div>
    </div>
  );
};

export default KeySkills;
