import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../../assets/scss/Talent/Layout/JobList/JobListContent.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import SuccessIcon from "../../../assets/images/SuccessIcon.png";
import QuestionIcon from "../../../assets/images/QuestionIcon.png";
import WarningIcon from "../../../assets/images/WarningIcon.png";
import { Parser } from "html-to-react";
import WarningModal from "../../WarningModal";
import SuccessModal from "../../SuccessModal";
import he from "he";
import ShareBtn from "../../UI/SharePop/ShareBtn";
import SocialMediaShare from "../../UI/SharePop/SocialMediaShare";
import Config from "../../../Config/config";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import moment from "moment";
const JobDetailsContent = ({ jobDetails }) => {
  let { id } = useParams();

  const [jobDescription, setJobDescription] = useState(
    jobDetails.jobDescription
  );
  const [benefit, setbenefit] = useState(jobDetails.benefits);
  const [dialect, setdialect] = useState(jobDetails.dialect);
  const [skill, setskill] = useState(jobDetails.skillSet);

  function isNxtClient() {
    return window.location.hostname === "www.nxt.com.ph";
  }

  function isDevClient() {
    return window.location.hostname === "dev.nxt.com.ph";
  }

  function isStagClient() {
    return window.location.hostname === "stage.nxt.com.ph";
  }

  const baseUrl = isNxtClient()
    ? "https://www.nxt.com.ph"
    : isDevClient()
    ? "https://dev.nxt.com.ph"
    : isStagClient()
    ? "https://stage.nxt.com.ph"
    : "http://localhost:3000";

  console.log(jobDescription);
  const shareUrl = `${baseUrl}/share/jobs/jd/${jobDetails.job_id}`;
  const decodedJobDescription = he.decode(jobDescription);
  const textWithoutTags = decodedJobDescription
    .toString()
    .replace(/<[^>]*>/g, "");

  // const message = `<!DOCTYPE html>
  //   <html lang="en">
  //   <head>
  //       <meta charset="UTF-8">
  //       <meta name="viewport" content="width=device-width, initial-scale=1.0">

  //   </head>
  //   <body>
  //       <img crossorigin="anonymous" src="${
  //         Config.nxtApi
  //       }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
  //   jobDetails.clientId
  // )}&thumbNail=yes" width="130"         onError="this.onerror=null;this.src='URL_TO_DEFAULT_IMAGE'"
  //   onError="this.onerror=null;this.src='https://www.nxt.com.ph/static/media/nxt-logo.f1ab4d737f1485fa9a23.png"
  //   />
  //       </br>
  //       </br>
  //       <h2>You're invited!</h2>
  //       </br>
  //       <p>${jobDetails.companyName} is inviting you to apply for ${
  //   jobDetails.jobTitle
  // } position.</p>
  //       </br>
  //       <p>To learn more about the job, please click on the link below. Good luck!</p>
  //       </br>
  //       <a href="${shareUrl}">${shareUrl}</a>
  //   </body>
  //   </html>
  //   `;

  const message = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
  
  </head>
  <body>
      <img crossorigin="anonymous" src="https://dev.nxt.com.ph/images/emailbanner.png"
      width="500"  />
      <br>
      <h2>You're invited!</h2> 
  
      <p><strong>${jobDetails.companyName}</strong> is inviting you to apply for <strong>${jobDetails.jobTitle}</strong> position. \nTo learn more about the job, please click on the link below. Good luck!</p>
      
      <a href="${shareUrl}">${shareUrl}</a>
  </body>
  </html>
  `;
  const messageId = jobDetails.id;
  // console.log(message);
  return (
    <div className="joblistcontent-container">
      <div className="header">
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ marginTop: "10px" }}>
              <h1>{jobDetails.jobTitle} </h1>
            </div>
          </div>
          {jobDetails.jobPostStatus === "open" && (
            <div className="socialMediaContainer">
              <SocialMediaShare
                disabled={
                  !moment(jobDetails.postingDate.startDate).isSame(
                    moment(),
                    "day"
                  )
                }
                hasInvitation={true}
                url={`${baseUrl}/share/jobs/jd/${jobDetails.job_id}`}
                subject={jobDetails.jobTitle}
                message={message}
                messageId={messageId}
              />
            </div>
          )}
          <h3>{jobDetails.companyName}</h3>
          <h3>
            {jobDetails.officeAddress} | {jobDetails.jobType} |{" "}
            {jobDetails.workArrangement}{" "}
          </h3>
          <h3>JOB ID : {jobDetails.job_id}</h3>
        </div>
        <div>
          {/* <Button onClick={clickApplyJob} disabled={isApplied}>
            {isLoading ? <Loader /> : isApplied ? "Applied" : "Apply"}
          </Button> */}
        </div>
      </div>
      <div className="body">
        <div className="job-details">
          <h1>Job Description</h1>
          <hr />
          <p>{Parser().parse(jobDetails.jobDescription)}</p>
        </div>
        <div className="job-details">
          <h1>Job Overview</h1>
          <hr />
          <p>{Parser().parse(jobDetails.jobOverview)}</p>
        </div>
        <div className="job-details">
          <h1>Job Qualification</h1>
          <hr />
          <p>{Parser().parse(jobDetails.qualification)}</p>
        </div>
        <div className="job-details">
          <h1>Benefits</h1>
          <hr />
          {benefit.map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </div>
        <div className="job-details">
          <h1>Required Skill Set</h1>
          <hr />
          {skill.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </div>
        <div className="job-details">
          <h1>Dialect</h1>
          <hr />
          {dialect.map((dialect, index) => (
            <li key={index}>{dialect}</li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsContent;
