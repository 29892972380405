import React from "react";
import { Offcanvas } from "react-bootstrap";
import "../../../assets/scss/Components/UI/Offcanvas/_offCanvas.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CustomOffcanvas = ({ show, handleClose, placement, children, title }) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={placement ? placement : "start"}
        className="custom_offcanvas"
        // backdrop="static"
      >
        {title && (
          <Offcanvas.Header className="custom_offcanvas_header">
            <Offcanvas.Title className="custom_offcanvas_title">
              {title}
            </Offcanvas.Title>
            <FontAwesomeIcon
              icon={faXmark}
              className="custom_offcanvas_icon"
              onClick={handleClose}
            />
          </Offcanvas.Header>
        )}

        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomOffcanvas;
