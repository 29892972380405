import React from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../assets/scss/Client/Layout/BannerBreadcrumb.scss";
import { useNavigate } from "react-router-dom";
const BannerBreadcrumb = () => {
  const navigate = useNavigate();

  const clickCompanyProfile = () => {
    navigate("/client/company-profile");
  };

  const clickTalentList = () => {
    navigate("/client/talent-list");
  };
  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>Talent List</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickCompanyProfile} tag="a">
                Company Profile
              </BreadcrumbItem>
              <BreadcrumbItem active onClick={clickTalentList} tag="a">
                Talent List
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerBreadcrumb;
