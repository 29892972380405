import React from "react";
import { Col, Card, Button } from "react-bootstrap";
import PlanAmount from "../SubscriptionCard/Details/PlanAmount";
import PremiumDetails from "../SubscriptionCard/Details/PremiumDetails";
import { useLocation, useNavigate } from "react-router-dom";
const SubsSelectContent = ({ selectedPlan, subsData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <div className={`container--subs__card card-shadow`}>
        <Card className="container--subs__card card-shadow bg-white p-4">
          <PlanAmount
            planName={selectedPlan.planNameTxt}
            amount={selectedPlan.amount}
          />
          <Button
            variant=""
            className="btn-green"
            onClick={() => {
              if (location.pathname === "/admin/ats/subscription/add-ons") {
                navigate("/admin/ats/subscription/config", {
                  state: { selectedPlan: selectedPlan, subsData: subsData },
                });
              } else {
                navigate("/client/subscription-config", {
                  state: { selectedPlan: selectedPlan, subsData: subsData },
                });
              }
            }}
          >
            SUBSCRIBE NOW
          </Button>
        </Card>
        <PremiumDetails
          details={selectedPlan.description}
          highlight={selectedPlan.highlight}
        />
      </div>
      <Button
        variant="outline-secondary"
        className="py-3 fw-bold w-100"
        onClick={() => {
          navigate("/client/subscription");
        }}
      >
        BACK
      </Button>
    </div>
  );
};

export default SubsSelectContent;
