import React, { ForwardRefRenderFunction } from "react";
import "../../assets/scss/Components/Form/_customInput.scss";
import ErrorMessage from "./ErrorMessage";

const CustomInput = (
  {
    name,
    required,
    labelOptional,
    placeholder,
    label,
    errors,
    type,
    inputClassname,
    className,
    defaultValue,
    onClick,
    classNameLabel,
    readOnly,
    value,
    ...props
  },
  ref
) => {
  return (
    <div className={`customInput ${className}`}>
      <label className={classNameLabel}>
        {label}{" "}
        {labelOptional && (
          <span className="label-optional">{labelOptional}</span>
        )}
        {required && <span className="required-field">*</span>}
      </label>
      <input
        name={name}
        placeholder={placeholder}
        ref={ref}
        {...props}
        type={type}
        className={inputClassname}
        defaultValue={defaultValue}
        // value={}
        disabled={readOnly}
        onClick={onClick}
      />
      {errors && errors[name] && <ErrorMessage label={errors[name].message} />}
    </div>
  );
};

export default React.forwardRef(CustomInput);
