import React, { useEffect, useState } from "react";
import "../../../../assets/scss/Page/Outer/ContactUs/_contactUsForm.scss";

import { useForm, Controller } from "react-hook-form";

import { z, ZodType } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import CustomInput from "../../../../components/Form/CustomInput";
import CustomTextArea from "../../../../components/Form/CustomTextArea";
import CustomCheckbox from "../../../../components/Form/CustomCheckbox";
import CustomButton from "../../../../components/UI/CustomButton";
import Config from "../../../../Config/config";
import axios from "axios";
import CustomModal from "../../../../components/UI/Modal";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ContactUsForm = () => {
  const refreshTokenTalent = localStorage.getItem("talentToken");
  const refreshTokenClient = localStorage.getItem("clientToken");
  const apiKeyTalent = process.env.REACT_APP_TALENT_API;
  const apiKeyClient = process.env.REACT_APP_CLIENT_API;
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const schema = z.object({
    emailAddress: z
      .string()
      .email("Please input valid email")
      .max(64, "Email address should not exceed 64 characters"),
    fullName: z
      .string()
      .max(64, "Full Name should not exceed 64 characters")
      .nonempty("Full Name is required"),
    companyName: z
      .string()
      .max(64, "Company Name should not exceed 64 characters")
      .optional(),
    contactNumber: z
      .string()
      .nonempty("Contact number is required")
      .refine(
        (value) => {
          if (value.startsWith("+")) {
            return /^\+\d{12}$/.test(value);
          } else {
            return /^\d{11}$/.test(value);
          }
        },
        {
          message:
            "Contact number should contain either exactly 11 digits or 12 digits with an optional plus sign (+)",
        }
      ),
    message: z
      .string()
      .nonempty("Message is required")
      .max(
        2000,
        "Maximum character limit exceeded. Please reduce your message to 2000 characters or less."
      ),
    checkbox: z.literal(true, {
      errorMap: () => ({ message: "You must accept Terms and Conditions" }),
    }),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    if (refreshTokenTalent) {
      getTalentInfo();
    } else if (refreshTokenClient) {
      getClientInfo();
    }
  };

  const getClientInfo = async () => {
    const response = await axios.get(`${apiKeyClient}/myprofile`, {
      headers: {
        Authorization: `Bearer ${refreshTokenClient}`,
      },
    });

    let userFullName = "";
    if (response.data.data[0].middleName === "N/A") {
      userFullName =
        response.data.data[0].firstName + " " + response.data.data[0].lastName;
    } else {
      userFullName =
        response.data.data[0].firstName +
        " " +
        response.data.data[0].middleName +
        " " +
        response.data.data[0].lastName;
    }

    setValue("fullName", userFullName);
    setValue("emailAddress", response.data.data[0].email);
    setValue("contactNumber", response.data.data[0].contactNumber);
    setValue("companyName", response.data.data[0].companyName);
  };

  const getTalentInfo = async () => {
    try {
      const response = await axios.get(`${apiKeyTalent}/myprofile`, {
        headers: {
          Authorization: `Bearer ${refreshTokenTalent}`,
        },
      });

      let userFullName = "";
      if (response.data.data[0].middleName === "N/A") {
        userFullName =
          response.data.data[0].firstName +
          " " +
          response.data.data[0].lastName;
      } else {
        userFullName =
          response.data.data[0].firstName +
          " " +
          response.data.data[0].middleName +
          " " +
          response.data.data[0].lastName;
      }

      setValue("fullName", userFullName);
      setValue("emailAddress", response.data.data[0].email);
      setValue("contactNumber", response.data.data[0].contactNumber);
    } catch (error) {
      console.log(error);
      localStorage.clear();
    }
  };

  const submitData = async (data) => {
    setIsLoading(true);
    try {
      const { checkbox, ...contactData } = data;
      const payload = { ...contactData };

      if (!data.checkbox) {
        return;
      }

      const response = await axios.post(
        `${Config.nxtApi}/api/contactus`,
        payload
      );
      setModalShow(true);
      reset();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      localStorage.clear();
    }
  };

  const handleModalClose = async () => {
    setModalShow(false);
  };

  return (
    <div className="contactUsForm">
      <h1>We’re Here To Help</h1>
      <h2>Got questions? Drop us a message below!</h2>
      <form onSubmit={handleSubmit(submitData)} className="form">
        <div className="formSection">
          <CustomInput
            errors={errors}
            type="text"
            placeholder="* Full Name"
            {...register("fullName")}
            className="formInputGroup"
            inputClassname="formInputGroupWithBorder"
          />
          <CustomInput
            errors={errors}
            type="emailAddress"
            {...register("emailAddress")}
            placeholder="* Email Address"
            className="formInputGroup"
            inputClassname="formInputGroupWithBorder"
          />
        </div>
        <div className="formSection">
          <CustomInput
            errors={errors}
            type="text"
            {...register("companyName")}
            placeholder="* Company"
            className="formInputGroup"
            inputClassname="formInputGroupWithBorder"
          />

          <CustomInput
            errors={errors}
            type="text"
            {...register("contactNumber")}
            placeholder="* Contact Number"
            className="formInputGroup"
            inputClassname="formInputGroupWithBorder"
          />
        </div>
        <div className="formController">
          <div>
            <CustomTextArea
              {...register("message")}
              placeholder="* Message"
              errors={errors}
              inputClassname="formInputGroupWithBorder"
            />
          </div>

          <div>
            <CustomCheckbox
              type="checkbox"
              errors={errors}
              link="/privacy-policy"
              linkName="click here"
              {...register("checkbox")}
              label="I hereby give consent to Acquiro Solutions and Tech Inc. to collect my personal information as stated in this website's Privacy Statement. To read more about our Privacy Policy"
            />
          </div>
        </div>

        <div className="customButtonContainer">
          <CustomButton
            name="Submit"
            className="customRoundedVioletButton"
            type="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
      <CustomModal
        buttonName="Close"
        show={modalShow}
        onHide={() => handleModalClose()}
        modalMessage="Form submitted"
        modalType="Success"
      />
    </div>
  );
};

export default ContactUsForm;
