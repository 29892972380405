import React from "react";

const ClientProfileSetup = () => {
  return (
    <div>
      <p>
        Our User-friendly platform ensures that employers find the right talent
        with our intelligent matching system. It simplifies your talents search
        process and correctly setting up your profile will give you higher
        chances of being found by your next talent.
      </p>

      <ol>
        <li>Login using your registered email address</li>
        <li>Click on ‘Profile’ button</li>
        <li>Click on ‘Company Profile’ button</li>
        <li>Complete your company details</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Company Name</li>
          <li>Company Size</li>
          <li>Industry</li>
          <li>Company Address</li>
          <li>Country</li>
          <li>Region</li>
          <li>Province / District</li>
          <li>City / Municipality</li>
          <li>Postal Code</li>
          <li>Last Name</li>
          <li>First Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
        </ol>
        <li>Click on ‘Update Setting’</li>
      </ol>
    </div>
  );
};

export default ClientProfileSetup;
