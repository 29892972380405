import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import loginBg from "../../../../assets/images/Background/Global/loginRegBg.jpg";
import { useLocation, useParams } from "react-router";
import axios from "axios";
import Config from "../../../../Config/config";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import CustomContainer from "../../../../layouts/Container/CustomContainer";
import CustomMessage from "../../../../components/Form/ErrorMessage";
import CustomButton from "../../../../components/UI/CustomButton";
import CustomModal from "../../../../components/UI/Modal";
import CustomInput from "../../../../components/Form/CustomInput";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import CustomPassword from "../../../../components/Form/CustomPassword";
import { GetData } from "../../../../Utilities/Fetch/GetData";
import { PostData } from "../../../../Utilities/Post/Post";
const SetupPassword = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMsg] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const schema = z
    .object({
      password: z
        .string()
        .nonempty("Password is required")
        .min(12, "Password must have a minimum of 12 characters")
        .max(16, "Password must not exceed 16 characters in length")
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-._@$#^+=!%*?)(&])[A-Za-z\d\-._@$#+=^!%*?)(&]{12,16}$/,
          "Atleast one capital, atleast one lower, atleast one number , atleast one symbol"
        ),
      confirmPassword: z.string().nonempty("Confirm Password is required"),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords do not match",
      path: ["confirmPassword"],
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handleRemoveError = () => {
    setErrorMsg("");
  };

  const submitForgotPassword = async (data) => {
    setIsBtnLoading(true);

    const payload = {
      ...data,
      token: token,
    };

    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/user/update/password`,
        payload
      );
      setIsBtnLoading(false);
      setModalShow(true);
    } catch (error) {
      setIsBtnLoading(false);
      if (
        error.response.code === "1" &&
        error.response.message === "Invalid token."
      ) {
        navigate("/invalid-code", {
          state: { error: error.response.message },
        });
      }
      console.log(error);
      setErrorMsg(error.response.data?.message);
      setModalErrorShow(true);
    }
  };

  const handleModalClose = () => {
    navigate("/");
    setModalShow(false);
  };

  const handleModalErrorClose = () => {
    setModalErrorShow(false);
  };

  useEffect(() => {
    validateToken();
  }, [token]);

  const validateToken = async () => {
    const response = await PostData("api/user/check/forgot/password/token", {
      token: token,
    });

    if (response.code !== "0") {
      navigate("/invalid-code", {
        state: { error: response.message },
      });
    }
  };

  return (
    <div>
      <CustomContainer
        backgroundImage={loginBg}
        className="customContainerLogin"
      >
        <div className="containerIndex">
          <div className="content">
            <form onSubmit={handleSubmit(submitForgotPassword)}>
              <div className="loginTitleLeft">
                <h1>Setup Password</h1>
              </div>

              <CustomPassword
                errors={errors}
                label="Please enter your password"
                {...register("password")}
                placeholder="Password"
                className="formInputGroup"
              />

              <CustomPassword
                errors={errors}
                label="Confirm Password"
                {...register("confirmPassword")}
                placeholder="Confirm Password"
                onClick={handleRemoveError}
                className="formInputGroup"
              />

              <div className="btnContainerMrgn">
                <CustomButton
                  type="submit"
                  name="Submit"
                  isLoading={isBtnLoading}
                  className="customLRButton"
                />
              </div>
            </form>
          </div>
        </div>
      </CustomContainer>
      <CustomModal
        buttonName="Close"
        show={modalShow}
        color="green"
        icon={faCheck}
        modalMessage="Password is successfully updated"
        modalType="Success"
        onClick={() => handleModalClose()}
      />
      <CustomModal
        show={modalErrorShow}
        onHide={() => handleModalErrorClose()}
        color="red"
        icon={faTimes}
        modalMessage={errorMessage}
        modalType="Warning"
        buttonName="Close"
        onClick={() => handleModalErrorClose()}
      />
    </div>
  );
};

export default SetupPassword;
