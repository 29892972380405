import React from "react";
import CustomContainer from "./Container/CustomContainer";
import LandingNav from "./Navbar/LandingNav";
import ClientNav from "./Navbar/ClientNav";
import TalentNav from "./Navbar/TalentNav";
import AdminNav from "./Navbar/AdminNav";

const Navbar = ({ layout }) => {
  const layouts = {
    landing: (
      <CustomContainer>
        <LandingNav />
      </CustomContainer>
    ),
    client: (
      <CustomContainer>
        <ClientNav />
      </CustomContainer>
    ),
    talent: (
      <CustomContainer>
        <TalentNav />
      </CustomContainer>
    ),
    admin: (
      <CustomContainer className="adminNavBg">
        <AdminNav />
      </CustomContainer>
    ),
  };

  return layouts[layout] || layouts.landing;
};

export default Navbar;
