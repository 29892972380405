import React from "react";
import "../../assets/scss/Gigster/GigsterLandingPage.scss";
import GigsterNavBar from "./Nav/GigsterNavBar";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const GigsterLandingPage = () => {
  return (
    <>
      {/* section 1 */}
      <div className="discover_page position-relative">
        {/* navbar */}
        <GigsterNavBar />
        <div className=""></div>
        <img
          src={require(`../../assets/images/Gigster/img/gigimage.png`)}
          className="discover__page-img"
        />
        <div className="discover__page-intro">
          <p>
            Discover the perfect
            <img
              src={require(`../../assets/images/Gigster/img/gigster-text.png`)}
              className="discover__page-imgtext ms-3"
            />
            <br />
            service instantly.
          </p>
          <button className="btn py-2 px-5">Get Started</button>
        </div>
      </div>

      {/* section 2 */}
      <div className="find__gigster">
        <Container>
          <Row>
            <Col
              md={6}
              className="fing__gigster-text d-flex align-content-center flex-wrap"
            >
              <h3>Find gigster your way</h3>
              <p>
                Work with the largest network of independent professionals and
                get things done.
              </p>
            </Col>
            <Col md={6}>
              <img
                src={require(`../../assets/images/Gigster/img/tablet_and_laptop_on_transparent_background.png`)}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* section 3 */}
      <div className="yellow__circle py-5 mb-5">
        <Container>
          <Row>
            <Col md={6}>
              <img
                className="formal__guy"
                src={require(`../../assets/images/Gigster/img/look-asian-office-executive-face.png`)}
              />
            </Col>
            <Col md={6}>
              <div className="formal__guy-text">
                <div className="d-flex align-content-center flex-wrap">
                  <p>
                    Work with the largest network of independent professionals
                    and get things done.
                  </p>
                </div>
              </div>
            </Col>

            {/* joining free section */}
            <div className="joining__free bg-white rounded p-5 mb-3">
              <Row>
                <Col md={6}>
                  <img
                    src={require(`../../assets/images/Gigster/img/image6.png`)}
                    className="img-fluid rounded"
                  />
                </Col>
                <Col md={6}>
                  <h3 className="mb-3 fw-bold">Joining is free</h3>
                  <li className="d-flex">
                    <img
                      src={require(`../../assets/images/Gigster/Logo/free-logo.png`)}
                      className="joining__logo"
                    />
                    <div>
                      <h4 className="fw-bold">Absolutely free to join</h4>
                      <p>
                        Register and browse professionals, explore projects, or
                        even book a consultation.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <img
                      src={require(`../../assets/images/Gigster/Logo/hire-logo.png`)}
                      className="joining__logo"
                    />
                    <div>
                      <h4 className="fw-bold">Hire top talent effortlessly</h4>
                      <p>
                        Finding talent doesn’t have to be a chore. Post a job or
                        we can search for you!
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <img
                      src={require(`../../assets/images/Gigster/Logo/group-logo.png`)}
                      className="joining__logo"
                    />
                    <div>
                      <h4 className="fw-bold">
                        Access elite talent without overspending
                      </h4>
                      <p>
                        Elevate your work and benefit from budget-friendly
                        transaction rates with our affordable solution.
                      </p>
                    </div>
                  </li>
                </Col>
              </Row>
            </div>

            {/* section find talent your way */}
            <div className="find__talent bg-white rounded p-5 mb-3">
              <div className="find__talent-bg rounded mb-5">
                <div className="find__talent-text p-4 w-50 d-flex align-content-center flex-wrap">
                  <h3 className="fw-bold">Find talent your way</h3>
                  <p className="mb-5">
                    Work with the largest network of independent
                    <br /> professionals and get things done—from quick
                    turnarounds to big transformations.
                  </p>
                  <button className="btn py-2 px-5">Post a job</button>
                </div>
              </div>

              <div className="discover__work-bg rounded">
                <div className="discover__work-text p-4 w-50 d-flex align-content-center flex-wrap">
                  <h3 className="fw-bold text-white">
                    Discover great work opportunities
                  </h3>
                  <p className="text-white mb-5">
                    Unlock limitless career or business potential as you
                    collaborate with inspiring clients, propelling your success
                    to new heights.
                  </p>
                  <button className="btn btn-light py-2 px-5">
                    Find Opportunities
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      {/* footer */}
      <footer className="py-5">
        <Container>
          <div className="">
            <Row>
              <Col md={6}>
                <img
                  src={require(`../../assets/images/Gigster/Logo/gigster-final-2.png`)}
                  className="img-fluid mb-4"
                />
                <ul className="d-flex footer__icon ps-0">
                  <li>
                    <FontAwesomeIcon icon={faFacebook} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faInstagram} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faLinkedin} />
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faTiktok} />
                  </li>
                </ul>
              </Col>
              <Col md={6}>
                <ul className="footer">
                  <li>Home</li>
                  <li>Talents</li>
                  <li>Help</li>
                  <li>Contact Us</li>
                </ul>
              </Col>
            </Row>
            <div className="d-flex text-white">
              <span>All rights reserved 2022</span>
              <span>Terms and Condition</span>
              <span>Privacy Policy</span>
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default GigsterLandingPage;
