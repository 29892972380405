import React from "react";
import CardWithIcon from "../../components/UI/Card/CardWithIcon";
import "../../assets/scss/SuperAdmin/LayoutScss/SuperAdminContainer.scss";
import total from "../../assets/global/total.json";
import CompanyList from "../../components/UI/Card/CompanyList";
import JobPostingChart from "../../components/UI/Card/JobPostingChart";
import AddGroupTable from "../../components/UI/Table/SuperAdmin/AddGroupTable";
import AddAdminForm from "../../components/Form/SuperAdmin/AddAdminForm";
import AddAdminTable from "../../components/UI/Table/SuperAdmin/AddAdminTable";
const PageContainer = ({ layout }) => {
  const layouts = {
    dashboard: (
      <div className="pageContainer">
        <CardWithIcon data={total.data} />
        <div className="dashboardChart">
          <CompanyList
            firstData={total.jobPosted}
            secondData={total.addedCompany}
          />
          <JobPostingChart />
        </div>
        <AddGroupTable />
      </div>
    ),
    addAdmin: (
      <div className="pageContainer">
        <div className="innerContainer">
          <div className="title">
            <h1>Admins</h1>
            <hr />
          </div>
          <div className="pageContent">
            <AddAdminForm />
            <AddAdminTable />
          </div>
        </div>
      </div>
    ),
    talent: (
      <>
        <h1>Talent</h1>
      </>
    ),
  };

  return layouts[layout] || layouts.dashboard;
};

export default PageContainer;
