import React, { useState } from "react";
import Choices from "./Choices";
import faqLinks from "../../../Data/about/FAQ.json";
import CustomButton from "../../../components/UI/CustomButton";

const FAQBody = () => {
  const links = faqLinks;

  const [activeButton, setActiveButton] = useState("talent");
  const [selectedLink, setSelectedLink] = useState("gettingStarted");

  const handleButtonToggle = (buttonName) => {
    setActiveButton(buttonName);
    setSelectedLink(
      buttonName === "client" ? "gettingStartedClient" : "gettingStarted"
    );
  };

  const handleLinkClick = (value) => {
    setSelectedLink(value.keyWord);
  };

  return (
    <div className="faqBody">
      <h1 className="faqTitle">Choose an account type</h1>
      <div className="faqBodyButtonGroup">
        <CustomButton
          name="Jobseeker"
          className={
            activeButton === "talent"
              ? "customRoundedBlueButton2 active"
              : "customRoundedBlueButtonWhiteBg"
          }
          onClick={() => handleButtonToggle("talent")}
        />
        <CustomButton
          name="Employer"
          className={
            activeButton === "client"
              ? "customRoundedBlueButton2 active"
              : "customRoundedBlueButtonWhiteBg"
          }
          onClick={() => handleButtonToggle("client")}
        />
      </div>
      <Choices
        link={activeButton === "talent" ? links.talent : links.client}
        selectedLink={selectedLink}
        handleLinkClick={handleLinkClick}
      />
    </div>
  );
};

export default FAQBody;
