import React from "react";
import CustomButton from "../../Button";
import { Button, Table } from "reactstrap";
import "../../../../assets/scss/UIStyle/Table/SuperAdmin/GroupTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExpand,
  faPenSquare,
} from "@fortawesome/free-solid-svg-icons";
const AddGroupTable = () => {
  const data = [
    { company: "FINSI", groupStatus: "Parent Group" },
    { company: "ABSI", groupStatus: "Parent Group" },
    { company: "FINSI", groupStatus: "Parent Group" },
    { company: "ACQUIRO", groupStatus: "Parent Group" },
    { company: "ASTICOM", groupStatus: "Parent Group" },
  ];

  const handleAddGroup = () => {
    // console.log("Click");
  };
  return (
    <div className="groupTable">
      <div className="buttonContainer">
        <CustomButton
          label="Add Group"
          onClick={handleAddGroup}
          classNameLabel="blueButton"
        />
      </div>
      <div className="tableContainer">
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Company/Group</th>
              <th>Group Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val) => {
              return (
                <tr>
                  <td className="contentText">{val.company}</td>
                  <td className="contentText">{val.groupStatus}</td>
                  <td className="actionButtons">
                    <FontAwesomeIcon icon={faExpand} className="icon" />
                    <FontAwesomeIcon icon={faEdit} className="icon" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddGroupTable;
