import React from "react";
import { Button, Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingBanner.scss";
import { useNavigate } from "react-router";

const Banner = () => {
  const navigate = useNavigate();
  const handleClickSignUpTalent = () => {
    navigate("/talent/login", {
      state: { sendClientState: "talent" },
    });
  };
  const handleClickSignUpClient = () => {
    navigate("/client/login", {
      state: { sendClientState: "client" },
    });
  };
  // const handleClickLoginClient = () => {
  //   navigate("/login");
  // };
  return (
    <>
      <div className="landing-banner">
        <Container>
          <div className="landing-body">
            <div className="body-text">
              <h1>Find your next opportunity with NXT</h1>
              <p>Creating New eXperiences Today</p>
            </div>
            <div className="button-container">
              <Button id="talent-login-btn" onClick={handleClickSignUpTalent}>
                Jobseeker Login
              </Button>
              <Button onClick={handleClickSignUpClient}>Employer Login</Button>
              {/* <Button onClick={handleClickLoginClient}>Client Login</Button> */}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
