export const modalSubsReminder = (modalType) => {
  let errorType;
  let message;
  if (modalType === "existSubs") {
    errorType = "Warning";
    message =
      "It looks like you're already subscribed. You can only have one active subscription at a time";
    return { errorType, message };
  }
};
