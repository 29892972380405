import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  errorModal: false,
  successModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModalError: (state) => {
      state.errorModal = true;
    },
    showModalSuccess: (state) => {
      state.successModal = true;
    },
    closeModalSuccess: (state) => {
      state.successModal = false;
    },
    closeModalError: (state) => {
      state.errorModal = false;
    },
  },
});

export const {
  showModalError,
  showModalSuccess,
  closeModalSuccess,
  closeModalError,
} = modalSlice.actions;

export default modalSlice.reducer;
