import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "../../../assets/scss/Page/Outer/InvalidCode/_invalidCode.scss";
import { useLocation, useNavigate } from "react-router-dom";
const InvalidCode = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  const error = location.state?.error || "Invalid referral code.";

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/");
    }
  }, [countdown]);

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <div className="invalidCode">
      <div className="invalidCodeInside">
        <div className="invalidCode-message">
          <FontAwesomeIcon icon={faClockRotateLeft} className="icon" />
          <h1>{error}</h1>
        </div>

        <div className="redirect-message">
          <h4>
            You will be redirected to the official NXT website in {countdown}{" "}
            seconds
          </h4>
        </div>

        <div className="button-sec">
          <p>If you are not redirected, click proceed to redirect</p>
          <Button onClick={navigateHome}>Proceed</Button>
        </div>
      </div>
    </div>
  );
};

export default InvalidCode;
