import React from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/BasicInformation.scss";
const BasicInformation = () => {
  return (
    <div className="st-basic-information-container">
      <h6>FILTER LIST</h6>
      <hr className="line-seperator" />
      <div className="user-information">
        <div className="user-name">
          <div>
            <p>Last Name:</p>
            <p>First Name:</p>
            <p>Middle Name:</p>
            <p>Email:</p>
            <p>Contact:</p>
          </div>
          <div>
            <p>Stone</p>
            <p>Michael</p>
            <p>Nicolas</p>
            <p>michnic@gmail.com</p>
            <p>+639198374446</p>
          </div>
        </div>
        <div className="birthday-country">
          <div>
            <p>Birthday:</p>
            <p>Address:</p>
            <p>Municipality:</p>
            <p>Country:</p>
          </div>
          <div>
            <p>05/17/2000</p>
            <p>Makati</p>
            <p>Makati</p>
            <p>Philippines</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
