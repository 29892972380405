import React, { useEffect, useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Card from "./Card";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../../assets/scss/Components/UI/DragAndDrop/_column.scss";
import { columnNameMappings } from "../../../Utilities/Reusable/Validation/Validator/Column.ats";

const Column = ({
  columnId,
  column,
  selectedItems,
  setSelectedItems,
  onDropdownSelect,
  isMoveButtonDisabled,
  transition,
  onSaveJobStatusSuccess,
  jobDetails,
}) => {
  console.log(column);
  const [sortedItems, setSortedItems] = useState(column.items);
  useEffect(() => {
    setSortedItems(column.items);
  }, [column.items]);

  const handleItemClick = (itemId, itemStatus) => {
    if (itemStatus === "Hired") {
      return;
    }
    console.log(itemStatus);

    setSelectedItems((prevSelectedItems) => {
      const isSameStatus = prevSelectedItems.every(
        (selectedId) =>
          column.items.find((item) => item.id === selectedId)?.status ===
          itemStatus
      );

      if (isSameStatus) {
        const updatedSelectedItems = prevSelectedItems.includes(itemId)
          ? prevSelectedItems.filter((id) => id !== itemId)
          : [...prevSelectedItems, itemId];

        return updatedSelectedItems;
      } else {
        return [itemId];
      }
    });
  };

  const handleSort = (sortDirection) => {
    const updatedItems = [...column.items];

    updatedItems.sort((a, b) => {
      const lastNameA = a.lastName.toUpperCase();
      const lastNameB = b.lastName.toUpperCase();

      if (sortDirection === "asc") {
        return lastNameA.localeCompare(lastNameB);
      } else {
        return lastNameB.localeCompare(lastNameA);
      }
    });

    setSortedItems(updatedItems);
  };

  const handleSelectAll = () => {
    const allItemIds = column.items.map((item) => item.id);
    const areAllSelected = allItemIds.every((id) => selectedItems.includes(id));

    if (areAllSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItemIds);
    }
  };

  return (
    <>
      <div className="dndColumn" key={columnId}>
        <h2 className="d-flex">
          {column.name}
          <div className="dndcolumn-circle_label ms-auto text-center me-2 text-white">
            {column.items.length}
          </div>
          <Dropdown className="ellipsis-dropdown" align={{ end: true }}>
            <Dropdown.Toggle variant="link">
              <i className="fa-solid fa-ellipsis-vertical pe-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {column.name !== "Hired" && (
                <Dropdown.Item onClick={handleSelectAll}>
                  Select All
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => handleSort("asc")}>
                Ascending
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSort("desc")}>
                Descending
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </h2>
        <div className="columnContainer " style={{ margin: 8 }}>
          <Droppable droppableId={columnId} key={columnId}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="bucketContainer"
              >
                {sortedItems.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={item.status === "Hired"}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: "none",
                          backgroundColor: snapshot.isDragging
                            ? "#f7f7f7"
                            : item.draftStatus
                            ? "#F0F0F0"
                            : "#fff",
                          ...provided.draggableProps.style,
                          border:
                            selectedItems.includes(item.id) &&
                            (item.status ===
                              (columnNameMappings[column.name] ||
                                column.name) ||
                              item.draftStatus ===
                                (columnNameMappings[column.name] ||
                                  column.name))
                              ? "2px solid #007bff"
                              : "none",
                        }}
                        className="bucketInnerContainer "
                        onClick={(e) => {
                          e.preventDefault();
                          handleItemClick(
                            item.id,
                            item.status,
                            item.draftStatus
                          );
                          console.log("fsdfsdfsdfsdf");
                        }}
                      >
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip className="card-tooltip">
                              Drag cards to the next step for progress
                            </Tooltip>
                          }
                        >
                          <div>
                            <Card
                              draggableId={item.id}
                              item={item}
                              onDropdownSelect={onDropdownSelect}
                              jobDetails={jobDetails}
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        {/* <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="bg-white border-0">
            <Modal.Title>Select Stage</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white rounded border-0">
            {selectStage.map((val, key) => (
              <p className="d-flex" key={key}>
                <span>{val.title}</span>{" "}
                <i className={`${val.icon} ms-auto text-info`}></i>
              </p>
            ))}
          </Modal.Body>
        </Modal> */}
      </div>
    </>
  );
};

export default Column;
