import { z } from "zod";

//can be email
export const emailValidator = z.string().email();

export const emailOptionalValidator = z.string().email().or(z.literal(""));

// Company Size
export const stringOnlyValidator = (propertyName) =>
  z.string().nonempty(`${propertyName} is required`);

// Can be Address
export const stringNumberValidator = (propertyName, max) =>
  z
    .string()
    .nonempty(`${propertyName} is required`)
    .max(max, `${propertyName} must contain at most ${max} characters`);

// Can be Name
export const nonEmptyStringValidator = (propertyName, min, max) =>
  z
    .string()
    .nonempty(`${propertyName} is required`)
    .min(min, `${propertyName} contain at least ${min} letters`)
    .max(max, `${propertyName} must contain at most ${max} letters`)
    .regex(/^[A-Za-z\s]+$/, {
      message: `${propertyName} must contain letters and spaces only`,
    })
    .trim();

export const optionalWithConditionStringValidator = (propertyName, min, max) =>
  z
    .string()
    .min(min, `${propertyName} contain at least ${min} letters`)
    .max(max, `${propertyName} must contain at most ${max} letters`)
    .regex(/^[A-Za-z\s]+$/, {
      message: `${propertyName} must contain letters and spaces only`,
    })
    .trim()
    .optional()
    .or(z.literal(""));

// Can be middlename
export const optionalStringValidator = (propertyName) =>
  z
    .string()

    .regex(/^[A-Za-z\s]*$/, {
      message: `${propertyName} must contain letters and spaces only`,
    })
    .optional();

// Can be Postal
export const numberOnlyMaxMinValidator = (propertyName, min, max) =>
  z
    .string()
    .min(min, `${propertyName} contain at least ${min} numbers`)
    .max(max, `${propertyName} must contain at most ${max} numbers`)
    .regex(new RegExp(`^\\d{${min},${max}}$`), {
      message: `${propertyName} should be a number`,
    });

// Can be Contact Number
export const contactNumberValidator = z
  .string()
  .refine((value) => /^\d{12}$/.test(value), {
    message: "Contact number must be exactly 12 digits",
  });

export const contactNumberOptionalValidator = z
  .string()
  .refine((value) => /^(\+63)?\d{10}$/.test(value), {
    message:
      "Contact number must be exactly 12 digits or start with +63 and be 12 digits",
  })
  .optional()
  .or(z.literal(""));

export const createArraySchema = (itemName) =>
  z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
        __isNew__: z.boolean(),
      })
    )
    .refine((values) => values.length > 0, {
      message: `${itemName} are required`,
    });
