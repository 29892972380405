import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../../../../assets/scss/Client/Layout/BannerBreadcrumb.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Config from "../../../../../Config/config";
import { TabTitle } from "../../../../../Utilities/FunctionTitle";

const BannerJobListDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const clickJobs = () => {
    navigate("/jobs");
  };

  const clickJobList = () => {
    navigate(`/jd/${id}`);
  };

  const apiKey = process.env.REACT_APP_TALENT_API;
  const [jobDetails, setJobDetails] = useState([]);

  useEffect(() => {
    const inside = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("talentToken")}`,
        };

        const payload = {
          id: id,
        };

        // const response = await axios.post(`${apiKey}/viewjob`, payload, {
        //   headers,
        // });

        const response = await axios.post(
          `${Config.nxtApi}/api/jobs/job/details`,
          { job_id: id }
        );
        // console.log(response.data);
        setJobDetails(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    inside();
  }, [apiKey, id]);

  TabTitle(`NXT | ${jobDetails.jobTitle} | ${jobDetails.companyName}`);
  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>{jobDetails.jobTitle}</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickJobs} tag="a">
                Job List
              </BreadcrumbItem>
              <BreadcrumbItem onClick={clickJobList} tag="a">
                Job Information
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerJobListDetails;
