import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { SubscriptionFunction } from "../../../Utilities/Reusable/Function/Subscription/Subscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faMessage } from "@fortawesome/free-solid-svg-icons";
import UpgradeContact from "../../UI/Alert/UpgradeContact";

const ActiveSubscription = ({ subsData }) => {
  const { getFreeTrialVerified, getButtonLabel } = SubscriptionFunction();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="subsciption-container active-subscription mb-5">
      <h6>SUBSCRIPTION</h6>
      <hr className="line-seperator" />
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="shadow text-center p-4">
            <h3 className="mb-4">
              {subsData[0].activeSubscription.current === "freemium" ? (
                <>Basic</>
              ) : (
                <>
                  {subsData[0].activeSubscription.current
                    .charAt(0)
                    .toUpperCase() +
                    subsData[0].activeSubscription.current.slice(1)}
                  {subsData[0].addOn.current?.includes("ats+ai") ?? false
                    ? " + ATS & AI"
                    : !subsData[0].addOn.current
                    ? ""
                    : ` + ${
                        !subsData[0].addOn.current
                          ? ""
                          : typeof subsData[0].addOn.current === "string"
                          ? subsData[0].addOn.current.toUpperCase()
                          : subsData[0].addOn.current
                      }`.toUpperCase()}
                </>
              )}
            </h3>

            {/* <p className="me-2">30 Day Trial </p> */}

            <div className="d-grid gap-2">
              <Button className="d-block p-3">ACTIVE SUBSCRIPTION</Button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <p className="mt-4">Subscription Time:</p>

          <p>
            {subsData[0].activeSubscription.daysLeft === 0 ||
            !subsData[0].activeSubscription.daysLeft ? (
              "Your Subscription is Free!"
            ) : (
              <>
                Your subscription will end in{" "}
                <span>{subsData[0].activeSubscription.daysLeft}</span> days
              </>
            )}

            {/* {subsTimeLabel === "freeSubs" &&
            !existingTransaction &&
            !subscribedToPremium ? (
              "Your Subscription is Free!"
            ) : (
              <>
                Your subscription will end in <span>{daysBeforeExpiry}</span>{" "}
                days
              </>
            )} */}
          </p>
          {subsData[0].activeSubscription.current !== "freemium" && (
            <UpgradeContact />
          )}

          {/* <p role="button" onClick={handleShow}>
            Subscription Details
          </p> */}
        </div>
      </div>
      <Modal
        className="active-subs__modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tr>
              <td>Subscription Type:</td>
              <td>Premium</td>
            </tr>
            <tr>
              <td>Subscription Price:</td>
              <td>6,000.00</td>
            </tr>
            <tr>
              <td>Subscription Date:</td>
              <td>03/25/2024 - 04/25/2024</td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>Active</td>
            </tr>
            <tr>
              <td>Subscription Details:</td>
            </tr>
            <tr>
              <td colSpan={2} className="subs-details__list">
                Unlimited Job Postings
                <br /> Applicant Tracking System
                <br /> Downloadable CV
                <br />
                Customer Support (eMail, call)
                <br /> Real-time Application Status Email Notification
              </td>
            </tr>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ActiveSubscription;
