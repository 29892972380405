// import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../../../assets/scss/Components/Button/_bottomButton.scss";

const NoticeModal = ({ onClose }) => {
  console.log(onClose);

  const handleClose = () => {
    onClose();
    console.log(onClose);
    console.log("asdasdfas");
  };

  return (
    <div className={`modals ${onClose ? "d-block" : "d-none"}`}>
      <div className="modal-contents ">
        <div
          className="modal_title_xmark ml-auto d-block "
          onClick={handleClose}
        >
          <i className="fa-regular fa-circle-xmark modal_close_icon"></i>
        </div>

        <div
          id="carouselExampleIndicators"
          className="carousel slide h-100 "
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators position-absolute bottom-0 end-0 translate-middle-y">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active circle-btn"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              className="circle-btn"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            {/* <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              className="circle-btn"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button> */}
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active  carousel-item1">
              <p className="pb-3">
                <span>Exciting news!</span> We're constantly innovating to
                enhance
                <br />
                your job search or hiring experience.
                <br />
                Stay tuned for upcoming features that will revolutionize
                <br />
                the way you navigate the process:
              </p>

              <ul>
                <li>
                  <p>
                    <span>For Jobseekers,</span> NXT offers a seamless
                    experience,
                    <br />
                    empowering you to explore endless possibilities and
                    <br />
                    harness AI technology to streamline your search.
                  </p>
                </li>

                <li>
                  <p>
                    <span>For Employers,</span> NXT provides robust solutions
                    including
                    <br />
                    talent sourcing, an advanced Applicant Tracking System
                    <br />
                    (ATS), and cutting-edge AI capabilities.
                    <br />
                    <br />
                    Don't miss out on these game-changing improvements!
                    <br />
                    Sign up for updates now to be the first to know.
                  </p>
                </li>
              </ul>
            </div>
            <div className="carousel-item carousel-item2">
              <p>Hello NXT User!</p>

              <span className="mb-4 mb-4 lh-base">
                We are thrilled to announce that we have a
                <br />
                number of qualified candidates ready to
                <br />
                join your organization!
              </span>

              <p>
                Project Manager
                <br />
                Solutions Architect
                <br />
                Systems Analyst
                <br />
                Customer Service Specialist
                <br />
                Channel Marketing Assistant
                <br />
                System And Technical Support
                <br />
                Technical And Help Desk Support
                <br />
                Network Database Admin
                <br />
                Team Leader
                <br />
                Graphic Design Specialist
                <br />
                Field Sales Manager
                <br />
                Operations Specialist
                <br />
                IT Project Manager
                <br />
                Systems Engineer
                <br />
                <br />
                If you are in need of talented individuals for any of these
                roles, please contact us at{" "}
                <a href="mailto:ged@nxt.com.ph">ged@nxt.com.ph</a> Thank you!
              </p>
            </div>
            {/* <div className="carousel-item"></div> */}
          </div>
          {/* <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default NoticeModal;
