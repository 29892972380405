import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  aiml: null,
  isOn: true,
  isOnClient: true,
  loading: false,
  error: false,
};

const uiSlice = createSlice({
  name: "aiml",
  initialState,
  reducers: {
    startGetAI: (state) => {
      state.loading = true;
    },
    updateAI: (state, action) => {
      state.loading = false;
      state.aiml = {
        ...state.aiml,
        ...action.payload,
      };
    },
    getAI: (state, action) => {
      state.loading = false;
      state.error = false;
      state.aiml = action.payload;
    },
    failAI: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearAI: (state) => {
      state.aiml = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { startGetAI, updateAI, getAI, failAI, clearAI } = uiSlice.actions;

export default uiSlice.reducer;
