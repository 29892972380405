import React from "react";
import { Link } from "react-router-dom";
import "../assets/scss/ComingSoon.scss";
import { TabTitle } from "../Utilities/FunctionTitle";

const ComingSoon = () => {
  TabTitle("NXT | Coming Soon");
  return (
    <div className="coming-soon">
      <div>
        <img
          src={require("../assets/images/nxt-logo.png")}
          alt="nxt-logo"
          className="logo"
        />
      </div>
      <h1>COMING SOON</h1>
      <p>
        Thank you for visiting our website! We are currently working hard to
        bring you a better experience. <br />
        Please check back soon for updates.
      </p>
      <div>
        <button>
          <Link className="btn-label" to="/">
            Back to Login Page
          </Link>
        </button>
      </div>
      <h4>
        © Copyright 2023 by {""}
        <a
          href="https://www.acquiro.com.ph"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "white" }}
        >
          Acquiro
        </a>
        . All rights reserved.
      </h4>
    </div>
  );
};

export default ComingSoon;
