import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../../../../assets/scss/Page/Outer/Registration/_choosetype.scss";
import CustomButton from "../../../../../components/UI/CustomButton";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import { useLocation } from "react-router-dom";

const ChooseUserType = ({ onSubmit, userType }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [userTypeValue, setUserTypeValue] = useState(userType || "");
  const [errorMessage, setErrorMessage] = useState("");
  const handleUserTypeClick = (userType) => {
    setErrorMessage("");
    setUserTypeValue(userType); // Call the onSubmit function with the selected userType
  };

  const handleUserType = () => {
    setIsBtnLoading(true);
    if (userTypeValue === "") {
      setErrorMessage("Please select a user type."); // Set an error message
    } else {
      setErrorMessage(""); // Clear the error message
      onSubmit(userTypeValue);
    }
    setIsBtnLoading(false);
  };

  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State", sendState);
  console.log("Current URL:", location.pathname);

  return (
    <div className="chooseType">
      <div className="loginTitleLeft">
        {location.pathname.includes("/client/registration") ? (
          <h1>Proceed as Employer</h1>
        ) : (
          <h1>Proceed as Jobseeker</h1>
        )}
      </div>
      {/* <div
        onClick={() => handleUserTypeClick("client")}
        className="clientButton"
      >
        <img
          src={require("../../../../../assets/images/Vector/clientButton.png")}
        ></img>
        <p>I’m a client, looking for talents</p>
        {userTypeValue === "client" && (
          <FontAwesomeIcon icon={faCheck} className="icon" />
        )}
      </div>
      <div
        onClick={() => handleUserTypeClick("applicant")}
        className="talentButton"
      >
        <img
          src={require("../../../../../assets/images/Vector/talentButton.png")}
        ></img>
        <p>I’m a Talent, looking for work</p>
        {userTypeValue === "applicant" && (
          <FontAwesomeIcon icon={faCheck} className="icon" />
        )}
      </div> */}

      {location.pathname.includes("/client/registration") ? (
        <div
          onClick={() => handleUserTypeClick("client")}
          className="clientButton"
        >
          <img
            src={require("../../../../../assets/images/Vector/clientButton.png")}
          ></img>
          <p>I’m an Employer looking for talents</p>
          {userTypeValue === "client" && (
            <FontAwesomeIcon icon={faCheck} className="icon" />
          )}
        </div>
      ) : (
        <div
          onClick={() => handleUserTypeClick("applicant")}
          className="talentButton"
        >
          <img
            src={require("../../../../../assets/images/Vector/talentButton.png")}
          ></img>
          <p>I’m a Jobseeker, looking for work</p>
          {userTypeValue === "applicant" && (
            <FontAwesomeIcon icon={faCheck} className="icon" />
          )}
        </div>
      )}
      {errorMessage && <CustomMessage label={errorMessage} />}
      <div className="customBtnContainer">
        <CustomButton
          isLoading={isBtnLoading}
          name="Next"
          className="customLRButton"
          onClick={handleUserType}
        />
      </div>
    </div>
  );
};

export default ChooseUserType;
