import { useSelector } from "react-redux";

export const useAuthToken = () => {
  const token = useSelector((state) => state.reducer.user.currentUser?.token);
  return token;
};

export const useRoleKey = () => {
  const code =
    useSelector((state) => state.reducer.currentUser?.currentUser?.code) || "";
  // console.log(code);
  return code;
};
