export const errorMessages = {
  "MGU-000": "Your access to this account has been revoked.",
  "MGU-001": "Unable to switch accounts at the moment. Please try again.",
  "MGU-002": "Only NXT client accounts can be added as moderators/recruiters.",
  "MGU-003":
    "You'll be logged out due to inactivity or because you've signed in from another device",
  "MGU-004": "You do not have permission to perform this action.",
  "MGU-005": "Your access to this account has been revoked.",
  "MGU-006": "Unable to load account data. Please try again later.",
  "MGU-007": "This account is currently disabled",
  "MGU-008": "You have reached the maximum number of users.",
  "MGU-0020": "Only NXT client accounts can be added as moderators/recruiters.",
  "MGU-0021": "Email address is already registered as talent account.",
  "MGU-009": "User is already within shared account",
  "ERR-000": "Network error. Please check your connection and try again",
  "ERR-001": "",
  "AUT-000": "Invalid login credentials. Please try again.",
  "CON-001":
    "This record is being updated by another user. Please try again later.",
};
