import React from "react";
import ReactQuill from "react-quill";
import { Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import ErrorMessage from "./ErrorMessage";

const CustomRichText = ({
  label,
  name,
  control,
  defaultValue,
  rules,
  labelOptional,
  classNameLabel,
  className,
  errors,
  required,
  readOnly,
  ...props
}) => {
  const errorToShow = errors[name]
    ? errors[name].message === "Required"
      ? `${label} is required`
      : errors[name].message
    : null;
  return (
    <div className={`customInput ${className}`}>
      <label className={classNameLabel}>
        {label}{" "}
        {labelOptional && (
          <span className="label-optional">{labelOptional}</span>
        )}
        {required && <span className="required-field">*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        rules={rules}
        render={({ field: { ref, ...field } }) => (
          <>
            <ReactQuill
              {...field}
              theme="snow"
              inputExtraProps={{
                ref,
                required,
                autoFocus: true,
              }}
              id={name}
              value={field.value}
              readOnly={readOnly}
            />
            {errorToShow && <ErrorMessage label={errorToShow} />}
          </>
        )}
      />
    </div>
  );
};

export default CustomRichText;
