import React from "react";

const TalentSignUp = () => {
  return (
    <div>
      <p>
        Welcome to NXT, your leading platform bridging the gap between
        jobseekers and businesses. Simply create a free NXT Jobseeker account
        and start your next career journey with NXT!
      </p>

      <p>What can nxt.com.ph do for you?</p>
      <ul>
        <li>Create your Jobseeker account easily</li>
        <li>
          Maximize your opportunities to be informed of the latest local and
          global career trends
        </li>
        <li>Effortlessly apply to career opportunities at your fingertips</li>
        <li>Monitor the status of your job applications</li>
      </ul>

      <p>
        Register as a Jobseeker for free! Go to
        <a href="/talent/registration"> www.nxt.com.ph/talent/registration</a>
      </p>

      <ol>
        <li>Click on the Sign up as ‘Jobseeker’ button.</li>
        <li>
          Fill in your First Name, Last Name, Birthday, Address, Phone Number,
          Email Address, Country, Region, How you learned about NXT and accept
          Privacy Policy. Click on ‘Submit’, then ‘Confirm’.
        </li>
      </ol>
    </div>
  );
};

export default TalentSignUp;
