import React, { useState } from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/DocumentsCV.scss";
import { Button, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
const DocumentsCV = () => {
  const [cvData, setCVData] = useState({
    title: "My CV - John Mayer Doe",
    level: "05/15/2022",
    startDate: "05/25/2022",
    endDate: "06/25/2023",
  });

  return (
    <div className="st-work-history-container">
      <div className="header-container">
        <h6>DOCUMENTS (CV)</h6>
        <Button>
          <FontAwesomeIcon icon={faPencil} className="pencil-icon" /> Add Resume
        </Button>
      </div>
      <hr className="line-seperator" />
      <div className="cv-list">
        <Label>{cvData.title}</Label>
        {/* <p>Level: {cvData.level}</p> */}
        <p>
          Time Period: {cvData.startDate} to {cvData.endDate}
        </p>
        <div className="remove-icon-container">
          <div className="remove-work-container">
            <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
            <span>Remove</span>
          </div>
          <Button>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="view-attachment-btn"
            />{" "}
            View Attachment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsCV;
