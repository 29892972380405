import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { List } from "react-content-loader";
import moment from "moment";
import { useAuthToken } from "../../../Utilities/Auth/Token.js";
import { Alert } from "react-bootstrap";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod.js";
import "../../../assets/scss/Client/Layout/ApplicantList/ApplicantList.scss";

const ApplicantList = () => {
  const getJob = JSON.parse(localStorage.getItem("jobList"));
  const [payload, setPayLoad] = useState({
    filter: {},
    search: "",
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [applicantlist, setapplicantlist] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const navigate = useNavigate();
  const [totalApplicant, setTotalApplicant] = useState("");
  const token = useAuthToken();

  const getListData = async () => {
    setIsLoading(true);
    try {
      const response = await RequestMethod(
        `post`,
        `nxtApi`,
        `/api/client/jobs/${getJob.jobId}/applicants`,
        payload,
        token,
        false
      );

      setapplicantlist((prevList) => [...prevList, ...response.data.data]);
      setTotalApplicant(response.data.total);
      setLastKey(response.data.lastKey);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleSearchChange = (event) => {
    const cevent = new CustomEvent("onApplicationListSearch", {
      detail: event.target.value,
    });
    document.dispatchEvent(cevent);

    setapplicantlist([]);
    setLastKey(null);
    setPayLoad((oldPayload) => ({
      ...oldPayload,
      filter: {},
      search: event.target.value,
    }));
  };

  const handleLoadMore = () => {
    setPayLoad((oldPayload) => ({
      ...oldPayload,
      lastKey,
    }));
  };

  const handleFilter = (filterObj) => {
    const isEmptyFilter = Object.values(filterObj).every(
      (value) => value === null || value === ""
    );
    setapplicantlist([]);
    setLastKey(null);
    setPayLoad((oldPayload) => ({
      ...oldPayload,
      filter: isEmptyFilter ? {} : filterObj,
      search: "",
    }));
  };

  useEffect(() => {
    getListData();
  }, [payload]);

  useEffect(() => {
    const handleFilterEvent = (event) => {
      handleFilter(event.detail);
    };

    document.addEventListener("onApplicationListFilter", handleFilterEvent);
    return () => {
      document.removeEventListener(
        "onApplicationListFilter",
        handleFilterEvent
      );
    };
  }, []);

  const clickViewResume = (talentId, status, calendar) => {
    const data = {
      jobId: getJob.jobId,
      talentId: talentId,
      status: status,
      calendar: calendar,
    };

    navigate(`/client/view-resume?talentId=${talentId}`, {
      state: data,
    });
  };

  return (
    <div className="applicant-list-container">
      <h6>{totalApplicant} APPLICANTS</h6>
      <hr className="line-seperator" />

      <div className="show-search">
        <FormGroup className="show-group">
          <p>Show</p>
          <Input
            type="select"
            value={payload.pageSize}
            onChange={(e) =>
              setPayLoad({ ...payload, pageSize: e.target.value })
            }
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </Input>
          <p>entries</p>
        </FormGroup>

        <FormGroup className="search-group">
          <p>Search</p>
          <Input
            type="text"
            onChange={handleSearchChange}
            value={payload.search}
          />
        </FormGroup>
      </div>

      <div className="applicant-container-inner">
        <Table className="applicant-table" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date Applied</th>
              <th>Location</th>
              <th>Date of Birth</th>
              <th>Status</th>
              <th>Calendar</th>
              <th>Apply</th>
              <th>Action</th>
            </tr>
          </thead>
          {isLoading ? (
            <tr>
              <td colSpan="8" className="loader">
                <List />
              </td>
            </tr>
          ) : applicantlist.length === 0 ? (
            <td colSpan="8" className="no-data">
              <Alert color="secondary" className="mt-2">
                No data available.
              </Alert>
            </td>
          ) : (
            applicantlist.map((val, index) => (
              <tbody key={index}>
                <tr>
                  <td>
                    {val.firstName} {val.lastName}
                  </td>
                  <td>{moment(val.dateAppliedISO).format("MM/DD/YYYY")}</td>
                  <td>{val.location}</td>
                  <td>{val.birthdate}</td>
                  <td>{val.status}</td>
                  <td>{val.calendar ? <>{val.calendar}</> : <>N/A</>}</td>
                  <td>{val.apply}</td>
                  <td>
                    <Button
                      className="view-btn"
                      onClick={() => {
                        clickViewResume(val.talentId, val.status, val.calendar);
                      }}
                    >
                      View Resume
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))
          )}
        </Table>
      </div>

      <div className="entries-pagination-container">
        <div>
          <Button onClick={handleLoadMore} disabled={!lastKey}>
            {!lastKey ? "No More to Load" : "Load More"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicantList;
