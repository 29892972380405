import {
  faAngleRight,
  faArrowRight,
  faPesoSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CustomButton from "../../../../../components/UI/CustomButton";
import plan from "../../../../../Data/ATS/subscriptionPlans.json";
import "../../../../../assets/scss/Page/Admin/Subscription/_subscriptionContent.scss";
const SubscriptionContent = ({ activePlans, isFirstTime }) => {
  const getButtonName = (plan, activePlans, isFirstTime) => {
    if (activePlans.includes(plan.title)) {
      return "Paused Subscription";
    } else if (isFirstTime) {
      return "Start Free Trial";
    } else {
      return "Activate";
    }
  };

  return (
    <div className="subscriptionContent">
      {plan.map((plan, key) => (
        <>
          <div
            className={`${
              getButtonName(plan, activePlans, isFirstTime) ===
              "Paused Subscription"
                ? "activePlan"
                : "inactivePlan"
            }`}
            key={key}
          >
            <p className="activeText">{`${
              getButtonName(plan, activePlans, isFirstTime) ===
              "Paused Subscription"
                ? "Active Plan"
                : ""
            }`}</p>

            <div className="subscriptionDetails">
              <div className="planTitle">
                <h1>{plan.title}</h1>
                <div className="price">
                  <FontAwesomeIcon icon={faPesoSign} className="icon" />
                  <h2>{plan.price}</h2>
                </div>

                <h3>per user/month</h3>
                <h3>
                  for the first 12 months<span>*</span>
                </h3>
              </div>
              <div className="buttonContainer">
                <CustomButton
                  className={`subscriptionBtn ${
                    getButtonName(plan, activePlans, isFirstTime) ===
                    "Paused Subscription"
                      ? "activeSubBtn"
                      : "inactiveSubBtn"
                  }`}
                  name={getButtonName(plan, activePlans, isFirstTime)}
                />
              </div>
              <div className="subscriptionAdditionalDetailsContainer">
                {plan.perks?.map((perks, key) => (
                  <div key={key} className="subscriptionAdditionalDetails">
                    <FontAwesomeIcon icon={faAngleRight} />
                    <p>{perks.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default SubscriptionContent;
