import React from "react";

const AddJob = () => {
  return (
    <div>
      <p>
        Whether you are seeking skilled professionals, fresh graduates, or
        experienced candidates, our platform connects you with the right talent
        effortlessly. Maximize your reach and increase your chances of finding
        the ideal candidate by taking advantage of this NXT feature:
      </p>
      <ol>
        <li>Set up your company profile</li>
        <li>Click on ‘Manage Job’</li>
        <li>Click on ‘Add Job’</li>
        <li>Add the following details</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            Name<span className="text-danger">*</span>
          </li>
          <li>
            Email<span className="text-danger">*</span>
          </li>
          <li>
            Contact Number<span className="text-danger">*</span>
          </li>
          <p className="mt-5">JOB INFORMATION</p>
          <li>
            Job Title<span className="text-danger">*</span>
          </li>
          <li>
            Job Type<span className="text-danger">*</span>
          </li>
          <li>
            Work Arrangement<span className="text-danger">*</span>
          </li>
          <li>
            Office Hours<span className="text-danger">*</span>
          </li>
          <li>
            Office Address<span className="text-danger">*</span>
          </li>
          <li>
            City<span className="text-danger">*</span>
          </li>
          <li>
            Salary<span className="text-danger">*</span>
          </li>
          <li>
            Job Overview<span className="text-danger">*</span>
          </li>
          <li>
            Qualification<span className="text-danger">*</span>
          </li>
          <li>
            Benefits<span className="text-danger">*</span>
          </li>
          <p className="mt-5">JOB REQUIREMENTS</p>
          <li>
            Skill Set<span className="text-danger">*</span>
          </li>
          <li>
            Dialect<span className="text-danger">*</span>
          </li>
          <p className="mt-5">POSTING DATE</p>
          <li>
            Start Date<span className="text-danger">*</span>
          </li>
          <li className="mb-5">
            End Date<span className="text-danger">*</span>
          </li>
        </ol>
        <li>Click on ‘Save as A Draft’</li>
        <li>Click on ‘Publish’. Click on ‘Confirm’</li>
      </ol>
    </div>
  );
};

export default AddJob;
