import { useDispatch } from "react-redux";
import { logout } from "./userSlice";
import { clearCompanyProfile } from "./companyProfileSlice";
import { clearSubscribe } from "./subscribe";
import { clearProfile } from "./profileSlice";
import { clearAI } from "./aiAction";
import { clearfeatures } from "./featuresAction";

export const LogoutAndClearProfile = () => (dispatch) => {
  dispatch(clearProfile());
  dispatch(logout());
  dispatch(clearfeatures());
  dispatch(clearCompanyProfile());
  dispatch(clearAI());
  dispatch(clearSubscribe());
};
