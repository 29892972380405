import React from "react";

const ManageJobFeature = () => {
  return (
    <div className="manage-job py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h5>Manage Job</h5>
            <p className="mt-3 mb-5">
              The Manage Job feature empowers employers
              <br /> to seamlessly oversee and manage job postings.
              <br />
              <br /> Easily create, view, and update job listings, and
              effortlessly
              <br /> enroll them into the{" "}
              <strong>ATS (Applicant Tracking System)</strong>.
            </p>
            <h3>KEY FEATURES</h3>
            <div className="image-list row mt-5 text-center">
              <div className="col-md-3">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/key-feature-candidate.png")}
                />
                <p className="mb-0 mt-3">Candidate</p>
                <small>List</small>
              </div>
              <div className="col-md-3">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/key-feature-talent.png")}
                />
                <p className="mb-0 mt-3">Talent</p>
                <small>List</small>
              </div>
              <div className="col-md-3">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/key-feature-pooling.png")}
                />
                <p className="mb-0 mt-3">Pooling</p>
                <small>List</small>
              </div>
              <div className="col-md-3">
                <img
                  className="img-fluid"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/key-feature-hired.png")}
                />
                <p className="mb-0 mt-3">Hired</p>
                <small>List</small>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/laptop.png")}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageJobFeature;
