import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentLoginUser: null,
  isOn: true,
};

const currentLoginUserSlice = createSlice({
  name: "currentLoginUser",
  initialState,
  reducers: {
    getCurrentLoginUser: (state, action) => {
      state.currentLoginUser = action.payload;
    },

    cleanCurrentLoginUser: (state) => {
      state.currentLoginUser = null;
    },
  },
});

export const { getCurrentLoginUser, cleanCurrentLoginUser } =
  currentLoginUserSlice.actions;

export default currentLoginUserSlice.reducer;
