import React from "react";

const ReminderTroubleShooting = () => {
  return (
    <div>
      <ol>
        <li>You can only use your email address to register once.</li>
        <li>
          Always use a professional email address. This may include your
          firstname and lastname, or any combination that can be easily
          remembered when your employer tries to reach out to you via your
          email.
        </li>
        <li>
          Keep your email passwords secure. Using a combination of 8 characters
          at minimum is recommended. Combine alphabet, numbers and special
          characters such as !, #, @, &, *. etc.
        </li>
        <li>
          Make sure you logout of your email account after using a public
          computer or another person’s device. This will ensure that only you
          has access to your personal files, details, OTPs
        </li>
        <li>
          Make sure that you nominate at least 1 registered mobile number for
          your personal email address. This will help you retrieve your password
          in case you forget your email’s password.
        </li>
        <li>Never share your passwords and OTP to anyone.</li>
        <li>
          In case of slow connection or unavailability of pages, kindly try to
          perform the following:
          <ul style={{ listStyleType: "lower-alpha " }}>
            <li>
              <a href="https://www.wikihow.com/Allow-Pop%E2%80%93ups">
                Disable pop up blockers and Enable pop ups from nxt.com.ph
              </a>
            </li>
            <li>
              Clear your cache/browsing history and ensure that you use the
              latest browser versions.{" "}
              <ul style={{ listStyleType: "lower-roman" }}>
                <li>
                  <a href="https://support.google.com/chrome/answer/95589">
                    Google chrome
                  </a>
                </li>
                <li>
                  <a href="https://support.apple.com/en-au/guide/safari/sfri47acf5d6/mac">
                    Safari on Mac
                  </a>
                </li>
                <li>
                  <a href="https://support.microsoft.com/en-au/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4">
                    Microsoft edge
                  </a>
                </li>
                <li>
                  <a href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox">
                    Mozilla firefox
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        Do you have suggestions or comments? <br />
        Please contact us by submitting this{" "}
        <a
          href="https://www.nxt.com.ph/contact-us"
          target="_blank"
          rel="noreferrer"
        >
          form
        </a>
      </p>
    </div>
  );
};

export default ReminderTroubleShooting;
