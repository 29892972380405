import React from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_basicInformation.scss";
import moment from "moment";

const BasicInformation = ({ talentSubs, data }) => {
  // console.log("Basic Info ", talentSubs);
  return (
    <div>
      <TitleLine title="Basic Information"></TitleLine>
      <div className="basic_information">
        {talentSubs === true ? (
          ""
        ) : (
          <>
            <div className="basic_information_property">
              <h5>Last Name:</h5>
              <p>{data.lastName}</p>
            </div>
            <div className="basic_information_property">
              <h5>First Name:</h5>
              <p>{data.firstName}</p>
            </div>
            <div className="basic_information_property">
              <h5>Middle Name:</h5>
              <p>{data.middleName}</p>
            </div>
            <div className="basic_information_property">
              <h5>Email:</h5>
              <p>{data.email}</p>
            </div>
            <div className="basic_information_property">
              <h5>Contact:</h5>
              <p>{data.contactNumber ? data.contactNumber : "N/A"}</p>
            </div>
            <div className="basic_information_property">
              <h5>Birthday:</h5>
              <p>{moment(data.birthdate).format("MM/DD/YYYY")}</p>
            </div>
            <div className="basic_information_property">
              <h5>Address:</h5>
              <p>{data.address}</p>
            </div>
          </>
        )}
        <div className="basic_information_property">
          <h5>Municipality:</h5>
          <p>{data.municipality}</p>
        </div>
        <div className="basic_information_property">
          <h5>Country:</h5>
          <p>{data.country}</p>
        </div>
      </div>
    </div>
  );
};

export default BasicInformation;
