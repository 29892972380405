import { z } from "zod";
import {
  emailValidator,
  stringOnlyValidator,
} from "../../Validator/CommonValidator";

export const UserManagementSchema = z.object({
  email: emailValidator,
  roleType: stringOnlyValidator("Role type"),
});
