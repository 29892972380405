import React from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_certificate.scss";

const Certificate = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div>
        <TitleLine title="Documents (CERTIFICATES, ETC.)"></TitleLine>
        <p>No documents found</p>
      </div>
    );
  }
  return (
    <div>
      <TitleLine title="Documents (CERTIFICATES, ETC.)"></TitleLine>

      <div className="viewresume_certificate">
        {data.map((val, key) => (
          <div className="certificate_content" key={key}>
            <h5>{val?.documentName}</h5>
            <p>Time Period: {val?.validityDate}</p>
          </div>
        ))}
        {/* <div className="certificate_content">
          <h5>{dataTalentArray.documents}</h5>
          <p>Time Period: {dataTalentArray.timePeriod}</p>
        </div> */}
      </div>
    </div>
  );
};

export default Certificate;
