import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobListSidebar from "../../components/LayoutTalent/JobList/JobListDetails/JobListSidebar";
import JobListContent from "../../components/LayoutTalent/JobList/JobListDetails/JobListContent";
import JobDetailsContent from "../../components/LayoutClient/ViewJobDetails/JobDetailsContent";
import JobDetailsSidebar from "../../components/LayoutClient/ViewJobDetails/JobDetailsSidebar";
import BannerJobListDetails from "../../components/LayoutClient/ViewJobDetails/BannerJobDetails";
import Header from "../../layouts/Header";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import { useClient } from "../../hook/useClient";
import { useParams } from "react-router-dom";
import { List } from "react-content-loader";
const ViewJobDetails = () => {
  const { id } = useParams();
  TabTitle("NXT | Job List Details");
  const getJobPayload = JSON.parse(localStorage.getItem("previewJob"));
  const { viewJobDetails, viewJobData } = useClient();
  const [jobDetails, setJobDetails] = useState([]);

  useEffect(() => {
    viewJobDetails(getJobPayload.id, getJobPayload.job_id);
  }, [id]);

  useEffect(() => {
    console.log(viewJobData);

    if (viewJobData) {
      setJobDetails(viewJobData);
    }
  }, [viewJobData]);

  console.log(jobDetails);

  return (
    <div className="background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>

      {jobDetails.length === 0 ? (
        <List />
      ) : (
        <>
          {" "}
          <div className="jobposting-banner">
            <BannerJobListDetails jobDetails={jobDetails} />{" "}
          </div>
          <Container className="jobposting-body">
            <div>
              <div className="sidebar-container">
                {" "}
                <JobDetailsSidebar jobDetails={jobDetails} />
              </div>
            </div>

            <div className="company-profile-container">
              <JobDetailsContent jobDetails={jobDetails} />
            </div>
          </Container>
        </>
      )}

      <Footer />
    </div>
  );
};

export default ViewJobDetails;
