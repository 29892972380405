import React from "react";

import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import FormProfile from "../../components/FormTalent/FormProfile";
import BannerTalent from "../../components/LayoutTalent/BannerTalent";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import Footer from "../LandingPage/Components/Footer";

const ProfileTalent = () => {
  TabTitle("NXT | My Profile");
  return (
    <div className="background-imagePost">
      <TalentAnalytics />
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      <div className="jobposting-banner">
        <BannerTalent />
      </div>
      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <SidebarTalent />
          </div>
        </div>

        <div className="company-profile-container">
          <FormProfile />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ProfileTalent;
