import React from "react";
import "../../../assets/scss/Product/ProductHomeBody.scss";
// import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProductHomeBody = () => {
  const navigate = useNavigate();
  const handleClickSubscribeNow = () => {
    navigate("/pricing");
  };

  return (
    <>
      <div className="prod-home__body container py-5">
        <div className="row">
          <div className="col prod-home__card">
            <div className="p-4">
              <img
                src={require(`../../../assets/images/group.png`)}
                className="img-fluid mb-3"
              />
              <h3>Post</h3>
              <p>
                <strong>Hire Talents</strong>
                <br />
                Effortlessly post jobs and efficiently source candidates for
                both tech and non-tech roles. With a vast pool of active job
                seekers across various industries, from Data Analysis to
                eCommerce, our platform hosts a multitude of candidates eager to
                apply and empower your business. Find your next hire with ease
                at NXT.
              </p>
            </div>
          </div>
          <div className="col prod-home__card">
            <div className="p-4">
              <img
                src={require(`../../../assets/images/women.png`)}
                className="img-fluid mb-3"
              />
              <h3>Recruit</h3>
              <p>
                <strong>Recruit with AI</strong>
                <br />
                Utilize AI recruitment to identify the best talent for your
                businesses. Benefit from automated screening of candidates,
                ranking based on the best match for the role, automated job
                description generation, and an AI chatbot assistant.
              </p>
            </div>
          </div>
          <div className="col prod-home__card">
            <div className="p-4">
              <img
                src={require(`../../../assets/images/tabs.png`)}
                className="img-fluid mb-3"
              />
              <h3>Track</h3>
              <p>
                <strong>Applicant Tracking System</strong>
                <br />
                Enhance your hiring process with our advanced AI-powered tools.
                Enjoy centralized applicant management, job post progress
                tracking, efficient workflow management, customizable status
                updates, and a user-friendly interface.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="prod-home__subs py-5">
        <div className="container d-none d-sm-none d-md-block">
          <div className="p-5 w-50">
            <div className="py-5">
              <p>
                Let's make your <br />
                recruitment process quick <br />
                to post, easy to track,
                <br />
                and <span>seamless</span>.
              </p>
              <h5>Subscribe to our Elite Plan now!</h5>
              <div className="text-end mt-5">
                <Button
                  onClick={handleClickSubscribeNow}
                  className="btn-subscribe px-5 py-3 mt-5 d-none d-sm-none d-md-block"
                >
                  Check Pricing Plans
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ms-2 ms-sm-2 ms-md-4 position-absolute top-50 start-0 translate-middle-y"></div> */}
      </div>
      <div className="container d-block d-sm-block d-md-none bg-">
        <div className="py-5">
          <p>
            Let's make your <br />
            recruitment process quick <br />
            to post, easy to track,
            <br />
            and <span>seamless</span>.
          </p>
          <h5>Subscribe to our Elite Plan now!</h5>
          <div className="text-end mt-5">
            <Button
              onClick={handleClickSubscribeNow}
              className="btn-subscribe px-5 py-3 mt-5 d-none d-sm-none d-md-block"
            >
              Check Pricing Plans
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductHomeBody;
