import React from "react";

const MonitorStatus = () => {
  return (
    <div>
      <p>
        Our mission is to create new and meaningful experiences today by
        connecting a talented individual like you with the right opportunities.
        For your convenience, you will be receiving real time status updates
        based on your submitted applications so you will not miss any milestone
        in your application.{" "}
      </p>
      <ol>
        <li>
          Logon to your <a href="/talent/login">NXT account</a>
        </li>
        <li>
          Verify the 6-Digits OTP Code sent to your email and click on ‘Verify
          OTP’. Click on ‘Confirm’.
        </li>
        <li>Click on ‘Applied job’</li>
        <li>
          Check on your job applications updates by referring to the ‘Status’
          column
        </li>
      </ol>
    </div>
  );
};

export default MonitorStatus;
