import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import "../assets/scss/Modal.scss";

const WarningModal = ({
  modalOpen,
  toggleModal,
  iconImage,
  modalLabel,
  modalSublabel,
  confirmButtonText,
  onConfirm,
  hasCancelBtn,
  onCancel,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      keyboard={false}
      className="custom-modal-container"
      centered
    >
      <ModalBody className="custom-modal-body">
        <Button color="link" className="close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} className="close-icon" />
        </Button>
        <div className="icon-container">
          <FontAwesomeIcon icon={faXmarkCircle} className="warning-icon" />
        </div>
        {/* <img src={iconImage} alt="Icon" style={{ paddingBottom: 20 }} /> */}
        <p className="modal-title">{modalLabel}</p>
        <p className="modal-sublabel">{modalSublabel}</p>
        <div className="btnContainer">
          {hasCancelBtn && (
            <>
              <Button onClick={toggleModal} className="cancelBtn">
                Cancel
              </Button>
            </>
          )}
          <Button color="primary" onClick={onConfirm} className="warning-btn">
            {confirmButtonText}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WarningModal;
