import Slider from "rc-slider";
import React, { useState } from "react";
import "rc-slider/assets/index.css";
import { Button } from "react-bootstrap";
const MultiRange = ({ filter }) => {
  const [values, setValues] = useState([0, 999999]);

  const OnChangeEventTriggerd = (newValue) => {
    console.log("new Value", newValue);
    setValues(newValue);
  };

  const handleApplyFilter = () => {
    filter(values);
  };
  return (
    <div className="slider-container">
      <p style={{ textAlign: "center" }}>
        <strong> Monthly Salary Range</strong> <br /> ₱{" "}
        {values[0].toLocaleString()} to ₱ {values[1].toLocaleString()}
      </p>
      <Slider
        range
        pushable
        value={values}
        onChange={OnChangeEventTriggerd}
        defaultValue={20}
        min={0}
        max={999999}
      />
      <p className="apply-btn" onClick={handleApplyFilter}>
        Apply
      </p>
    </div>
  );
};

export default MultiRange;
