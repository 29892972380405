import axios from "axios";
import Config from "../../Config/config";

export const checkIsReferralCodeValid = async (code) => {
  try {
    const response = await axios(
      `${Config.nxtApi}/api/referrals/${code}/validity`
    );

    return response.data.value || response.data.referralCode;
  } catch (error) {
    console.error(error);
    return null;
  }
};
