import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomInput from "../../../../components/Form/CustomInput";
import PhoneInput from "react-phone-number-input/react-hook-form";
import CustomMessage from "../../../../components/Form/ErrorMessage";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomContactNumber from "../../../../components/Form/CustomContactNumber";
import { useSelector } from "react-redux";
const ContactPerson = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext({});

  return (
    <>
      <h1>Contact Person</h1>
      <hr className="line" />
      <div className="profile-fullname">
        <CustomInput
          errors={errors}
          label="Last Name"
          type="text"
          {...register("lastName")}
          placeholder="Last Name"
          className="company-input-name"
          classNameLabel="company-input-title"
          required={true}
        />
        <CustomInput
          errors={errors}
          label="First Name"
          type="text"
          {...register("firstName")}
          placeholder="First Name"
          className="company-input-name"
          classNameLabel="company-input-title"
          required={true}
        />
        <CustomInput
          errors={errors}
          label="Middle Name"
          type="text"
          {...register("middleName")}
          placeholder="Middle Name"
          className="company-input-name"
          classNameLabel="company-input-title"
          labelOptional="(Optional)"
        />
      </div>

      <div className="contact-details">
        <CustomInput
          errors={errors}
          label="Email Address"
          type="email"
          {...register("email")}
          placeholder="Email Address"
          className="company-input-contact"
          classNameLabel="company-input-title"
          required={true}
          readOnly={true}
        />

        <div className="company-input-contact">
          <CustomContactNumber
            errors={errors}
            control={control}
            {...register("contactNumber")}
            label="Contact Number"
          />
        </div>
      </div>
    </>
  );
};

export default ContactPerson;
