import React from "react";

const ValidateEmailClient = () => {
  return (
    <div>
      <p>
        We value your security. Your account is secured with us! A validation is
        required to proceed on accessing your account.. This also functions as a
        verification on your end if your registered email address is indeed
        accessible. This is important as this email address will be used by your
        prospective employer to contact you.
      </p>
      <ol>
        <li>
          Check your registered email’s inbox, and open the email with this
          subject: ‘Welcome to Acquiro’ and click on ‘Login’
        </li>
        <li>Enter your registered email address. Click on ‘Login’</li>
        <li>
          Type in the 6-Digits OTP Code sent to your email and click on ‘Verify
          OTP’. Click on ‘Confirm’.
        </li>
      </ol>
      <p>
        In case you do not see the email from your inbox, kindly check your spam
        folder. In the event that you accidentally deleted the validation email,
        please check your email’s trash.{" "}
      </p>

      <p className="mt-5">Tips on email address: </p>
      <ul>
        <li>
          <em>Use your personal email address or your</em> company-issued email
          address. Applicants are more likely to trust job postings with
          company-issued email addresses.
        </li>
        <li>
          <em>Recovery number and email address</em> . Ensure that you have
          included your recovery phone number and recovery email address in your
          personal email address so you will be able to retrieve your access in
          case you forget your email address’s password.
        </li>
        <li>
          <em>Every time you use a public computer</em> , ensure that you logout
          of your email account and ensure that you never save your password. If
          possible, clear cache or or clear history from the used browser
          accessing your email account.
        </li>
        <li>
          <em>First impressions matter</em> . Always use a professional email
          address during your job applications.
        </li>
      </ul>
    </div>
  );
};

export default ValidateEmailClient;
