import axios from "axios";
import Config from "../Config/config";
import { useAuthToken, useRoleKey } from "../Utilities/Auth/Token";
import { useState } from "react";

export const useAI = () => {
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [exposeQuestionnare, setExposeQuestionnare] = useState(null);
  const getScreeningId = async (jobId) => {
    try {
      const response = await axios.get(`${Config.ats}/api/ats/ai/screening`, {
        params: { jobId: jobId },
        headers,
      });
      console.log(response.data.result.data);
      setExposeQuestionnare(response.data.result.data);
      //   setExposeQuestionare(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestion = async (questions, jobId) => {
    const payload = {
      questions: questions,
      jobId,
    };
    try {
      const response = await axios.put(
        `${Config.ats}/api/ats/ai/screening`,
        payload,
        {
          headers,
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return { getScreeningId, exposeQuestionnare, getQuestion };
};
