import { z } from "zod";
import {
  contactNumberValidator,
  emailValidator,
  nonEmptyStringValidator,
  numberOnlyMaxMinValidator,
  optionalStringValidator,
  stringNumberValidator,
  stringOnlyValidator,
} from "../Validator/CommonValidator";

export const CompanyProfileSchema = z.object({
  email: emailValidator,
  lastName: nonEmptyStringValidator("Last Name", 1, 64),
  firstName: nonEmptyStringValidator("First Name", 1, 64),
  middleName: optionalStringValidator("Middle Name"),
  companyName: stringNumberValidator("Company Name", 64),
  industry: stringOnlyValidator("Industry"),
  companySize: stringOnlyValidator("Company Size"),
  address: stringNumberValidator("Address", 64),
  country: stringOnlyValidator("Country"),
  region: stringOnlyValidator("Region"),
  province: stringOnlyValidator("Province"),
  municipality: stringOnlyValidator("Municipality"),
  postal: numberOnlyMaxMinValidator("Postal Code", 4, 6),
  contactNumber: contactNumberValidator,
});
