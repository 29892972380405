import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

const CustomCreatableSelect = ({
  label,
  name,
  defaultValue,
  rules,
  labelOptional,
  classNameLabel,
  className,
  errors,
  required,
  options,
  value,
  readOnly,
  characterLimit,
  validationPattern,
  maxItems,
  ...props
}) => {
  const { setValue, clearErrors } = useFormContext();

  const [selectedOptions, setSelectedOptions] = useState(
    defaultValue ? defaultValue : []
  );

  console.log(defaultValue);
  useEffect(() => {
    setSelectedOptions(defaultValue ? defaultValue : []);
  }, [defaultValue]);

  useEffect(() => {
    if (Array.isArray(selectedOptions)) {
      const formattedOptions = selectedOptions.map((option) => ({
        label: option.label,
        value: option.value,
        __isNew__: option.__isNew__ || false,
      }));

      setValue(name, formattedOptions);
    } else {
      setValue(name, {
        label: selectedOptions.label,
        value: selectedOptions.value,
        __isNew__: selectedOptions.__isNew__ || false,
      });
    }
  }, [selectedOptions, setValue, name]);

  const errorToShow = errors[name]
    ? errors[name].message === "Required"
      ? `${label} is required`
      : errors[name].message
    : null;

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const allowedCharacters = validationPattern;
      if (!allowedCharacters.test(inputValue)) {
        inputValue = inputValue.replace(/[^a-zA-Z0-9-_./&\s]/g, "");
      }
      return inputValue.substring(0, characterLimit);
    }
    return inputValue;
  };

  const handleSelectChange = (newValue, actionMeta) => {
    if (maxItems && newValue.length > maxItems) {
      // Display error message when the limit is exceeded
      return;
    }

    setSelectedOptions(newValue);
    clearErrors(name);
  };

  const inputProps = {};
  if (characterLimit && validationPattern) {
    inputProps.onInputChange = handleInputChange;
  }

  return (
    <div className={`customInput ${className}`}>
      <label className={classNameLabel}>
        {label}{" "}
        {labelOptional && (
          <span className="label-optional">{labelOptional}</span>
        )}
        {required && <span className="required-field">*</span>}
      </label>
      <CreatableSelect
        id={name}
        value={selectedOptions}
        isMulti
        isClearable
        onChange={handleSelectChange}
        onBlur={() => {
          setValue(
            name,
            selectedOptions.map((option) => ({
              label: option.label,
              value: option.value,
              __isNew__: option.__isNew__ || false,
            }))
          );
        }}
        {...inputProps}
        placeholder=""
        options={options ? options : []}
        isDisabled={readOnly}
      />
      {(errorToShow || (maxItems && selectedOptions.length > maxItems)) && (
        <ErrorMessage
          label={
            errorToShow ||
            `You have exceeded the ${maxItems} maximum input for this field.`
          }
        />
      )}
    </div>
  );
};

export default CustomCreatableSelect;
