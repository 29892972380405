import React, { ForwardRefRenderFunction } from "react";
import "../../assets/scss/Components/Form/_customCheckbox.scss";
import ErrorMessage from "./ErrorMessage";
import { Link } from "react-router-dom";

const CustomCheckbox = (
  {
    name,
    placeholder,
    label,
    labelAfter,
    errors,
    errorClass,
    onChange,
    type,
    className,
    linkClassName,
    link = "", // Default value for link
    linkName = "", // Default value for linkName
    ...props
  },
  ref
) => {
  return (
    <div className={`CustomCheckbox ${className} `}>
      <label>
        <input
          name={name}
          ref={ref}
          {...props}
          type={type}
          onChange={onChange}
          className={`checkbox ${
            errorClass === "error-border" ? "error-border" : ""
          }`}
        />
        {label}
        <Link
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          className={`customLink ${linkClassName}`}
        >
          {linkName}{" "}
        </Link>
        {labelAfter}
      </label>
      {errors && errors[name] && <ErrorMessage label={errors[name].message} />}
    </div>
  );
};

export default React.forwardRef(CustomCheckbox);
