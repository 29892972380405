import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  resume: null,
  loading: false,
  error: false,
};

const resumeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    startResume: (state) => {
      state.loading = true;
    },
    updateResume: (state, action) => {
      state.loading = false;
      state.resume = {
        ...state.resume,
        ...action.payload,
      };
    },
    getResume: (state, action) => {
      state.loading = false;
      state.error = false;
      state.resume = action.payload;
    },
    failResume: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearResume: (state) => {
      state.resume = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { startResume, updateResume, getResume, failResume, clearResume } =
  resumeSlice.actions;

export default resumeSlice.reducer;
