import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import CustomInput from "../../../../../../components/Form/CustomInput";
import CustomContactNumber from "../../../../../../components/Form/CustomContactNumber";
const HiringManager = ({ editData, isViewJob }) => {
  const {
    register,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (editData) {
      setValue("name", editData.hiringManager?.name);
      setValue("email", editData.hiringManager?.email);

      const contactNumber = editData.hiringManager?.contactNumber;
      if (contactNumber && contactNumber.startsWith("09")) {
        const modifiedContactNumber = "639" + contactNumber.slice(2);
        setValue("contactNumber", modifiedContactNumber);
      } else {
        setValue("contactNumber", contactNumber);
      }
    }
  }, [editData, setValue]);

  useEffect(() => {
    const element = document.getElementById("topCreateForm");
    if (Object.keys(errors).length > 0) {
      console.log("first");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors]);

  return (
    <div>
      <h4 className="create-job__title border-bottom pb-3 mb-4">
        HIRING MANAGER INFORMATION
      </h4>

      <Row>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <CustomInput
            errors={errors}
            label="Name"
            type="text"
            {...register("name")}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            readOnly={isViewJob && true}
            required={true}
          />
          <Form.Text id="passwordHelpBlock" muted>
            e.g Juan Dela Cruz
          </Form.Text>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <CustomInput
            errors={errors}
            label="Email"
            type="text"
            {...register("email")}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            readOnly={isViewJob || editData?.hiringManager?.email}
            required={true}
          />
          <Form.Text id="passwordHelpBlock" muted>
            e.g jdelacruz@mail.com
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <CustomContactNumber
            errors={errors}
            label="Contact Number"
            {...register("contactNumber")}
            required={true}
            // value={watch("contactNumber")}
            // onChange={(e) => setValue("contactNumber", e.target.value)}
            className="company-input-lg-width"
            readOnly={isViewJob && true}
          />

          <Form.Text id="passwordHelpBlock" muted>
            e.g +639123456789
          </Form.Text>
        </Form.Group>
      </Row>
    </div>
  );
};

export default HiringManager;
