import React from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
// import Footer from "../../layouts/Footer";
import "../../assets/scss/Client/Page/TalentList.scss";
import SidebarJobList from "../../layouts/Client/SidebarJobList";
import BannerBreadcrumb from "../../layouts/Client/BannerBreadcrumb";
import BasicInformation from "../../components/LayoutClient/TalentList/SelectTalent/BasicInformation";
import KeySkill from "../../components/LayoutClient/TalentList/SelectTalent/KeySkill";
import WorkHistory from "../../components/LayoutClient/TalentList/SelectTalent/WorkHistory";
import DocumentsCV from "../../components/LayoutClient/TalentList/SelectTalent/DocumentsCV";
import DocumentsCert from "../../components/LayoutClient/TalentList/SelectTalent/DocumentsCert";
import SidebarSelectTalent from "../../layouts/Client/SidebarSelectTalent";
// import FooterClient from "../../layouts/FooterClient";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import Footer from "../LandingPage/Components/Footer";

const SelectTalent = () => {
  TabTitle("NXT | Talent List");
  return (
    <div className="background-imagePost-talent">
      <TalentAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <BannerBreadcrumb />
      </div>

      <Container className="jobposting-body">
        <div>
          <div className="sidebar-container">
            <SidebarSelectTalent />
          </div>
        </div>
        <div className="talent-list-container">
          <BasicInformation />
          <KeySkill />
          <WorkHistory />
          <DocumentsCV />
          <DocumentsCert />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default SelectTalent;
