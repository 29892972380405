import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import { Button, Nav, Tab } from "react-bootstrap";
import SubsCardPro from "../../../../components/LayoutClient/Subscription/SubscriptionCard/SubsCardPro";
import "../../../../assets/scss/Page/Admin/Subscription/_subscriptionCard.scss";
import SubsCardPremium from "../../../../components/LayoutClient/Subscription/SubscriptionCard/SubsCardList";
import { useSubscription } from "../../../../hook/useSubscription";
import { useSelector } from "react-redux";
import SubscriptionCardContainer from "../../../../components/LayoutClient/Subscription/Subscription";
import { List } from "react-content-loader";
import ActiveSubscription from "./Active/ActiveSubscription";
import AvailableAddOns from "./AddOn/AddOnList/AvailableAddOns";
import "../../../../../src/assets/scss/Client/Layout/Subscription/Subsciption.scss";
import { useLocation, useNavigate } from "react-router-dom";
import UpgradeContact from "../../../../components/UI/Alert/UpgradeContact";
const SubscriptionAts = () => {
  const clientId = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.clientId
  );

  const navigate = useNavigate();

  const location = useLocation();
  const subsSelectDetails = location.state?.messageSubs;
  console.log(subsSelectDetails);

  const { checkSubscription, checkSubsData } = useSubscription();
  console.log(Array.isArray(checkSubsData) && checkSubsData[0]?.multiuser);
  const multiUser =
    Array.isArray(checkSubsData) && checkSubsData?.[0]?.multiuser;
  const [isLoading, setIsLoading] = useState(false);
  const selectedPlan = {
    planName: "Premium",
    amount: 1000,
    value: 3,
    month: 1,
    description: [
      { isBullet: false, text: "Unlimited Job Postings" },
      { isBullet: false, text: "Basic Applicant Tracking System" },
      { isBullet: false, text: "Downloadable CV" },
      { isBullet: false, text: "Customer Support (email, call)" },
    ],
  };

  console.log(checkSubsData);

  const sampData = [
    {
      activeSubscription: {
        current: "premium",
        pendingPlan: null,
        daysLeft: 23,
      },
      addOn: {
        current: null,
        pendingPlan: null,
      },
    },
  ];

  const handleCheckSubscription = async () => {
    await checkSubscription(clientId);
  };

  useEffect(() => {
    handleCheckSubscription();
  }, [clientId]);

  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer subscription-card__ats">
          <WhiteContainer>
            <Tab.Container id="tabs-example" defaultActiveKey="first">
              <Nav variant="underline">
                <Nav.Item>
                  <Nav.Link eventKey="first">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Add Ons</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  {isLoading ? (
                    <List />
                  ) : (
                    <div className="content-container">
                      {checkSubsData && (
                        <ActiveSubscription activePlan={checkSubsData} />
                      )}
                    </div>
                  )}
                  {/* Your content for Tab 1 */}
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="content-container">
                    <div
                      className="subsciption-container d-flex w-100 active-subs-tab-content shadow-none border-0"
                      style={{
                        border: "none",
                        boxShadow: "none",
                        gap: "50px",
                      }}
                    >
                      <div className="container--subs__card card-shadow bg-white p-4 card">
                        <h2 className="container--subs__card--plan text-center my-2 fw-bold">
                          Multi-user
                        </h2>
                        <div className="container--subs__card--amount d-flex justify-content-center">
                          <h5 className="me-2">₱</h5>
                          <h2 className="display-4"> 2,000</h2>
                        </div>
                        <p className="text-center">per month</p>
                        <Button
                          onClick={() =>
                            navigate(
                              "/admin/ats/subscription/subscription-addons"
                            )
                          }
                          className={`${
                            multiUser.pendingMultiuser === true ||
                            multiUser.current === true
                              ? "btn-blue"
                              : "btn-green"
                          } d-block p-3 border-0 mb-5`}
                          disabled={
                            multiUser.pendingMultiuser || multiUser.current
                          }
                        >
                          {multiUser.pendingMultiuser === true
                            ? "PENDING ADD ONS"
                            : multiUser.current === true
                            ? "SUBSCRIBED"
                            : "SUBSCRIBE NOW"}
                        </Button>
                        <ul className="list-unstyled">
                          <li className="mb-4">
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            same access to the client as the subscriber who
                            invited them
                          </li>
                          <li className="mb-4">
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            added member will have their own employer profile
                          </li>
                          <li>
                            <i className="fa-solid fa-chevron-right pe-2 lh-base"></i>
                            added member can switch access if they have multiple
                            clients
                          </li>
                        </ul>
                      </div>
                      <div>
                        <strong>
                          <div>
                            <p className="text-decoration-underline m-0 mb-2">
                              Subscription Time:
                            </p>

                            <p>
                              {Array.isArray(checkSubsData) &&
                              checkSubsData[0].activeSubscription.daysLeft ===
                                0 ? (
                                "Your Subscription is Free!"
                              ) : (
                                <>
                                  Your subscription will end in{" "}
                                  <span className="text-danger">
                                    {Array.isArray(checkSubsData) &&
                                      checkSubsData[0].activeSubscription
                                        .daysLeft}
                                  </span>{" "}
                                  days
                                </>
                              )}
                            </p>
                          </div>
                          {checkSubsData?.[0]?.activeSubscription?.current !==
                            "freemium" && (
                            <div className="mt-5">
                              <UpgradeContact />
                            </div>
                          )}
                        </strong>
                      </div>
                    </div>
                    {/* {checkSubsData && (
                      <AvailableAddOns checkSubsData={checkSubsData} />
                    )} */}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  {/* Your content for Tab 3 */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAts;
