import React from "react";

const Sticker = () => {
  return (
    <div className="sticker text-center position-relative">
      <p className="position-absolute top-50 start-50 translate-middle w-100">
        Enjoy exclusive features for FREE for a Limited Time!
      </p>
    </div>
  );
};

export default Sticker;
