import React from "react";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import Image from "../../components/UI/Image";
import CustomButton from "../../components/UI/CustomButton";

const ClientNav = () => {
  return (
    <>
      <Navbar expand="md" className="bg-body-tertiary">
        <Navbar.Brand className="nxtLogo">
          <Image
            height={54}
            width={150}
            src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <Image
                height={54}
                width={150}
                src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3 landingNavMenu">
              <NavDropdown
                title="Profile"
                id={`offcanvasNavbarDropdown-expand-md`}
              >
                <NavDropdown.Item>FAQ</NavDropdown.Item>
                <NavDropdown.Item>Contact Us</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>Talent List</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link>
                <CustomButton name="Post a Job" />
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};

export default ClientNav;
