import React from "react";
import { Container } from "reactstrap";
import "../../assets/scss/About/Content.scss";
const Content = () => {
  return (
    <div className="about-content">
      <Container>
        <h1>NXT: Bridging the gap between jobseekers and business</h1>

        <p>
          NXT stands at the forefront of talent connection, bridging the gap
          between job seekers and businesses with its innovative platform.
          Powered by Asticom Technology Inc., a shared services and outsourcing
          provider within Globe, NXT revolutionizes the industry by connecting
          employers with top-tier talent.
        </p>

        <p>
          <span>For employers,</span> NXT offers unparalleled benefits,
          including access to a <span>diverse pool</span> of skilled
          professionals, <span>streamlined recruitment </span>
          processes, and advanced analytics to ensure{" "}
          <span>optimal hiring</span> outcomes. Our platform empowers employers
          to post jobs effortlessly, harnessing the power of tech and AI to
          attract the best candidates and drive business success.
        </p>

        <p>
          <span>For job seekers,</span> NXT is a gateway to exciting career
          opportunities across a <span>wide range</span> of industries and
          roles. With <span>user-friendly </span>
          features and personalized job recommendations, job seekers can
          navigate the job market with ease and confidence. Whether you're a
          recent graduate or an experienced professional, NXT provides the tools
          and resources you need to take the next step in your career journey.
        </p>

        <p>
          Ready to experience the future of talent acquisition? Join us at NXT
          and create your Next eXperience Today!
        </p>
      </Container>
    </div>
  );
};

export default Content;
