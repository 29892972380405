import Dashboard from "../../../pages/Admin/Dashboard/Dashboard";

export const Message = {
  client: {
    dashboard: { 500: "Something went wrong" },
    getUser: {
      401: "Your session has expired. Please log in again.",
      500: "Something went wrong",
    },
    switchUser: {
      401: "Your session has expired. Please log in again.",
      404: "Network error. Please check your connection and try again.",
      500: "Something went wrong",
    },
    addUser: {
      401: "Your session has expired. Please log in again.",
      200: "User successfully invited!",
      201: "User successfully invited!",
      500: "Something went wrong",
    },
    deleteUser: {
      400: "This record is being updated by another user. Please try again later.",
      500: "Unable to delete users",
    },
    postJob: {
      200: "Success",
      403: "Unauthorized",
      500: "Internal Server Error",
    },
    getJobs: {
      401: "Your session has expired. Please log in again.",
    },
    viewJob: {
      200: "Success",
      400: "Job is not open",
      403: "Unauthorized",
      500: "Internal Server Error",
    },
    aiQuestionare: {
      200: "The questionnaire was successfully created.",
    },
    aiQuestionareDraft: {
      200: "Your job posting draft has been saved. Press 'OK' to continue",
      401: "Atleast 1 question is required",
    },
    jobDeleted: {
      200: "Are you sure want to close this job?",
    },
  },
  talent: {
    workHistory: {
      200: "Job posted successfully",
      403: "You are not authorized to post this job",
      500: "There was an error while posting the job",
    },
    viewJob: {
      400: "This job is no longer available",
    },
    aiQuestionare: {
      200: "Thank you for answering the questionnaire!",
      500: "Internal Server Error",
    },
  },
  admin: {
    postJob: {
      200: "Job has been successfully posted",
      403: "Please fill in all required fields for your work history details before saving.",
      500: "Internal Server Error. Please try again later",
    },
  },

  ai: {
    cvParsing: {
      200: "Job posting succeeded",
      205: "Unable to extract information from the file. Please try again.",
      400: "Please fill in all required fields for your work history details before saving.",
      401: "Please fill in all the required fields for each entry in your work history",
      100: "Are you sure you want to cancel saving the AI-extracted work history?",
      101: "Are you sure you want to delete this work history?",
      102: "You have unsaved work history. Are you sure you want to leave this page?",
      403: "Access denied",
      500: "System error occurred",
    },
  },
};
