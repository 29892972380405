import React from "react";
import "../../assets/scss/Talent/Page/AppliedJob.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import FormProfile from "../../components/FormTalent/FormProfile";
import BannerTalent from "../../components/LayoutTalent/BannerTalent";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import KeySkills from "../../components/LayoutTalent/MyResumeLayout/KeySkills";
import WorkHistory from "../../components/LayoutTalent/MyResumeLayout/WorkHistory";
import DocumentsCV from "../../components/LayoutTalent/MyResumeLayout/DocumentsCV";
import DocumentCertificates from "../../components/LayoutTalent/MyResumeLayout/DocumentCertificates";
import AppliedJobTable from "../../components/LayoutTalent/AppliedJob/AppliedJobTable";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import Footer from "../LandingPage/Components/Footer";

const AppliedJobPageTalent = () => {
  TabTitle("NXT | Applied Job");
  return (
    <div className="background-imagePost-appledJob">
      <TalentAnalytics />{" "}
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      <div className="jobposting-banner">
        <BannerTalent />
      </div>
      <Container className="applied-job-body">
        <div className="applied-job-body-inner">
          <div>
            <div className="sidebar-container">
              <SidebarTalent />
            </div>
          </div>
          <div className="company-profile-container">
            <AppliedJobTable />
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AppliedJobPageTalent;
