import React from "react";
import { useNavigate } from "react-router";
import { Button, Container } from "reactstrap";
import "../../assets/scss/About/Apply.scss";

const Apply = () => {
  const navigate = useNavigate();

  const handleClickApplyNow = () => {
    navigate("/talent/registration");
  };

  const handleClickPostJob = () => {
    navigate("/client/registration");
  };

  return (
    <div className="about-apply">
      <Container>
        <div className="apply-body">
          <div className="apply-img-container-post">
            <Button onClick={handleClickPostJob}>Post Jobs</Button>
          </div>
          <div className="apply-img-container-apply">
            <Button onClick={handleClickApplyNow}>Find Jobs</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Apply;
