import React, { useState, useEffect } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import cities from "../../assets/global/cities.json";
import languageData from "../../assets/global/ListofLanguages.json";
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faFileLines, faUpload } from "@fortawesome/free-solid-svg-icons";
import "../../assets/scss/Client/Components/FormManageJobModal.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreatableSelect from "react-select/creatable";
import GlobalText from "../../assets/global/GlobalText.json";
import ListofCity from "../../assets/global/ListofCity.json";
import WarningModal from "../WarningModal";
import QuestionModal from "../QuestionModal";
import SuccessModal from "../SuccessModal";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../layouts/loader/Loader";
import { NumericFormat } from "react-number-format";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { RequestMethod } from "../../Utilities/Reusable/Method/RequestMethod";
import Config from "../../Config/config";
import { reModalMessage } from "../../Utilities/Reusable/Function/ModalMessage";
import { reModalType } from "../../Utilities/Reusable/Function/ModalType";
import CustomModal from "../UI/Modal";
import { useSelector } from "react-redux";

const FormManageJobModal = ({
  isOpen,
  close,
  jobId,
  createdAt,
  jobPostStatus,
}) => {
  const daysOfValidity = useSelector(
    (state) => state.reducer.feature?.isfeatures?.subscription?.daysOfValidity
  );

  const isSubscribed = useSelector(
    (state) => state.reducer.feature?.isfeatures?.isSubscribed
  );

  const apiKey = process.env.REACT_APP_CLIENT_API;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState("");
  const [show, setShowModal] = useState(false);
  const message = reModalMessage(modalType);
  const modalTypeName = reModalType(modalType);
  //Data Form
  const [hiringName, setHiringName] = useState("");
  const [hiringEmail, setHiringEmail] = useState("");
  const [hiringContact, setHiringContact] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [workArrangement, setWorkArrangement] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [city, setCity] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [jobOverview, setJobOverview] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [qualification, setQualification] = useState("");
  const [dialect, setDialect] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [lengthDescription, setLengthDescription] = useState(0);
  const [lengthJobOverView, setLengthJobOverView] = useState(0);
  const [lengthQualification, setLengthQualification] = useState(0);

  const [createdAtState, setCreatedAtState] = useState("");
  const [jobIdState, setjobIdState] = useState("");
  const [jobStatus, setJobStatus] = useState("");

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBenefits, setSelectedBenefits] = useState([]);

  //Displyed Time Format
  const [displayStartTime, setDisplayStartTime] = useState("");
  const [displayEndTime, setDisplayEndTime] = useState("");

  //Data Form Error
  const [hiringEmailExistsError, setHiringEmailExistsError] = useState("");

  //Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);

  // Loader
  const [isLoading, setIsLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const toggleModal2 = () => {
    setModalOpen2(!modalOpen2);
  };
  const toggleModal3 = () => {
    setModalOpen3(!modalOpen3);
  };
  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };
  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const token = useAuthToken();
  useEffect(() => {
    const fetchJobData = async (jobId, createdAt) => {
      setErrors("");

      const url = `${Config.nxtApi}/api/client/getonejob/`;

      try {
        // const response = await axios.post(
        //   url,
        //   {
        //     id: jobId,
        //     createdAt: createdAt,
        //   },
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );

        const response = await RequestMethod(
          "post",
          "nxtApi",
          "/api/client/getonejob",
          { id: jobId, createdAt: createdAt },
          token,
          null,
          null
        );

        const jobData = response.data.data;
        const officeHours = response.data.data.officeHours;

        // Split the officeHours string using the hyphen as the separator
        const [startTimeStr, endTimeStr] = officeHours.split(" - ");

        // Format the start and end times using moment.js
        const displayStartTime = moment(startTimeStr, "h:mm A").format("HH:mm");
        const displayEndTime = moment(endTimeStr, "h:mm A").format("HH:mm");

        // Format Date
        const startDateStr = moment(jobData.postingDate.startDate).format(
          "YYYY-MM-DD"
        );
        const endDateStr = moment(jobData.postingDate.endDate).format(
          "YYYY-MM-DD"
        );

        // Update the state with the fetched job data
        setHiringName(jobData.hiringManager.name);
        setHiringEmail(jobData.hiringManager.email);
        setHiringContact(jobData.hiringManager.contactNumber);
        setJobTitle(jobData.jobTitle);
        setJobType(jobData.jobType);
        setWorkArrangement(jobData.workArrangement);
        setOfficeAddress(jobData.officeAddress);
        setCity(jobData.city);
        setMinSalary(jobData.minSalary);
        setMaxSalary(jobData.maxSalary);
        setJobOverview(jobData.jobOverview);
        setJobDescription(jobData.jobDescription);
        setQualification(jobData.qualification);
        setDialect(jobData.dialect);
        setStartDate(startDateStr);
        setEndDate(endDateStr);
        setStartTime(displayStartTime);
        setEndTime(displayEndTime);

        // Update selected skills and benefits based on job data
        setSelectedSkills(jobData.skillSet);
        setSelectedBenefits(jobData.benefits);
      } catch (error) {
        console.error("Error fetching job data:", error);
        // Handle error accordingly
      }
    };

    if (isOpen) {
      // Fetch job data when the component mounts or when the jobId prop changes
      fetchJobData(jobId, createdAt);
      setCreatedAtState(createdAt);
      setjobIdState(jobId);
      setJobStatus(jobPostStatus);
    }
  }, [isOpen, createdAt, jobId, apiKey, jobPostStatus]);

  const handleManageClick = () => {
    window.location.reload();
  };

  const handleJobOverviewChange = (value) => {
    // setJobOverview(value);
    // setErrors({ ...errors, jobOverview: "" });
    let trimmedValue = value.trim();
    trimmedValue = trimmedValue.replace(/<\/?[^>]+(>|$)/g, "");

    if (trimmedValue === "") {
      setJobOverview("");
      setLengthJobOverView(0);

      setErrors({ ...errors, jobOverview: "" });
      return "Job Overview is required";
    } else {
      setLengthJobOverView(trimmedValue.length);

      if (trimmedValue.length <= 5000) {
        setJobOverview(value);
        setErrors({ ...errors, jobOverview: "" });
      } else {
        setErrors({ jobOverview: "You have exceeded the maximum length." });
        return "You have exceeded the maximum length.";
      }
    }
    return "";
  };

  const handleJobDescriptionChange = (value) => {
    // setJobDescription(value);
    // setErrors({ ...errors, jobDescription: "" });
    let trimmedValue = value.trim();
    trimmedValue = trimmedValue.replace(/<\/?[^>]+(>|$)/g, "");

    if (trimmedValue === "") {
      setJobDescription("");
      setLengthDescription(0);
      setErrors({ ...errors, jobDescription: "" });
      return "Job Description is required";
    } else {
      setLengthDescription(trimmedValue.length);

      if (trimmedValue.length <= 5000) {
        setJobDescription(value);
        setErrors({ ...errors, jobDescription: "" });
      } else {
        setErrors({ jobDescription: "You have exceeded the maximum length." });
        return "You have exceeded the maximum length.";
      }
    }
    return "";
  };

  const handleJobQualiChange = (value) => {
    // setQualification(value);
    // setErrors({ ...errors, qualification: "" });

    let trimmedValue = value.trim();
    trimmedValue = trimmedValue.replace(/<\/?[^>]+(>|$)/g, "");

    if (trimmedValue === "") {
      setQualification("");
      setLengthQualification(0);
      setErrors({ ...errors, qualification: "" });
      return "Job Qualification is required";
    } else {
      setLengthQualification(trimmedValue.length);

      if (trimmedValue.length <= 5000) {
        setQualification(value);
        setErrors({ ...errors, qualification: "" });
      } else {
        setErrors({ qualification: "You have exceeded the maximum length." });
        return "You have exceeded the maximum length.";
      }
    }
    return "";
  };

  //Start and End Date
  const today = new Date();
  const formattedToday = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

  const handleStartDateChange = (e) => {
    const value = e.target.value;

    if (new Date(value) > new Date(today.getFullYear(), 11, 31)) {
      setErrors({
        ...errors,
        startDate: "Start date cannot exceed the current year.",
      });
    } else {
      setErrors({ ...errors, startDate: "" });
      setStartDate(value);
    }
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    const oneYearFromStartDate = new Date(startDate);
    oneYearFromStartDate.setFullYear(oneYearFromStartDate.getFullYear() + 1);

    if (new Date(value) < new Date(startDate)) {
      setErrors({
        ...errors,
        endDate: "End date cannot be before the start date.",
      });
    } else if (new Date(value) > oneYearFromStartDate) {
      setErrors({
        ...errors,
        endDate: "End date cannot exceed 1 year from the start date.",
      });
    } else {
      setErrors({ ...errors, endDate: "" });
      setEndDate(value);
    }
  };

  //Error messages for validation
  const [errors, setErrors] = useState({
    hiringName: "",
    hiringEmail: "",
    hiringContact: "",
    jobTitle: "",
    jobType: "",
    workArrangement: "",
    officeAddress: "",
    city: "",
    minSalary,
    maxSalary,
    jobOverview: "",
    jobDescription: "",
    qualification: "",
    benefits: "",
    skillSet: "",
    dialect: "",
    startDate: "",
    endDate: "",
  });

  //Regex Validation
  const validateField = (value, regex, fieldName) => {
    if (!value || value.trim() === "") {
      return `${fieldName} is required`;
    } else if (!regex.test(value.trim())) {
      return `Enter a valid ${fieldName}`;
    }
    return "";
  };

  //Handle Validation
  const handleValidation = async () => {
    const hiringEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const hiringContactRegex =
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/;
    const salaryRegex = /^[^a-zA-Z]*$/;

    const errors = {
      hiringName: hiringName.trim() === "" ? "Name is required" : "",
      hiringEmail:
        hiringEmail.trim() === ""
          ? "Email is required"
          : validateField(hiringEmail, hiringEmailRegex, "Email Address"),
      hiringContact: validateField(
        hiringContact,
        hiringContactRegex,
        "Contact Number"
      ),
      jobTitle: jobTitle.trim() === "" ? "Job Title is required" : "",
      jobType: jobType.trim() === "" ? "Job Type is required" : "",
      workArrangement:
        workArrangement.trim() === "" ? "Work Arrangement is required" : "",
      officeAddress:
        officeAddress.trim() === "" ? "Office Address is required" : "",
      city: city.trim() === "" ? "City is required" : "",
      minSalary: validateSalaryRangeMin(),
      maxSalary: validateSalaryRangeMax(),

      jobOverview: handleJobOverviewChange(jobOverview),
      jobDescription: handleJobDescriptionChange(jobDescription),
      qualification: handleJobQualiChange(qualification),
      dialect:
        Array.isArray(dialect) && dialect.length === 0
          ? "Dialect is required"
          : "",
      startDate:
        startDate.trim() === ""
          ? "Start Date is required"
          : new Date(startDate) < new Date(formattedToday)
          ? "Start date cannot be in the past."
          : "",
      endDate:
        endDate.trim() === ""
          ? "End Date is required"
          : new Date(endDate) < new Date(startDate)
          ? "End date cannot be before the start date."
          : new Date(endDate).getTime() === new Date(startDate).getTime()
          ? "Start date and End date must not be the same."
          : "",
      startTime: startTime.trim() === "" ? "Start Time is required" : "",
      endTime: endTime.trim() === "" ? "End Time is required" : "",
      benefits: selectedBenefits.length === 0 ? "Benefits are required" : "",
      skillSet: selectedSkills.length === 0 ? "Skill Set is required" : "",
    };

    const startDateMoment = moment(startDate, "YYYY-MM-DD");
    const endDateMoment = moment(endDate, "YYYY-MM-DD");

    // const daysDifference = endDateMoment.diff(daysOfValidity, "days");

    // const dov = !daysOfValidity || daysOfValidity === 0 ? 30 : daysOfValidity;
    const daysDifference =
      daysOfValidity === 0 || !daysOfValidity
        ? endDateMoment.diff(daysOfValidity, "days")
        : moment(endDate, "YYYY-MM-DD").isBefore(
            moment(startDate, "YYYY-MM-DD").add(daysOfValidity, "days")
          );

    if (daysOfValidity !== 0 && isSubscribed) {
      if (daysDifference) {
        setErrors({
          ...errors,
          endDate: `End date must be within ${daysOfValidity} days of Start Date`,
        });
        return false;
      }
    }

    if ((daysOfValidity === 0 || !daysOfValidity) && daysDifference > 30) {
      setErrors({
        ...errors,
        endDate: `End date must be within 30 days of Start Date`,
      });
      return false;
    }

    if (!jobOverview.trim()) {
      setErrors({ ...errors, jobOverview: "Job Overview is required" });
      return;
    }
    if (!jobDescription.trim()) {
      setErrors({ ...errors, jobDescription: "Job Description is required" });
      return;
    }
    if (!qualification.trim()) {
      setErrors({ ...errors, qualification: "Qualification is required" });
      return;
    }
    // existing validations...

    if (selectedBenefits.length === 0) {
      setErrors({ ...errors, benefits: "Benefits are required" });
      return;
    }
    if (selectedSkills.length === 0) {
      setErrors({ ...errors, skillSet: "Skill Set is required" });
      return;
    }

    const isValid = Object.values(errors).every((error) => error === "");
    setErrors(errors);
    return isValid;
  };

  const validateSalaryRangeMin = () => {
    const regex = /^[0-9]+$/;

    if (minSalary === "") {
      return "Minimum salary is required";
    }

    if (minSalary === "0") {
      return "Minimum salary cannot be zero (0)";
    }

    if (parseInt(minSalary) === parseInt(maxSalary)) {
      return "";
    }

    if (!regex.test(minSalary) && parseInt(maxSalary)) {
      return "Minimum salary must be whole numbers.";
    }

    if (!regex.test(minSalary)) {
      return "Minimum salary must be whole numbers.";
    }

    if (parseInt(minSalary) > parseInt(maxSalary)) {
      return "";
    }

    setErrors("");
    return "";
  };

  const validateSalaryRangeMax = () => {
    const regex = /^[0-9]+$/;

    if (maxSalary === "") {
      return "Maximum salary is required";
    }

    if (maxSalary === "0") {
      return "Maximum salary cannot be zero (0)";
    }

    if (parseInt(minSalary) === parseInt(maxSalary)) {
      return "Minimum and maximum salary cannot be the same";
    }

    if (!regex.test(maxSalary) && parseInt(minSalary)) {
      return "Maximum salary must be whole numbers.";
    }

    if (!regex.test(maxSalary)) {
      return "Maximum salary must be whole numbers.";
    }

    if (parseInt(minSalary) >= parseInt(maxSalary)) {
      return "Minimum salary must be less than the maximum salary.";
    }

    setErrors("");
    return "";
  };

  //Time Format
  const handleStartTimeChange = (e) => {
    const selectedTime = e.target.value;
    if (selectedTime.trim() === "") {
      setErrors({ ...errors, startTime: "Start time is required" });
    } else {
      setErrors({ ...errors, startTime: "" });
      setStartTime(selectedTime);
      const timeWithSuffix = addAmPmSuffix(selectedTime);
      setDisplayStartTime(timeWithSuffix);
    }
  };

  const handleEndTimeChange = (e) => {
    const selectedTime = e.target.value;
    if (selectedTime.trim() === "") {
      setErrors({ ...errors, endTime: "End time is required" });
    } else {
      setErrors({ ...errors, endTime: "" });
      setEndTime(selectedTime);
      const timeWithSuffix = addAmPmSuffix(selectedTime);
      setDisplayEndTime(timeWithSuffix);
    }
  };

  const addAmPmSuffix = (time) => {
    const [hours, minutes] = time.split(":");
    const parsedHours = parseInt(hours, 10);

    let suffix = "AM";
    if (parsedHours >= 12) {
      suffix = "PM";
    }

    return `${hours}:${minutes} ${suffix}`;
  };

  const handleMaxSalaryChange = (e) => {
    setMaxSalary(e.target.value);
  };

  const handleMinSalaryChange = (e) => {
    const { value } = e;
    setMinSalary(e.target.value);
  };

  const saveAsDraft = async (e) => {
    e.preventDefault();
    setIsDraft(true);
    const displayStartTime = moment(startTime, "HH:mm").format("h:mm A");
    const displayEndTime = moment(endTime, "HH:mm").format("h:mm A");

    try {
      const isValid = await handleValidation();

      if (!isValid) {
        const errorField = document.querySelector(".edit-save-draft-modal"); // Select the input field with the 'invalid' pseudo-class
        if (errorField) {
          errorField.scrollIntoView({ behavior: "smooth" });
        } else {
          window.scrollTo(0, 0); // scroll to top of the page if error field not found
        }
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      const postData = {
        hiringManager: {
          name: hiringName,
          email: hiringEmail,
          contactNumber: hiringContact || "",
        },
        jobTitle,
        jobType,
        workArrangement,
        officeAddress,
        officeHours: `${displayStartTime} - ${displayEndTime}`,
        city,
        minSalary,
        maxSalary,
        jobOverview,
        jobDescription,
        qualification,
        benefits: selectedBenefits,
        skillSet: selectedSkills,
        dialect,
        postingDate: {
          startDate,
          endDate,
        },
        jobPostStatus: "draft",
        createdAt: createdAtState,
      };

      const { data } = await axios.patch(
        `${Config.nxtApi}/api/client/jobs/${jobIdState}`,
        postData,
        { headers }
      );

      if (data.code === "0") {
        toggleModal5();
      }
    } catch (error) {
      if (error.response.status === 402) {
        setModalType("exceedJobTrial30");
        setShowModal(true);
      } else {
        toggleModal2();
      }
    } finally {
      setIsDraft(false);
    }
  };

  const publishJob = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    const displayStartTime = moment(startTime, "HH:mm").format("h:mm A");
    const displayEndTime = moment(endTime, "HH:mm").format("h:mm A");

    const isValid = await handleValidation();

    if (!isValid) {
      const errorField = document.querySelector(".edit-save-draft-modal");
      if (errorField) {
        errorField.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
      setIsLoading(false);
      return;
    }
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };

      const postData = {
        hiringManager: {
          name: hiringName,
          email: hiringEmail,
          contactNumber: hiringContact || "",
        },
        jobTitle,
        jobType,
        workArrangement,
        officeAddress,
        officeHours: `${displayStartTime} - ${displayEndTime}`,
        city,
        minSalary,
        maxSalary,
        jobOverview,
        jobDescription,
        qualification,
        benefits: selectedBenefits,
        skillSet: selectedSkills,
        dialect,
        postingDate: {
          startDate,
          endDate,
        },
        jobPostStatus: "open",
        createdAt: createdAtState,
      };

      const { data } = await axios.patch(
        `${Config.nxtApi}/api/client/jobs/${jobIdState}`,
        postData,
        { headers }
      );
      if (data.code === "0") {
        toggleModal();
      }
    } catch (error) {
      if (error.response.status === 402) {
        setModalType("exceedJobTrial30");
        setShowModal(true);
      } else {
        toggleModal2();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const allowedCharacters = /^[a-zA-Z0-9-_./&\s]*$/;
      if (!allowedCharacters.test(inputValue)) {
        inputValue = inputValue.replace(/[^a-zA-Z0-9-_./&\s]/g, "");
      }
      return inputValue.substring(0, 100);
    }
    return inputValue;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={close}
        className="edit-save-draft-modal"
        size="lg"
      >
        <ModalHeader toggle={close} className="edit-save-title">
          Edit Save as Draft Job Posting
        </ModalHeader>
        <ModalBody className="manage-job-modal-body">
          <div className="hiring-manager-info-container">
            <h6>HIRING MANAGER INFORMATION</h6>
            <hr className="line-seperator" />
            <div className="hiring-manager-info-fields">
              <FormGroup className="fullname-group">
                <Label>
                  Name
                  <span className="required-field">*</span>
                </Label>
                <Input
                  type="text"
                  name="hiringName"
                  id="hiringName"
                  value={hiringName}
                  maxLength={32}
                  invalid={!!errors.hiringName}
                  onChange={(e) => setHiringName(e.target.value)}
                  onFocus={() => setErrors({ ...errors, hiringName: "" })}
                />
                <span className="label-optional">e.g Juan Dela Cruz</span>
                {errors.hiringName && (
                  <FormFeedback className="error-msg" color="danger">
                    {errors.hiringName}
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup className="email-group">
                <Label>
                  Email
                  <span className="required-field">*</span>
                </Label>
                <Input
                  type="email"
                  name="hiringEmail"
                  id="hiringEmail"
                  value={hiringEmail}
                  maxLength={32}
                  invalid={!!errors.hiringEmail || !!hiringEmailExistsError}
                  onChange={(e) => {
                    setHiringEmail(e.target.value);
                    setHiringEmailExistsError("");
                  }}
                  onFocus={() => setErrors({ ...errors, hiringEmail: "" })}
                />
                <span className="label-optional">e.g jdelacruz@gmail.com</span>
                {errors.hiringEmail || hiringEmailExistsError ? (
                  <FormFeedback className="error-msg" color="danger">
                    {errors.hiringEmail || hiringEmailExistsError}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup className="contact-number-group">
                <Label>
                  Contact Number
                  <span className="required-field">*</span>
                </Label>
                <PhoneInput
                  id="hiringContact"
                  name="hiringContact"
                  label=""
                  international
                  focusInputOnCountrySelection={false}
                  defaultCountry="PH"
                  maxLength={16}
                  value={hiringContact}
                  onChange={(value, country) => setHiringContact(value)}
                  {...(errors.hiringContact && { invalid: "true" })}
                  onFocus={() => setErrors({ ...errors, hiringContact: "" })}
                  onKeyPress={(event) => {
                    if (event.key === "0") {
                      setHiringContact((prevNumber) => {
                        if (prevNumber && prevNumber.length >= 4) {
                          if (prevNumber[3] === "0") {
                            return "+639" + prevNumber.slice(4);
                          } else {
                            if (prevNumber.length <= 12) {
                              return prevNumber + "0";
                            } else {
                              return prevNumber;
                            }
                          }
                        } else {
                          return "+639";
                        }
                      });
                      event.preventDefault();
                    }
                  }}
                />

                <span className="label-optional">e.g +63912345678</span>
                <div className="error-msg">{errors.hiringContact}</div>
              </FormGroup>
            </div>
          </div>

          {/* Job Info Container */}
          <div className="job-information-container">
            <h6>JOB INFORMATION</h6>
            <hr className="line-seperator" />

            {/* Job Information Fields */}
            <div className="job-information-fields">
              {/* Job title & job type container */}
              <div className="job-title-type-container">
                {/* Job Title Field */}
                <FormGroup className="job-title-group">
                  <Label>Job Title</Label>
                  <span className="required-field">*</span>
                  <Input
                    type="text"
                    name="jobTitle"
                    id="jobTitle"
                    value={jobTitle}
                    maxLength={32}
                    invalid={!!errors.jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                    onFocus={() => setErrors({ ...errors, jobTitle: "" })}
                  />
                  {errors.jobTitle && (
                    <FormFeedback className="error-msg" color="danger">
                      {errors.jobTitle}
                    </FormFeedback>
                  )}
                </FormGroup>
                {/* Job Type Field */}
                <FormGroup className="job-type-group">
                  <Label>Job Type</Label>
                  <span className="required-field">*</span>
                  <Input
                    type="text"
                    name="jobType"
                    id="jobType"
                    value={jobType}
                    maxLength={32}
                    invalid={!!errors.jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    onFocus={() => setErrors({ ...errors, jobType: "" })}
                  />
                  {errors.jobType && (
                    <FormFeedback className="error-msg" color="danger">
                      {errors.jobType}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>

              {/* Work Arrangement & Office Hours Container*/}
              <div className="work-arrangement-office-hours-container">
                {/* Work Arrangement Field */}
                <FormGroup className="work-arrangement-group">
                  <Label>Work Arrangement</Label>
                  <span className="required-field">*</span>
                  <Input
                    type="select"
                    name="workArrangement"
                    id="workArrangement"
                    value={workArrangement}
                    invalid={!!errors.workArrangement}
                    onChange={(e) => setWorkArrangement(e.target.value)}
                  >
                    {GlobalText.workArrangements.map((arrangement) => (
                      <option
                        key={arrangement.value}
                        value={arrangement.value}
                        disabled={arrangement.disabled}
                      >
                        {arrangement.label}
                      </option>
                    ))}
                  </Input>
                  {errors.workArrangement && (
                    <FormFeedback className="error-msg" color="danger">
                      {errors.workArrangement}
                    </FormFeedback>
                  )}
                </FormGroup>
                {/* Office Hours Field */}
                <FormGroup className="office-hours-group">
                  <Label>Office Hours</Label>
                  <span className="required-field">*</span>
                  <div className="start-end-time-container">
                    <div className="start-time">
                      <Input
                        id="1sttime"
                        name="1sttime"
                        placeholder="time placeholder"
                        type="time"
                        value={startTime}
                        invalid={!!errors.startTime}
                        onChange={handleStartTimeChange}
                        onFocus={() =>
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            startTime: "",
                          }))
                        }
                      />
                      <span className="label-time">Start Time</span>
                      {errors.startTime && (
                        <FormFeedback className="error-msg" color="danger">
                          {errors.startTime}
                        </FormFeedback>
                      )}
                    </div>
                    <div className="end-time">
                      <Input
                        id="2ndtime"
                        name="2ndtime"
                        placeholder="time placeholder"
                        type="time"
                        value={endTime}
                        invalid={!!errors.endTime}
                        onChange={handleEndTimeChange}
                        onFocus={() =>
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            endTime: "",
                          }))
                        }
                      />
                      <span className="label-time">End Time</span>
                      {errors.endTime && (
                        <FormFeedback className="error-msg" color="danger">
                          {errors.endTime}
                        </FormFeedback>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>

              {/* Address & City Container */}
              <div className="address-city-container">
                {/* Address Field */}
                <FormGroup className="office-address-group">
                  <Label>Office Address</Label>
                  <span className="required-field">*</span>
                  <Input
                    type="text"
                    name="officeAddress"
                    id="officeAddress"
                    value={officeAddress}
                    maxLength={255}
                    invalid={!!errors.officeAddress}
                    onChange={(e) => setOfficeAddress(e.target.value)}
                    onFocus={() => setErrors({ ...errors, officeAddress: "" })}
                  />
                  <span className="label-optional">
                    (Unit Number / Floor / Street Name / Barangay)
                  </span>
                  {errors.officeAddress && (
                    <FormFeedback className="error-msg" color="danger">
                      {errors.officeAddress}
                    </FormFeedback>
                  )}
                </FormGroup>
                {/* City Field */}
                <FormGroup className="city-group">
                  <Label>City</Label>
                  <span className="required-field">*</span>
                  <Input
                    type="select"
                    name="city"
                    id="city"
                    value={city}
                    maxLength={32}
                    invalid={!!errors.city}
                    onChange={(e) => setCity(e.target.value)}
                    onFocus={() => setErrors({ ...errors, city: "" })}
                  >
                    <option value="" disabled>
                      Select City
                    </option>
                    {cities.map((options, key) => (
                      <option value={options} key={key}>
                        {options}
                      </option>
                    ))}
                  </Input>
                  {errors.city && (
                    <FormFeedback className="error-msg" color="danger">
                      {errors.city}
                    </FormFeedback>
                  )}
                </FormGroup>
              </div>
              {/* Salary Group */}
              <FormGroup className="salary-group">
                <Label>Salary</Label>
                <span className="required-field">*</span>
                <div className="max-min-salary">
                  <div>
                    <div className="salary-code-input-container">
                      <span className="php-code"> ₱ </span>
                      <NumericFormat
                        value={minSalary}
                        onValueChange={(values) => {
                          const { value } = values;
                          setMinSalary(value);
                        }}
                        // thousandSeparator={true}
                        // prefix={"₱"}
                        customInput={Input}
                        name="minSalary"
                        id="minSalary"
                        maxLength={7}
                        invalid={!!errors.minSalary}
                        onChange={handleMinSalaryChange}
                        onFocus={() => setErrors({ ...errors, minSalary: "" })}
                      />
                    </div>
                    <span className="label-optional">Minimum Range</span>
                    {/* <span className="label-optional"></span> */}
                    {errors.minSalary && (
                      <div className="error-msg" color="danger">
                        {errors.minSalary}
                      </div>
                    )}
                  </div>
                  <p className="range">-</p>
                  <div>
                    <div className="salary-code-input-container">
                      <span className="php-code"> ₱ </span>
                      <NumericFormat
                        value={maxSalary}
                        onValueChange={(values) => {
                          const { value } = values;
                          setMaxSalary(value);
                        }}
                        // thousandSeparator={true}
                        // prefix={"₱"}
                        customInput={Input}
                        name="maxSalary"
                        id="maxSalary"
                        maxLength={7}
                        invalid={!!errors.maxSalary}
                        onChange={handleMaxSalaryChange}
                        onFocus={() => setErrors({ ...errors, maxSalary: "" })}
                      />
                    </div>
                    <span className="label-optional">Maximum Range</span>
                    {errors.maxSalary && (
                      <div className="error-msg">{errors.maxSalary}</div>
                    )}
                  </div>
                </div>
              </FormGroup>

              {/* Job Overview Group*/}
              <FormGroup className="job-overview-group">
                <Label>Job Overview</Label>
                <span className="required-field">*</span>
                <ReactQuill
                  value={jobOverview}
                  onChange={handleJobOverviewChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  placeholder="Enter job overview..."
                />
                {/* <p className="charactersLength">
                  Characters: {lengthJobOverView}
                </p> */}
                {errors.jobOverview && (
                  <div className="error-msg">{errors.jobOverview}</div>
                )}
              </FormGroup>

              {/* Job Description Group */}
              <FormGroup className="job-description">
                <Label>Job Description</Label>
                <span className="required-field">*</span>
                <ReactQuill
                  value={jobDescription}
                  onChange={handleJobDescriptionChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  placeholder="Enter job description..."
                />
                {/* <p className="charactersLength">
                  Characters: {lengthDescription}
                </p> */}
                {errors.jobDescription && (
                  <div className="error-msg">{errors.jobDescription}</div>
                )}
              </FormGroup>

              {/* Qualifications Group */}
              <FormGroup className="qualification-group">
                <Label>Qualification</Label>
                <span className="required-field">*</span>
                <ReactQuill
                  value={qualification}
                  onChange={handleJobQualiChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  placeholder="Enter a qualification..."
                />
                {/* <p className="charactersLength">
                  Characters: {lengthQualification}
                </p> */}
                {errors.qualification && (
                  <div className="error-msg">{errors.qualification}</div>
                )}
              </FormGroup>

              {/* Benefits Group */}
              <FormGroup className="benefits-group">
                <Label>Benefits</Label>
                <span className="required-field">*</span>
                <CreatableSelect
                  className={`basic-multi-select ${
                    errors.benefits ? "select-error" : ""
                  }`}
                  isMulti
                  isSearchable
                  name="benefits"
                  id="benefits"
                  options={[]}
                  classNamePrefix="select"
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedBenefits(selectedValues);
                  }}
                  onInputChange={handleInputChange}
                  onFocus={() => setErrors({ ...errors, benefits: "" })}
                  isInvalid={!!errors.benefits}
                  value={selectedBenefits.map((value) => ({
                    label: value,
                    value,
                  }))}
                  onCreateOption={(inputValue) => {
                    if (selectedBenefits.length >= 50) {
                      setErrors({
                        ...errors,
                        benefits:
                          "You have exceed the 50 maximum input for this field.",
                      });
                      return;
                    }

                    const newOption = { label: inputValue, value: inputValue };
                    setSelectedBenefits([...selectedBenefits, newOption.value]);
                  }}
                  placeholder="Type to add benefits"
                />
                {errors.benefits && (
                  <div className="error-msg">{errors.benefits}</div>
                )}
              </FormGroup>
            </div>
          </div>

          {/* Job Requirements Container */}
          <div className="job-requirements-container">
            <h6>JOB REQUIREMENTS</h6>
            <hr className="line-seperator" />

            {/* Skill Set and Dialect Container */}
            <div className="skill-set-dialect-fields">
              {/* Skill Set Group */}
              <FormGroup className="skill-set-group">
                <Label>Skill Set</Label>
                <span className="required-field">*</span>
                <CreatableSelect
                  className={`basic-multi-select ${
                    errors.skillSet ? "select-error" : ""
                  }`}
                  isMulti
                  isSearchable
                  name="skillSet"
                  id="skillSet"
                  options={[]}
                  onInputChange={handleInputChange}
                  classNamePrefix="select"
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedSkills(selectedValues);
                  }}
                  onFocus={() => setErrors({ ...errors, skillSet: "" })}
                  isInvalid={!!errors.skillSet}
                  value={selectedSkills.map((value) => ({
                    label: value,
                    value,
                  }))}
                  onCreateOption={(inputValue) => {
                    if (selectedSkills.length >= 50) {
                      setErrors({
                        ...errors,
                        skillSet:
                          "You have exceed the 50 maximum input for this field.",
                      });
                      return;
                    }

                    const newOption = { label: inputValue, value: inputValue };
                    setSelectedSkills([...selectedSkills, newOption.value]);
                  }}
                  placeholder="Type to add Skillset"
                />
                {errors.skillSet && (
                  <div className="error-msg">{errors.skillSet}</div>
                )}
              </FormGroup>

              {/* Dialect Group */}
              <FormGroup className="dialect-group">
                <Label>Dialect</Label>
                <span className="required-field">*</span>
                <CreatableSelect
                  className={`basic-multi-select ${
                    errors.dialect ? "select-error" : ""
                  }`}
                  isMulti
                  isSearchable
                  name="dialect"
                  id="dialect"
                  options={languageData.listLanguages}
                  classNamePrefix="select"
                  onChange={(selectedOptions) => {
                    const selectedValues = selectedOptions.map(
                      (option) => option.value
                    );
                    setDialect(selectedValues);
                  }}
                  onInputChange={handleInputChange}
                  onFocus={() => setErrors({ ...errors, dialect: "" })}
                  isInvalid={!!errors.dialect}
                  value={dialect.map((value) => ({ label: value, value }))} // Updated value prop
                  onCreateOption={(inputValue) => {
                    if (dialect.length >= 50) {
                      setErrors({
                        ...errors,
                        dialect:
                          "You have exceed the 50 maximum input for this field.",
                      });
                      return;
                    }
                    const newOption = { label: inputValue, value: inputValue };
                    setDialect([...dialect, newOption.value]);
                  }}
                  placeholder="Select or type to add benefits..."
                />
                {errors.dialect && (
                  <div className="error-msg">{errors.dialect}</div>
                )}
              </FormGroup>
            </div>
          </div>

          {/* Posting date Container */}
          <div className="posting-date-container">
            <h6>POSTING DATE</h6>
            <hr className="line-seperator" />

            {/* Start Date and End Date Fields  */}
            <div className="start-end-date-fields">
              {/* Start date Group */}
              <FormGroup className="start-date-group">
                <Label>Start Date</Label>
                <span className="required-field">*</span>
                <Input
                  type="date"
                  name="startDate"
                  id="startDate"
                  value={startDate}
                  min={formattedToday}
                  invalid={!!errors.startDate}
                  onChange={handleStartDateChange}
                  onFocus={() => setErrors({ ...errors, startDate: "" })}
                />
                {errors.startDate && (
                  <FormFeedback className="error-msg" color="danger">
                    {errors.startDate}
                  </FormFeedback>
                )}
              </FormGroup>

              {/* End Date Group */}
              <FormGroup className="end-date-group">
                <Label>End Date</Label>
                <span className="required-field">*</span>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  value={endDate}
                  min={startDate ? startDate : formattedToday}
                  invalid={!!errors.endDate}
                  onChange={handleEndDateChange}
                  onFocus={() => setErrors({ ...errors, endDate: "" })}
                />
                {errors.endDate && (
                  <FormFeedback className="error-msg" color="danger">
                    {errors.endDate}
                  </FormFeedback>
                )}
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="actions-button-container">
          {jobStatus === "open" || jobStatus === "expired" ? (
            <Button className="publish-btn" onClick={publishJob}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <FontAwesomeIcon icon={faUpload} className="icon-publish" />{" "}
                  Publish
                </>
              )}
            </Button>
          ) : jobStatus === "draft" ? (
            <>
              <Button className="save-draft-btn" onClick={saveAsDraft}>
                {isDraft ? (
                  <Loader />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faFileLines} className="btn-icon" />
                    Save as Draft
                  </>
                )}
              </Button>
              <Button className="publish-btn" onClick={publishJob}>
                {isLoading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faUpload} className="btn-icon" />
                    Publish
                  </>
                )}
              </Button>
            </>
          ) : jobStatus === "closed" ? (
            <></>
          ) : null}
        </ModalFooter>
      </Modal>
      <SuccessModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        iconImage={require("../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Your Job Post has been updated."
        confirmButtonText="Ok"
        onConfirm={handleManageClick}
      />
      {/* <WarningModal
        modalOpen={modalOpen2}
        toggleModal={toggleModal2}
        iconImage={require("../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel="Please review the entered information and try again."
        confirmButtonText="Confirm"
        onConfirm={toggleModal2}
      /> */}
      <QuestionModal
        modalOpen={modalOpen3}
        toggleModal={toggleModal3}
        iconImage={require("../../assets/images/QuestionIcon.png")}
        modalLabel="Oops"
        modalSublabel="Oops you did'nt change any thing in the field."
        confirmButtonText="Confirm"
        onConfirm={toggleModal3}
      />
      <WarningModal
        modalOpen={modalOpen4}
        toggleModal={toggleModal4}
        iconImage={require("../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel="Internal Server Error"
        confirmButtonText="Confirm"
        onConfirm={toggleModal4}
      />
      <QuestionModal
        modalOpen={modalOpen5}
        toggleModal={toggleModal5}
        iconImage={require("../../assets/images/QuestionIcon.png")}
        modalLabel="Success"
        modalSublabel="Your Job Post has been saved as Draft"
        confirmButtonText="Confirm"
        onConfirm={handleManageClick}
      />
      <CustomModal
        hasOption={true}
        onHide={() => setShowModal(false)}
        show={show}
        modalMessage={message}
        modalType={modalTypeName}
        buttonName={modalType === "exceedJobTrial30" ? "OK" : ""}
        onClick={() => {
          modalType === "exceedJobTrial30"
            ? setShowModal(false)
            : setShowModal(false);
        }}
      />
    </div>
  );
};

export default FormManageJobModal;
