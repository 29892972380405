import React, { useState } from "react";
import "../../../../assets/scss/Client/Layout/ApplicantList/ViewResume/DocumentsCert.scss";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Alert,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { List } from "react-content-loader";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// ... (import statements)

const DocumentsCert = ({ data }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const certificateList = data?.data || [];

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pdfPath, setPdfPath] = useState("");

  const endpointKey = process.env.REACT_APP_KEYCERTIFICATE_CLIENT_API;

  const toggleModal = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    setModalOpen(!modalOpen);
    setPdfPath(downloadLink);
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const downloadCV = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    window.open(downloadLink);
  };

  const viewImage = (documentPath) => {
    const downloadLink = `${endpointKey}/?fileName=${documentPath}`;
    window.open(downloadLink);
  };

  return (
    <div className="documents-cert-container">
      <div className="header-container">
        <h6>DOCUMENTS (CERTIFICATES, ETC.)</h6>
      </div>
      <hr className="line-separator" />
      <div className="cert-list">
        {Array.isArray(certificateList) && certificateList.length > 0 ? (
          certificateList.map((cvData, index) => (
            <div key={cvData.id}>
              <>
                <Label>{cvData.documentName}</Label>
                <Label>{cvData.documentPath}</Label>
                <p>Validity Date: {cvData.validityDate}</p>
                {cvData.documentPath.endsWith(".docx") ? (
                  <Button onClick={() => downloadCV(cvData.documentPath)}>
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="download-attachment-btn"
                    />
                    {"  "}
                    Click to Download
                  </Button>
                ) : (
                  <>
                    {cvData.documentPath.endsWith(".png") ||
                    cvData.documentPath.endsWith(".jpg") ? (
                      <Button onClick={() => viewImage(cvData.documentPath)}>
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="view-attachment-btn"
                        />{" "}
                        {"  "}
                        Click to view image
                      </Button>
                    ) : (
                      <Button onClick={() => toggleModal(cvData.documentPath)}>
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="view-attachment-btn"
                        />{" "}
                        View Attachment
                      </Button>
                    )}
                  </>
                )}
              </>
            </div>
          ))
        ) : (
          <Alert color="secondary">No available certificates</Alert>
        )}
      </div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader
          toggle={toggleModal}
          style={{
            fontFamily: "Lato",
          }}
        ></ModalHeader>
        <ModalBody style={{ background: "#E0E0E0" }}>
          <div
            className="document-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={pdfPath}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <div className="page-wrapper">
                <Page
                  pageNumber={currentPage}
                  width={window.innerWidth * 0.41}
                />
              </div>
            </Document>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="page-navigation">
            <Button
              onClick={previousPage}
              disabled={currentPage <= 1}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginRight: 10,
                fontFamily: "Lato",
              }}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              onClick={nextPage}
              disabled={currentPage >= numPages}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginLeft: 10,
                fontFamily: "Lato",
              }}
            >
              Next
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentsCert;
