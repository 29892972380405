import React from "react";
import moment from "moment";
import "../../../../assets/scss/Client/Layout/ApplicantList/ViewResume/WorkHistory.scss";
import { Alert, Label } from "reactstrap";

const WorkHistory = (data) => {
  const workHistoryList = data?.data || [];

  return (
    <div className="work-history-container">
      <h6>WORK HISTORY</h6>
      <hr className="line-separator" />

      {workHistoryList.length > 0 ? (
        workHistoryList.map((val, index) => (
          <div className="work-history-list" key={index}>
            <Label>
              {val.companyName} - {val.jobRole}
            </Label>
            <p>Job Level: {val.jobLevel}</p>

            <p>
              Time Period:{" "}
              {moment(val.experienceDate.startDate).format("MM/DD/YYYY")} to{" "}
              {val.isPresent === 0 ? (
                "Present"
              ) : (
                <>{moment(val.experienceDate.endDate).format("MM/DD/YYYY")}</>
              )}
            </p>
          </div>
        ))
      ) : (
        <Alert color="secondary">No available Work History</Alert>
      )}
    </div>
  );
};

export default WorkHistory;
