import React from "react";
import { Helmet } from "react-helmet";

const ClientAnalytics = () => {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-E71ZZFYT1Q"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-E71ZZFYT1Q');
          `}
        </script>
      </Helmet>
    </>
  );
};

export default ClientAnalytics;
