import React from "react";
import "../assets/scss/NotFound.scss";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UnderMaintenance = () => {
  return (
    <div className="under__maintenance text-center p-5 position-relative">
      <div className="position-absolute top-50 start-50 translate-middle">
        <img
          src={require("../assets/images/Logo/Brand/nxtDefault.png")}
          className="img-fluid"
        />
        <h2>We'll be back</h2>
        <button className="btn py-2 px-4">
          <FontAwesomeIcon icon={faHouseChimney} className="me-2" />
          Back to Home Page
        </button>
      </div>
    </div>
  );
};

export default UnderMaintenance;
