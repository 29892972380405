import React, { useEffect, useState } from "react";
import "../../../assets/scss/Client/Layout/ManageUser/ManageUser.scss";
import { Table } from "react-bootstrap";
import { faPenSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserManagementModal from "../../../pages/Admin/ATS/UserManagement/UserManagementTable/UserManagementModal";
import { localDevBase } from "../../../services/API/localDevBase";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomModal from "../../UI/Modal";
const ManageUserTable = () => {
  const [useList, setUserList] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const devBase = localDevBase();
  const token = useAuthToken();
  const [userRole, setUserRole] = useState("");
  const [modalMode, setModalMode] = useState("");
  const [editData, setEditData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const handleAddUserModal = () => {
    setEditData(null);
    setShow(true, { state: { addUser: "adduser" } });
  };

  const getUser = async () => {
    try {
      const response = await axios.get(`${devBase}/api/ats/user/`, {
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
      });

      setUserList(response.data.result.roles);
      setUserRole(response.data.result.roleType);
    } catch (error) {
      console.log(error);
    }
  };

  const delUser = async () => {
    try {
      const response = await axios.delete(`${devBase}/api/ats/user/`, {
        params: {
          roleId: roleId,
        },
        headers: {
          // "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
        // withCredentials: true,
      });

      if (response.status === 200) {
        setModalMode("successDelete");

        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.clientId !== roleId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const companyName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.email
  );

  const [load, setLoad] = useState(false);
  const onAddUserSuccess = () => {
    setModalMode("successAdd");
    setLoad(!load);
  };

  const handleEditJob = (email, clientId, roleType) => {
    setEditData({ email, clientId, roleType });
    setShow(true);
  };

  const onEditUserSuccess = () => {
    setModalMode("successEdit");
    setShowModal(true);
    setAddConfirmation(false);
    getUser();
  };

  const handleShowAddUserModal = () => {
    setModalMode("addUserOption");
    setShowModal(true);
  };

  const handleUserDeleteModal = (value) => {
    setRoleId(value);
    setModalMode("deleteUserOption");
    setShowModal(true);
  };

  const [addConfirmation, setAddConfirmation] = useState(false);
  const confirmAddUser = () => {
    setAddConfirmation(!addConfirmation);
  };

  const handleErrorAddModal = (errorType) => {
    if (errorType === "alreadyExist") {
      setModalMode("alreadyExist");
      // setShowModal(true);
    } else if (errorType === "") {
    }
  };

  useEffect(() => {
    getUser();
  }, [load]);

  return (
    <div className="manage-user-container">
      <h6>Members</h6>
      <hr />
      <button
        className="btn manage__btn-adduser d-flex ms-auto mb-2"
        onClick={handleAddUserModal}
      >
        Add User
      </button>
      <Table>
        <thead>
          <tr className="table-active">
            <th>Users</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {useList.map((val, key) => {
            return (
              <>
                <tr className="subs__history-tbody">
                  <td>{val.email}</td>

                  <td>
                    {(userRole === "admin" && val.roleType === "recruiter") ||
                    (userRole === "owner" &&
                      val.roleType !== "owner" &&
                      val.email !== companyName) ? (
                      <>
                        <div className="d-flex">
                          <div
                            className="manage__btn-edituser me-2"
                            // onClick={() => setShow(true)}
                            onClick={() =>
                              handleEditJob(
                                val.email,
                                val.clientId,
                                val.roleType
                              )
                            }
                          >
                            {" "}
                            <FontAwesomeIcon icon={faPenSquare} />
                          </div>
                          <div
                            className="manage__btn-deleteuser"
                            onClick={() => handleUserDeleteModal(val.clientId)}
                            // onClick={() => delUser(val.clientId)}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faTrashCan} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
        <UserManagementModal
          show={show}
          close={() => setShow(false)}
          editData={editData}
          onEditUserSuccess={onEditUserSuccess}
          onAddUserSuccess={onAddUserSuccess}
          showAddModal={handleShowAddUserModal}
          addConfirmation={addConfirmation}
          onErrorAddModal={handleErrorAddModal}
        />
      </Table>

      <CustomModal
        hasOption={true}
        show={showModal}
        buttonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist"
            ? "OK"
            : "YES"
        }
        onHide={() => setShowModal(false)}
        secondButtonName={
          modalMode === "successDelete" ||
          modalMode === "successAdd" ||
          modalMode === "alreadyExist" ||
          modalMode === "successEdit"
            ? null
            : "NO"
        }
        modalMessage={
          modalMode === "addUserOption"
            ? "Confirm adding this user?"
            : modalMode === "successAdd"
            ? "User successfully invited!"
            : modalMode === "deleteUserOption"
            ? "Are you sure you want to remove this user?"
            : modalMode === "successDelete"
            ? "User Succesfully Removed"
            : modalMode === "alreadyExist"
            ? "Email already registered."
            : modalMode === "successEdit"
            ? "User role change successful"
            : ""
        }
        modalType={
          modalMode === "successAdd" ||
          modalMode === "successDelete" ||
          modalMode === "successEdit"
            ? "Success"
            : modalMode === "addUserOption" || modalMode === "deleteUserOption"
            ? "Warning"
            : "Oops!"
        }
        onClick={() => {
          modalMode === "addUserOption"
            ? confirmAddUser()
            : modalMode === "deleteUserOption"
            ? delUser()
            : modalMode === "successAdd" ||
              modalMode === "successDelete" ||
              modalMode === "alreadyExist" ||
              modalMode === "successEdit"
            ? setShowModal(false)
            : setShowModal(false);
        }}
      />
    </div>
  );
};

export default ManageUserTable;
