import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import "../../assets/scss/layout/Navbar/_adminNav.scss";
import AdminSidebar from "../Sidebar/Admin/AdminSidebar";
import Image from "../../components/UI/Image";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useRole } from "../../hook/role/useRole";
import { updatefeatures } from "../../services/Actions/featuresAction";
import { getCurrentUser } from "../../services/Actions/currentUser";
import { useNavigate } from "react-router-dom";
import { Message } from "../../Utilities/Reusable/Message/Message";
import CustomModal from "../../components/UI/Modal";

const AdminNav = () => {
  const { role, isLoading, errorNetwork, getRoleKey } = useRole();
  const [modalInfo, setModalInfo] = useState({ show: false });
  const dispatch = useDispatch();
  const clientId = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.clientId
  );
  const companyName = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.companyName
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(clientId);
  const [currentUser, setCurrentUser] = useState(companyName);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const reduxFirstName = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.firstName
  );

  const toggleOffcanvas = () => {
    setShowOffcanvas((prevState) => !prevState);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedDateTime = currentDateTime.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  useEffect(() => {
    if (errorNetwork === "Network Error") {
      setModalInfo({
        show: true,
        type: "Error",
        message: Message.client.switchUser[404],
        func: "draftJob",
        ErrButton: "Close",
      });
    }
  }, [errorNetwork]);

  const handleChooseCompany = (ownerId) => {
    const selectedCompany = role?.find(
      (company) => company.ownerId === ownerId
    );

    if (selectedCompany) {
      setSelectedCompanyId(ownerId);
      setCurrentUser(selectedCompany);
      dispatch(getCurrentUser(selectedCompany));

      dispatch(
        updatefeatures({
          isSubscribed: selectedCompany.isSubscribed,
          withATS: selectedCompany.withATS,
          withAI: selectedCompany.withAI,
          subscription: {
            daysOfValidity: selectedCompany.daysOfValidity,
            endDate: selectedCompany.endDate,
            jobLimit: selectedCompany.jobLimit,
          },
        })
      );

      if (selectedCompany.withATS) {
        navigate("/admin/ats/dashboard");
        window.location.reload();
      }
    }
  };

  const handleDropdownToggle = (isOpen) => {
    if (isOpen) {
      getRoleKey();
    }
  };

  const { withATS } = useSelector((state) => state.reducer.feature?.isfeatures);
  const navigate = useNavigate();
  useEffect(() => {
    if (!withATS) {
      navigate("/manage-user");
    }
  }, [withATS]);

  return (
    <div style={{ background: "#6f64f8" }}>
      <Navbar
        expand="md"
        className="adminNav"
        bg="none"
        style={{ background: "#6f64f8" }}
      >
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-md`}
          onClick={toggleOffcanvas}
        />
        <Navbar.Brand className="date">
          Today is: <span>{formattedDateTime}</span>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end welcomeUser">
          <div className="ml-auto">Welcome Back!</div>
          <DropdownButton
            className="btn-company--admin"
            title={currentUser?.companyName || companyName}
            disabled={isLoading}
            onToggle={handleDropdownToggle}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              role?.map((company) => (
                <Dropdown.Item
                  as="button"
                  className="items"
                  key={company.ownerId}
                  style={{
                    color:
                      currentUser?.companyName === company.companyName ||
                      companyName === company.companyName
                        ? "#2C97EB"
                        : "#000000",
                  }}
                  onClick={() => handleChooseCompany(company.ownerId)}
                >
                  {company.companyName}
                </Dropdown.Item>
              ))
            )}
          </DropdownButton>
        </Navbar.Collapse>
      </Navbar>
      <Offcanvas
        show={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        id={`offcanvasNavbar-expand-md`}
        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
            <Image
              height={54}
              width={150}
              src={require("../../assets/images/Logo/Brand/nxtDefault.png")}
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AdminSidebar />
        </Offcanvas.Body>
      </Offcanvas>
      <CustomModal
        closeButtonErrNetwork={modalInfo.ErrButton}
        secondButtonName={modalInfo.secondBName}
        show={modalInfo.show}
        modalType={modalInfo.type}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        hasOption={true}
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
      />
    </div>
  );
};

export default AdminNav;
