import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const GigsterNavBar = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="nav__gigster">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={require(`../../../assets/images/Gigster/Logo/gigster-final-2.png`)}
              className="img-fluid"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="nav__link">
              <Nav.Link href="#features">Home</Nav.Link>
              <Nav.Link href="#pricing">Talents</Nav.Link>
              <Nav.Link href="#pricing">Help</Nav.Link>
              <Nav.Link href="#pricing">Contact Us</Nav.Link>
              <Nav.Link href="#pricing">
                <img
                  src={require(`../../../assets/images/Gigster/Logo/logo-white-1.png`)}
                  className="img-fluid"
                />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default GigsterNavBar;
