import React from "react";
import "../../assets/scss/Client/Layout/ApplicantList/SidebarViewApplicant.scss";
import { Parser } from "html-to-react";

const SidebarViewApplicant = () => {
  const getJobPayload = JSON.parse(localStorage.getItem("jobList"));

  return (
    <div className="sidebar-va-container">
      <div className="sva-header">
        <h5>{getJobPayload.jobTitle}</h5>
        <h6>{getJobPayload.companyName}</h6>
      </div>

      <p>{Parser().parse(getJobPayload.jobOverview)}</p>
    </div>
  );
};

export default SidebarViewApplicant;
