import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import InvitationForm from "../../LayoutClient/ViewJobDetails/InvitationForm";

const Invitation = ({
  url,
  message,
  messageId,
  description,
  isList,
  disabled,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(message);
  return (
    <>
      <div className={`envelopeIcon`}>
        <FontAwesomeIcon
          onClick={disabled ? null : handleShow}
          icon={faEnvelopeOpenText}
          size="lg"
          style={{
            width: "15px",
            fontSize: "15px",
            padding: isList ? "8px" : "7px",
            borderRadius: isList ? "5px" : "100%",
            cursor: disabled ? "cursor" : "pointer",
            background: disabled ? "#ababab" : "grey",
            color: "white",
            // width: "15px",
            // margin: "none",
            // fontSize: "15px",
            // padding: "7px",
            // borderRadius: "100%",
            // cursor: "pointer",
          }}
        />
      </div>
      <InvitationForm
        messageId={messageId}
        message={message}
        handleClose={handleClose}
        show={show}
        // onClickFunc={console.log("first")}
      />
    </>
  );
};

export default Invitation;
