import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../../../../Config/config";

const MatchScore = ({ data }) => {
  const [appData, setAppData] = useState();
  const jobId = data?.jobId;
  const appId = data?.talentId;

  useEffect(() => {
    const loadScreeningScore = async () => {
      const payload = {
        createdAt: "2024-05-30T10:31:07.670Z",
        content: {
          applicantId: appId,
          jobId: jobId,
          platform: "NXT",
        },
      };
      try {
        const response = await axios.post(
          `${Config.ai}/api/Matching/score`,
          payload
        );
        console.log("im the response", response);
        setAppData(response);
      } catch (error) {
        console.log(error);
      }
    };

    loadScreeningScore();
  }, []);

  console.log(appData?.data?.content?.workHistoryScore);
  return (
    <>
      <div className="row">
        <div className="col-md-5 d-flex">
          <CircularProgressbar
            value={data.overallScore || appData?.data?.content?.overallScore}
            text={`${
              data.overallScore || appData?.data?.content?.overallScore
            }%`}
            className="screen-score__circle"
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: "butt",
              textSize: "16px",
              pathTransitionDuration: 0.5,
              pathColor: `rgba(62, 152, 199, 1)`,
              textColor: "#30adf3",
              trailColor: "#d6d6d6",
              backgroundColor: "#3e98c7",
            })}
          />
          <div className="px-2">
            <h5>AI Match Score</h5>
            <p>by Skills, Working History, Education</p>
          </div>
        </div>
        <div className="col-md-5">
          <div className="w-100 d-flex mb-2">
            <small className="w-25 me-2 text-nowrap">Work History</small>
            <div className="w-50">
              <div className="progress h-100 me-2">
                <div
                  class="progress-bar"
                  style={{
                    width: `${
                      data.workHistoryScore ||
                      appData?.data?.content?.workHistoryScore
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-25">
              {data.workHistoryScore ||
                appData?.data?.content?.workHistoryScore}
              %
            </div>
          </div>
          <div className="w-100 d-flex mb-2">
            <small className="w-25 me-2 text-nowrap">Skills</small>
            <div className="w-50">
              <div
                className="progress h-100 me-2"
                // role="progressbar"
                // aria-label="Example with label"
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <div
                  class="progress-bar bg-danger"
                  style={{
                    width: `${
                      data.skillsScore || appData?.data?.content?.skillsScore
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-25">
              {data.skillsScore || appData?.data?.content?.skillsScore}%
            </div>
          </div>
          <div className="w-100 d-flex">
            <small className="w-25 me-2 text-nowrap">Education</small>
            <div className="w-50">
              <div
                className="progress h-100 me-2"
                // role="progressbar"
                // aria-label="Example with label"
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              >
                <div
                  class="progress-bar bg-success"
                  style={{
                    width: `${
                      data.educationScore ||
                      appData?.data?.content?.educationScore
                    }%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-25">
              {data.educationScore || appData?.data?.content?.educationScore}%
            </div>
          </div>
        </div>
        {/* <div className="col-md-2">
          <Button className="match-score__btn d-flex ms-auto">
            Regenerate
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default MatchScore;
