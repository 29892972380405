// import React, { useEffect, useState } from "react";
// import loginBg from "../../../../assets/images/Background/Global/loginRegBg.jpg";
// import ContainerIndex from "./Container";
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router";
// import CustomContainer from "../../../../layouts/Container/CustomContainer";
// import LoginAnalytics from "../../../../Utilities/Analytics/LoginAnalytics";

// const Login = () => {
//   const isAuthenticated = useSelector(
//     (state) => state.reducer.user.isAuthenticated
//   );
//   const userType = useSelector(
//     (state) => state.reducer.user.currentUser?.userType
//   );

//   const allowedUserTypes = ["client", "applicant", "admin"];

//   const [redirectToProfile, setRedirectToProfile] = useState(false);

//   useEffect(() => {
//     if (isAuthenticated && allowedUserTypes.includes(userType)) {
//       setRedirectToProfile(true);
//     }
//   }, [isAuthenticated, userType]);

//   const getPubId = localStorage.getItem("pub_id") || "";

//   if (redirectToProfile) {
//     if (userType === "applicant" && getPubId) {
//       console.log("Navigating to job-list-details");
//       return <Navigate to={`/talent/job-list-details/${getPubId}`} />;
//     } else if (userType === "client") {
//       console.log("Navigating to company-profile");
//       return <Navigate to="/client/company-profile" />;
//     } else if (userType === "applicant") {
//       console.log("Navigating to talent profile");
//       return <Navigate to="/talent/profile" />;
//     } else if (userType === "admin") {
//       console.log("Navigating to admin dashboard");
//       return <Navigate to="/admin/dashboard" />;
//     }
//   }

//   return (
//     <>
//       <LoginAnalytics />
//       <CustomContainer
//         backgroundImage={loginBg}
//         className="customContainerLogin"
//       >
//         <ContainerIndex />
//       </CustomContainer>
//     </>
//   );
// };

// export default Login;

import React, { useEffect, useState } from "react";
import loginBg from "../../../../assets/images/Background/Global/loginRegBg.jpg";
import ContainerIndex from "./Container";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import CustomContainer from "../../../../layouts/Container/CustomContainer";
import LoginAnalytics from "../../../../Utilities/Analytics/LoginAnalytics";
import { fetchSysparValue } from "../../../../Utilities/Fetch/FetchSysparValue";
import AlertWithHide from "../../../../components/UI/Alert/AlertWithHide";
import { SubscriptionFunction } from "../../../../Utilities/Reusable/Function/Subscription/Subscription";
import subsciption from "../../../../Data/subscription/subscription.json";
import { useSubscription } from "../../../../hook/useSubscription";
const LoginClient = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state) => state.reducer.user.isAuthenticated
  );
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State", sendState);
  const { getURLSubsPlanPrice, feature_pricing } = SubscriptionFunction();
  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  const allowedUserTypes = ["client", "applicant", "admin"];
  const [redirectToProfile, setRedirectToProfile] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
          console.log("New Site Key:", sysparVal);

          if (sysparVal === "Y") {
            navigate("/under-maintenance");
          } else if (isAuthenticated && allowedUserTypes.includes(userType)) {
            setRedirectToProfile(true);
          }
        });
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, [isAuthenticated, userType, navigate]);

  const clientId = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.clientId
  );

  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.isSubscribed
  );
  const { checkSubscription, checkSubsData, checkSubsError, isLoading } =
    useSubscription();

  // useEffect(() => {
  //   console.log("IM RUNNING");
  //   checkSubscription(clientId);
  // }, [clientId]);

  console.log(checkSubsData);
  const getPubId = localStorage.getItem("pub_id") || "";

  if (redirectToProfile) {
    if (userType === "applicant" && getPubId) {
      console.log("Navigating to job-list-details");
      return <Navigate to={`/talent/job-list-details/${getPubId}`} />;
    } else if (userType === "client") {
      let selectedPlan = null;
      console.log(feature_pricing);
      if (feature_pricing) {
        navigate("/client/company-profile/?feature=pricing");
      } else {
        navigate("/client/company-profile");
      }
    } else if (userType === "applicant") {
      console.log("Navigating to talent profile");
      return <Navigate to="/talent/profile" />;
    } else if (userType === "admin") {
      console.log("Navigating to admin dashboard");
      return <Navigate to="/admin/dashboard" />;
    }
  }

  const queryParams = new URLSearchParams(location.search);
  const isPricing = queryParams.get("feature");
  // const planName = queryParams.get("plan");
  const alertMsg =
    "<strong>Reminder</strong>: Before proceeding to subscription, ensure that you are logged in or registered as an Employer.";

  return (
    <>
      <LoginAnalytics />
      <CustomContainer
        backgroundImage={loginBg}
        className="customContainerLogin"
      >
        <div className="login-status__employer d-none d-sm-block">employer</div>
        {isPricing && (
          <div className="float__alert">
            <AlertWithHide variant="warning" message={alertMsg} />
          </div>
        )}
        <ContainerIndex />
      </CustomContainer>
    </>
  );
};

export default LoginClient;
