import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

const Loader = () => (
  <div>
    <Spinner size="sm">Loading...</Spinner>
    <span> Loading</span>
  </div>
);
export default Loader;
