import { useEffect, useState } from "react";
import { useAuthToken, useRoleKey } from "../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../Config/config";
import { handleAxiosError } from "../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

export const useNotes = () => {
  const token = useAuthToken();
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState(null);

  const getNoteApi = async (talentInfo) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${Config.ats}/api/ats/candidate-list/notes`,
        {
          headers,
          params: {
            talentId: talentInfo.talentId,
            jobId: talentInfo.jobId,
          },
        }
      );

      setNote(res.data.result);
    } catch (error) {
      handleAxiosError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNoteApi();
  }, []);

  return {
    getNoteApi,
    note,
    isLoading,
  };
};
