import React from "react";
import Footer from "../../LandingPage/Components/Footer";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import { Button, Image } from "react-bootstrap";
import "../../../assets/scss/ChoosePlan.scss";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import { useNavigate } from "react-router-dom";

const ChoosePlan = () => {
  TabTitle("NXT | Pricing");

  const navigate = useNavigate();

  return (
    <>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require("../../../assets/images/LandingPage/TrackingSystem/pricing-banner.png")}
      />
      <div className="choose-plan py-5">
        <h5 className="mb-5 text-center">
          Choose the plan that fits your needs.
        </h5>
        <div className="d-flex justify-content-center">
          <div className="freemium mt-4 p-5">
            <h4>Freemium</h4>
            <label>Basic Job Listing</label>
            <h3 className="my-4">Free</h3>
            <Button className="p-3" onClick={() => navigate("/registration")}>
              Sign Up, It’s Free
            </Button>
            <p className="mt-5">Key Features</p>
            <ul>
              <li>Unlimited Job Postings</li>
              <li>Downloadable CV</li>
              <li>Customer Support (eMail)</li>
            </ul>
          </div>
          <div className="premium shadow">
            <div className="most-popular text-center py-2">Most Popular</div>
            <div className="p-5">
              <h4>Premium</h4>
              <label>Enhance Job Posting</label>
              <h3 className="my-4">P 6,000</h3>
              <Button className="p-3" onClick={() => navigate("/login")}>
                Start Free 30-Day Trial
              </Button>
              <p className="mt-5">Key Features</p>
              <ul>
                <li>Unlimited Job Postings</li>
                <li>Applicant Tracking System</li>
                <li>Downloadable CV</li>
                <li>Customer Support (eMail, call)</li>
                <li>Real-time Application Status Email Notification</li>
              </ul>
              <p>Upcoming AI Features</p>
              <ul>
                <li>Chatbot</li>
                <li>JD Generator</li>
                <li>Talent and Job Matching</li>
                <li>Resume Parsing</li>
                <li>AI Job Screening</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChoosePlan;
