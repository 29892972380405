import React from "react";
import CustomButton from "../../UI/Button";
import { Input } from "reactstrap";
import "../../../assets/scss/Form/SuperAdmin/AddAdminForm.scss";
const AddAdminForm = () => {
  return (
    <div className="addAdminForm">
      <Input type="text" placeholder="Email Address" />
      <Input type="select"></Input>
      <CustomButton label="Invite" classNameLabel="addAdminButton" />
    </div>
  );
};

export default AddAdminForm;
