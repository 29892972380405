import React from "react";
import { Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingPageCareer.scss";
const Career = () => {
  const cardInfo = [
    {
      img: "career1.png",
      title: "Colleague Cues: Tips on how to be a great workmate",
      content: "Want to be the colleague everyone loves working with?...",
      linkTo:
        "https://blog.nxt.com.ph/colleague-cues-tips-on-how-to-be-a-great-workmate/",
    },
    {
      img: "career2.png",
      title: "Reinventing Your Job Search: Embracing Innovation for Success",
      content:
        "From harnessing the power of social media to crafting captivating video...",
      linkTo:
        "https://blog.nxt.com.ph/reinventing-your-job-search-embracing-innovation-for-success/",
    },
    {
      img: "career3.png",
      title: "So You've Got a Job Offer, Now What?",
      content:
        "Unlock a world of career opportunities. Gain valuable insights from Ged Gutierrez, Acquiro's Deputy General Manager, ....",
      linkTo:
        "https://blog.nxt.com.ph/podcast-so-youve-got-a-job-offer-now-what/",
    },
  ];

  return (
    <>
      <div className="landing-career">
        <Container>
          <h1>MeNXT Career Tips!</h1>
          <div className="landing-card-group">
            {cardInfo.map((val, key) => (
              <div key={key} className="landing-card">
                <div className="card-img-container">
                  <img
                    alt="card-img"
                    src={require(`../../../assets/images/LandingPage/Career/${val.img}`)}
                    className="card-img"
                  />
                </div>
                <div className="landing-card-body">
                  <a href={val.linkTo} style={{ textDecoration: "none" }}>
                    <h5>{val.title}</h5>
                  </a>
                  <p>{val.content}</p>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Career;
