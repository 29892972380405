import React from "react";

const StartJobSearch = () => {
  return (
    <div>
      <p>
        To know more about the latest career trends and tips, visit our{" "}
        <span className="brandLink" style={{ color: "#8046fa" }}>
          MeNXT Career Tips!
        </span>{" "}
        Now that you have set up your accounts, you can begin searching for your
        next dream job. You can effectively search for your dream job by
        exploring our Joblist:
      </p>
      <ol>
        <li>Once logged in, click ‘Job list’ button</li>
        <li>On the ‘Search Job’ field, you may use the following keywords</li>
        <li>Click on ‘Search Job’</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Role Title</li>
          <li>Skill set</li>
          <li>Company name</li>
        </ol>
        <li>The filtered jobs will be loaded on the same page.</li>
      </ol>
    </div>
  );
};

export default StartJobSearch;
