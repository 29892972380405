import React from "react";
import { Button, Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingPostJob.scss";
import { useNavigate } from "react-router";
const PostJob = () => {
  const navigate = useNavigate();
  const handleClickRegister = () => {
    navigate("/client/registration");
  };
  return (
    <div className="landing-postJob">
      <Container>
        <div className="postJob-body">
          <div className="postJob-img-container">
            <img
              alt="postJob-img"
              src={require(`../../../assets/images/LandingPage/PostJob/vector2.png`)}
              className="postJob-img"
            />
          </div>
          <div className="postJob-reg d-flex justify-content-center  ">
            <p className="mt-3 ">Sign-up today</p>
            <h1 className="mb-0">and enjoy FREE</h1>
            <p className="mb-3">job postings </p>
            <Button className="mb-3" onClick={handleClickRegister}>
              Post Jobs
            </Button>
          </div>
          <div className="postJob-text me-5 ">
            <p>
              Connect effortlessly with skilled professionals, fresh graduates,
              and experienced candidates through our platform.
            </p>
            <p>Maximize your reach and find the ideal candidate with NXT.</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PostJob;
