import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/scss/NotFound.scss";
import { TabTitle } from "../../../Utilities/FunctionTitle";

const CustomNotFound = ({ message, btnName }) => {
  const talentToken = localStorage.getItem("talentToken");
  const clientToken = localStorage.getItem("clientToken");
  const navigate = useNavigate();
  // Set default values if message or btnName is undefined or null
  message =
    message ||
    "We're sorry, but the job link you clicked on is no longer active or has been closed. Please feel free to explore other job opportunities on our website";

  if (clientToken) {
    btnName = btnName || "Back to Manage Job"; // Token exists
  } else if (talentToken) {
    btnName = btnName || "Back to Job List";
  } else {
    btnName = btnName || "Go to Public Jobs"; // Token doesn't exist
  } // Default button name

  const link = "www.nxt.com.ph";

  const handleBackButtonClick = () => {
    if (clientToken) {
      navigate("/client/manage-job");
    } else if (talentToken) {
      navigate("/talent/job-list");
    } else {
      navigate("/jobs");
    }
  };

  const handleNXTLINK = () => {
    if (clientToken) {
      navigate("/client/company-profile");
    } else if (talentToken) {
      navigate("/talent/profile");
    } else {
      navigate("/");
    }
  };
  return (
    <div className="not-found">
      <img
        src={require("../../../assets/images/nxt-logo.png")}
        alt="NXT Logo"
      />
      <h3>{message}</h3>
      <h3 className="link" onClick={handleNXTLINK}>
        {link}
      </h3>
      <div className="btnContainer">
        <button onClick={handleBackButtonClick}>{btnName}</button>
      </div>
    </div>
  );
};

export default CustomNotFound;
