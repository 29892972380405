import React from "react";
import CustomTabs from "../../../components/UI/Tabs/Tabs";

import ManageJobsContent from "./TabsContent/ManageJobsContent";

const ManageJobsTabs = () => {
  const activePlans = ["ATS Plan 1"]; // Example array of active plans
  const isFirstTime = false; // Example isFirstTime flag

  const tabsData = [
    {
      eventKey: "manageJobs",
      title: "Manage Jobs",
      content: <ManageJobsContent />,
    },
  ];
  return <div>{CustomTabs(tabsData)}</div>;
};

export default ManageJobsTabs;
