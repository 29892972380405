import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  skillSet: null,
  loading: false,
  error: false,
};

const skillSetSlice = createSlice({
  name: "skillSet",
  initialState,
  reducers: {
    startSkillSet: (state) => {
      state.loading = true;
    },
    updateSkillSet: (state, action) => {
      state.loading = false;
      state.skillSet = {
        ...state.skillSet,
        ...action.payload,
      };
    },
    getSkillSet: (state, action) => {
      state.loading = false;
      state.error = false;
      state.skillSet = action.payload;
    },
    failSkillSet: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearSkillSet: (state) => {
      state.skillSet = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  startSkillSet,
  updateSkillSet,
  getSkillSet,
  failSkillSet,
  clearSkillSet,
} = skillSetSlice.actions;

export default skillSetSlice.reducer;
