import React, { useEffect, useState } from "react";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../../../../Config/config";

const JobPosting = (graphData) => {
  return (
    <>
      <div className="position-absolute top-0 start-0 p-4 ats__dasboard-title">
        Total Job Posting
      </div>
      <div class="position-absolute top-50 start-50 translate-middle text-center p-4">
        Total Job Posting
        <br />
        <span>{graphData?.graphData?.totalJobs?.count}</span>
      </div>
      <div class="position-absolute bottom-0 start-0 text-center p-4">
        New Job Post
        <br />
        <span>{graphData?.graphData?.jobPostStats?.newJobCount}</span>
      </div>
      <div class="position-absolute bottom-0 start-50 translate-middle-x text-center p-4">
        Job Posting Applied <br />
        <span>{graphData?.graphData?.jobPostStats?.jobPostAppliedCount}</span>
      </div>
      <div class="position-absolute bottom-0 end-0 text-center p-4">
        Closed Posting <br />
        <span>{graphData?.graphData?.closedJobs?.count}</span>
      </div>
    </>
  );
};

export default JobPosting;
