import React from "react";
import UpgradeContact from "../../../../../../components/UI/Alert/UpgradeContact";

const SubscriptionTime = ({ activePlan }) => {
  console.log("activePlan", activePlan);
  return (
    <div>
      <strong>
        <div>
          <p className="text-decoration-underline m-0 mb-2">
            Subscription Time:
          </p>

          <p>
            {activePlan[0].activeSubscription.daysLeft === 0 ? (
              "Your Subscription is Free!"
            ) : (
              <>
                Your subscription will end in{" "}
                <span className="text-danger">
                  {activePlan[0].activeSubscription.daysLeft}
                </span>{" "}
                days
              </>
            )}
          </p>
        </div>
        {/* <div>
          <p className="text-decoration-underline mt-5 mb-2">Add On Time:</p>
          <p>
            {activePlan[0].activeSubscription.daysLeft === 0 ? (
              "Your Subscription is Free!"
            ) : (
              <>
                Your subscription will end in{" "}
                <span className="text-danger">
                  {activePlan[0].activeSubscription.daysLeft}
                </span>{" "}
                days
              </>
            )}
          </p>
        </div> */}

        {activePlan?.[0]?.activeSubscription?.current !== "freemium" && (
          <div className="mt-5">
            <UpgradeContact />
          </div>
        )}
      </strong>
    </div>
  );
};

export default SubscriptionTime;
