import React from "react";
import { Button, Input } from "reactstrap";
import "../../assets/scss/Client/Layout/TalentList/SidebarSelectTalent.scss";
const SidebarSelectTalent = () => {
  return (
    <div className="st-sidebar">
      <h5>Endorse Job Posting</h5>
      <Input type="select"></Input>
      <Button>Add to Job Posting</Button>
    </div>
  );
};

export default SidebarSelectTalent;
