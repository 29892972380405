import React from "react";
import ProductSubscribeBody from "./ProductSubscribeBody";
import Footer from "../../LandingPage/Components/Footer";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";

const ProductSubscribe = () => {
  return (
    <>
      <LandingNavbar />
      <ProductSubscribeBody />
      <Footer />
    </>
  );
};

export default ProductSubscribe;
