import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../../assets/scss/Client/Layout/Subscription/Subsciption.scss";
import Stepper from "react-stepper-horizontal";
import SubsOrderSummary from "./SubscriptionCheckout/SubsOrderSummary";
import SubsPaymentMethod from "./SubscriptionCheckout/SubsPaymentMethod";

import { useLocation } from "react-router-dom";
const SubsCheckout = () => {
  const location = useLocation();
  const typeOfSub = location.state?.typeOfSub;
  const billing = location.state?.billing;
  const expiredFreeTrial = location.state?.expiredFreeTrial;
  const existingTransaction = location.state?.existingTransaction;
  const selectedPlan = location.state?.selectedPlan;
  const subsData = location.state?.subsData;

  return (
    <div className="subsciption-container subscription-container__mod">
      <Container>
        <div className="mb-5 stepper">
          <Stepper
            activeColor="#8046FA"
            defaultColor="#EFF0F6"
            completeColor="#8046FA"
            defaultBarColor="#EFF0F6"
            steps={[
              { title: "Select Subscription" },
              { title: "Configure Subscription" },
              { title: "Checkout" },
            ]}
            activeStep={2}
          />
        </div>
        <h2 className="text-center pb-5">Checkout</h2>
        <Row>
          <Col md={12}>
            <SubsOrderSummary
              subsData={subsData}
              selectedPlan={selectedPlan}
              typeOfSub={typeOfSub}
              billing={billing}
              expiredFreeTrial={expiredFreeTrial}
              existingTransaction={existingTransaction}
            />
            <SubsPaymentMethod
              count={billing}
              typeOfSub={typeOfSub}
              selectedPlan={selectedPlan}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubsCheckout;
