import React, { useEffect, useState } from "react";
import companySizeList from "../../../../Data/dropdown/companySize.json";
import industryList from "../../../../Data/dropdown/industry.json";
import countryList from "../../../../Data/place/countryPhil.json";
import CustomInput from "../../../../components/Form/CustomInput";
import CustomSelect from "../../../../components/Form/CustomSelect";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import { fetchRegionMunicipalityProvince } from "../../../../Utilities/Fetch/Location";

const CompanyProfileDetails = () => {
  const token = useAuthToken();
  const [regionList, setRegionList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const [companySizeVal, setCompanySizeVal] = useState("");
  const [industryVal, setIndustryVal] = useState("");
  const [regionVal, setRegionVal] = useState("");
  const [provinceVal, setProvinceVal] = useState("");
  const [municipalityVal, setMunicipalityVal] = useState("");

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchRegionMunicipalityProvince(
        token,
        regionVal,
        provinceVal,
        municipalityVal
      );
      setRegionList(data.region);
      setProvinceList(data.province);
      setMunicipalityList(data.municipality);
    };

    fetchData();
  }, [regionVal, provinceVal, municipalityVal]);

  const companyProfile = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );
  return (
    <>
      <h1>Company Profile</h1>
      <hr className="line" />
      <div className="company-details">
        <CustomInput
          errors={errors}
          label="Company Name"
          type="text"
          {...register("companyName")}
          placeholder="Company Name"
          className="company-input-lg-width"
          classNameLabel="company-input-title"
          required={true}
        />
        <CustomSelect
          label="Company Size"
          data={companySizeList}
          errors={errors}
          {...register("companySize", {
            required: "Company Size is required",
          })}
          defaultOption="Select to choose"
          className="company-input-sm-width"
          classNameLabel="company-input-title"
          required={true}
          onChange={(event) => {
            setCompanySizeVal(event.target.value);
          }}
          // value={companySizeVal}
        />
        <CustomSelect
          id="industry"
          label="Industry"
          data={industryList}
          errors={errors}
          {...register("industry", {
            required: "Industry is required",
          })}
          defaultOption="Select to choose"
          className="company-input-lg-width"
          classNameLabel="company-input-title"
          required={true}
          onChange={(event) => {
            setIndustryVal(event.target.value);
          }}
          // value={industryVal}
        />

        <CustomInput
          errors={errors}
          label="Company Address"
          labelOptional="(Unit Number / Floor / Street Name / Barangay)"
          type="text"
          {...register("address")}
          placeholder="Address"
          className="company-input-lg-width"
          classNameLabel="company-input-title"
          required={true}
        />
        <CustomSelect
          id="countrySelect"
          label="Country"
          data={countryList}
          errors={errors}
          {...register("country", {
            required: "Country is required",
          })}
          value="Phillippines"
          className="company-input-sm-width"
          classNameLabel="company-input-title"
          required={true}
        />
        <div className="ph-input">
          <CustomSelect
            id="regionSelect"
            label="Region"
            data={regionList}
            errors={errors}
            {...register("region", {
              required: "Region is required",
            })}
            defaultOption={
              companyProfile?.region !== "" &&
              regionVal === "" &&
              companyProfile?.region !== "N/A"
                ? companyProfile?.region
                : "Select to choose"
            }
            className="ph-input-container"
            classNameLabel="company-input-title"
            required={true}
            onChange={(event) => {
              setRegionVal(event.target.value);
              setValue("province", "");
              setValue("municipality", "");
              setProvinceVal("");
              setMunicipalityVal("");
            }}
            // value={regionVal}
          />
          <CustomSelect
            id="provinceSelect"
            label="Province / District"
            data={provinceList}
            errors={errors}
            {...register("province")}
            defaultOption={
              companyProfile?.province !== "" &&
              regionVal === "" &&
              companyProfile?.province !== "N/A"
                ? companyProfile?.province
                : "Select to choose"
            }
            className="ph-input-container"
            classNameLabel="company-input-title"
            required={true}
            onChange={(event) => {
              setProvinceVal(event.target.value);
            }}
            value={provinceVal}
          />

          <CustomSelect
            id="municipality"
            label="Municipality"
            data={municipalityList}
            errors={errors}
            {...register("municipality")}
            defaultOption={
              companyProfile?.municipality !== "" &&
              regionVal === "" &&
              companyProfile?.province !== "N/A"
                ? companyProfile?.municipality
                : "Select to choose"
            }
            className="ph-input-container"
            classNameLabel="company-input-title"
            required={true}
            onChange={(event) => {
              setMunicipalityVal(event.target.value);
            }}
            value={municipalityVal}
          />
        </div>

        <div>
          <CustomInput
            errors={errors}
            label="Postal Code"
            type="text"
            {...register("postal")}
            placeholder="XXXXX"
            className="form-input-group"
            classNameLabel="company-input-title"
            required={true}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyProfileDetails;
