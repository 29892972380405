import React, { useState } from "react";
import { Button, Container, Input } from "reactstrap";
import "../../../../../assets/scss/Talent/Layout/TalentList/SearchFilter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPin,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import useDebounce from "../../../../../components/useDebounce";

const SearchFilter = ({ onSearch, loading, initialSearchValue, className }) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue); // Initialize with initialSearchValue prop

  const handleSearch = () => {
    onSearch(searchValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={`search-filter-container ${className}`}>
      <Container>
        <div className="search-filter-content">
          <div className="search-icon">
            {/* ... */}
            <FontAwesomeIcon icon={faMagnifyingGlass} className="searchIcon" />
            <Input
              type="text"
              placeholder="Enter job title, location, or job id"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Button
            onClick={handleSearch}
            disabled={loading}
            className={className}
          >
            {loading ? "Loading..." : "Search Job"}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default SearchFilter;
