import React from "react";
import {
  faPhone,
  faEnvelope,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../../assets/scss/Page/Outer/ContactUs/_getInTouch.scss";
const GetInTouch = () => {
  return (
    <div className="getInTouch">
      <h1>Get in touch with us:</h1>
      <div className="information">
        <div className="numEmailContainer">
          <div className="iconText">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            <p>+63 917 853 2506</p>
          </div>
          <div className="iconText">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <p>inquiry@nxt.com.ph</p>
          </div>
        </div>
        <div className="locContainer">
          <div className="iconTextLoc">
            <FontAwesomeIcon icon={faLocationPin} className="icon" />
            <p>
              15F, W Fifth Avenue Bldg., 5th Ave. cor. 32nd St., Bonifacio
              Global City, Taguig City 1630, Metro Manila, Philippines
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
