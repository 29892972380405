import React from "react";
import Image from "../../../components/UI/Image";
import fb from "../../../assets/images/Logo/SocMed/facebook.png";
const FacebookBtn = ({ onPrivacyPolicyCheck }) => {
  return (
    <>
      <div className="fbLogo" onClick={() => onPrivacyPolicyCheck(false)}>
        <Image src={fb} width={11.11} height={20} />
      </div>
    </>
  );
};

export default FacebookBtn;
