import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import CustomCreatableSelect from "../../../../../../components/Form/CustomCreatableSelect";
import languageData from "../../../../../../assets/global/ListofLanguages.json";
const JobRequirement = ({ editData, isViewJob, aiData }) => {
  console.log(aiData);
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  console.log(errors);
  const [skillSetData, setSkillSetData] = useState([]);

  const [formattedSkillSet, setFormattedSkillSet] = useState([]);
  const [formattedDialect, setFormattedDialect] = useState([]);

  useEffect(() => {
    if (editData) {
      // Format skillSet values
      const skillSet = editData?.skillSet?.map((value) => ({
        label: value,
        value,
        __isNew__: true,
      }));

      // Format dialect values
      const dialect = editData?.dialect?.map((value) => ({
        label: value,
        value,
        __isNew__: true,
      }));

      setValue("skillSet", skillSet);
      setValue("dialect", dialect);

      setFormattedSkillSet(skillSet);
      setFormattedDialect(dialect);
    } else if (aiData) {
      const skillSet = aiData?.content?.skillSet?.map((value) => ({
        label: value,
        value,
        __isNew__: true,
      }));

      // Format dialect values
      const dialect = aiData?.content?.dialect?.map((value) => ({
        label: value,
        value,
        __isNew__: true,
      }));

      setValue("skillSet", skillSet);
      setValue("dialect", dialect);

      setFormattedSkillSet(skillSet);
      setFormattedDialect(dialect);
    }
  }, [editData, setValue, aiData]);

  const validationPattern = /^[a-zA-Z0-9-_./&\s]*$/;
  return (
    <div>
      {" "}
      <h4 className="create-job__title border-bottom pb-3 mb-4 mt-5">
        JOB REQUIREMENTS
      </h4>
      <Row>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomCreatableSelect
            label="Skill Set"
            name="skillSet"
            control={control}
            defaultValue={formattedSkillSet}
            rules={{ required: "Skill Set is required" }}
            errors={errors}
            required
            readOnly={isViewJob && true}
            characterLimit={100}
            validationPattern={validationPattern}
            maxItems={50}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomCreatableSelect
            label="Dialect"
            name="dialect"
            control={control}
            defaultValue={formattedDialect}
            options={languageData.listLanguages}
            rules={{ required: "Dialect is required" }}
            errors={errors}
            required
            readOnly={isViewJob && true}
            characterLimit={100}
            validationPattern={validationPattern}
            maxItems={50}
          />
        </Form.Group>
      </Row>
    </div>
  );
};

export default JobRequirement;
