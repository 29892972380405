import axios from "axios";
import Config from "../../Config/config";

export const patchAPI = async (url, payload, token) => {
  try {
    const response = await axios.patch(`${Config.nxtApi}/${url}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
};
