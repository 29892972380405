import React from "react";
import PersonImg from "../../../assets/images/WhatsNext/img/LandingPage/PersonImg.png";
import FooterImg from "../../../assets/images/WhatsNext/img/LandingPage/FooterImg.png";
import GradBg from "../../../assets/images/WhatsNext/img/LandingPage/GradBg.png";
import "../../../assets/scss/WhatsNext/styles.scss";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import { useNavigate } from "react-router-dom";

const Index = () => {
  TabTitle("What's Nexts");

  const navigate = useNavigate();
  const handleClickSignUpTalent = () => {
    navigate("/nxt/jobseeker/?ref=rJ4nGx6B", {
      state: { sendClientState: "talent" },
    });
  };

  const handleClickSignUpClient = () => {
    navigate("/nxt/employer/?ref=hO2mFu9V", {
      state: { sendClientState: "client" },
    });
  };

  return (
    <div className="whats-next__container">
      <div className="bg-container__section-one">
        {" "}
        <img className="bg-container__img-grad" src={GradBg} alt="grad" />
        <img
          className="bg-container__img-person"
          src={PersonImg}
          alt="person"
        />
        <div className="bg-container__blue-bg">
          {/* <h1>I'm</h1>
          <h2>an EMPLOYER</h2> */}
          <p>
            <span>I'm</span> <br />a JOBSEEKER
          </p>
          <button
            className="jobseeker-button"
            onClick={handleClickSignUpTalent}
          >
            CLICK HERE
          </button>
        </div>
        <div className="bg-container__violet-bg">
          {/* <h1>I'm</h1>
          <h2>a JOB SEEKER</h2> */}
          <p>
            <span>I'm</span> <br />
            an EMPLOYER
          </p>
          <button className="employer-button" onClick={handleClickSignUpClient}>
            CLICK HERE
          </button>
        </div>
      </div>
      <div className="bg-container__section-two">
        <div className="bg-container__footer-img">
          <div className="footer-text-img__container">
            <div className="bg-container__footer-text-img"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
