import { GetData } from "./GetData";

export const fetchRegionMunicipalityProvince = async (
  token,
  regionVal,
  provinceVal,
  municipalityVal
) => {
  const regionResponse = await GetData(`api/talent/region`, token);
  const regionData = regionResponse.regions || [];

  const selectedRegion = regionData.find((val) => val.name === regionVal);

  let provinceData = [];
  let municipalityData = [];

  if (selectedRegion) {
    const regCode = selectedRegion.reg_code;

    const provinceResponse = await GetData(
      `api/talent/province?reg_code=${regCode}`,
      token
    );
    provinceData = provinceResponse.provinces || [];

    if (provinceData.length > 0) {
      const selectedProvince = provinceData.find(
        (val) => val.name === provinceVal
      );

      if (selectedProvince) {
        const provCode = selectedProvince.prov_code;

        const municipalityResponse = await GetData(
          `api/talent/municipality?prov_code=${provCode}`,
          token
        );

        municipalityData = municipalityResponse.municipality || [];
      }
    }
  }

  return {
    region: regionData,
    province: provinceData,
    municipality: municipalityData,
  };
};
