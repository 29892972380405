import React, { useEffect, useState } from "react";
import { Button, Container, Input } from "reactstrap";
import "../../../assets/scss/Client/Layout/TalentList/TalentListSearchFilter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
const TalentListSearchFilter = ({ searchValue }) => {
  const [search, setSearch] = useState("");
  const performSearch = () => {
    searchValue(search);
    // const payload = {
    //   search: search,
    // };

    // //TalentList.js - for the listener of this event
    // const event = new CustomEvent("onTalentListSearch", { detail: payload });
    // document.dispatchEvent(event);
  };

  return (
    <div className="talentlist-search-filter-container">
      <Container>
        <div className="search-filter-content">
          <div className="search-icon">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            <Input
              type="text"
              placeholder="Enter applicant last name or first name"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </div>
          <Button
            onClick={() => {
              performSearch();
            }}
          >
            Search
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default TalentListSearchFilter;
