import React from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_viewResumeHistory.scss";
import moment from "moment";

const StatusHistory = ({ data, client }) => {
  // console.log("status history", data);
  if (!data) {
    return (
      <div>
        <TitleLine title="Status History"></TitleLine>
        <p>No status history</p>
      </div>
    );
  }
  console.log(data);
  return (
    <div className="status-history__fixedsize">
      <TitleLine title="Status History"></TitleLine>
      <div className="view_resume_history">
        <div className="view_resume_history_content">
          {data?.map((val, key) => (
            <div className="certificate_content" key={key}>
              <h6>
                <strong>{val?.name}</strong>
                <br />
                {val?.statusType === "submit"
                  ? `Submitted Candidate to ${val.status}`
                  : val?.statusType === "draft"
                  ? `Moved the candidate to ${val.status}`
                  : ""}
              </h6>
              <p>{moment(val?.updatedAt).format("YYYY-MM-DD, h:mm:ss a")}</p>
              <hr className="line-seperator" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusHistory;
