import React, { useState } from "react";
import logo from "../../../../../assets/images/Icon/iconBuilding.png";
import { Dropdown, Form } from "react-bootstrap";
import "../../../../../assets/scss/Page/Admin/ATS/_profile.scss";
import CustomButton from "../../../../../components/UI/CustomButton";
import { useForm } from "react-hook-form";
import axios from "axios";
import Config from "../../../../../Config/config";
import CustomModal from "../../../../../components/UI/Modal";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import { useSelector } from "react-redux";
import moment from "moment";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Table } from "react-bootstrap";

const SendInvite = React.forwardRef(
  (
    {
      style,
      className,
      "aria-labelledby": labeledBy,
      data,
      clientEmail,
      onSuccess,
    },
    ref
  ) => {
    const token = useAuthToken();
    const code = useRoleKey();
    const headers = {
      Authorization: `Bearer ${token}`,
      ...(code && { rolekey: code }),
    };
    const [value, setDate] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const schema = z.object({
      meetingTime: z.string().nonempty("Date is required"),
      meetingLink: z
        .string()
        .nonempty("Link is required")
        .refine(
          (value) => {
            try {
              new URL(value);
              return true;
            } catch (error) {
              const domainRegex =
                /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/\S*)?$/;
              const match = value.match(domainRegex);
              return match !== null && match[1] !== undefined;
            }
          },
          {
            message: "Invalid meeting link",
          }
        ),
      remarks: z.string().nonempty("Remarks is required"),
    });

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({ resolver: zodResolver(schema) });

    const submitInvitation = async (formData) => {
      setIsLoading(true);
      console.log(formData);
      console.log(data);
      try {
        const payload = {
          jobId: data.jobId,
          to: [data.email],
          clientEmail: clientEmail,
          remarks: formData.remarks,
          firstName: data.firstName,
          lastName: data.lastName,
          jobTitle: data.jobTitle,
          meetingLink: formData.meetingLink,
          meetingTime: moment(formData.meetingTime).format(
            "MMMM DD, YYYY, h:mm:ss A"
          ),
          // uncomment for format
          // meetingTime: formData.meetingTime,
          // meetingTime: moment(formData.meetingTime).format(
          //   "YYYY/MM/DD hh:mm a"
          // ),
          status: "Hired",
          companyName: data.companyName,
          hiringManagerEmail: data.hiringManagerEmail,
          talentId: data.talentId,
        };

        console.log(payload);

        // uncomment if payload is okay
        const response = await axios.post(
          `${Config.ats}/api/ats/candidate-list/invite`,
          payload,
          {
            headers,
          }
        );

        console.log(response);

        onSuccess();
        reset();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    return (
      <div
        ref={ref}
        style={{ ...style, zIndex: 5000 }}
        className={className}
        onClick={(e) => e.stopPropagation()}
      >
        <Form onSubmit={handleSubmit(submitInvitation)}>
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="datetime-local"
            className="mx-3 my-2 date"
            {...register("meetingTime")}
            // checking value of date time
            onChange={(e) => console.log(e.target.value)}
          />
          <div className="mx-3">
            {errors.meetingTime && (
              <CustomMessage label={errors.meetingTime.message} />
            )}
          </div>
          <Form.Label>Links</Form.Label>
          <Form.Group>
            <Form.Control
              type="text"
              className="mx-3 my-2 date"
              {...register("meetingLink")}
            />
            <div className="mx-3">
              {errors.meetingLink && (
                <CustomMessage label={errors.meetingLink.message} />
              )}
            </div>
          </Form.Group>

          <Form.Label>Remarks</Form.Label>
          <Form.Control
            as="textarea"
            className="mx-3 my-2 remarks"
            {...register("remarks")}
          />

          <div className="mx-3">
            {errors.remarks && <CustomMessage label={errors.remarks.message} />}
          </div>
          <div className="container_right_btn">
            <CustomButton
              name="Send"
              className="customDBlueBtn"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </div>
    );
  }
);

const AddNote = React.forwardRef(
  (
    { style, className, "aria-labelledby": labeledBy, data, onSuccess },
    ref
  ) => {
    const token = useAuthToken();
    const code = useRoleKey();
    const headers = {
      Authorization: `Bearer ${token}`,
      ...(code && { rolekey: code }),
    };

    const schema = z.object({
      content: z.string().nonempty("Content is required"),
    });
    const [isLoading, setIsLoading] = useState(false);
    const [value, setDate] = useState("");
    const [isPrivate, setIsPrivate] = useState(false);
    const [radioSelected, setRadioSelected] = useState(false);
    const [radioError, setRadioError] = useState("");

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({ resolver: zodResolver(schema) });

    const submitNote = async (formData) => {
      setIsLoading(true);
      try {
        const payload = {
          content: formData.content,
          talentId: data.talentId,
          jobId: data.jobId,
        };

        if (isPrivate) {
          payload.isPrivate = true;
        }

        const response = await axios.post(
          `${Config.ats}/api/ats/candidate-list/notes`,
          payload,
          {
            headers,
          }
        );
        setIsLoading(false);
        onSuccess();

        reset();
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    return (
      <div ref={ref} className={className} style={{ ...style, zIndex: 1000 }}>
        <Form onSubmit={handleSubmit(submitNote)}>
          <Form.Label>Remarks</Form.Label>
          <Form.Control
            as="textarea"
            className="mx-3 my-2 remarks"
            {...register("content")}
          />
          <div className="mx-3">
            {errors.content && <CustomMessage label={errors.content.message} />}
          </div>

          <div key={`inline-radio`} className="mx-3 my-2">
            <Form.Check
              type="radio"
              label="Save Public"
              id="radio-public"
              checked={!isPrivate}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                setRadioSelected(true);
                setIsPrivate(false);
              }}
            />
            <Form.Check
              type="radio"
              label="Save Private"
              id="radio-private"
              checked={isPrivate}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                setIsPrivate(true);
                setRadioSelected(true);
              }}
            />
          </div>

          <div className="mx-3">
            {radioError && <CustomMessage label={radioError} />}
          </div>
          <div className="container_right_btn">
            <CustomButton
              onClick={(e) => e.stopPropagation()}
              name="Send"
              type="submit"
              className="customDBlueBtn"
              isLoading={isLoading}
            />
          </div>
        </Form>
      </div>
    );
  }
);

const Profile = ({
  clientData,
  data,
  talentSubs,
  hasNotes,
  loadRemarksData,
}) => {
  const [showModalTalent, setShowModalTalent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const clientEmail = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.email
  );

  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const handleNoteSuccess = () => {
    loadRemarksData();
    setModalType("successNote");
    setShowModal(true);
  };

  const handleInviteSuccess = () => {
    setModalType("successInvite");
    setShowModal(true);
  };

  const submitInvitationTalent = async () => {
    console.log("item data !!", data.applicantId, clientData.jobId);
    try {
      const payload = null;

      console.log(payload);

      // uncomment if payload is okay
      const response = await axios.post(
        `${Config.ats}/api/ats/ai/invite`,
        payload,
        {
          params: {
            applicantId: data.applicantId,
            jobId: clientData.jobId,
          },
          headers,
        }
      );

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const sentInvitationTalent = () => {
    submitInvitationTalent();
    setModalType("successNote");
    setShowModalTalent(true);
  };

  return (
    <div className="profile_ats">
      <div className="profile_ats_personal">
        {talentSubs === true ? (
          <div className="font-awesome__user position-relative">
            <FontAwesomeIcon
              className="position-absolute top-50 start-50 translate-middle"
              icon={faUser}
            />
          </div>
        ) : (
          <img
            crossOrigin="anonymous"
            src="sample.img"
            alt="logo"
            onError={(e) => {
              e.target.src = logo;
              e.target.classList.add("errorImg");
            }}
          />
        )}

        <div className="profile_ats_personal_name">
          <h1>
            {talentSubs === true
              ? "Talent"
              : data.firstName + " " + data.lastName}
            {/* {data.firstName + " " + data.lastName} */}
          </h1>
          <h5>
            {data?.jobType && `${data?.jobType}, `} {data?.companyName}
          </h5>
        </div>
      </div>
      <div className="profile_ats_dropdown_container">
        {hasNotes && (
          <>
            {" "}
            {talentSubs === true ? (
              <Button
                className="profile-invite__to_apply"
                onClick={sentInvitationTalent}
              >
                Invite to apply
              </Button>
            ) : (
              <Dropdown>
                <Dropdown.Toggle>Send Invite</Dropdown.Toggle>{" "}
                <Dropdown.Menu>
                  <SendInvite
                    data={data}
                    clientEmail={clientEmail}
                    onSuccess={handleInviteSuccess}
                  />
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Dropdown>
              {talentSubs === true ? (
                ""
              ) : (
                <Dropdown.Toggle>Add Note</Dropdown.Toggle>
              )}
              <Dropdown.Menu>
                <AddNote data={data} onSuccess={handleNoteSuccess} />
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
      <CustomModal
        show={showModal}
        modalType={
          modalType === "successNote" || modalType === "successInvite"
            ? "Success"
            : "Warning"
        }
        modalMessage={
          modalType === "successNote"
            ? "Note successfully created"
            : modalType === "successInvite"
            ? "Invite successfully sent"
            : ""
        }
        buttonName="OK"
        onClick={() => setShowModal(false)}
      />
      <CustomModal
        show={showModalTalent}
        modalType={
          modalType === "successNote" || modalType === "successInvite"
            ? "Success"
            : "Warning"
        }
        modalMessage={
          modalType === "successNote"
            ? "Success invite"
            : modalType === "successInvite"
            ? "Invite successfully sent"
            : ""
        }
        buttonName="OK"
        onClick={() => setShowModalTalent(false)}
      />
    </div>
  );
};

export default Profile;
