import React from "react";
import "../../../assets/scss/Product/ProductHero.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ProductHero = () => {
  const navigate = useNavigate();

  const handleClickSubscribeNow = () => {
    navigate("/pricing");
  };

  return (
    <>
      <div className="prod-hero position-relative">
        <div className="container">
          <div className="p-1 p-sm-1 p-md-5">
            <div className="py-5">
              <h2>
                Streamline Your <br />
                Recruitment <br /> Process with NXT
              </h2>
              <h3>Post + Recruit + Track</h3>
              <Button
                onClick={handleClickSubscribeNow}
                className="btn-subscribe px-5 py-3 mt-5 d-none d-sm-none d-md-block"
              >
                Subscribe now
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="subs-container d-block d-sm-block d-md-none">
        <div className="d-grid gap-2 col-6 mx-auto">
          <Button
            onClick={handleClickSubscribeNow}
            className="btn-subscribe mt-3"
          >
            Subscribe now
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductHero;
