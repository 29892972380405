import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import workArrangement from "../../../../../../Data/company/workArrangement.json";
import cities from "../../../../../../assets/global/cities.json";
import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../../../../../components/Form/CustomInput";
import CustomSelect from "../../../../../../components/Form/CustomSelect";
import CustomRichText from "../../../../../../components/Form/CustomRichText";
import CustomCreatableSelect from "../../../../../../components/Form/CustomCreatableSelect";
import moment from "moment";
import { RequestMethod } from "../../../../../../Utilities/Reusable/Method/RequestMethod";
import Loader from "../../../../../../layouts/loader/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import Config from "../../../../../../Config/config";
const JobInformation = ({ editData, isViewJob, getGeneratedData }) => {
  const {
    register,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useFormContext();
  //   console.log(editData);
  const [list, setList] = useState([]);
  const [formattedBenefits, setFormattedBenefits] = useState([]);
  // const aiInfo = useSelector((state) => state.reducer?.aimlInfo?.isOn);
  const aiInfo = useSelector(
    (state) => state.reducer.feature.isfeatures.withAI
  );
  // console.log("AIII", aiInfo);
  const [isGenBtnLoading, setIsGenBtnLoading] = useState(false);
  const autoPopulateFields = async () => {
    setValue("benefits", "");
    setFormattedBenefits("");
    setValue("jobDescription", "");
    setValue("jobOverview", "");
    setValue("qualification", "");
    getGeneratedData({
      content: {
        skillSet: [],
        dialect: [],
      },
    });

    const jobPostReqQuery = {
      jobTitle: getValues("jobTitle"),
    };

    if (!jobPostReqQuery.jobTitle) {
      setValue("benefits", "");
      setFormattedBenefits("");
      setValue("jobTitle", "");
      setValue("jobDescription", "");
      setValue("jobOverview", "");
      setValue("qualification", "");
      getGeneratedData({ content: "empty" });
      return;
    }

    try {
      setIsGenBtnLoading(true);
      const jobPostResponse = await axios.get(
        `${Config.ai}/api/genai/jobpost`,
        { params: { JobDetails: jobPostReqQuery.jobTitle } }
      );

      if (!jobPostResponse.data.content.jobDescription) {
        console.log("Sdfsdf");
        getGeneratedData({ content: "nullData" });
      } else {
        // localStorage.setItem("renderJD", false);
        getGeneratedData(jobPostResponse.data);

        console.log(jobPostResponse.data);

        const benefits = jobPostResponse?.data.content?.benefits.map(
          (value) => ({
            label: value,
            value,
            __isNew__: true,
          })
        );

        setValue("benefits", benefits);
        setFormattedBenefits(benefits);
        setValue("jobTitle", jobPostResponse.data.content.jobTitle);
        setValue("jobDescription", jobPostResponse.data.content.jobDescription);
        setValue("jobOverview", jobPostResponse.data.content.jobOverview);
        setValue("qualification", jobPostResponse.data.content.qualification);
      }

      setIsGenBtnLoading(false);
    } catch (error) {
      // localStorage.removeItem("renderJD");
      setIsGenBtnLoading(false);
      console.log("Error fetching data:", error.status, error.data);
    }
  };

  useEffect(() => {
    if (editData) {
      setValue("jobTitle", editData.jobTitle);
      setValue("jobType", editData.jobType);
      setValue("workArrangement", editData.workArrangement);
      setValue(
        "startTime",
        moment(editData.startTime, "hh:mm A").format("HH:mm")
      );
      setValue("endTime", moment(editData.endTime, "hh:mm A").format("HH:mm"));
      setValue("officeAddress", editData.officeAddress);
      setValue("city", editData.city);
      setValue("minSalary", editData.minSalary);
      setValue("maxSalary", editData.maxSalary);
      setValue("jobOverview", editData.jobOverview);
      setValue("jobDescription", editData.jobDescription);
      setValue("qualification", editData.qualification);
      const benefits = editData.benefits?.map((value) => ({
        label: value,
        value,
        __isNew__: true,
      }));

      setValue("benefits", benefits);
      setFormattedBenefits(benefits);
    }
  }, [editData, setValue]);

  console.log(isViewJob && editData?.jobTitle !== "");
  const validationPattern = /^[a-zA-Z0-9-_./&\s]*$/;

  const aiTooltip = (
    <Tooltip id="ai-tooltip">
      For better job details generation, kindly fill up first the job title,
      Alternatively, you can fill any fields to strengthen the generation
      results.
    </Tooltip>
  );
  return (
    <div>
      {" "}
      <h4 className="create-job__title border-bottom pb-3 mb-4 mt-5 ">
        JOB INFORMATION
      </h4>
      <Row>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Job Title"
            type="text"
            {...register("jobTitle")}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            required={true}
            readOnly={!editData?.jobTitle ? false : true}
          />
        </Form.Group>
        {!editData && aiInfo && (
          <div className="col-sm-12 col-md-6 ">
            <br />
            <Button
              className="btn btn-generate__jobdetail mt-2"
              onClick={() => autoPopulateFields()}
              disabled={isGenBtnLoading}
            >
              {isGenBtnLoading ? (
                <Loader />
              ) : (
                <>
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faWandMagicSparkles}
                  />
                  AI Generate Job Details
                </>
              )}
            </Button>

            <div className="ai-chatbot-tooltip_mod d-md-inline-flex mx-3 my-2 ">
              <OverlayTrigger placement="top" overlay={aiTooltip}>
                <div className="tooltip-circle">
                  <FontAwesomeIcon className="info-png" icon={faInfo} />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}
        {/* <Button onClick={() => autoPopulateFields()}>Generate</Button> */}
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Job Type"
            type="text"
            {...register("jobType")}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomSelect
            errors={errors}
            label="Work Arrangement"
            {...register("workArrangement")}
            defaultOption="Select to choose"
            data={workArrangement}
            readOnly={isViewJob && true}
            required
          />
        </Form.Group>
        <Row
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mt-2 mb-4 d-flex"
        >
          <CustomInput
            errors={errors}
            label="Start Time"
            type="time"
            {...register("startTime")}
            className="company-input-lg-width col-md-6"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
          <CustomInput
            errors={errors}
            label="End Time"
            type="time"
            {...register("endTime")}
            // defaultValue={editData?.endTime || ""}
            className="company-input-lg-width col-md-6"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
        </Row>
        <Form.Group
          as={Col}
          sm="12"
          md="8"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Office Address"
            type="text"
            {...register("officeAddress")}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="4"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomSelect
            errors={errors}
            label="City"
            {...register("city")}
            defaultOption="Select to choose"
            data={cities}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Minimum Salary"
            type="text"
            {...register("minSalary", {
              valueAsNumber: true,
            })}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="6"
          controlId="validationCustom01"
          className="mb-4"
        >
          <CustomInput
            errors={errors}
            label="Max Salary"
            type="text"
            {...register("maxSalary", {
              valueAsNumber: true,
            })}
            className="company-input-lg-width"
            classNameLabel="company-input-title"
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="12"
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <CustomRichText
            label="Job Overview"
            errors={errors}
            control={control}
            {...register("jobOverview")}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="12"
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <CustomRichText
            label="Job Description"
            errors={errors}
            control={control}
            {...register("jobDescription")}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="12"
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <CustomRichText
            label="Job Qualification"
            errors={errors}
            control={control}
            {...register("qualification")}
            required={true}
            readOnly={isViewJob && true}
          />
        </Form.Group>
        <Form.Group
          as={Col}
          sm="12"
          md="12"
          className="mb-3"
          controlId="exampleForm.ControlTextarea1"
        >
          <CustomCreatableSelect
            label="Benefits"
            name="benefits"
            control={control}
            defaultValue={formattedBenefits}
            rules={{ required: "Benefits are required" }}
            classNameLabel="benefits-label"
            className="benefits-select"
            errors={errors}
            required
            readOnly={isViewJob && true}
            characterLimit={100}
            maxItems={50}
            validationPattern={validationPattern}
          />
        </Form.Group>
      </Row>
    </div>
  );
};

export default JobInformation;
