import React, { useState, useEffect, useCallback } from "react";
import "../../assets/scss/FormProfile.scss";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import PhoneInput from "react-phone-number-input";
import ListofCountries from "../../assets/global/ListofCountries.json";
import axios from "axios";
import WarningModal from "../WarningModal";
import QuestionModal from "../QuestionModal";
import RequiredModal from "../RequiredModal";
import SuccessModal from "../SuccessModal";
import { useNavigate } from "react-router-dom";
import { List } from "react-content-loader";
import Loader from "../../layouts/loader/Loader";
import { useAuthToken } from "../../Utilities/Auth/Token";
import ValidatedProfileRoutes from "../../routes/ValidatedProfileRoutes";
import Config from "../../Config/config";
import { useSelector } from "react-redux";
import { updateProfile } from "../../services/Actions/profileSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchRegionMunicipalityProvince } from "../../Utilities/Fetch/Location";
const FormProfile = () => {
  const token = useAuthToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getPubId = localStorage.getItem("pub_id");
  const talentInfo = useSelector(
    (state) => state.reducer.profile?.currentProfile
  );
  const location = useLocation();
  console.log(talentInfo);
  // User Information
  const [lastName, setLastName] = useState(
    talentInfo.lastName === "N/A" || !talentInfo.lastName
      ? ""
      : talentInfo.lastName
  );
  const [firstName, setFirstName] = useState(
    talentInfo.firstName === "N/A" || !talentInfo.firstName
      ? ""
      : talentInfo.firstName
  );
  const [middleName, setMiddleName] = useState(
    talentInfo.middleName === "N/A" ? "" : talentInfo.middleName
  );
  const [contactNumber, setContactNumber] = useState(
    talentInfo.contactNumber === "N/A" ? "+63" : talentInfo.contactNumber
  );
  const [email, setEmail] = useState(talentInfo.email || "");
  const [address, setAddress] = useState(
    talentInfo.address === "N/A" || !talentInfo.address
      ? ""
      : talentInfo.address
  );
  const [birthday, setBirthday] = useState(
    talentInfo.birthday === "N/A" || !talentInfo.birthday
      ? ""
      : talentInfo.birthday
  );
  const [postal, setPostal] = useState(
    talentInfo.postal === "N/A" ? "" : talentInfo.postal
  );

  const [municipality, setMunicipality] = useState(
    talentInfo.municipality === "N/A" ? "" : talentInfo.municipality
  );
  const [country, setCountry] = useState("Philippines");
  const [region, setRegion] = useState(
    talentInfo.region === "N/A" || !talentInfo.region ? "" : talentInfo.region
  );
  const [province, setProvince] = useState(
    talentInfo.province === "N/A" ? "" : talentInfo.province
  );
  const [regionOptions, setRegionOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const [errors, setErrors] = useState({
    lastName: "",
    firstName: "",
    email: "",
    contactNumber: "",
    birthday: "",
    municipality: "",
    middleName: "",
    address: "",
    country: "",
    region: "",
    province: "",
    postal: "",
  });
  // Empty dependency array ensures this effect runs only once

  function checkIfProfileHasEmptyProps(profile) {
    if (profile != null && Object.keys(profile).length !== 0) {
      for (const property in profile) {
        if (
          profile[property] == null ||
          profile[property] === "" ||
          profile[property] === "N/A"
        )
          return false;
      }
      return true;
    }
    return false;
  }

  useEffect(() => {
    const profileData = {
      lastName: lastName,
      firstName: firstName,
      contactNumber: contactNumber,
      email: email,
      birthday: birthday,
      address: address,
      country: country,
      region: region,
      province: province,
      municipality: municipality,
      postal: postal,
    };

    localStorage.setItem("profile", JSON.stringify(profileData));

    const validated = checkIfProfileHasEmptyProps(profileData);

    if (location.pathname === "/talent/profile" && !initialValuesLoaded) {
      setInitialValuesLoaded(true);
      setTimeout(() => {
        setModalOpen5(!validated);
      }, 2000);
    }
  }, [talentInfo]);

  //State variables for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleModal2 = useCallback(() => {
    setModalOpen2((prevModalOpen2) => !prevModalOpen2);
  }, []);

  const toggleModal4 = () => {
    setModalOpen4(!modalOpen4);
  };

  const toggleModal5 = () => {
    setModalOpen5(!modalOpen5);
  };

  const handleLogoutClick = () => {
    localStorage.clear(); // Clear all local storage
    const cevent = new CustomEvent("onChangeUserType", {
      detail: null,
    });
    document.dispatchEvent(cevent);
    navigate("/logintalent");
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${Config.nxtApi}/api/talent/myprofile`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       if (response.data.code === "0") {
  //         const data = response.data.data[0];

  //         const profileData = {
  //           lastName: data.lastName,
  //           firstName: data.firstName,
  //           contactNumber: data.contactNumber,
  //           email: data.email,
  //           birthday: data.birthday,
  //           address: data.address,
  //           country: data.country,
  //           region: data.region,
  //           province: data.province,
  //           municipality: data.municipality,
  //           postal: data.postal,
  //         };
  //         console.log(profileData);
  //         localStorage.setItem("profile", JSON.stringify(profileData));

  //         const validated = checkIfProfileHasEmptyProps(profileData);

  //         if (getPubId && validated) {
  //           navigate(`/talent/job-list-details/${getPubId}`);
  //         }

  //         setModalOpen5(!validated);

  //         // Update the state variables with the received data
  //         setLastName(data.lastName);
  //         setFirstName(data.firstName);
  //         setMiddleName(data.middleName === "N/A" ? "" : data.middleName);
  //         setEmail(data.email);
  //         setBirthday(data.birthday);
  //         setAddress(data.address);
  //         setContactNumber(data.contactNumber);
  //         setRegion(data.region);
  //         setMunicipality(data.municipality === "N/A" ? "" : data.municipality);
  //         setProvince(data.province === "N/A" ? "" : data.province);
  //         setPostal(data.postal === "N/A" ? "" : data.postal);
  //       } else {
  //         console.error("An error occurred:", response.data.message);
  //         toggleModal2();
  //       }
  //     } catch (error) {
  //       console.error("An error occurred:", error);
  //     } finally {
  //       setIsLoading(false); // Mark loading as complete
  //     }
  //   };

  //   fetchData();
  // }, [getPubId, navigate, setIsLoading, setModalOpen5, token]);
  // // Include getPubId in the dependency array if it's used inside the useEffect

  // useEffect(() => {
  //   // Fetch data from API
  //   fetch(`${Config.nxtApi}/api/talent/region`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Set region options to component state
  //       const options = data.regions.map((region) => ({
  //         name: region.name,
  //         reg_code: region.reg_code,
  //       }));
  //       setRegionOptions(options);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [setRegionOptions]);

  // useEffect(() => {
  //   const selectedRegion = regionOptions.find(
  //     (option) => option.name === region
  //   );
  //   if (selectedRegion) {
  //     const regCode = selectedRegion.reg_code;
  //     fetch(`${Config.nxtApi}/api/talent/province?reg_code=${regCode}`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         // // Check if data.provinces is an array
  //         const provinces = data.provinces.map((province) => ({
  //           name: province.name,
  //           prov_code: province.prov_code,
  //         }));
  //         setProvinceOptions(provinces);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     setProvinceOptions([]); // If no region is selected, set the province options to an empty array
  //   }
  // }, [region, regionOptions]); // add regionOptions as a dependency here

  // useEffect(() => {
  //   if (province === "") {
  //     setMunicipalityOptions([]);
  //   } else if (province) {
  //     const selectedProvince = provinceOptions.find(
  //       (option) => option.name === province
  //     );
  //     if (selectedProvince) {
  //       const provCode = selectedProvince.prov_code;
  //       fetch(`${Config.nxtApi}/api/talent/municipality?prov_code=${provCode}`)
  //         .then((response) => response.json())
  //         .then((data) => {
  //           const municipalityList = data.municipality.map((municipality) => ({
  //             name: municipality.name,
  //             prov_code: municipality.prov_code,
  //             mun_code: municipality.mun_code, // include the mun_code property
  //           }));
  //           setMunicipalityOptions(municipalityList);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     } else {
  //       setMunicipalityOptions([]);
  //     }
  //   }
  // }, [province, provinceOptions]);

  const validateField = (value, regex, fieldName) => {
    if (!value || value.trim() === "") {
      return `${fieldName} is required`;
    } else if (!regex.test(value.trim())) {
      return `Enter a valid ${fieldName}`;
    }
    return "";
  };
  const handleValidation = async () => {
    const lastNameRegex = /^[a-zA-ZñÑ]+( [a-zA-ZñÑ]+){0,2}$/;
    const firstNameRegex = /^[a-zA-ZñÑ]+( [a-zA-ZñÑ]+){0,2}$/;
    const middleNameRegex = /^[a-zA-ZñÑ]+( [a-zA-ZñÑ]+){0,2}$/;
    const addressRegex = /^[#.0-9a-zA-ZñÑ\s,-]+$/;
    const contactNumberRegex =
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{5}$/;
    const now = new Date();
    const minAge = 18;
    const dobDate = new Date(birthday);
    const diff = now.getTime() - dobDate.getTime();
    const age = diff / (963.6 * 60 * 60 * 24 * 365);
    const postalRegex = /^\d{4,5}(?:[-\s]\d{4})?$/; // Define the postal code regex pattern

    const errors = {
      lastName: validateField(lastName, lastNameRegex, "Last Name"),
      firstName: validateField(firstName, firstNameRegex, "First Name"),

      contactNumber: validateField(
        contactNumber,
        contactNumberRegex,
        "Contact Number"
      ),
      address: validateField(address, addressRegex, "Address"),
      birthday:
        birthday === undefined || birthday.trim() === ""
          ? "Birthday is required"
          : "",
      region:
        region === undefined || region.trim() === ""
          ? "Region is required"
          : "",
      postal:
        postal === undefined || postal.trim() === ""
          ? "Postal is required"
          : "",
      province:
        province === undefined ||
        province.trim() === "" ||
        province.trim() === "undefined"
          ? "Province is required"
          : "",
      municipality:
        municipality === undefined ||
        municipality.trim() === "" ||
        municipality.trim() === "undefined"
          ? "Municipality is required"
          : "",
    };

    if (middleName && !middleNameRegex.test(middleName)) {
      errors.middleName = "Middle Name is invalid";
    }
    if (age <= minAge) {
      errors.birthday = `You must be at least ${minAge} years old to proceed`;
    }
    // Check postal code only if it's not empty
    if (postal && !postalRegex.test(postal)) {
      errors.postal = "Postal code is invalid";
    }

    const isValid =
      Object.values(errors).every((error) => error === "") && age >= minAge;
    setErrors(errors);
    return isValid; // Return whether there are any validation errors
  };

  // Submiti
  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsUpdate(true);

    const isValid = await handleValidation();

    if (!isValid) {
      const errorField = document.querySelector(".profile-container");
      if (errorField) {
        errorField.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
      setIsUpdate(false);
      return;
    }

    const updateData = {
      lastName: lastName,
      firstName: firstName,
      middleName: middleName || "N/A",
      contactNumber: contactNumber,
      email: email,
      birthday: birthday,
      address: address,
      municipality: municipality,
      country: country,
      region: region,
      province: province,
      postal: postal || "N/A",
    };

    axios
      .post(`${Config.nxtApi}/api/talent/updateprofile`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.code === "0") {
          dispatch(updateProfile(updateData));
          toggleModal();
          setIsUpdate(false);
        } else {
          toggleModal2();
        }
      });
  };

  const handleProvinceChange = (e) => {
    setProvince(e.target.value);
    setMunicipality("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchRegionMunicipalityProvince(
        token,
        region,
        province,
        municipality
      );

      setRegionOptions(data.region);
      setProvinceOptions(data.province);
      setMunicipalityOptions(data.municipality);
    };

    fetchData();
  }, [region, province, municipality]);

  return (
    <>
      <div className="profile-container">
        <div className="profile-header">
          <h1>Basic Information</h1>
          <hr className="line" />
        </div>

        <div className="profile-name">
          <FormGroup className="formInput">
            <Label className="label-text" for="lastName">
              Last Name<span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={lastName}
              maxLength={32}
              invalid={!!errors.lastName}
              onChange={(e) => setLastName(e.target.value)}
              onFocus={() => setErrors({ ...errors, lastName: "" })}
            />
            {errors.lastName && (
              <FormFeedback className="error-msg" color="danger">
                {errors.lastName}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="formInput">
            <Label className="label-text" for="firstName">
              First Name<span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={firstName}
              maxLength={32}
              invalid={!!errors.firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onFocus={() => setErrors({ ...errors, firstName: "" })}
            />
            {errors.firstName && (
              <FormFeedback className="error-msg" color="danger">
                {errors.firstName}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="formInput">
            <Label className="label-textOptional" for="middleName">
              Middle Name <span className="label-optional">(Optional)</span>
            </Label>
            <Input
              type="text"
              name="middleName"
              id="middleName"
              value={middleName}
              maxLength={32}
              invalid={!!errors.middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
            {errors.middleName && (
              <FormFeedback className="error-msg" color="danger">
                {errors.middleName}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="profile-contact">
          <FormGroup className="email-container">
            <Label className="label-text" for="email">
              Email Address<span className="required-field">*</span>
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={64}
              disabled
            />
          </FormGroup>
          <FormGroup className="phone-container">
            <Label className="label-text" for="contactNumber">
              Phone Number<span className="required-field">*</span>
            </Label>
            <div className="formInput">
              <PhoneInput
                id="contactNumber"
                name="contactNumber"
                label=""
                // style={{ width: 300 }}
                addInternationalOption={false}
                countries={["PH"]}
                countryCallingCodeEditable={false}
                defaultCountry="PH"
                maxLength={16}
                value={contactNumber}
                onChange={(value, country) => {
                  console.log(value);
                  if (!contactNumber) {
                    setContactNumber("+63" + value);
                  } else {
                    setContactNumber(value);
                  }
                }}
                onFocus={() => setErrors({ ...errors, contactNumber: "" })}
                onKeyPress={(event) => {
                  if (event.key === "0") {
                    setContactNumber((prevNumber) => {
                      if (prevNumber && prevNumber.length >= 4) {
                        if (prevNumber[3] === "0") {
                          return "+639" + prevNumber.slice(4);
                        } else {
                          if (prevNumber.length <= 12) {
                            return prevNumber + "0";
                          } else {
                            return prevNumber;
                          }
                        }
                      } else {
                        return "+639";
                      }
                    });
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <FormFeedback
              className="error-msg"
              color="danger"
              style={{ display: "block" }}
            >
              {errors.contactNumber}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="birthday-container">
            <Label className="label-textOptional" for="middleName">
              Birthday<span className="required-field">*</span>
            </Label>
            <Input
              type="date"
              name="bday"
              id="bday"
              value={birthday}
              maxLength={32}
              invalid={!!errors.birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
            {errors.birthday && (
              <FormFeedback className="error-msg" color="danger">
                {errors.birthday}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="profile-address">
          <FormGroup className="formInput">
            <Label className="label-text" for="lastName">
              Address<span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              name="address"
              id="address"
              value={address}
              maxLength={64}
              invalid={!!errors.address}
              onChange={(e) => setAddress(e.target.value)}
              onFocus={() => setErrors({ ...errors, address: "" })}
            />
            {errors.address && (
              <FormFeedback className="error-msg" color="danger">
                {errors.address}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup className="formInput">
            <Label className="label-text" for="country">
              Country<span className="required-field">*</span>
            </Label>
            <Input
              type="select"
              name="country"
              id="countrySelect"
              value={country}
              invalid={errors.country !== "" && country === ""}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option disabled value="">
                Select a Country
              </option>
              <option>Philippines</option>
            </Input>
            {errors.country && (
              <FormFeedback className="label-subText" color="danger">
                {errors.country}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="ph-input-container">
            <Label className="company-input-title" for="region">
              Region<span className="required-field">*</span>
            </Label>
            <Input
              type="select"
              name="region"
              id="regionSelect"
              value={region}
              invalid={!!errors.region && region === ""}
              onChange={(e) => setRegion(e.target.value)}
            >
              <option disabled value="">
                Select a Region
              </option>
              {regionOptions.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Input>
            {errors.region && (
              <FormFeedback className="error-msg" color="danger">
                {errors.region}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
        <div className="profile-address">
          <FormGroup className="ph-input-container">
            <Label className="company-input-title" for="province">
              Province / District<span className="required-field">*</span>
            </Label>
            <Input
              type="select"
              name="province"
              id="provinceSelect"
              value={province}
              invalid={!!errors.province}
              onChange={handleProvinceChange}
            >
              <option value="">Select a Province</option>
              {provinceOptions.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Input>
            {errors.province && (
              <FormFeedback className="error-msg" color="danger">
                {errors.province}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup className="formInput">
            <Label className="label-textOptional" for="municipality">
              City / Municipality
              <span className="required-field">*</span>
            </Label>
            <Input
              type="select"
              name="municipality"
              id="municipalitySelect"
              value={municipality}
              invalid={!!errors.municipality}
              onChange={(e) => setMunicipality(e.target.value)}
            >
              <option value="">Select a Municipality</option>
              {municipalityOptions.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Input>
            {errors.municipality && (
              <FormFeedback className="error-msg" color="danger">
                {errors.municipality}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup className="formInput">
            <Label className="label-textOptional" for="postal">
              Postal Code
              <span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              name="postalCode"
              id="postalCode"
              value={postal}
              maxLength={5}
              invalid={!!errors.postal}
              onChange={(e) => setPostal(e.target.value)}
            />
            {errors.postal && (
              <FormFeedback className="error-msg" color="danger">
                {errors.postal}
              </FormFeedback>
            )}
          </FormGroup>
        </div>
        <Button
          className="update-talent-profile-btn"
          onClick={handleProfileSubmit}
        >
          {isUpdate ? <Loader /> : "Update Profile"}
        </Button>

        <SuccessModal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          iconImage={require("../../assets/images/SuccessIcon.png")}
          modalLabel="Success"
          modalSublabel="Successfully updated your profile"
          confirmButtonText="Ok"
          onConfirm={() => window.location.reload()}
        />
        <WarningModal
          modalOpen={modalOpen2}
          toggleModal={toggleModal2}
          iconImage={require("../../assets/images/WarningIcon.png")}
          modalLabel="Warning"
          modalSublabel="Invalid entered data. Please double check the form and try again."
          confirmButtonText="Ok"
          onConfirm={handleLogoutClick}
        />
        <WarningModal
          modalOpen={modalOpen4}
          toggleModal={toggleModal4}
          iconImage={require("../../assets/images/WarningIcon.png")}
          modalLabel="Warning"
          modalSublabel="Internal Server Error"
          confirmButtonText="Ok"
          onConfirm={toggleModal4}
        />
        <RequiredModal
          modalOpen={modalOpen5}
          toggleModal={toggleModal5}
          // iconImage={require("../../assets/images/WarningIcon.png")}
          modalLabel="Please Update"
          modalSublabel="Your next career move is just a step away. Complete the essential fields and enjoy the journey."
          confirmButtonText="OK"
          onConfirm={toggleModal5}
        />
      </div>
    </>
  );
};

export default FormProfile;
