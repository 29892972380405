import React, { useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import "../../../assets/scss/Talent/Layout/MyResume/Modal/DocumentCertModal.scss";
import axios from "axios";
import SuccessModal from "../../SuccessModal";
import WarningModal from "../../WarningModal";
import Loader from "../../../layouts/loader/Loader";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";

const DocumentCertModal = ({ isOpen, close }) => {
  const token = useAuthToken();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const apiUpload = process.env.REACT_APP_S3UPLOAD_API;
  const [documentTitle, setdocumentTitle] = useState("");
  const [documentName, setdocumentName] = useState("");
  const [validityDate, setvalidityDate] = useState("");
  const [certificate, setCertificate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");

  //State variables for modal
  const [warningModalOpen, setwarningModalOpen] = useState(false);
  const [successModalOpen, setsuccessModalOpen] = useState(false);

  const toggleWarning = () => {
    setwarningModalOpen(!warningModalOpen);
  };

  const toggleSuccess = () => {
    setsuccessModalOpen(!successModalOpen);
  };

  const [errors, setErrors] = useState({
    certificate: "",
    documentTitle: "",
    documentName: "",
    validityDate: "",
  });

  const validateField = (value, regex, fieldName) => {
    if (!value || value.trim() === "") {
      return `${fieldName} is required`;
    } else if (!regex.test(value.trim())) {
      return `Enter a valid ${fieldName}`;
    }
    return "";
  };

  const validations = async () => {
    const stringNumberRegex = /^[a-zA-Z]+([a-zA-Z]+){0,2}$/;
    const stringCVTitle = /^[a-zA-Z]+(?: +[a-zA-Z]+)*$/;

    const numberRegex = /^[0-9]{4}$/;
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    const allowedFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/png",
      "image/jpeg",
    ];

    const errors = {
      documentTitle: validateField(documentTitle, stringCVTitle, "CV Title"),
      documentName: validateField(documentName, stringNumberRegex, "CV Name"),
      validityDate: validateField(validityDate, numberRegex, "Validity Date"),
      certificate: !certificate ? "File is required" : "",
    };

    if (certificate) {
      if (certificate.size > maxSize) {
        errors.certificate = "Certificate size should not exceed 5MB";
      } else if (!allowedFormats.includes(certificate.type)) {
        errors.certificate =
          "Invalid file format. Allowed formats: .pdf, .doc, .docx, .png, .jpg";
      }
    }

    setErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (!hasErrors) {
      try {
        const formData = new FormData();
        formData.append("file", certificate);

        const response = await axios.post(
          `${Config.nxtApi}/api/talent/uploadcertificate`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );

        clickSaveDocumentCert(response.data.fileName);
      } catch (error) {
        console.log("error", error.response);
      }
    }
  };

  const handleValidityDateChange = (event) => {
    const inputValue = event.target.value;
    const maxLength = 4;

    if (inputValue.length <= maxLength) {
      setvalidityDate(inputValue);
      setErrors({ ...errors, validityDate: "" });
    } else {
      setErrors({
        ...errors,
        validityDate: `Max length exceeded (${maxLength} characters)`,
      });
    }
  };

  const handleManageClick = () => {
    window.location.reload();
  };

  const certificateChange = (e) => {
    const file = e.target.files[0];
    setCertificate(file);
    // if (file && file.size <= 5000000) {
    //   setCertificate(file);
    //   setErrors({
    //     ...errors,
    //     certificate: "",
    //   });
    // } else {
    //   setCertificate(file);
    //   setErrors({
    //     ...errors,
    //     certificate: "File size exceeds the limit of 5MB.",
    //   });
    // }
  };

  const uploadDocumentCert = async (e) => {
    setIsLoading(true);
    await validations();
    setIsLoading(false);
  };

  const clickSaveDocumentCert = async (fileName) => {
    const payload = {
      documentTitle: documentTitle,
      documentName: documentName,
      validityDate: validityDate,
      filePath: fileName,
    };
    try {
      await axios
        .post(`${Config.nxtApi}/api/talent/fill-up-certificate`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          toggleSuccess();
        })
        .catch((error) => {});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={close}
        centered
        className="modal-container"
      >
        <ModalHeader
          className="modal-header-container"
          toggle={() => {
            close();
          }}
        >
          Online Document
        </ModalHeader>
        <ModalBody className="modal-body-container">
          <FormGroup>
            <Label>
              Title <span className="required-field">*</span>
            </Label>
            <Input
              type="text"
              value={documentTitle}
              invalid={!!errors.documentTitle}
              onFocus={() => setErrors({ ...errors, documentTitle: "" })}
              onChange={(event) => {
                setdocumentTitle(event.target.value);
              }}
            />

            {errors.documentTitle ? (
              <FormFeedback className="error-msg" color="danger">
                {errors.documentTitle}
              </FormFeedback>
            ) : null}
          </FormGroup>

          <div className="name-validity">
            <FormGroup>
              <Label>
                Document Name <span className="required-field">*</span>
              </Label>
              <Input
                type="text"
                value={documentName}
                invalid={!!errors.documentName}
                onFocus={() => setErrors({ ...errors, documentName: "" })}
                onChange={(event) => {
                  setdocumentName(event.target.value);
                }}
              />

              {errors.documentName ? (
                <FormFeedback className="error-msg" color="danger">
                  {errors.documentName}
                </FormFeedback>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>
                Validity Date <span className="required-field">*</span>
              </Label>
              <Input
                type="number"
                value={validityDate}
                invalid={!!errors.validityDate}
                maxLength={4}
                placeholder="XXXX"
                onFocus={() => setErrors({ ...errors, validityDate: "" })}
                onChange={handleValidityDateChange}
              />
              {errors.validityDate ? (
                <FormFeedback className="error-msg" color="danger">
                  {errors.validityDate}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </div>

          <Input
            type="file"
            accept=".pdf,.doc,.docx,.png,.jpg"
            invalid={!!errors.certificate}
            onFocus={() => setErrors({ ...errors, certificate: "" })}
            onChange={certificateChange}
          />
          {errors.certificate ? (
            <FormFeedback className="error-msg" color="danger">
              {errors.certificate}
            </FormFeedback>
          ) : null}
          <label htmlFor="cv-upload" className="cv-label">
            Upload Certificate (Max size: 5MB)
          </label>
        </ModalBody>
        <ModalFooter className="modal-footer-container">
          <Button
            className="cancel"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button className="save" onClick={uploadDocumentCert}>
            {isLoading ? <Loader /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
      <SuccessModal
        modalOpen={successModalOpen}
        toggleModal={toggleSuccess}
        iconImage={require("../../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Certificate is successfully added"
        confirmButtonText="Ok"
        onConfirm={handleManageClick}
      />
      <WarningModal
        modalOpen={warningModalOpen}
        toggleModal={toggleWarning}
        iconImage={require("../../../assets/images/WarningIcon.png")}
        modalLabel="Warning"
        modalSublabel="Please attach you CV here"
        confirmButtonText="Confirm"
        onConfirm={toggleWarning}
      />
    </div>
  );
};

export default DocumentCertModal;
