// import React from "react";

// import GetInTouch from "./Content/GetInTouch";
// import ContactUsForm from "./Content/ContactUsForm";
// import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
// import Footer from "../../LandingPage/Components/Footer";
// import CustomContainer from "../../../layouts/Container/CustomContainer";
// import Image from "../../../components/UI/Image";
// import { TabTitle } from "../../../Utilities/FunctionTitle";

// const Contact = () => {
//   TabTitle("NXT | Contact Us");
//   return (
//     <div>
//       <LandingNavbar />

//       <Image
//         className="full-width-height"
//         src={require("../../../assets/images/Background/Outside/contactUs.jpg")}
//       />
//       <CustomContainer className="customContainerWhiteBg">
//         <ContactUsForm />
//       </CustomContainer>
//       <CustomContainer className="getInTouchContainer">
//         <GetInTouch />
//       </CustomContainer>

//       <Footer />
//     </div>
//   );
// };

// export default Contact;

import React, { useEffect, useState } from "react";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import Image from "../../../components/UI/Image";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import ContactUsForm from "./Content/ContactUsForm";
import GetInTouch from "./Content/GetInTouch";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import Footer from "../../LandingPage/Components/Footer";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";
import { Navigate, useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  const [redirectToMaintenance, setRedirectToMaintenance] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
          console.log("Maintainance VAlue", sysparVal);

          if (sysparVal === "Y") {
            setRedirectToMaintenance(true);
          }
        });
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, []);

  if (redirectToMaintenance) {
    return <Navigate to="/under-maintenance" />;
  }

  TabTitle("NXT | Contact Us");

  return (
    <div>
      <LandingNavbar />

      <Image
        className="full-width-height"
        src={require("../../../assets/images/Background/Outside/contactUs.png")}
      />
      <CustomContainer className="customContainerWhiteBg">
        <ContactUsForm />
      </CustomContainer>
      <CustomContainer className="getInTouchContainer">
        <GetInTouch />
      </CustomContainer>

      <Footer />
    </div>
  );
};

export default Contact;
