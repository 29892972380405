import axios from "axios";
import Config from "../../../../Config/config";

var qs = require("qs");
export const fetchApplicantData = async (dataIndex, token) => {
  const apiKey = process.env.REACT_APP_CLIENT_API;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const search = qs.parse((window.location || { search: "" }).search, {
    ignoreQueryPrefix: true,
  });
  const payload = {
    applicantId: search.talentId,
    workHistory: [
      {
        sortKeys: "desc",
        numRow: 1000,
      },
    ],
    certificateHistory: [
      {
        sortKeys: "desc",
        numRow: 1000,
      },
    ],
  };

  try {
    const response = await axios.post(
      `${Config.nxtApi}/api/client/talentinfo`,
      payload,
      {
        headers,
      }
    );
    const applicantData = response.data[dataIndex].data;
    return applicantData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchApplicant = async (talentId, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const payload = {
    applicantId: talentId,
    workHistory: [
      {
        sortKeys: "desc",
        numRow: 1000,
      },
    ],
    certificateHistory: [
      {
        sortKeys: "desc",
        numRow: 1000,
      },
    ],
  };

  try {
    const response = await axios.post(
      `${Config.nxtApi}/api/client/talentinfo`,
      payload,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
