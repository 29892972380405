import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from "reactstrap";
import "../../assets/scss/Talent/Layout/BannerTalent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheck,
  faEnvelope,
  faLocationDot,
  faMobile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import SuccessModal from "../SuccessModal";
import Loader from "../../layouts/loader/Loader";
import { useSelector } from "react-redux";
import WarningModal from "../WarningModal";
import { LogoutAndClearProfile } from "../../services/Actions/logoutAction";
import { useDispatch } from "react-redux";
import Config from "../../Config/config";
import { updateProfile } from "../../services/Actions/profileSlice";

const BannerTalent = () => {
  const apiKey = process.env.REACT_APP_TALENT_API;
  //
  const dispatch = useDispatch();
  const token = useSelector((state) => state.reducer.user.currentUser?.token);

  const navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [isProfileAvailable, setIsProfileAvailable] = useState(null);
  const [address, setAddress] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [modalError, setModalError] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const modalLogout = () => {
    handleLogoutClick();
    setModalError(false);
  };

  const handleLogoutClick = async () => {
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(LogoutAndClearProfile());
      localStorage.clear(); // Clear all local storage
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      console.log(error);
      navigate("/");
    }
  };

  const toggleModalErr = () => {
    localStorage.clear();
    setModalError(!modalError);
  };

  const handleComingsoon = () => {
    navigate("/comingsoonin");
  };

  const showSuccessModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const up = () => {
    document.getElementById("upload-image").click();
  };

  const clickUploadPicture = async (event) => {
    const file = event.target.files[0];

    setisLoading(true);
    if (file) {
      if (file.size <= 5000000) {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async function () {
          const maxWidth = 720;
          const maxHeight = 720;

          if (img.width <= maxWidth && img.height <= maxHeight) {
            await handleConfirmUpload(file);
          } else {
            setisLoading(false);

            setUploadError("Image dimensions exceed the limit of 720px.");

            setTimeout(() => {
              setUploadError(null);
            }, 5000);
          }
        };
      } else {
        setisLoading(false);

        setUploadError("File size exceeds the limit of 5MB.");

        setTimeout(() => {
          setUploadError(null);
        }, 5000);
      }
    } else {
      setisLoading(false);
      setUploadError("No file selected.");
      setTimeout(() => {
        setUploadError(null);
      }, 5000);
    }
  };

  const handleConfirmUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadProfile = await axios.post(
        `${Config.nxtApi}/api/talent/uploadprofile`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      if (uploadProfile.data.code === "0") {
        const payload = { fileName: uploadProfile.data.fileName };

        dispatch(updateProfile({ profilePic: uploadProfile.data.fileName }));

        const uploadProfilePic = await axios
          .post(`${Config.nxtApi}/api/talent/updateprofilepic`, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            if (response.data.code === "0") {
              setIsProfileAvailable(
                `${
                  Config.nxtApi
                }/api/talent/profile-pic?fileName=${encodeURIComponent(
                  payload.fileName
                )}`
              );
            } else {
              console.log("err update profile pic");
            }
            setisLoading(false);
          })
          .catch((err) => {
            setisLoading(false);
            console.log("/updateprofilepic Err: ", err);
          });

        // updateProfile();
      } else {
        setisLoading(false);
        console.log("err");
      }
    } catch (error) {
      setisLoading(false);
      if (error.response.status === 400) {
        setUploadError(error.response.data.message);
      }
      console.log("Error uploading profile picture:", error);
    }
  };

  const talentInfo = useSelector(
    (state) => state.reducer.profile?.currentProfile
  );

  useEffect(() => {
    setIsProfileAvailable(
      `${Config.nxtApi}/api/talent/profile-pic?fileName=${encodeURIComponent(
        talentInfo.profilePic
      )}`
    );
  }, [talentInfo.profilePic]);

  // useEffect(() => {
  //   axios
  //     .get(`${Config.nxtApi}/api/talent/myprofile`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data.code === "0") {
  //         const data = response.data.data[0];

  //         setLastName(data.lastName);
  //         setFirstName(data.firstName);
  //         setMiddleName(data.middleName);
  //         setEmail(data.email);
  //         setAddress(data.address);
  //         setContactNumber(data.contactNumber);

  //         if ("profilePic" in data && data.profilePic !== null) {
  //           setIsProfileAvailable(
  //             `${apiKey}/profile-pic?fileName=${encodeURIComponent(
  //               data.profilePic
  //             )}`
  //           );
  //         } else {
  //           setIsProfileAvailable("");
  //         }
  //       } else {
  //         setModalErrorMsg(
  //           response.data.message || response.data || "An error occurred."
  //         );
  //         setShowErrorModal(true);
  //         console.error("An error occurred:", response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setShowErrorModal(true);
  //       setModalErrorMsg(
  //         error.response
  //           ? error.response.data.message || error.response.data
  //           : error.message
  //       );
  //       console.error("An error occurred:", error);
  //     });
  // }, [apiKey, token]);

  return (
    <div className="background-banner-talent">
      <div className="background-overlay">
        <Container>
          <div className="banner-container">
            {" "}
            <div className="upload-image-container-outer">
              {" "}
              <div className="upload-image-container">
                {/* <input type="file" id="upload-image" hidden onClick={handleComingsoon}></input> */}
                <input
                  id="upload-image"
                  type="file"
                  accept=".png,.jpeg,.jpg"
                  hidden
                  onChange={clickUploadPicture}
                ></input>
                <div className="banner-image-bg" onClick={up}>
                  {encodeURIComponent(talentInfo.profilePic) === "undefined" ? (
                    <>
                      <FontAwesomeIcon
                        className="banner-icon-user"
                        icon={faUser}
                      />
                    </>
                  ) : (
                    <>
                      {isLoading ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        <>
                          <img
                            className="banner-profile"
                            crossOrigin="anonymous"
                            src={isProfileAvailable}
                          ></img>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="banner-camera-bg" onClick={up}>
                  <FontAwesomeIcon
                    className="banner-icon-camera"
                    icon={faCamera}
                  />
                </div>
              </div>
              <p style={{ marginBottom: "0" }}>Photo Size sample 720 x 720</p>
              <div
                className="error-msg"
                style={{ margin: "10px 0", textAlign: "center" }}
              >
                {uploadError}
              </div>
            </div>
            <div className="banner-profile-information">
              <div>
                <h1>
                  {talentInfo.lastName +
                    ", " +
                    talentInfo.firstName +
                    (talentInfo.middleName !== "N/A"
                      ? " " + talentInfo.middleName
                      : "")}
                </h1>
                <div className="banner-user-info">
                  <div>
                    <p>
                      <FontAwesomeIcon
                        className="banner-user-info-icon"
                        icon={faLocationDot}
                      />
                      {talentInfo.address}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="banner-user-info-icon"
                      />
                      Applicant
                    </p>
                  </div>
                  <div>
                    <p>
                      <FontAwesomeIcon
                        icon={faMobile}
                        className="banner-user-info-icon"
                      />
                      {talentInfo.contactNumber}
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="banner-user-info-icon"
                      />
                      {talentInfo.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-freemium-btn">
              {/* <Button className="freemium-btn">
                <h3>FREEmium Account</h3>
                <div className="freemium-btn-desc">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="banner-user-info-icon"
                  />
                  <p>Unlimited Job Posting</p>
                </div>
              </Button> */}
            </div>
          </div>
          {/* {isLoading ? (
            <div>
              <ContentLoader
                speed={2}
                width={400}
                height={160}
                viewBox="0 0 400 160"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
                <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                <circle cx="20" cy="20" r="20" />
              </ContentLoader>
            </div>
          ) : ()} */}
          {/* <> */}

          {/* </>
          )} */}
        </Container>
      </div>
      <WarningModal
        modalOpen={showErrorModal}
        toggleModal={toggleModalErr}
        modalLabel="Warning"
        modalSublabel={modalErrorMsg}
        confirmButtonText="Confirm"
        onConfirm={modalLogout}
      />
      <SuccessModal
        modalOpen={showConfirmationModal}
        toggleModal={showSuccessModal}
        iconImage={require("../../assets/images/SuccessIcon.png")}
        modalLabel="Success"
        modalSublabel="Successfully updated your profile"
        confirmButtonText="Confirm"
        onConfirm={handleConfirmUpload}
      />
    </div>
  );
};

export default BannerTalent;
