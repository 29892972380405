import React from "react";
import GettingStarted from "./Content/Talent/GettingStarted";
import TalentSignUp from "./Content/Talent/TalentSignUp";
import GettingStartedClient from "./Content/Client/GettingStartedClient";
import "../../../assets/scss/Page/Outer/FAQ/_faq.scss";
import StartJobSearch from "./Content/Talent/StartJobSearch";
import ApplyForJob from "./Content/Talent/ApplyForJob";
import MonitorStatus from "./Content/Talent/MonitorStatus";
import TalentProfileSetup from "./Content/Talent/TalentProfileSetup";
import ResumeSetup from "./Content/Talent/ResumeSetup";
import TalentProfileLogin from "./Content/Talent/TalentProfileLogin";
import ValidateEmail from "./Content/Talent/ValidateEmail";
import ClientSignup from "./Content/Client/ClientSignup";
import ValidateEmailClient from "./Content/Client/ValidateEmailClient";
import ClientProfileLogin from "./Content/Client/ClientProfileLogin";
import ClientProfileSetup from "./Content/Client/ClientProfileSetup";
import ManageJob from "./Content/Client/ManageJob";
import ViewingApplicants from "./Content/Client/ViewingApplicants";
import ViewingSubscription from "./Content/Client/ViewingSubscription";
import UpdateApplicationStatus from "./Content/Client/UpdateApplicationStatus";
import Reminders from "./Content/Client/Reminders";
import AddJob from "./Content/Client/AddJob";
import ReminderTroubleShooting from "./Content/Talent/ReminderTroubleShooting";
import MultiUser from "./Content/Client/MultiUser";

const TextPerChoice = ({ selectedLink }) => {
  const messages = {
    gettingStarted: <GettingStarted />,
    talentSignup: <TalentSignUp />,
    startJobSearch: <StartJobSearch />,
    applyForJob: <ApplyForJob />,
    monitor: <MonitorStatus />,
    talentProfileSetup: <TalentProfileSetup />,
    resumeSetup: <ResumeSetup />,
    talentProfileLogin: <TalentProfileLogin />,
    validateEmailTalent: <ValidateEmail />,
    reminderTroubleShoot: <ReminderTroubleShooting />,
    multiUser: <MultiUser />,

    gettingStartedClient: <GettingStartedClient />,
    clientSignup: <ClientSignup />,
    validateEmailClient: <ValidateEmailClient />,
    clientProfileLogin: <ClientProfileLogin />,
    clientProfileSetup: <ClientProfileSetup />,
    manageJob: <ManageJob />,
    viewingApplicants: <ViewingApplicants />,
    updatingApplicationStatus: <UpdateApplicationStatus />,
    viewingSubscription: <ViewingSubscription />,
    reminders: <Reminders />,
    addJob: <AddJob />,
  };

  return (
    <div className="messageContainer">
      {selectedLink && messages[selectedLink]}
    </div>
  );
};

export default TextPerChoice;
