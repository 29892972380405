import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export const SubscriptionFunction = () => {
  const location = useLocation();
  const getPlanPrice = (billing) => {
    switch (billing) {
      case "3":
        return 18000;
      case "6":
        return 36000;
      default:
        return 72000;
    }
  };

  const getPlanName = (option) => {
    switch (option) {
      case "freemium":
        return "Basic";
      case "premium":
        return "Premium";
      case "premium+":
        return "Premium+";
      case "elite":
        return "Elite";
      default:
        return "";
    }
  };

  const getButtonLabel = ({
    subscribedToFreeTrial,
    expiredFreeTrial,
    existingTransaction,
  }) => {
    if (existingTransaction) {
      return "PENDING SUBSCRIPTION";
    } else if (
      !subscribedToFreeTrial &&
      !expiredFreeTrial &&
      !existingTransaction
    ) {
      return "FREE 30 DAY TRIAL";
    } else {
      return "SUBSCRIBE NOW";
    }
  };

  const getFreeTrialVerified = ({
    subscribedToFreeTrial,
    expiredFreeTrial,
    existingTransaction,
  }) => {
    if (subscribedToFreeTrial && expiredFreeTrial && !existingTransaction) {
      return "premVerified";
    } else if (
      subscribedToFreeTrial &&
      !expiredFreeTrial &&
      !existingTransaction
    ) {
      return "freemTrialSubs";
    } else {
      return "freeSubs";
    }
  };

  const calculatePrice = (amount, daysLeft) => {
    if (daysLeft > 30) {
      daysLeft = 30;
    }

    const addOnCostPerDay = amount / 30;

    const today = moment();
    const futureDate = today.clone().add(daysLeft, "days");
    const remainingDays = futureDate.diff(moment().startOf("day"), "days");
    const proratedPrice = addOnCostPerDay * remainingDays;

    return proratedPrice;
  };

  const [price_planName, setPrice_planName] = useState("");
  const [feature_pricing, setFeature_pricing] = useState("");
  const getURLSubsPlanPrice = () => {
    const queryParams = new URLSearchParams(location.search);
    const price = queryParams.get("psrc");
    const planName = queryParams.get("plan");
    const feature = queryParams.get("feature");
    if (price && planName) {
      setPrice_planName({ price, planName });
    }

    if (feature) {
      setFeature_pricing("pricing");
    }

    console.log(price, planName);
  };

  return {
    getPlanPrice,
    getPlanName,
    getButtonLabel,
    getFreeTrialVerified,
    calculatePrice,
    getURLSubsPlanPrice,
    price_planName,
    feature_pricing,
  };
};
