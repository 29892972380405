import React from "react";

const ManageJob = () => {
  return (
    <div>
      <p>
        By utilizing NXT, you can streamline the process and enhance the overall
        experience of your candidates, ultimately contributing to the success of
        your recruitment efforts. Efficiently managing job applications is
        essential in finding the right talent for your organization.
      </p>
      <p>Once logged on to your Client account</p>
      <ol>
        <li>Click on ‘Profile’</li>
        <li>Click on ‘Manage Job’</li>
        <li>
          A summary of your posted jobs will be displayed with the following
          summary details:
        </li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Job Title</li>
          <li>Link to list of Applicants: click on ‘View Applicants’</li>
          <li>Job posting Status</li>
          <li>Option to edit the post: click on edit icon (pencil button)</li>
          <li>Option to delete the post; trash can icon</li>
        </ol>
      </ol>
    </div>
  );
};

export default ManageJob;
