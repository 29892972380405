import React from "react";
import AdminSidebar from "../../../../layouts/Sidebar/Admin/AdminSidebar";
import Navbar from "../../../../layouts/Navbar";
import WhiteContainer from "../../../../layouts/Container/WhiteContainer";
import CreateJobForm from "./CreateJobForm/CreateJobForm";

const AttendanceTrackingCreateJob = () => {
  return (
    <div className="admin">
      <div className="sidebar">
        <AdminSidebar />
      </div>
      <div className="navbarContent">
        <Navbar layout="admin" />
        <div className="pageContainer create--job">
          <WhiteContainer>
            <CreateJobForm />
          </WhiteContainer>
        </div>
      </div>
    </div>
  );
};

export default AttendanceTrackingCreateJob;
