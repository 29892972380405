import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomButton from "../../../../../components/UI/CustomButton";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchSysparValue } from "../../../../../Utilities/Fetch/FetchSysparValue";
import { useNavigate } from "react-router-dom";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import axios from "axios";
import Config from "../../../../../Config/config";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";
const InputEmail = ({ email, id, onSubmit }) => {
  const [errorMessage, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [siteKey, setSiteKey] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const schema = z
    .object({
      email: z.string().nonempty("Email is required").email(),
      confirmEmail: z.string().nonempty("Confirm Email is required").email(),
    })
    .refine((data) => data.email === data.confirmEmail, {
      message: "Emails do not match",
      path: ["confirmEmail"], // Set the path to confirmPassword field
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  useEffect(() => {
    fetchSysparValue("GOOGLE_CAPTCHA_SITE_KEY", setSiteKey);
  }, []);

  function onChange(value) {
    setErrorMsg("");
    setRecaptcha(value);
  }

  const onSubmitWithReCAPTCHA = async (data) => {
    setIsBtnLoading(true);

    if (recaptcha) {
      // const fbResponse = await axios.post(
      //   `${Config.nxtApi}/api/user/sso/signup/fb`,

      //   { email: data.email }
      // );
      // const response = await axios.post(
      //   `${Config.nxtApi}/api/user/email/signup`,
      //   {
      //     email: data.email,
      //   }
      // );
      // const sendCode = await axios.post(
      //   `${Config.nxtApi}/api/user/sso/signup/fb/email/sendcode`,
      //   { id: id, email: data.email }
      // );

      const sendCode = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/sso/signup/fb/email/sendcode",
        { id: id, email: data.email }
      );
      onSubmit(data.email, id);
    } else {
      setErrorMsg("Invalid Recaptcha");
    }

    setIsBtnLoading(false);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}>
        <div className="loginTitleLeft">
          <h1>Enter Your Email</h1>
        </div>
        <CustomInput
          errors={errors}
          label="Please enter your email address"
          type="email"
          {...register("email")}
          placeholder="Email Address"
          className="formInputGroup"
        />
        <CustomInput
          errors={errors}
          label="Confirm email address"
          type="email"
          {...register("confirmEmail")}
          placeholder="Confirm Email Address"
          className="formInputGroup"
        />
        {siteKey && (
          <div className="captcha">
            <label>Captcha:</label>
            <ReCAPTCHA sitekey={siteKey} onChange={onChange} />
            {recaptcha === "" ||
              (recaptcha === null && (
                <CustomMessage label="Please verify captcha" />
              ))}

            {errorMessage && <CustomMessage label={errorMessage} />}
          </div>
        )}
        <div className="btnContainerMrgn">
          <CustomButton
            type="submit"
            name="Submit"
            className="customLRButton"
          />
        </div>
      </form>
    </div>
  );
};

export default InputEmail;
