import axios from "axios";
import React, { useState } from "react";
import Config from "../../../../../Config/config";
import { useForm } from "react-hook-form";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import CustomButton from "../../../../../components/UI/CustomButton";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";

const ConfirmEmail = ({ email, id, reg_type, onSubmit }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitEmailPassword = async (data) => {
    setIsBtnLoading(true);
    setLoginError("");

    const endpoint =
      reg_type === "fb"
        ? `/api/user/sso/signup/fb/email/verifycode`
        : reg_type === "fb"
        ? `/api/user/email/signup/verifycode`
        : `/api/user/email/signup/verifycode`;

    const ref_id = id;

    const requestData = {
      ...data,
      email,
    };

    if (reg_type === "google") {
      requestData.ref_id = ref_id; // Add ref_id to requestData if it exists
    }

    if (reg_type === "fb") {
      requestData.id = id; // Add ref_id to requestData if it exists
    }

    console.log(reg_type);
    try {
      console.log("first");
      // const response = await axios.post(endpoint, requestData);
      const response = await RequestMethod(
        "post",
        "nxtApi",
        endpoint,
        requestData,
        null,
        null
      );
      console.log(response);
      setIsBtnLoading(false);
      setResponseMsg(response.data.message || response.data.code);
    } catch (error) {
      console.log(error);
      setIsBtnLoading(false);

      setLoginError(error?.data?.message || error.data?.details[0]?.message);
    }
  };

  const resendCode = async () => {
    setLoginError("");
    try {
      // const sendCode = await axios.post(
      //   `${Config.nxtApi}/api/user/email/signup/sendcode`,
      //   {
      //     email,
      //   }
      // );
      const sendCode = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/email/signup/sendcode",
        { email }
      );
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        setLoginError("Still a valid code");
      } else {
        setLoginError("Server error");
      }
    }
  };

  const handleNext = () => {
    onSubmit();
  };

  return (
    <div className="emailRegStep2">
      <form>
        <div className="loginTitleLeft">
          <h1>Confirm Your Email</h1>
          <p>
            We've sent a confirmation email to <span>{email}</span>
          </p>
        </div>

        <div className="inputOtpGroup">
          <div className="verifyCodeContainer">
            <input {...register("code")} placeholder="OTP" />
            <label
              className="verifyCode"
              onClick={handleSubmit(submitEmailPassword)}
            >
              Verify Code
            </label>
          </div>
        </div>
        <p className="resendEmail" onClick={resendCode}>
          Resend Confirmation Email
          {responseMsg && (
            <CustomMessage label={responseMsg} className="successMsg" />
          )}
          {errors.code && <CustomMessage label={errors.code.message} />}
          {loginError && <CustomMessage label={loginError} />}
        </p>

        <CustomButton
          type="submit"
          name="Submit"
          className="customLRButton"
          onClick={handleNext}
          disabled={!responseMsg}
          // isLoading={isBtnLoading}
        />
      </form>
    </div>
  );
};

export default ConfirmEmail;
