import React, { useEffect, useState } from "react";
import Config from "../../../../../Config/config";
import { useForm } from "react-hook-form";
import survey from "../../../../../Data/dropdown/survey.json";
import socialMedia from "../../../../../Data/dropdown/socialMedia.json";
import influencer from "../../../../../Data/dropdown/influencer.json";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomSelect from "../../../../../components/Form/CustomSelect";
import CustomModal from "../../../../../components/UI/Modal";
import CustomButton from "../../../../../components/UI/CustomButton";
import { checkIsReferralCodeValid } from "../../../../../Utilities/Fetch/ReferralCode";

const ReferralInformation = ({
  userType,
  ref_id,
  id,
  onSubmit,
  reg_type,
  step,
  backStep,
  onError,
  signUpData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [responseMsg, setResponseMsg] = useState("");
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const storedRefId = localStorage.getItem("ref_id");
    const storedSurvey = localStorage.getItem("survey");
    const storedUserType = localStorage.getItem("userType");

    if (storedRefId) {
    }
    if (storedSurvey) {
      setSelectedOption(storedSurvey);
      setValue("survey", storedSurvey);
    }
    if (storedUserType) {
    }
  }, [setValue]);

  const storeInLocalStorage = (data) => {
    localStorage.setItem("survey", data.survey || "");

    localStorage.setItem("userType", userType || "");
  };

  // const handleSignUp = async (data) => {
  //   setErrorMsg(null); // Clear any previous error messages
  //   setBtnIsLoading(true);

  //   // Handle survey value
  //   switch (data.survey) {
  //     case "Others (Please specify.)":
  //       data.survey = data.others;
  //       break;
  //     case "Social Media":
  //       data.survey = data.socialMedia;
  //       break;
  //     case "Content Creator / Influencer":
  //       data.survey = data.influencer;
  //       break;
  //     default:
  //       break;
  //   }

  //   // Validate survey selection
  //   if (
  //     data.survey === "Others (Please specify.)" ||
  //     data.survey === "Social Media" ||
  //     data.survey === "Content Creator / Influencer"
  //   ) {
  //     setErrorMsg("Please select an option");
  //     return;
  //   }

  //   // Validate referral code if provided
  //   if (data.referralCode.trim() !== "") {
  //     data.referralCode = data.referralCode.trim();
  //     try {
  //       let referralResponse = await checkIsReferralCodeValid(
  //         data.referralCode
  //       );

  //       // Check if referralResponse is valid and has the expected structure
  //       if (
  //         referralResponse &&
  //         referralResponse.code === "1" &&
  //         referralResponse.message === "Expired referral code"
  //       ) {
  //         setBtnIsLoading(false);
  //         setResponseMsg("Expired referral code");
  //         onError("Expired referral code"); // Pass error to parent component
  //         return;
  //       }
  //     } catch (error) {
  //       console.error("Referral code validation error:", error);
  //       setBtnIsLoading(false);
  //       setResponseMsg("Referral code validation failed.");
  //       onError("Referral code validation failed.");
  //       return;
  //     }
  //   } else {
  //     delete data.referralCode;
  //   }

  //   const { socialMedia, others, influencer, ...requestData } = data;

  //   try {
  //     const requestBody = {
  //       ...requestData,
  //       userType,
  //     };

  //     // Check if ref_id is passed as a prop, if not, get it from localStorage
  //     const storedRefId = localStorage.getItem("ref_id");
  //     if (reg_type === "google" && id) {
  //       requestBody.ref_id = id;
  //     } else if (reg_type === "fb" && ref_id) {
  //       requestBody.ref_id = ref_id;
  //     } else if (reg_type === "email" && ref_id) {
  //       requestBody.ref_id = ref_id;
  //     } else if (!requestBody.ref_id && storedRefId) {
  //       requestBody.ref_id = storedRefId;
  //     }

  //     // Save the ref_id to localStorage
  //     localStorage.setItem("ref_id", requestBody.ref_id || "");

  //     const response = await fetch(`${Config.nxtApi}/api/user/signup`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestBody),
  //     });

  //     const responseBody = await response.text();
  //     const responseObject = JSON.parse(responseBody);
  //     const message = responseObject.message;
  //     const code = responseObject.code;
  //     console.log(responseBody, message, code);
  //     const res = responseBody.split("|");
  //     const signUpInfo = JSON.parse(res[1]).data;
  //     setErrMsg(message);
  //     const isResError = Object.keys(signUpInfo).includes("err");
  //     console.log(signUpInfo, isResError);
  //     // Check if the response status is 422 (Unprocessable Entity)
  //     if (code === "1" || (code === 1 && message === "Expired referral code")) {
  //       console.log(code);
  //       setBtnIsLoading(false);
  //       setResponseMsg(message); // Show the error message in the UI
  //       onError(message); // Pass the error to the parent component
  //       return;
  //     }

  //     if (!responseBody) {
  //       throw new Error("No response body from the server.");
  //     }

  //     if (res.length > 1) {
  //       setBtnIsLoading(false);

  //       if (!isResError) {
  //         signUpData(signUpInfo);
  //         onSubmit();
  //       } else {
  //         setResponseMsg(signUpInfo.err);
  //         if (signUpInfo.err === "User already exists.") {
  //           onError(signUpInfo.err);
  //         }
  //       }
  //     }

  //     setBtnIsLoading(false);
  //   } catch (error) {
  //     setBtnIsLoading(false);
  //     console.error("Signup error:", JSON.parse(error));
  //     console.log(errMsg);
  //     // // Check if the error is a JSON error
  //     // if (error.message === "Failed to fetch") {
  //     //   setResponseMsg("Network error. Please try again later.");
  //     //   onError("Network error. Please try again later.");
  //     // } else if (error.message === "User already exists.") {
  //     //   setResponseMsg(error.message);
  //     //   onError(error.message);
  //     // } else {
  //     //   setResponseMsg("An unexpected error occurred.");
  //     //   onError("An unexpected error occurred.");
  //     // }
  //   }
  // };

  const handleSignUp = async (data) => {
    setErrorMsg(null);
    setBtnIsLoading(true);

    if (data.survey === "Others (Please specify.)") {
      data.survey = data.others;
    } else if (data.survey === "Social Media") {
      data.survey = data.socialMedia;
    } else if (data.survey === "Content Creator / Influencer") {
      data.survey = data.influencer;
    }

    if (data.referralCode?.trim()) {
      data.referralCode = data.referralCode.trim();
      try {
        const referralResponse = await checkIsReferralCodeValid(
          data.referralCode
        );
        if (
          referralResponse?.code === "1" &&
          referralResponse?.message === "Expired referral code"
        ) {
          setBtnIsLoading(false);
          setResponseMsg("Expired referral code");
          onError("Expired referral code");
          return;
        }
      } catch (error) {
        console.error("Referral code validation error:", error);
        setBtnIsLoading(false);
        setResponseMsg("Referral code validation failed.");
        onError("Referral code validation failed.");
        return;
      }
    } else {
      delete data.referralCode;
    }

    const { socialMedia, others, influencer, ...requestData } = data;

    const requestBody = {
      ...requestData,
      userType,
    };

    const storedRefId = localStorage.getItem("ref_id");
    if (reg_type === "google" && id) {
      requestBody.ref_id = id;
    } else if (reg_type === "fb" && ref_id) {
      requestBody.ref_id = ref_id;
    } else if (reg_type === "email" && ref_id) {
      requestBody.ref_id = ref_id;
    } else if (!requestBody.ref_id && storedRefId) {
      requestBody.ref_id = storedRefId;
    }

    localStorage.setItem("ref_id", requestBody.ref_id || "");
    try {
      const response = await fetch(`${Config.nxtApi}/api/user/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...requestData, userType, ref_id: storedRefId }),
      });

      const rawResponseBody = await response.text();
      console.log("Raw Response Body:", rawResponseBody);

      const splitResponses = rawResponseBody.split("|").filter(Boolean);
      let successData;

      for (const jsonPart of splitResponses) {
        try {
          const parsedResponse = JSON.parse(jsonPart);
          if (parsedResponse.code === "0") {
            successData = parsedResponse.data;
            break;
          } else if (parsedResponse.code === "1") {
            setResponseMsg(parsedResponse.message);
            onError(parsedResponse.message);
            setBtnIsLoading(false);
            return;
          }
        } catch (error) {
          console.error("Parsing error for part:", jsonPart, error);
        }
      }

      if (!successData) {
        setResponseMsg("Unexpected server response.");
        onError("Unexpected server response.");
        setBtnIsLoading(false);
        return;
      }

      console.log("Signup success:", successData);
      signUpData(successData);
      onSubmit();

      // const responseBody = await response.text();
      // console.log("Raw Response Body:", responseBody);
      // setErrMsg(responseBody);
      // if (!response.ok) {
      //   const parseErr = JSON.parse(errMsg);
      //   console.log(parseErr);
      //   const { code, message } = parseErr;
      //   console.log(code, message);
      //   setResponseMsg(message);
      //   onError(message);
      //   setBtnIsLoading(false);
      //   return;
      // }

      // let responseObject;
      // try {
      //   responseObject = JSON.parse(responseBody);
      // } catch (parseError) {
      //   console.error("JSON Parsing Error:", parseError, responseBody);
      //   setResponseMsg("Invalid server response.");
      //   onError("Invalid server response.");
      //   setBtnIsLoading(false);
      //   return;
      // }

      // const { message, code } = responseObject;
      // if (code === "1" || (code === 1 && message === "Expired referral code")) {
      //   setResponseMsg(message);
      //   onError(message);
      //   setBtnIsLoading(false);
      //   return;
      // }

      // const resBody = await response.text();
      // const resObj = JSON.parse(resBody);
      // const msg = resObj.message;
      // const resCode = resObj.code;
      // console.log(resObj, msg, resCode);
      // const res = resBody.split("|");

      // if (res.length > 1) {
      //   signUpData(responseObject.data);
      //   onSubmit();
      // }
    } catch (error) {
      console.error("Signup error:", error, errMsg);
      console.log(errMsg);
      handleError(error);
    } finally {
      setBtnIsLoading(false);
    }
  };

  const handleError = (error) => {
    if (errMsg) {
      const parseErr = JSON.parse(errMsg);
      console.log(parseErr);
      const { code, message } = parseErr;
      console.log(code, message);
      setResponseMsg(message);
      onError(message);
    } else {
      if (error.message === "Failed to fetch") {
        // setResponseMsg(
        //   "Failed to fetch data please reload page or click this link."
        // );
        onError("Failed to fetch data please reload page.");
      } else {
        console.log(error);
        // setResponseMsg(error.message);
        // onError(error.message);
      }
    }
  };

  const handleSelectChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    localStorage.setItem("survey", newSelectedOption);
    storeInLocalStorage({
      survey: newSelectedOption,
      referralCode: selectedOption,
    });
  };

  const handleModalClose = () => {
    setModalShow(false);
    if (userType === "applicant") {
      navigate("/talent/profile");
    } else if (userType === "client") {
      navigate("/client/company-profile");
    }
  };

  const handleBackBtn = () => {
    backStep = 4;
    onSubmit(userType, backStep);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <div className="loginTitleLeft">
          <h1>Referral Information</h1>
        </div>
        <CustomSelect
          label="How did you learn about NXT?"
          data={survey}
          errors={errors}
          {...register("survey", { required: "Survey is required" })}
          defaultOption="Select to choose"
          value={selectedOption}
          onChange={handleSelectChange}
          className="formInputGroup"
        />
        {selectedOption === "Social Media" && (
          <CustomSelect
            label="Social Media"
            data={socialMedia}
            errors={errors}
            {...register("socialMedia", {
              required: "Social Media is required",
            })}
            defaultOption="Select to choose"
            className="formInputGroup"
          />
        )}
        {selectedOption === "Content Creator / Influencer" && (
          <CustomSelect
            label="Content Creator / Influencer"
            data={influencer}
            errors={errors}
            {...register("influencer", {
              required: "Content Creator / Influencer is required",
            })}
            defaultOption="Select to choose"
            className="formInputGroup"
          />
        )}
        {selectedOption === "Others (Please specify.)" && (
          <CustomInput
            label="Other (please specify)"
            errors={errors}
            type="text"
            {...register("others", { required: "Others is required" })}
            className="formInputGroup"
            onChange={(e) => {
              localStorage.setItem("others", e.target.value);
              storeInLocalStorage({
                survey: selectedOption,
                referralCode: e.target.value,
              });
            }}
          />
        )}
        {errorMsg && <CustomMessage label={errorMsg} />}
        <CustomInput
          label="Referral Code"
          placeholder="Referral Code"
          errors={errors}
          type="text"
          defaultValue={""}
          {...register("referralCode")}
          className="formInputGroup"
          labelOptional="(Optional)"
          onChange={(e) => {
            storeInLocalStorage({
              survey: selectedOption,
              referralCode: e.target.value,
            });
          }}
        />
        {responseMsg && <CustomMessage label={responseMsg} />}
        <div className="backNext">
          <CustomButton
            type="button"
            name="Back"
            className="customLRButton"
            onClick={handleBackBtn}
          />{" "}
          <CustomButton
            type="submit"
            name="Submit"
            isLoading={btnIsLoading}
            className="customLRButton"
          />
        </div>
      </form>
      <CustomModal
        buttonName="Close"
        show={modalShow}
        onHide={() => handleModalClose()}
        modalMessage="You are successfully registered"
        modalType="Success"
      />
    </div>
  );
};

export default ReferralInformation;
