import React from "react";
import { Button, Modal } from "react-bootstrap";
import "../../../assets/scss/Components/UI/Modal/_formModal.scss";
const FormModal = ({
  show,
  handleClose,
  hasBtn,
  onClickFunc,
  btnName,
  modalTitle,
  children,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="formModal">
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {hasBtn && (
        <>
          <Modal.Footer>
            <Button onClick={handleClose} className="cancelBtn">
              Cancel
            </Button>
            <Button onClick={onClickFunc} className="yellowBtn">
              {btnName}
            </Button>
          </Modal.Footer>{" "}
        </>
      )}
    </Modal>
  );
};

export default FormModal;
