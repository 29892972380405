import React from "react";

const ValidateEmail = () => {
  return (
    <div>
      <p>
        We value your security. Your account is secured with us! A validation is
        required to proceed on accessing your account.. This also functions as a
        verification on your end if your registered email address is indeed
        accessible. This is important as this email address will be used by your
        prospective employer to contact you.
      </p>
      <ol>
        <li>
          Check your registered email’s inbox, and open the email with this
          subject: ‘Welcome to Acquiro’ and click on ‘Login’
        </li>
        <li>Enter your registered email address. Click on ‘Login’</li>
        <li>
          Type in the 6-Digits OTP Code sent to your email and click on ‘Verify
          OTP’. Click on ‘Confirm’.
        </li>
      </ol>
      <p>
        In case you do not see the email from your inbox, kindly check your spam
        folder. In the event that you accidentally deleted the validation email,
        please check your email’s trash.{" "}
      </p>
    </div>
  );
};

export default ValidateEmail;
