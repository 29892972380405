import React, { ForwardRefRenderFunction, useEffect } from "react";
import CustomMessage from "./ErrorMessage";

const CustomSelect = (
  {
    id,
    required,
    name,
    data,
    placeholder,
    label,
    errors,
    className,
    defaultOption,
    onChange,
    classNameLabel,
    value,
    defaultValue,
    readOnly,
    ...props
  },
  ref
) => {
  const handleChange = (event) => {
    // Call the provided onChange callback (if any) when the select value changes
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`customInput ${className}`}>
      <label className={classNameLabel}>
        {label}
        {required && <span className="required-field">*</span>}
      </label>
      <select
        name={name}
        placeholder={placeholder}
        id={id}
        ref={ref}
        {...props}
        onChange={handleChange}
        value={value}
        disabled={readOnly}
      >
        {defaultOption && (
          <option
            value={defaultOption !== "Select to choose" ? defaultOption : ""}
            disabled
            selected
          >
            {defaultOption}
          </option>
        )}

        {data?.map((val) => (
          <option key={val.key} value={val.name || val.value || val}>
            {val.label || val.name || val.value || val}
          </option>
        ))}
      </select>

      {errors[name] && <CustomMessage label={errors[name]?.message || ""} />}
    </div>
  );
};

export default React.forwardRef(CustomSelect);
