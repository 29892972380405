// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { fetchSysparValue } from "../../Utilities/Fetch/FetchSysparValue";
// import CustomContainer from "../../layouts/Container/CustomContainer";
// import SearchFilter from "../Outside/PubJobList/JobList/Content/SearchFilter";
// import LandingNavbar from "./Components/LandingNavbar";
// import Banner from "./Components/Banner";
// import MostRecentJob from "./Components/MostRecentJob";
// import TopCompanies from "./Components/TopCompanies";
// import About from "./Components/About";
// import Career from "./Components/Career";
// import Sentence from "./Components/Sentence";
// import PostJob from "./Components/PostJob";
// import Footer from "./Components/Footer";

// const LandingPage = () => {
//   const navigate = useNavigate();
//   const api = process.env.REACT_APP_API;
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const referralCode = queryParams.get("ref");
//   const userToken = useSelector((state) => state.reducer.user.currentUser?.token);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
//           console.log("New Site Key:", sysparVal);

//           if (sysparVal === "Y") {
//             navigate("/under-maintenance");
//           } else if (referralCode) {
//             checkReferralValidity(referralCode);
//           } else if (userToken) {
//             console.log("");
//           } else {
//             // navigate("/");
//             localStorage.clear();
//           }
//         });
//       } catch (error) {
//         console.error("Error fetching syspar value:", error);
//       }
//     };

//     fetchData();
//   }, [referralCode]);

//   const checkReferralValidity = (code) => {
//     axios
//       .get(`${api}/api/referrals/${code}/validity`)
//       .then((response) => {
//         if (response.data.isValid) {
//           localStorage.setItem("referralCode", code);
//           navigate(`/registration?ref=${code}`);
//         } else {
//           navigate("/invalid-code", {
//             state: { error: response.data.error },
//           });
//         }
//       })
//       .catch((error) => {
//         console.error("Error checking referral validity:", error);
//         navigate("/invalid-code");
//       });
//   };

//   const [searchValue, setSearchValue] = useState("");
//   const handleSearch = (value) => {
//     setSearchValue(value || "");
//     navigate(`/jobs?search=${encodeURIComponent(value || "")}`);
//   };

//   return (
//     <>
//       <LandingNavbar />
//       <Banner />
//       <CustomContainer className="containerPadding">
//         <h1
//           style={{
//             textAlign: "center",
//             color: "#8046fa",
//             fontFamily: "Rubik, sans-serif",
//             fontWeight: "600",
//             fontSize: "64px",
//           }}
//         >
//           Find Your Next Job
//         </h1>
//         <h2
//           style={{
//             textAlign: "center",
//             color: "#01B5E0",
//             fontFamily: "Rubik, sans-serif",
//             marginBottom: "20px",
//             fontWeight: "400",
//             fontSize: "24px",
//           }}
//         >
//           Jobs and Careers That Best Fit You
//         </h2>
//         <SearchFilter onSearch={handleSearch} className="landingPageBtn" />
//       </CustomContainer>
//       <MostRecentJob />
//       <div id="top-companies">
//         <TopCompanies />
//       </div>
//       <div id="about">
//         <About />
//       </div>
//       <div id="career">
//         <Career />
//       </div>
//       <Sentence />
//       <div id="postjob">
//         <PostJob />
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default LandingPage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Banner from "./Components/Banner";
import Career from "./Components/Career";
import About from "./Components/About";
import Sentence from "./Components/Sentence";
import PostJob from "./Components/PostJob";
import TopCompanies from "./Components/TopCompanies";
import LandingNavbar from "./Components/LandingNavbar";
import Footer from "./Components/Footer";
import axios from "axios";
import FormRegistrationTalent from "../../components/FormTalent/FormRegistrationTalent";
import { useLocation, useNavigate } from "react-router-dom";

import CustomContainer from "../../layouts/Container/CustomContainer";
import SearchFilter from "../Outside/PubJobList/JobList/Content/SearchFilter";
import Jobs from "../Outside/PubJobList/JobList/Content/Jobs";
import { InlineShareButtons, StickyShareButtons } from "sharethis-reactjs";
import MostRecentJob from "./Components/MostRecentJob";
import { TabTitle } from "../../Utilities/FunctionTitle";
import { fetchSysparValue } from "../../Utilities/Fetch/FetchSysparValue";
import { useSelector } from "react-redux";

const LandingPage = () => {
  TabTitle("Job Search in Philippines - 2024 | NXT");
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API;
  const [isValidReferral, setIsValidReferral] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("ref");
  // const userToken =
  //   localStorage.getItem("clientToken") || localStorage.getItem("talentToken");
  const userToken = useSelector(
    (state) => state.reducer.user.currentUser?.token
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sysparVal = await fetchSysparValue("UNDER_MAINTENANCE");

        if (sysparVal === "Y") {
          navigate("/under-maintenance");
          return;
        }

        if (referralCode) {
          const userType = queryParams.get("user");
          localStorage.setItem("referralCode", referralCode);
          localStorage.setItem("userType", userType);

          if (userType === "client" || userType === "talent") {
            checkReferralValidity(referralCode);
          }

          return;
        }

        if (userToken) {
          console.log("");
          return;
        }

        localStorage.clear();
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, [referralCode]);

  const checkReferralValidity = (code) => {
    axios
      .get(
        `${api}/api/referrals/${code}/validity
      `
      )
      .then((response) => {
        if (response.data.isValid) {
          localStorage.setItem("referralCode", code);
          const userType = localStorage.getItem("userType");
          console.log(userType);
          if (userType === "client") {
            navigate(`/client/registration?ref=${code}`);
          } else if (userType === "talent") {
            navigate(`/talent/registration?ref=${code}`);
          }
        } else {
          navigate("/invalidReferral", {
            state: { error: response.data.error },
          });
        }
      })
      .catch((error) => {
        console.error("Error checking referral validity:", error);
        setIsValidReferral(false);
        navigate("/invalidReferral");
      });
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (value) => {
    setSearchValue(value || "");
    navigate(`/jobs?search=${encodeURIComponent(value || "")}`);
  };

  return (
    <>
      <LandingNavbar />

      <Banner />
      <CustomContainer className="containerPadding" id="searchJob">
        <h1
          style={{
            textAlign: "center",
            color: "#8046fa",
            fontFamily: "Rubik, sans-serif",
            fontWeight: "600",
            fontSize: "64px",
          }}
        >
          Start Your Next Career Chapter
        </h1>
        <h2
          style={{
            textAlign: "center",
            color: "#01B5E0",
            fontFamily: "Rubik, sans-serif",
            marginBottom: "20px",
            fontWeight: "400",
            fontSize: "24px",
          }}
        >
          Find Your Perfect Fit Job
        </h2>
        <SearchFilter onSearch={handleSearch} className="landingPageBtn" />
      </CustomContainer>
      <MostRecentJob />
      <div id="top-companies">
        <TopCompanies />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="career">
        <Career />
      </div>
      <Sentence />
      <div id="postjob">
        <PostJob />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
