import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../../assets/scss/Client/Layout/Subscription/Subsciption.scss";
import Stepper from "react-stepper-horizontal";
import SubsConfBasic from "./SubscriptionConfigList/SubsConfBasic";
import SubsConfPro from "./SubscriptionConfigList/SubsConfPro";
import SubsConfDetails from "./SubscriptionConfigList/SubsConfDetails";

const SubsConfig = ({ typeOfSub, subsData, selectedPlan }) => {
  return (
    <div className="subsciption-container subscription-container__mod">
      <Container>
        <div className="mb-5 stepper">
          <Stepper
            activeColor="#8046FA"
            defaultColor="#EFF0F6"
            completeColor="#8046FA"
            defaultBarColor="#EFF0F6"
            steps={[
              { title: "Select Subscription" },
              { title: "Configure Subscription" },
              { title: "Checkout" },
            ]}
            activeStep={1}
          />
        </div>
        <h2 className="text-center pb-5">Configure Subscription</h2>
        <SubsConfDetails
          subsData={subsData}
          typeOfSub={typeOfSub}
          selectedPlan={selectedPlan}
        />
      </Container>
    </div>
  );
};

export default SubsConfig;
