import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const SourcingSelection = () => {
  const navigate = useNavigate();

  return (
    <div className="sourcing-selection py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>Sourcing and Selection</h5>
            <p>
              Experience a seamless hiring process as employers leverage our
              revolutionary Sourcing and Selection feature. <br />
              <br /> Effortlessly navigate through every stage of the
              applicant's journey, managing each candidate with ease. From
              initial application to final selection, our advanced feature
              ensures a smoother and more efficient hiring process.
            </p>
            {/* <h4 className="mb-3">
              Subscribe now for exclusive
              <br /> access to this feature!
            </h4>
            <Button
              className="btn-lg me-3"
              onClick={() => navigate("/choose-plan")}
            >
              Subscribe
            </Button>
            <Button className="btn-lg" onClick={() => navigate("/choose-plan")}>
              30 Days Trial
            </Button> */}
          </div>
          <div className="col-md-6 position-relative">
            <img
              className="position-absolute bottom-0 start-0 img-fluid"
              src={require("../../../assets/images/LandingPage/TrackingSystem/sourcing-selection-group.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcingSelection;
