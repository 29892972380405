import React, { useEffect, useState } from "react";

import Footer from "../../WhatsNext/Footer/Footer";
import PinkDrops from "../../../assets/images/WhatsNext/img/JobSeekerImg/BackgroundImg/PinkDrops.png";
import Dots from "../../../assets/images/WhatsNext/img/JobSeekerImg/BackgroundImg/Dots.png";
import SunsetBackground from "../../../assets/images/WhatsNext/img/JobSeekerImg/BackgroundImg/SunsetBackground.png";
import Girl from "../../../assets/images/WhatsNext/img/JobSeekerImg/BackgroundImg/Girl.png";
import "../../../assets/scss/WhatsNext/styles.scss";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import { useLocation, useNavigate } from "react-router";
import { fetchSysparValue } from "../../../Utilities/Fetch/FetchSysparValue";
import axios from "axios";

const Index = () => {
  TabTitle("JobSeeker");

  const navigate = useNavigate();
  const handleClickSignUpTalent = () => {
    navigate("/talent/registration?ref=rJ4nGx6B", {
      state: { sendClientState: "talent" },
    });
  };

  const api = process.env.REACT_APP_API;
  const [isValidReferral, setIsValidReferral] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("ref");
  const userToken =
    localStorage.getItem("clientToken") || localStorage.getItem("talentToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSysparValue("UNDER_MAINTENANCE", (sysparVal) => {
          console.log("sysparVal:", sysparVal);

          if (sysparVal === "Y") {
            navigate("/under-maintenance");
          } else if (referralCode) {
            checkReferralValidity(referralCode);
          } else if (userToken) {
            console.log("");
          } else {
            // navigate("/");
            localStorage.clear();
          }
        });
      } catch (error) {
        console.error("Error fetching syspar value:", error);
      }
    };

    fetchData();
  }, [referralCode]);

  const checkReferralValidity = (code) => {
    axios
      .get(
        `${api}/api/referrals/${code}/validity
      `
      )
      .then((response) => {
        if (response.data.isValid) {
          localStorage.setItem("referralCode", code);
          const userType = localStorage.getItem("userType");
          console.log(userType);
          if (userType === "client") {
            navigate(`/client/registration?ref=${code}`);
          } else if (userType === "talent") {
            navigate(`/talent/registration?ref=${code}`);
          }
        } else {
          navigate("/invalidReferral", {
            state: { error: response.data.error },
          });
        }
      })
      .catch((error) => {
        console.error("Error checking referral validity:", error);
        setIsValidReferral(false);
        navigate("/invalidReferral");
      });
  };
  return (
    <div className="jobSeeker__body">
      <img className="dots__img" src={Dots} alt="Dots" />
      <div className="title-name__container">
        <h1>Find your</h1>
        <h2>
          Dream Job!
          <img src={PinkDrops} alt="pinkdrops" />
        </h2>
      </div>
      <div className="button__container">
        <button className="signup--button" onClick={handleClickSignUpTalent}>
          Sign Up Now
        </button>
      </div>
      <div>
        <p className="para__container">
          Explore abundant job opportunities offering flexible work
          arrangements. Our user-friendly and secure platform empowers job
          seekers like you.
        </p>
      </div>
      <div className="bottom--bg__container">
        <img className="sunset__bg" src={SunsetBackground} alt="sunset" />
        <img className="girl" src={Girl} alt="girl" />
      </div>
    </div>
  );
};

export default Index;
