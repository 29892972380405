import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const UpgradeContact = () => {
  return (
    <div className="upgrade__contact-label">
      <FontAwesomeIcon icon={faCommentDots} className="icon" /> For upgrades and
      assistance, please feel free to email us at{" "}
      <a href="mailto:inquiry@nxt.com.ph">inquiry@nxt.com.ph</a>.
    </div>
  );
};

export default UpgradeContact;
