import {
  faFile,
  faHouse,
  faUser,
  faUserTag,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../assets/scss/SuperAdmin/LayoutScss/SuperAdminSidebar.scss";
import CustomButton from "../../components/UI/Button";

const SuperAdminSidebar = () => {
  return (
    <div className="superAdminSidebar">
      <div className="sidebarLinkContainer">
        <img src={require("../../assets/images/nxt-logo.png")} />
        <div className="sidebarLink">
          <FontAwesomeIcon icon={faHouse} />
          <p>Dashboard</p>
        </div>
        <div className="sidebarSection">
          <h1>User Management</h1>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faUser} />
            <p>Talents</p>
          </div>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faUsers} className="icon" />
            <p>Company/Group</p>
          </div>
        </div>
        <div className="sidebarSection">
          <h1>Reports</h1>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faFile} className="icon" />
            <p>Reports</p>
          </div>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faFile} className="icon" />
            <p>Logs</p>
          </div>
        </div>
        <div className="sidebarSection">
          <h1>Admin Settings</h1>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faUserTag} className="icon" />
            <p>User Roles</p>
          </div>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faUserTag} className="icon" />
            <p>User Admin</p>
          </div>
          <div className="sidebarLink">
            <FontAwesomeIcon icon={faUserTag} className="icon" />
            <p>User Groups</p>
          </div>
        </div>
      </div>
      <div className="buttonContainer">
        <CustomButton label="LOGOUT" classNameLabel="violetButton" />
      </div>
    </div>
  );
};

export default SuperAdminSidebar;
