import React, { useEffect, useState } from "react";
import axios from "axios";
import fb from "../../../assets/images/Logo/SocMed/facebook.png";
import Config from "../../../Config/config";
import { initFacebookSDK } from "../../../Utilities/SocialMedia/facebook";
import Image from "../../../components/UI/Image";
import { RequestMethod } from "../../../Utilities/Reusable/Method/RequestMethod";

const Facebook = ({ onSubmit, onError }) => {
  const [signedRequest, setSignedRequest] = useState("");
  useEffect(() => {
    const loadFBSSO = async () => {
      if (!window.FB) {
        // If the FB object is not available, load the Facebook SDK first.
        await initFacebookSDK(); // Make sure this function loads the Facebook SDK.
      }

      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.nxtApi}/api/syspar`,
        //url: "https://ey4mvtet1g.execute-api.ap-southeast-1.amazonaws.com/latest/api/syspar",
      };

      try {
        const response = await axios.request(config);
        const data = response.data;
        const [appId] = data.filter((e) => e.code === "FB_APP_ID");

        if (appId) {
          const FB = window.FB;
          FB.init({
            appId: appId.value,
            cookie: true,
            xfbml: true,
            version: "v18.0",
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    loadFBSSO();
  }, []);

  function checkLoginState() {
    console.log("checkLoginState...");
    const FB = window.FB;
    // Called when a person is finished with the Login Button.
    FB.getLoginStatus(function (response) {
      // See the onlogin handler
      statusChangeCallback(response);
    });
  }

  function statusChangeCallback(response) {
    if (response.authResponse === null) {
      onError("No Signed Request");
    } else {
      console.log(response);
      // setSignedRequest(response.authResponse.signedRequest);
    }

    if (response.status === "connected") {
      testAPI();
    }
  }

  function testAPI() {
    const FB = window.FB;
    // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    // console.log("Welcome!  Fetching your information.... ");
    FB.api("/me", function (response) {
      // console.log("Successful login for: ", response);
      if (response.error) {
        onClickLoginFB();
      } else {
      }
    });
  }

  function onClickLoginFB() {
    const FB = window.FB;

    FB.login(
      function (response) {
        if (response.authResponse) {
          // The user has successfully logged in.
          const signedRequest = response.authResponse.signedRequest;
          // console.log("response.authResponse:", response.authResponse);

          FB.api(
            "/me",
            "GET",
            { fields: "id,name,email,last_name,first_name,middle_name" },
            function (apiResponse) {
              if (apiResponse.error) {
                // Handle errors from the API call, if any.
                console.log("API Error:", apiResponse.error);
                onError("An error occurred while fetching user data.");
              } else {
                // Insert your code to handle the successful login and user data.
                postFB(apiResponse, signedRequest);
              }
            }
          );
        } else if (response.status === "unknown") {
          // Handle when the user exits the modal or cancels the transaction.
          onError("User cancelled login or did not fully authorize.");
          console.log("User cancelled login or did not fully authorize.");
        } else {
          // Handle other possible scenarios.
          onError("An error occurred during login.");
          console.log("An error occurred during login.");
        }
      },
      { scope: "email,public_profile" }
    );
  }

  const postFB = async (response, signedRequest) => {
    const email = response.email;
    const payload = {
      public_profile: {
        first_name: response.first_name || "N/A",
        last_name: response.last_name || "N/A",
        middle_name: response.middle_name || "N/A",
      },
      id: response.id,
      name: response.name || "N/A",
      signedRequest: signedRequest,
    };

    if (email && email.trim() !== "") {
      payload.email = email;
    }

    try {
      // const fbResponse = await axios.post(
      //   `${Config.nxtApi}/api/user/sso/signup/fb`,

      //   payload
      // );

      const fbResponse = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/sso/signup/fb",
        payload,
        null,
        null
      );

      onSubmit(
        fbResponse.data.email,
        fbResponse.data.id,
        response.id,
        fbResponse.data.reg_type
      );
    } catch (error) {
      console.log(error);
      onError(error.data.message || error.data.message);
    }
  };

  function checkLoginState() {
    const FB = window.FB;
    FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  }

  return (
    <div onClick={onClickLoginFB} className="fbLogo">
      <Image src={fb} width={11.11} height={20} />
    </div>
  );
};

export default Facebook;
