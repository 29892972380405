import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import "../../../assets/scss/LandingPage/LandingNavbar.scss";
import { useLocation } from "react-router-dom";

const LandingNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <div className="landingNav" style={{ background: "#fff" }}>
      <Container>
        <Navbar expand="md" bg="none">
          <Navbar.Brand onClick={() => navigate("/")} className="nxtLogo">
            <img
              src={require(`../../../assets/images/LandingPage/nxt.png`)}
              style={{ height: "54px", width: "150px", cursor: "pointer" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="start"
            style={{ background: "#fff" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <img
                  src={require(`../../../assets/images/LandingPage/nxt.png`)}
                  style={{
                    height: "54px",
                    width: "150px",
                    cursor: "pointer",
                  }}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-grow-1 pe-3 landingNavMenu">
                <Nav.Link
                  className={url === "/" ? "act-nav" : ""}
                  onClick={() => navigate("/")}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  className={url === "/about" ? "act-nav" : ""}
                  onClick={() => navigate("/about")}
                >
                  About NXT
                </Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/product")}>
                  Product
                </Nav.Link> */}
                <Nav.Link
                  className={url === "/jobs" ? "act-nav" : ""}
                  onClick={() => navigate("/jobs")}
                >
                  Job List
                </Nav.Link>
                {/* <Nav.Link onClick={() => navigate("/product")}>
                  Product
                </Nav.Link> */}
                {/* <Nav.Link
                  onClick={() => navigate("/applicant-tracking-system")}
                >
                  Applicant tracking system

                </Nav.Link> */}
                {/* {location.pathname === "/product" ? (
                  ""
                ) : ( */}
                {/* <Nav.Link onClick={() => navigate("/pricing")}>
                  Pricing
                </Nav.Link> */}
                {/* // )} */}

                <NavDropdown title="Products">
                  <NavDropdown.Item
                    onClick={() => navigate("/product")}
                    className={url === "/product" ? "act-nav" : ""}
                  >
                    FEATURES
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/pricing")}
                    className={url === "/pricing" ? "act-nav" : ""}
                  >
                    Pricing Plans
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  title="Help"
                  id={`offcanvasNavbarDropdown-expand-md`}
                >
                  <NavDropdown.Item
                    onClick={() => navigate("/faq")}
                    className={url === "/faq" ? "act-nav" : ""}
                  >
                    FAQ<span className="text-lowercase">s</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/contact-us")}
                    className={url === "/contact-us" ? "act-nav" : ""}
                  >
                    Contact Us
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/privacy-policy")}
                    className={url === "/privacy-policy" ? "act-nav" : ""}
                  >
                    Privacy Policy
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    onClick={() => navigate("/terms-and-condition")}
                    className={url === "/terms-and-condition" ? "act-nav" : ""}
                  >
                    Terms and Conditions
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/data-deletion")}
                    className={url === "/data-deletion" ? "act-nav" : ""}
                  >
                    Data Deletion
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/cookie-policy")}
                    className={url === "/cookie-policy" ? "act-nav" : ""}
                  >
                    Cookie Policy
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    onClick={() => navigate("/terms-and-condition")}
                  >
                    Terms and Conditions
                  </NavDropdown.Item> */}
                </NavDropdown>
                {/* <Nav.Link>Career Advise</Nav.Link>
                  <Nav.Link>
                    <img
                      src={require(`../../../assets/images/LandingPage/gigster.png`)}
                      style={{
                        height: "20px",
                        width: "80px",
                        cursor: "pointer",
                        margin: "0",
                      }}
                    />
                  </Nav.Link> */}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </Container>
    </div>
  );
};

export default LandingNavbar;
