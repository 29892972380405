import { useSelector } from "react-redux";
import Config from "../Config/config";
import axios from "axios";
import { useState } from "react";
export const CVParsing = () => {
  const userId = useSelector((state) => state.reducer.user?.currentUser.userId);
  const [parseCVData, setParseCVData] = useState(null);
  const [parseCVLoad, setParseCVLoad] = useState(false);
  const parseCV = async (fileUrl) => {
    const parseCVPayload = {
      fileUrl: fileUrl,
      userId: userId,
      platform: "NXT",
    };

    try {
      setParseCVLoad(true);
      const response = await axios.post(
        `${Config.ai}/api/files/parse`,
        parseCVPayload
      );

      console.log("parseCV", response);
      console.log(response.data);
      setParseCVData(response.data);
      setParseCVLoad(false);
    } catch (err) {
      setParseCVLoad(false);
      console.log(err);
    }
  };

  console.log("asdasda", parseCVData);
  return { parseCV, parseCVData, parseCVLoad };
};
