import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentProfile: null,
  loading: false,
  error: false,
};

const profileSlice = createSlice({
  name: "currentProfile",
  initialState,
  reducers: {
    loadProfile: (state) => {
      state.loading = true;
    },
    updateProfile: (state, action) => {
      state.loading = false;
      state.currentProfile = {
        ...state.currentProfile,
        ...action.payload,
      };
    },
    getProfile: (state, action) => {
      state.loading = false;
      state.error = false;
      state.currentProfile = action.payload;
    },
    failProfile: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearProfile: (state) => {
      state.currentProfile = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  loadProfile,
  updateProfile,
  getProfile,
  failProfile,
  clearProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
