import React from "react";
import "../assets/scss/ComingSoon.scss";
import { TabTitle } from "../Utilities/FunctionTitle";

const ComingSoonInside = () => {
  TabTitle("NXT | Coming Soon");

  return (
    <div className="coming-soon">
      <div>
        <img
          src={require("../assets/images/nxt-logo.png")}
          alt="logo"
          className="nxt-logo"
        />
      </div>
      <h1>COMING SOON</h1>
      <p>
        Thank you for visiting our website! We are currently working hard to
        bring you a better experience. <br />
        Please check back soon for updates.
      </p>
      <div>
        <h4>© {new Date().getFullYear()} Acquiro. All rights reserved.</h4>
      </div>
    </div>
  );
};

export default ComingSoonInside;
