import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { SubscriptionFunction } from "../../../../../Utilities/Reusable/Function/Subscription/Subscription";

const SubsConfPremiumReceipt = ({
  subsData,
  billing,
  typeOfSub,
  selectedPlan,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { existingTransaction, expiredFreeTrial, subscribedToFreeTrial } =
  //   subsData;

  const handleGoBack = () => {
    // window.history.back();
    navigate("/client/subscription-select", {
      state: { subsSelectDetails: selectedPlan, subsData: subsData },
    });
  };

  const handleSubscriptionCheckOut = () => {
    if (location.pathname === "/admin/ats/subscription/config") {
      navigate("/admin/ats/subscription/checkout", {
        state: {
          selectedPlan: selectedPlan,
          subsData: subsData,
          billing: billing,
        },
      });
    } else {
      navigate("/client/subscription-checkout", {
        state: {
          selectedPlan: selectedPlan,
          subsData: subsData,
          billing: billing,
        },
      });
    }
  };

  const { calculatePrice } = SubscriptionFunction();
  const [proratedPrice, setProratedPrice] = useState(null);
  const getProratedPrice = () => {
    if (subsData[0].activeSubscription.current !== "freemium") {
      setProratedPrice(
        calculatePrice(
          selectedPlan.amount,
          subsData[0].activeSubscription.daysLeft
        )
      );
    }
  };

  useEffect(() => {
    getProratedPrice();
  }, [billing]);

  return (
    <>
      <Col md={4}>
        <div className="order-summary bg-white p-4">
          <h2 className="fw-bold order-summary--title">Order Summary</h2>
          <div className="p-2 receipt__background">
            <div className="d-flex">
              <div className="p-2 receipt--plan__name">
                {selectedPlan.planNameTxt}
              </div>
              <div className="p-2 ms-auto">
                ₱{" "}
                {subsData[0].activeSubscription.current !== "freemium"
                  ? proratedPrice?.toLocaleString()
                  : selectedPlan.amount.toLocaleString()}
              </div>
            </div>
            <div className="d-flex border-bottom">
              <div className="p-2 ms-auto">x {billing} months</div>
            </div>
            <div className="d-flex">
              <div className="p-2 ms-auto total">
                Total ₱{" "}
                {subsData[0].activeSubscription.current !== "freemium"
                  ? (proratedPrice * billing).toLocaleString()
                  : (selectedPlan.amount * billing).toLocaleString()}
              </div>
            </div>
          </div>
          <div className="text-center receipt--total__amount mt-3">
            <h2>
              ₱{" "}
              {subsData[0].activeSubscription.current !== "freemium"
                ? (proratedPrice * billing).toLocaleString()
                : (selectedPlan.amount * billing).toLocaleString()}
            </h2>
          </div>
          <div className="text-center mb-4 receipt--total--text">
            <small>Total</small>
          </div>
          <div className="d-grid gap-2">
            <Button
              variant=""
              className="btn-green py-3"
              onClick={handleSubscriptionCheckOut}
            >
              Continue
            </Button>
            <Button
              variant="outline-secondary"
              className="py-3 fw-bold"
              onClick={handleGoBack}
            >
              Back
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
};

export default SubsConfPremiumReceipt;
