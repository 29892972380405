import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import "../../../assets/scss/Client/Layout/BannerBreadcrumb.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthToken } from "../../../Utilities/Auth/Token";
const BannerJobListDetails = ({ jobDetails }) => {
  const navigate = useNavigate();

  const clickManageJob = () => {
    navigate("/client/manage-job");
  };

  return (
    <div className="breadcrumb-banner">
      <div className="background-overlay">
        <Container className="breadcrumb-container">
          <div className="breadcrumb-content">
            <h5>{jobDetails.jobTitle}</h5>
            <Breadcrumb listTag="div" className="breadcrumb">
              <BreadcrumbItem onClick={clickManageJob} tag="a">
                Manage Job
              </BreadcrumbItem>
              <BreadcrumbItem tag="a" className="not-pointer">
                Job Details
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerJobListDetails;
