import React from "react";

const UnlockWorld = () => {
  return (
    <div className="unlock-world py-5 text-center">
      <h5>Unlock a world of possibilities</h5>
      <p className="mt-4">
        Introducing <strong>ATS</strong> (Applicant Tracking System), your
        ultimate solution for streamlined hiring.
      </p>
      <div className="container">
        <div className="unlock-world__image-list row mt-5 py-5">
          <div className="col">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/icon-one.png")}
              className=""
            />
            <p>Centralized Applicant Management</p>
          </div>
          <div className="col">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/icon-two.png")}
              className=""
            />
            <p>
              Job Post
              <br /> Progress
              <br /> Tracking
            </p>
          </div>
          <div className="col">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/icon-three.png")}
              className=""
            />
            <p>
              Efficient
              <br /> Workflow
            </p>
          </div>
          <div className="col">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/icon-four.png")}
              className=""
            />
            <p>
              Customizable <br />
              Status
              <br /> Updates
            </p>
          </div>
          <div className="col">
            <img
              src={require("../../../assets/images/LandingPage/TrackingSystem/icon-five.png")}
              className=""
            />
            <p>User-Friendly Interface</p>
          </div>
        </div>
      </div>
      <p className="mt-4">
        <strong>ATS</strong> is here to make recruitment effortless and
        efficient. 
        <br />
        Sign-up now and enjoy these cutting-edge features for FREE
      </p>
    </div>
  );
};

export default UnlockWorld;
