import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import RequiredModal from "../components/RequiredModal";
import { SubscriptionFunction } from "../Utilities/Reusable/Function/Subscription/Subscription";

const ValidatedProfileRoutes = () => {
  const profile = JSON.parse(localStorage.getItem("profile"));
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const companyProfile = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );

  const clientProfile = {
    companyName:
      companyProfile?.companyName === "N/A" ? "" : companyProfile?.companyName,
    companySize:
      companyProfile?.companySize === "N/A" ? "" : companyProfile?.companySize,
    industry:
      companyProfile?.industry === "N/A" ? "" : companyProfile?.industry,
    address: companyProfile?.address === "N/A" ? "" : companyProfile?.address,
    country:
      companyProfile?.country === "N/A"
        ? "Philippines"
        : companyProfile?.country,
    region:
      companyProfile?.region === undefined || companyProfile?.region === "N/A"
        ? ""
        : companyProfile?.region,
    postal: companyProfile?.postal === "N/A" ? "" : companyProfile?.postal,
    firstName:
      companyProfile?.firstName === "N/A" ? "" : companyProfile?.firstName,
    email: companyProfile?.email === "N/A" ? "" : companyProfile?.email,
    lastName:
      companyProfile?.lastName === "N/A" ? "" : companyProfile?.lastName,
    contactNumber:
      companyProfile?.contactNumber === "N/A"
        ? "+63"
        : companyProfile?.contactNumber,
  };

  function checkIfTalentProfileHasEmptyProps() {
    delete profile?.middleName;
    if (profile != null) {
      for (const property in profile) {
        if (
          profile[property] == null ||
          profile[property] === "" ||
          profile[property] === "N/A"
        )
          return false;
      }
      return true;
    }
    return false;
  }

  function checkIfClientProfileHasEmptyProps() {
    if (clientProfile != null) {
      for (const property in clientProfile) {
        if (
          clientProfile[property] == null ||
          clientProfile[property] === "" ||
          clientProfile[property] === "N/A"
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  const navigate = useNavigate();
  const { getURLSubsPlanPrice, price_planName } = SubscriptionFunction();

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  useEffect(() => {
    let isValid = false;

    if (userType === "applicant") {
      isValid = checkIfTalentProfileHasEmptyProps();
    } else if (userType === "client") {
      isValid = checkIfClientProfileHasEmptyProps();
    }

    setValidated(isValid);

    if (!isValid) {
      if (userType === "applicant") {
        navigate("/talent/profile");
      } else if (userType === "client") {
        navigate(-1);
      }
    }
  }, [userType, profile, clientProfile, navigate]);

  console.log(price_planName);

  // If the profile is validated, render the protected route
  return validated ? <Outlet /> : null;
};

export default ValidatedProfileRoutes;
