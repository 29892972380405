import React, { useEffect } from "react";
import "../../../../../assets/scss/Client/Layout/TalentList/JobList.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookBookmark,
  faClock,
  faLocationPin,
  faBuilding,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../../../../assets/images/Icon/iconBuilding.png";
import { Button } from "reactstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { List } from "react-content-loader";
import Config from "../../../../../Config/config";
import axios from "axios";

const Jobs = ({ joblist, totalLength, loadMoreData, isLoading }) => {
  // console.log(joblist);
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const handleClick = (job_id, createdAt, jobPostStatus) => {
    navigate(`/jd/${job_id}`);
    const obj = {
      id: job_id,
      createdAt: createdAt,
      jobPostStatus: jobPostStatus,
    };

    localStorage.setItem("jobPayload", JSON.stringify(obj));
  };

  const handleLoadMore = () => {
    loadMoreData(joblist.length);
  };

  return (
    <div className="job-list-client">
      {/* <h6>{totalLength} JOBS FOUND</h6> */}
      <hr className="line-seperator" />

      <div>
        {joblist.map((list) => {
          // console.log(list);
          // const companyLogoIcon =
          //   list.companyLogo === "N/A" ? faBuilding : faLocationPin;

          return (
            <div className="job-details-container" key={list.id}>
              <div className="img-container">
                <img
                  crossOrigin="anonymous"
                  src={`${
                    Config.nxtApi
                  }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
                    list.clientId
                  )}&thumbNail=yes`}
                  alt="logo"
                  onError={(e) => {
                    if (list.clientId === undefined) {
                      e.target.classList.remove("errorImg");
                    } else {
                      e.target.src = logo;
                      e.target.classList.add("errorImg");
                    }
                  }}
                />
              </div>
              <div className="details">
                <h4
                  style={{ margin: "0", padding: "0" }}
                  onClick={() =>
                    handleClick(list.job_id, list.createdAt, list.jobPostStatus)
                  }
                >
                  {list.jobTitle}
                </h4>
                <div className="additional-details">
                  <h5>{list.companyName}</h5>

                  <div className="information">
                    <h6 className="jobId">Job ID : {list.job_id}</h6>
                    <div className="information-group">
                      {/* <FontAwesomeIcon icon={faIdBadge} className="icon" /> */}

                      <FontAwesomeIcon icon={faBuilding} className="icon" />
                      <h6>{list.city}</h6>
                    </div>
                    <div className="information-group">
                      <FontAwesomeIcon icon={faBookBookmark} className="icon" />
                      <h6>{list.workArrangement}</h6>
                    </div>
                    <div className="information-group">
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      <h6>
                        {moment(list.postingDate.startDate).format(
                          "MMMM D, YYYY"
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              {/* <JobListContent jobid={list.id} /> */}
            </div>
          );
        })}
        {isLoading && <List />} {/* Display the List component while loading */}
      </div>
    </div>
  );
};

export default Jobs;
