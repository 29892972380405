import React, { useEffect, useState } from "react";
import "../../assets/scss/Jobposting.scss";
import { Container } from "reactstrap";
import SidebarTalent from "../../components/LayoutTalent/SidebarTalent";
import { TabTitle } from "../../Utilities/FunctionTitle";
import HeaderTalent from "../../components/LayoutTalent/HeaderTalent";
import BannerJobListDetails from "../../components/LayoutTalent/BannerJobListDetails";
import SidebarJobList from "../../components/LayoutTalent/JobList/SidebarJobList";
import JobListSidebar from "../../components/LayoutTalent/JobList/JobListDetails/JobListSidebar";
import JobListContent from "../../components/LayoutTalent/JobList/JobListDetails/JobListContent";
import TalentAnalytics from "../../Utilities/Analytics/TalentAnalytics";
import { useNavigate, useParams } from "react-router-dom";
import { useTalent } from "../../hook/useTalent";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";
import CustomModal from "../../components/UI/Modal";
import { Message } from "../../Utilities/Reusable/Message/Message";
import { Helmet } from "react-helmet";

const JobListDetailsPageTalent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [modalInfo, setModalInfo] = useState({ show: false });
  const getJobPayload = JSON.parse(localStorage.getItem("jobPayload"));
  const {
    viewJobDetails,
    viewJobData,
    viewJobDetailsLoad,
    viewJobErrorStatus,
  } = useTalent();
  const [jobDetails, setJobDetails] = useState([]);
  console.log(jobDetails, viewJobData);
  useEffect(() => {
    viewJobDetails(getJobPayload.id, getJobPayload.job_id);
  }, [id]);

  useEffect(() => {
    if (viewJobData) {
      setJobDetails(viewJobData);
    }
  }, [viewJobData]);

  useEffect(() => {
    if (viewJobErrorStatus === 400) {
      setModalInfo({
        show: true,
        message: Message.talent.viewJob[400],
        modalType: "Error",
        func: "isJobDeleted",
        bName: "Back to applied job",
      });
    }
  }, [viewJobErrorStatus]);

  TabTitle("NXT | Job List Details");

  const handleModalBtn = () => {
    if (modalInfo.func === "isJobDeleted") {
      navigate("/talent/applied-job");
    }
  };

  // const response = await axios.post(
  //   `${Config.nxtApi}/api/jobs/job/details`,
  //   { job_id: id }
  //   {
  //     headers,
  //   }
  // );

  const jobPostingSEOData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: viewJobData?.jobDetails?.jobTitle,
    description: viewJobData?.jobDetails?.jobDescription,
    identifier: {
      "@type": "PropertyValue",
      name: viewJobData?.jobDetails?.companyName,
      value: viewJobData?.jobDetails?.id,
    },
    datePosted: viewJobData?.jobDetails?.postingDate.startDate,
    validThrough: viewJobData?.jobDetails?.postingDate.endDate,

    applicantLocationRequirements: {
      "@type": "Country",
      name: "Philippines",
    },

    ...(viewJobData?.jobDetails?.workArrangement === "Work From Home"
      ? {
          jobLocationType: "TELECOMMUTE",
        }
      : {}),

    // employmentType: "CONTRACTOR",
    employmentType: "FULL_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: viewJobData?.jobDetails?.companyName,
      sameAs: "https://www.nxt.com.ph/",
      logo: "https://nxt-public-resource.s3.ap-southeast-1.amazonaws.com/email-template-resource/nxtLogo.png",
    },

    // * Can't be accurately pursued due to current job schema
    // * Alternative is to source from client address
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: viewJobData?.jobDetails?.officeAddress,
        addressRegion: viewJobData?.jobDetails?.city,
        addressCountry: "PH",
      },
    },

    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "PHP",
      value: {
        "@type": "QuantitativeValue",
        minValue: viewJobData?.jobDetails?.minSalary,
        maxValue: viewJobData?.jobDetails?.maxSalary,
        unitText: "MONTH",
      },
    },
  };

  return (
    <div className="background-imagePost">
      <TalentAnalytics jobPostingSEOData={jobPostingSEOData} />{" "}
      <div className="header-fixed">
        <HeaderTalent />
      </div>
      {jobDetails.length === 0 ? (
        <div className="loader_viewJob">
          <List />
        </div>
      ) : (
        <>
          <div className="jobposting-banner">
            <BannerJobListDetails jobDetails={jobDetails} />{" "}
          </div>
          <Container className="jobposting-body">
            <div>
              <div className="sidebar-container">
                <JobListSidebar jobDetails={jobDetails} />
              </div>
            </div>

            <div className="company-profile-container">
              <JobListContent jobDetails={jobDetails} />
            </div>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(viewJobData) }}
            />
          </Container>
        </>
      )}
      <CustomModal
        show={modalInfo.show}
        modalType={modalInfo.modalType}
        modalMessage={modalInfo.message}
        buttonName={modalInfo.bName}
        onClick={() => handleModalBtn()}
      />
      <Footer />
    </div>
  );
};

export default JobListDetailsPageTalent;
