import React, { useState } from "react";
import {
  Alert,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import "../../../../assets/scss/Client/Layout/ApplicantList/ViewResume/DocumentsCV.scss";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useAuthToken } from "../../../../Utilities/Auth/Token";

const DocumentsCV = (data) => {
  const cvName = data.data.resume;
  const token = useAuthToken();
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const endpointKey = process.env.REACT_APP_KEYRESUME_CLIENT_API;
  const endpointLink = `${endpointKey}/?fileName=${cvName}`;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const nextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const downloadCV = () => {
    const downloadLink = endpointLink;
    window.open(downloadLink);
  };
  const viewImage = () => {
    const downloadLink = endpointLink;
    window.open(downloadLink);
  };

  return (
    <div className="work-history-container">
      <div className="header-container">
        <h6>DOCUMENTS (CV)</h6>
      </div>
      <hr className="line-seperator" />
      <div className="cv-list">
        {cvName === undefined || cvName === "N/A" ? (
          <Alert color="secondary">No available CV</Alert>
        ) : (
          <>
            <Label>{cvName}</Label>
            <div className="remove-icon-container">
              {cvName.endsWith(".docx") ? (
                <Button onClick={downloadCV}>
                  <FontAwesomeIcon
                    icon={faDownload}
                    className="download-attachment-btn"
                  />{" "}
                  Click to Download
                </Button>
              ) : (
                <>
                  {cvName.endsWith(".png") || cvName.endsWith(".jpg") ? (
                    <Button onClick={viewImage}>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="view-attachment-btn"
                      />{" "}
                      Click to view image
                    </Button>
                  ) : (
                    <Button onClick={toggleModal}>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className="view-attachment-btn"
                      />{" "}
                      View Attachment
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
        <ModalHeader
          toggle={toggleModal}
          style={{
            fontFamily: "Lato",
            fontSize: "18px",
          }}
        >
          {cvName}
        </ModalHeader>
        <ModalBody style={{ background: "#E0E0E0" }}>
          <div
            className="document-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Document
              file={endpointLink}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <div className="page-wrapper">
                <Page
                  pageNumber={currentPage}
                  width={window.innerWidth * 0.4} // Adjust the width as desired
                />
              </div>
            </Document>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <div className="page-navigation">
            <Button
              onClick={previousPage}
              disabled={currentPage <= 1}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginRight: 10,
                fontFamily: "Lato",
              }}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {numPages}
            </span>
            <Button
              onClick={nextPage}
              disabled={currentPage >= numPages}
              style={{
                width: "100px",
                height: "41px",
                backgroundColor: "#efbb20",
                alignItems: "center",
                border: "none",
                marginLeft: 10,
                fontFamily: "Lato",
              }}
            >
              Next
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DocumentsCV;
