import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentUser: null,
  isOn: true,
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    getCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },

    updateCurrentUser: (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
});

export const { getCurrentUser, updateCurrentUser, clearCurrentUser } =
  currentUserSlice.actions;

export default currentUserSlice.reducer;
