import SpecificSyspar from "../Reusable/SpecificSyspar";

export const fetchSysparValue = async (key, setSiteKey) => {
  try {
    const siteKey = await SpecificSyspar(key);
    if (siteKey) {
      setSiteKey(siteKey); // Set the siteKey state
    } else {
      console.log("No SiteKey");
    }
  } catch (error) {
    console.error(error);
  }
};
