import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "../../../assets/scss/Components/UI/Search/_inputSearch.scss";

const InputSearch = ({
  placeholder,
  inputClassname,
  className,
  defaultValue,
  onSearch,
  ...props
}) => {
  const [keyword, setKeyword] = useState("");

  const handleSearch = () => {
    // Trigger the search action with the current keyword
    onSearch(keyword);
  };

  return (
    <div className={`input_search ${className}`}>
      <input
        placeholder={placeholder}
        {...props}
        type="text"
        className={inputClassname}
        defaultValue={defaultValue}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="input_search_magnifying"
        onClick={handleSearch}
      />
    </div>
  );
};

export default InputSearch;
