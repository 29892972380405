import React from "react";
import { Button, Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingAbout.scss";
import { useNavigate } from "react-router";
const About = () => {
  const navigate = useNavigate();
  const handleClickAbout = () => {
    navigate("/about");
  };
  return (
    <div className="landing-about">
      <Container>
        <div className="landing-content">
          <div className="about-img-container">
            <img
              alt="about-img"
              src={require(`../../../assets/images/LandingPage/About/vector.png`)}
              className="about-img"
            />
          </div>
          <div className="about-body">
            <h1>NXT: Bridging the gap between jobseekers and business</h1>
            <p>
              Leveraging cutting-edge technology and backed by industry experts,
              NXT redefines recruitment and job searching. Job seekers access a
              diverse range of opportunities with flexible work arrangements.
              Employers streamline recruitment with features like talent
              sourcing and AI insights.
            </p>
            <p style={{ marginTop: "20px" }}>
              NXT is powered by Asticom Technology Inc., a shared services and
              outsourcing provider under Globe. NXT supports Asticom's mission
              of improving people's lives by leveraging tech to create the next
              opportunities for jobseekers and employers alike.
            </p>

            <Button onClick={handleClickAbout}>About NXT</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
