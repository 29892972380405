import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentCompanyProfile: null,
  loading: false,
  error: false,
};

const companyProfileSlice = createSlice({
  name: "currentCompanyProfile",
  initialState,
  reducers: {
    startGetCompany: (state) => {
      state.loading = true;
    },
    updateCompanyProfile: (state, action) => {
      state.loading = false;
      state.currentCompanyProfile = {
        ...state.currentCompanyProfile,
        ...action.payload,
      };
    },
    getCompanyProfile: (state, action) => {
      state.loading = false;
      state.error = false;
      state.currentCompanyProfile = action.payload;
    },
    failCompanyProfile: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearCompanyProfile: (state) => {
      state.currentCompanyProfile = null;
      state.loading = false;
      state.error = false;
    },
  },
});

export const {
  startGetCompany,
  updateCompanyProfile,
  getCompanyProfile,
  failCompanyProfile,
  clearCompanyProfile,
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
