import React, { useEffect, useState } from "react";
import { Table, Button, Modal, InputGroup, Form } from "react-bootstrap";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserManagementSchema } from "../../../../../Utilities/Reusable/Validation/Schema/ATS/UserManagementSchema";
import { useRole } from "../../../../../hook/role/useRole";
import { useMessageHandler } from "../../../../../hook/error/messageHandler";

const UserManagementModal = ({
  editData,
  show,
  close,
  onAddUserSuccess,
  onEditUserSuccess,
  showAddModal,
  addConfirmation,
  onErrorAddModal,
  clickAddUser,
  addErr,
  changeAddUser,
  invitedSuccessfully,
}) => {
  const {
    addUserAPI,
    editUserAPI,
    userStatus,
    errorRole,
    addUserStatus,
    editUserRes,
  } = useRole();
  const devBase = localDevBase();
  const token = useAuthToken();
  console.log(userStatus);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(UserManagementSchema) });
  const {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    handleResponse,
    handleError,
    handleLogout,
    closeModal,
  } = useMessageHandler();
  // const { userStatus } = useRole();
  const [apiFunc, setApiFunc] = useState("null");
  const [userFormData, setUserFormData] = useState([]);
  const submitUser = async (data) => {
    console.log(data);
    console.log(`  Authorization: Bearer ${token}`);

    if (editData) {
      editUser(data);
    } else {
      setUserFormData(data);
      showAddModal();
      // addUser(data);
    }
  };

  const addUser = async () => {
    setApiFunc("");
    changeAddUser(false);
    close();
    try {
      // const response = await axios.post(
      //   `${devBase}/api/ats/user/`,
      //   userFormData,
      //   {
      //     headers: {
      //       // "Content-Type": "application/json;charset=UTF-8",
      //       Authorization: `Bearer ${token}`,
      //     },
      //     // withCredentials: true,
      //   }
      // );

      // const response = await addUserAPI(userFormData);
      // console.log("Add success", response);
      // // onAddUserSuccess()

      // invitedSuccessfully(userStatus);
      const response = await addUserAPI(userFormData);
      setApiFunc("addUser");
      console.log(response, "success add", errorRole, userStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const editUser = async (data) => {
    setApiFunc("");
    console.log("ddddddddddddd");
    close();
    // console.log(data);
    console.log(`  Authorization: Bearer ${token}`);

    const payload = {
      patchOptions: {
        clientId: editData.clientId,
      },
      setOptions: {
        prevUpdateAt: editData.prevUpdateAt,
        roleType: data.roleType,
      },
    };

    console.log(payload);
    try {
      const response = await editUserAPI({ editData, data });
      setApiFunc("editUser");
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(editData);

  useEffect(() => {
    if (editData) {
      setValue("email", editData.email);
      setValue("roleType", editData.roleType);
    } else {
      setValue("email", "");
      setValue("roleType", "");
    }
  }, [editData]);

  useEffect(() => {
    if (clickAddUser) {
      addUser();
    }
  }, [clickAddUser]);

  useEffect(() => {
    if (editUserRes === 200 && apiFunc === "editUser" && !errorRole) {
      onEditUserSuccess();
      reset();
    }
  }, [editUserRes, apiFunc, errorRole]);

  useEffect(() => {
    if (
      (addUserStatus === 201 || addUserStatus === "201") &&
      apiFunc === "addUser" &&
      !errorRole
    ) {
      onAddUserSuccess();
      reset();
    }
  }, [addUserStatus, apiFunc]);

  useEffect(() => {
    if (errorRole) {
      reset();
      setApiFunc("");
      addErr(errorRole || []);
    }
  }, [errorRole]);

  // useEffect(() => {
  //   if (userStatus) {
  //     invitedSuccessfully(userStatus);
  //   }
  // }, [userStatus]);

  return (
    <>
      {addUserStatus}
      <Modal
        className="add-modal__button"
        show={show}
        onHide={() => {
          close();
          reset();
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="border-0 bg-white">
          <Modal.Title>{editData ? "Edit role" : "Add Role"}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(submitUser)}>
          <Modal.Body className="bg-white">
            <Form.Label htmlFor="basic-url" className="fw-bold">
              User
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                // value="admin@nxt.com.ph"
                id="basic-url"
                aria-describedby="basic-addon3"
                disabled={editData}
                {...register("email")}
                errors={errors}
              />
            </InputGroup>
            {errors?.email && <p>{errors.email.message}</p>}
            <Form.Label htmlFor="basic-url" className="fw-bold">
              Role
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              {...register("roleType")}
            >
              <option value="">Open this select menu</option>
              <option value="admin">Moderator </option>
              <option value="recruiter">Recuiter</option>
            </Form.Select>
            {errors?.roleType && <p>{errors.roleType.message}</p>}
          </Modal.Body>
          <Modal.Footer className="border-0 bg-white">
            <button className="btn ats__btn-save" type="submit">
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UserManagementModal;
