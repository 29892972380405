import React from "react";
import { Modal } from "react-bootstrap";

const ModalOnly = ({ show, handleClose, children }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="modal_only"
        centered
        size="lg"
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalOnly;
