import axios from "axios";

export const RequestMethod = async (
  method = "get",
  baseUrlKey,
  endpoint,
  data = null,
  token = null,
  params = {},
  withCredentials = false
) => {
  const isLocalhost = window.location.hostname === "localhost";
  const apiBaseUrls = isLocalhost
    ? {
        nxtApi: "http://localhost:8080",
        admin: "http://localhost:8900",
        ats: "http://localhost:8000",
        subs: "http://localhost:8002",
        ai: process.env.REACT_APP_AI,
      }
    : {
        nxtApi: process.env.REACT_APP_NXT_API,
        admin: process.env.REACT_APP_ADMIN_API,
        ats: process.env.REACT_APP_ATS_API,
        subs: process.env.REACT_APP_SUBSCRIPTION,
        ai: process.env.REACT_APP_AI,
      };
  console.log(apiBaseUrls);

  console.log(apiBaseUrls);
  const apiUrl = apiBaseUrls[baseUrlKey];

  try {
    const createAxiosInstance = () => {
      const headers = {
        "Content-Type": "application/json;charset=UTF-8",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      return axios.create({
        baseURL: apiUrl,
        withCredentials,
        headers,
      });
    };

    const axiosInstance = createAxiosInstance();

    const response = await axiosInstance({
      url: endpoint,
      method,
      data,
      params,
    });

    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    const data = error.response.data;
    const status = error.response.status;

    if (status === 401) {
      console.error("Unauthorized:", data);
      localStorage.setItem("error", status);
    } else if (status === 403) {
      console.error("403:", data);
      localStorage.setItem("error", status);
    } else if (status === 500) {
      console.error("Server:", data);
      localStorage.setItem("error", status);
    }
    console.log(error.response.data, error.response.status);
    console.log("Error making API request:", error);
    throw { data, status };
  }
};
