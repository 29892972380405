import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Config from "../../../../../Config/config";
import axios from "axios";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const ScreenScore = ({ data, jobDetails }) => {
  const [questionData, setQuestionData] = useState([]);
  const [screenScore, setScreenScore] = useState(null);
  const [error, setError] = useState(null);
  const clientId = data.clientId;
  const jobId = data.jobId;
  const talentId = data.talentId;
  const code = useRoleKey();
  const token = useAuthToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  useEffect(() => {
    const loadScreeningData = async () => {
      try {
        // Step 1: Load Screening Questions
        const response = await axios.get(`${Config.ats}/api/ats/ai/qna`, {
          params: { talentId: talentId, jobId: jobId },
          headers,
        });
        console.log("1 done: Loaded screening questions", response);
        setQuestionData(response.data.result);

        // Step 2: Load Question Scores
        const content = response.data.result.map((item) => ({
          questionId: item.question.questionId,
          question: item.question.question,
          answerId: item.answer.answerId,
          answer: item.answer.answer,
        }));
        await loadQuestionScore(content);
      } catch (error) {
        console.log("Error loading screening data:", error);
        setError(error);
      }
    };

    loadScreeningData();
  }, [jobId, talentId]);

  const loadQuestionScore = async (content) => {
    const payload = {
      createdAt: new Date().toISOString(),
      clientId: clientId,
      jobId: jobId,
      applicantId: talentId,
      platform: "NXT",
      content,
    };

    try {
      const response = await axios.post(
        `${Config.ai}/api/screening/Question`,
        payload,
        headers
      );
      console.log("2 done: Loaded question scores", response);
      setScreenScore(response.data);

      // Step 3: Save Insights
      await saveInsight(response.data);
    } catch (error) {
      console.log("Error loading question scores:", error);
    }
  };

  const saveInsight = async (screenScoreData) => {
    const payload = {
      score: screenScoreData.overallScore,
      insight: screenScoreData.overallInsight,
      talentId: talentId,
      jobId: jobId,
    };

    try {
      await axios.post(`${Config.ats}/api/ats/ai/score`, payload, headers);
      console.log("3 done: Saved insights");
    } catch (error) {
      console.log("Error saving insights:", error);
    }
  };

  useEffect(() => {
    const getSavedInsight = async () => {
      try {
        const response = await axios.get(`${Config.ats}/api/ats/ai/score`, {
          params: { talentId: talentId, jobId: jobId },
          headers,
        });
        console.log("Fetched saved insights", response);
      } catch (error) {
        console.log("Error fetching saved insights:", error);
      }
    };
    getSavedInsight();
  }, [talentId, jobId]);

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("Accordion toggled!")
    );

    return (
      <button
        className="btn p-0"
        type="button"
        onClick={decoratedOnClick}
        disabled={!screenScore?.content?.length}
      >
        {children}
      </button>
    );
  };

  return (
    <>
      {error?.response?.status === 400 ? (
        "This applicant was unable to answer the screening questionnaires."
      ) : (
        <div className="d-flex">
          <div className="me-auto">
            <h4>
              Screening Analysis:
              <span className="screen-score__green">
                &nbsp;{screenScore?.overallScore}
              </span>
            </h4>
            <p>{screenScore?.overallInsight}</p>
            <Accordion flush>
              <Card className="shadow-none">
                <Card.Header className="p-0">
                  <CustomToggle eventKey="0">
                    <h4>
                      {!screenScore?.content?.length
                        ? "Screening questionnaires for this job post have not yet been set."
                        : "View details"}
                    </h4>
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-0">
                    <h3 className="p-1 fw-bold">Questions</h3>
                    {screenScore?.content?.map((item, key) => (
                      <div key={key}>
                        <p className="fw-bold">{item.question}</p>
                        <p className="border-bottom">
                          <span className="fw-bold">Answer:</span> {item.answer}
                        </p>
                      </div>
                    ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default ScreenScore;
