import React from "react";
import Footer from "../../LandingPage/Components/Footer";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import Image from "../../../components/UI/Image";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import FAQBody from "./Body";
const FAQ = () => {
  return (
    <div>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require(`../../../assets/images/Background/Outside/faq.png`)}
      />

      <CustomContainer className="customContainerWhiteBg">
        <FAQBody />
      </CustomContainer>
      <Footer />
    </div>
  );
};

export default FAQ;
