import React from "react";

const TalentProfileSetup = () => {
  return (
    <div>
      <p>
        Our User-friendly platform simplifies your job search process and
        correctly setting up your profile will give you higher chances of being
        found by your next employer.
      </p>
      <ol>
        <li>Login using your registered email address</li>
        <li>Click on ‘Profile’ button</li>
        <li>Complete your personal details</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Last name </li>
          <li>First name </li>
          <li>Email address </li>
          <li>Phone number </li>
          <li>Birthday </li>
          <li>Address </li>
          <li>Country </li>
          <li>Region </li>
          <li> Province /District </li>
          <li> Municipality</li>
          <li>Postal Code</li>
        </ol>
        <li>Click on ‘Update Profile’</li>
      </ol>
    </div>
  );
};

export default TalentProfileSetup;
