import React from "react";
import { Container, Row } from "react-bootstrap";
import "../../../assets/scss/Client/Layout/Subscription/Subsciption.scss";
import SubsCardBasic from "./SubscriptionCard/SubsCardBasic";
import SubsCardPro from "./SubscriptionCard/SubsCardPro";
import Stepper from "react-stepper-horizontal";
import { useLocation } from "react-router-dom";
import SubsCardPremium from "./SubscriptionCard/SubsCardList";
import Button from "../../UI/Button";
import SubsSelectContent from "./SubsSelect/SubsSelectContent";

const SubsSelect = () => {
  const location = useLocation();
  const subsSelectDetails = location.state?.subsSelectDetails;
  const subsData = location.state?.subsData;

  // const existingTransaction = location.state?.existingTransaction;
  // const expiredFreeTrial = location.state?.expiredFreeTrial;
  // console.log("sbsOption", typeOfSub, existingTransaction);
  return (
    <div className="subsciption-container subscription-container__mod">
      <Container>
        <div className="mb-5 stepper">
          <Stepper
            activeColor="#8046FA"
            defaultColor="#EFF0F6"
            completeColor="#8046FA"
            defaultBarColor="#EFF0F6"
            steps={[
              { title: "Select Subscription" },
              { title: "Configure Subscription" },
              { title: "Checkout" },
            ]}
            activeStep={0}
          />
        </div>
        <h2 className="text-center pb-5">Select Subscription</h2>
        <Row>
          <div className="d-flex justify-content-center">
            <SubsSelectContent
              selectedPlan={subsSelectDetails}
              subsData={subsData}
            />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SubsSelect;
