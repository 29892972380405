import React, { useEffect, useState } from "react";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faList,
  faSignOutAlt,
  faUser,
  faUsers,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/scss/layout/_sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionModal from "../components/QuestionModal";
import axios from "axios";
import Config from "../Config/config";
import { useDispatch } from "react-redux";
import { logout } from "../services/Actions/userSlice";
import { useAuthToken } from "../Utilities/Auth/Token";
import { LogoutAndClearProfile } from "../services/Actions/logoutAction";
import { localDevBase } from "../services/API/localDevBase";
import CheckIsSubscribe from "../Utilities/Reusable/Validation/Validator/CheckIsSubscribe.ats";
import { setSubscribe } from "../services/Actions/subscribe";
import { useSelector } from "react-redux";
import { SubscriptionFunction } from "../Utilities/Reusable/Function/Subscription/Subscription";

const Sidebar = () => {
  const companyProfile = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile
  );

  console.log(companyProfile);

  const isOnClient = useSelector((state) => state.reducer.aimlInfo?.isOnClient);

  const feature = useSelector(
    (state) => state.reducer.feature?.isfeatures?.withATS
  );

  console.log("feature ", feature);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const token = useAuthToken();
  const devBase = localDevBase();
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { getURLSubsPlanPrice, price_planName, feature_pricing } =
    SubscriptionFunction();

  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  const handleCompanyProfileClick = () => {
    if (feature_pricing) {
      navigate("/client/company-profile/?feature=pricing");
    } else {
      navigate("/client/company-profile");
    }
    // navigate("/client/company-profile");
    setActiveItem("client/companyProfile");
  };

  const handleManageJobClick = async () => {
    if (feature) {
      console.log("first");
      navigate("/admin/ats/manage-job");
    } else {
      console.log("firstss");
      navigate("/client/manage-job");
    }
    // checkIsSubscribe();
    setActiveItem("client/manageJob");

    // navigate("/client/manage-job");
    // const baseUrl = `${Config.nxtApi}`;
    // await CheckIsSubscribe(
    //   navigate,
    //   "manageJob",
    //   dispatch,
    //   setActiveItem,
    //   token,
    //   baseUrl
    // );
  };

  const handleTalentListClick = () => {
    // setActiveItem("client/talentList");
    // navigate("/client/talent-list");

    if (feature) {
      console.log("first");
      navigate("/admin/ats/talent-list");
    } else {
      console.log("firstss");
      navigate("/client/talent-list");
    }
    setActiveItem("client/talentList");
  };

  const handleManageUserClick = () => {
    setActiveItem("client/manage-user");
    navigate("/client/manage-user");
  };

  const handleSubscriptionClick = () => {
    // const getSubscriptionCard = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${Config.subs}/api/v1/subscription/subscription-plan`
    //     );
    //     console.log("success!");
    //     console.log(response);
    //   } catch (error) {
    //     console.log("error!");
    //   }
    // };

    // getSubscriptionCard();
    // setActiveItem("client/subscription");
    navigate("/client/subscription");

    // Add your desired  here
  };

  const handleSubscriptionHistoryClick = () => {
    setActiveItem("client/subscription-history");
    navigate("/client/subscription-history");
  };

  const handleLogoutClick = async () => {
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/client/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const FB = window.FB;
      if (FB) {
        console.log("Logging out from Facebook...");
        FB.logout(function (response) {
          // Log out successful
          console.log("User logged out from Facebook");
        });
      }
      // localStorage.clear(); // Clear all local storage
      // const cevent = new CustomEvent("onChangeUserType", {
      //   detail: null,
      // });
      // document.dispatchEvent(cevent);
      console.log("Logout ...", response);
      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      console.log("Logout ...");
      dispatch(LogoutAndClearProfile());

      localStorage.clear();
      sessionStorage.clear();
      console.log(error);
      navigate("/");
    }
  };

  const isActiveItem = (routeName) => {
    return location.pathname === `/${routeName}`;
  };

  return (
    <div className="alignment">
      <Container className="side-bar">
        <ListGroup>
          <ListGroupItem
            action
            tag="a"
            onClick={handleCompanyProfileClick}
            active={isActiveItem(
              location.pathname.includes("/client/company-profile")
            )}
            disabled={!companyProfile}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faUser} />
            Company Profile
          </ListGroupItem>
          <ListGroupItem
            action
            tag="a"
            onClick={handleManageJobClick}
            active={isActiveItem("client/managejob")}
            disabled={!companyProfile}
          >
            <FontAwesomeIcon
              className="sidebar-icon"
              icon={faBriefcase}
              onClick={handleManageJobClick}
            />
            Manage Job
          </ListGroupItem>
          {/* <ListGroupItem
            action
            tag="a"
            onClick={handleManageUserClick}
            active={isActiveItem("client/manage-user")}
            disabled={!companyProfile}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faUsers} />
            Member
          </ListGroupItem> */}
          <ListGroupItem
            action
            tag="a"
            onClick={handleTalentListClick}
            active={isActiveItem("client/talentlist")}
            disabled={!companyProfile}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faList} />
            Applicant Pool
          </ListGroupItem>

          {isOnClient && (
            <ListGroupItem
              action
              tag="a"
              onClick={handleSubscriptionClick}
              active={isActiveItem("client/subscription")}
              disabled={!companyProfile}
            >
              <FontAwesomeIcon className="sidebar-icon" icon={faBriefcase} />
              Subscription
            </ListGroupItem>
          )}

          {/* <ListGroupItem
            action
            tag="a"
            onClick={handleSubscriptionHistoryClick}
            active={isActiveItem("client/subscription-history")}
            disabled={!companyProfile}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faBriefcase} />
            Subscription History
          </ListGroupItem> */}
          <ListGroupItem
            action
            tag="a"
            active={isActiveItem("logout")}
            onClick={toggleModal}
          >
            <FontAwesomeIcon className="sidebar-icon" icon={faSignOutAlt} />
            Logout
          </ListGroupItem>
          <QuestionModal
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            modalLabel="Oops"
            modalSublabel="Are you sure you want to logout?"
            confirmButtonText="Yes, log me out"
            onConfirm={handleLogoutClick}
            onCancel={toggleModal}
          />
        </ListGroup>
      </Container>
    </div>
  );
};

export default Sidebar;
