import React, { ForwardRefRenderFunction } from "react";
import ErrorMessage from "./ErrorMessage";
import "../../assets/scss/Components/Form/_customTextArea.scss";
const CustomTextArea = (
  { name, placeholder, label, inputClassname, errors, className, ...props },
  ref
) => {
  return (
    <div className={`customTextArea ${className}`}>
      <label>{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        className={inputClassname}
        ref={ref}
        {...props}
      />
      {errors && errors[name] && <ErrorMessage label={errors[name].message} />}
    </div>
  );
};

export default React.forwardRef(CustomTextArea);
