import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Button, ButtonGroup } from "react-bootstrap";
import Column from "./Column";
import "../../../assets/scss/Components/UI/DragAndDrop/_dragAndDrop.scss";
import axios from "axios";
import CustomModal from "../Modal";
import Config from "../../../Config/config";
import { localDevBase } from "../../../services/API/localDevBase";
import {
  atsColumn,
  columnNameMappings,
  columnNameMappingsRev,
} from "../../../Utilities/Reusable/Validation/Validator/Column.ats";
import { useAuthToken, useRoleKey } from "../../../Utilities/Auth/Token";
import { useSelector } from "react-redux";
import { handleAxiosError } from "../../../Utilities/Reusable/ErrorHandler/AxiosErrorHandler";

const DragAndDrop = ({
  columnsFromBackend,
  stats,
  statistics,
  clientData,
  AllError,
}) => {
  const companyName = useSelector(
    (state) => state.reducer.currentUser?.currentUser?.companyName
  );
  console.log(clientData);
  const devBase = localDevBase();
  const token = useAuthToken();
  const code = useRoleKey();
  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const [modalShow, setModalShow] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [modalType, setModalType] = useState("");
  const [columns, setColumns] = useState(columnsFromBackend);
  const [selectedItems, setSelectedItems] = useState([]);
  const [invStatusValue, setInvalidStatusValue] = useState("");
  const [dropdownVal, setDropdownVal] = useState("");
  const [prevStatus, setPrevStatus] = useState("");
  const [updatedAt, setUpdatedAt] = useState(null);
  const clientEmail = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile.email
  );
  console.log(columnsFromBackend);
  useEffect(() => {
    if (columnsFromBackend) {
      setColumns(columnsFromBackend);
    }
  }, [columnsFromBackend]);

  const updateStatus = async (userDetailsArray, destinationColumnName) => {
    console.log(columnNameMappings[destinationColumnName]);
    const statusAbbreviation =
      columnNameMappings[destinationColumnName] || destinationColumnName;
    console.log(destinationColumnName);
    const payload = {
      data: userDetailsArray.map((userDetails) => ({
        talentId: userDetails.talentId,
        draftStatus:
          destinationColumnName === ""
            ? columnNameMappings[userDetails.status] || userDetails.status
            : statusAbbreviation,
        firstName: userDetails.firstName,
        currentStatus:
          columnNameMappings[userDetails.status] || userDetails.status,
        prevUpdatedAt: userDetails.updatedAt,
      })),
      clientEmail: clientEmail,
      jobId: userDetailsArray[0].jobId,
      jobTitle: userDetailsArray[0].jobTitle,
      companyName: companyName,
      hiringManagerEmail: clientData.hiringManager?.email,
    };

    console.log("=====================Update Payload ", payload);

    const previousColumns = { ...columns };

    try {
      const response = await axios.patch(
        `${devBase}/api/ats/candidate-list`,
        payload,
        { headers }
      );

      if (response.status === 200) {
        userDetailsArray.forEach((item) => {
          item.updatedAt = response.data.result.newUpdatedAt[0].updatedAt;
        });

        if (payload.data.status === "Failed") {
          statistics({
            ...stats,
            inProgressLength: stats.inProgressLength - 1,
            failedLength: stats.failedLength + 1,
          });
        }
        setSelectedItems([]);
        console.log("Success ", response);
        return { success: true };
      }
    } catch (error) {
      if (error.response.status === 422) {
        setModalShow(true);
        setModalType("Error");
      }

      if (error.response.status === 400) {
        setModalShow(true);
        setModalType("attemptModify");
      }

      console.log(error.response.data.message);
      setColumns(columnsFromBackend);
      handleAxiosError(error);
    }
  };

  const columnValidator = (sourceColumn, destinationColumn) => {
    console.log("Source Column:", sourceColumn);
    console.log("Destination Column:", destinationColumn);
    setInvalidStatusValue(
      columnNameMappingsRev[destinationColumn] || destinationColumn
    );
    console.log(invStatusValue);
    const validTransitions = atsColumn[sourceColumn];
    console.log("Valid Transitions:", validTransitions);
    console.log(
      validTransitions.includes(
        columnNameMappings[destinationColumn] || destinationColumn
      )
    );
    return validTransitions && validTransitions.includes(destinationColumn);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (selectedItems.length === 0) {
      console.log("Single Drag");
      const draggedItemId = result.draggableId;
      console.log("draggedItemId: ", draggedItemId);

      const sourceColumn = columns[source.droppableId];
      console.log("source column: ", sourceColumn);

      const destColumn = columns[destination.droppableId];

      console.log("destColumn: ", destColumn);

      console.log(source.droppableId, destination.droppableId);

      const draggedCard = sourceColumn.items.find(
        (item) => item.id === draggedItemId
      );
      console.log("draggedItem value: ", draggedCard);

      // Check if applicant status is equal to destination name
      if (
        draggedCard.status ===
        (columnNameMappings[destColumn.name] || destColumn.name)
      ) {
        if (!draggedCard.draftStatus) {
          console.log("Don't update me ");

          return;
        } else {
          draggedCard.draftStatus = "";
        }
      } else {
        // If its not equal it will validate if column is valid for transition
        console.log("Check if im in valid transition");
        if (
          columnValidator(
            draggedCard.status,
            columnNameMappings[destColumn.name] || destColumn.name
          )
        ) {
          console.log("I'm valid");
          console.log(
            "destination if valid: ",
            columnNameMappings[destColumn.name] || destColumn.name
          );

          if (
            draggedCard.draftStatus ===
            (columnNameMappings[destColumn.name] || destColumn.name)
          ) {
            console.log(draggedCard.draftStatus);
            console.log("Dont update me");
            return;
          } else {
            draggedCard.draftStatus =
              columnNameMappings[destColumn.name] || destColumn.name;
          }
        } else {
          // Show modal if it is not valid
          setModalShow(true);
          setModalType("Error");
          console.log("I'm not valid single");
          return;
        }
      }
      console.log("Updated draggedCard:", draggedCard);

      // Log the status before updating
      console.log("Status before update:", draggedCard.status);

      updateStatus([draggedCard], draggedCard.draftStatus);

      // Log the status after updating
      console.log("Status after update:", draggedCard.status);

      const updatedColumns = { ...columns };
      const updatedSourceColumn = {
        ...sourceColumn,
        items: sourceColumn.items.filter((item) => item.id !== draggedItemId),
      };
      const updatedDestColumn = {
        ...destColumn,
        items: [...destColumn.items, draggedCard],
      };

      updatedColumns[source.droppableId] = updatedSourceColumn;
      updatedColumns[destination.droppableId] = updatedDestColumn;
      // // New Line

      setColumns((prevColumns) => ({ ...prevColumns, ...updatedColumns }));
    } else {
      console.log("Multi Drag");
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];

      console.log("Source Column: ", sourceColumn, " destColumn: ", destColumn);

      const sourceItems = Array.from(sourceColumn.items);
      const destItems = Array.from(destColumn.items);
      console.log("Source Items: ", sourceItems, " destItems: ", destItems);
      const draggedItems = selectedItems.map((itemId) =>
        sourceItems.find((item) => item.id === itemId)
      );

      const uniqueStatuses = [
        ...new Set(draggedItems.map((item) => item?.status)),
      ];
      const draggedItemsStatus = uniqueStatuses.join(", ");
      console.log("Unique draggedItems statuses:", draggedItemsStatus);

      if (
        draggedItemsStatus ===
        (columnNameMappings[destColumn.name] || destColumn.name)
      ) {
        const allDraftStatusEmpty = draggedItems.every(
          (item) => !item.draftStatus
        );

        if (allDraftStatusEmpty) {
          console.log("Don't update me");
          return;
        } else {
          console.log("else");
          draggedItems.forEach((item) => (item.draftStatus = ""));
        }
      } else {
        console.log("Check if im in valid transition");
        if (
          columnValidator(
            draggedItemsStatus,
            columnNameMappings[destColumn.name] || destColumn.name
          )
        ) {
          console.log("Im valid");
          const uniqueDraftStatusSet = new Set(
            draggedItems.map((item) => item.draftStatus)
          );

          // Convert the Set back to an array if needed
          const uniqueDraftStatus = Array.from(uniqueDraftStatusSet);

          // Log or use the unique draftStatus values
          console.log("Unique draftStatus values:", uniqueDraftStatus);
          if (
            uniqueDraftStatus.join(", ") ===
            (columnNameMappings[destColumn.name] || destColumn.name)
          ) {
            console.log("Dont update me");
            return;
          } else {
            draggedItems.forEach(
              (item) =>
                (item.draftStatus =
                  columnNameMappings[destColumn.name] || destColumn.name)
            );
          }

          // Log all the data
          console.log("Updated draggedItems:", draggedItems);
        } else {
          setModalShow(true);
          setModalType("Error");
          console.log("I'm not valid");
          return;
        }
      }

      updateStatus(draggedItems, draggedItemsStatus);

      const updatedColumns = { ...columns };
      const updatedSourceColumn = {
        ...sourceColumn,
        items: sourceColumn.items.filter(
          (item) => !selectedItems.includes(item.id)
        ),
      };
      const updatedDestColumn = {
        ...destColumn,
        items: [...destColumn.items, ...draggedItems],
      };

      updatedColumns[source.droppableId] = updatedSourceColumn;
      updatedColumns[destination.droppableId] = updatedDestColumn;

      setColumns((prevColumns) => ({ ...prevColumns, ...updatedColumns }));
    }
  };

  const showWarningModal = () => {
    const uniqueStatuses = Array.from(
      new Set(
        selectedItems.map((itemId) => {
          const item = Object.values(columns)
            .flatMap((column) => column.items)
            .find((columnItem) => columnItem.id === itemId);
          return item ? item.draftStatus.trim() : null;
        })
      )
    );

    const commonMapping = uniqueStatuses.map(
      (status) => columnNameMappingsRev[status]
    );

    const newStatus = commonMapping.length > 0 ? commonMapping[0] : "";

    console.log("Selected Items Unique Statuses:", uniqueStatuses);
    console.log("Common Mapping:", commonMapping);
    console.log("New Status:", newStatus);

    setNewStatus(uniqueStatuses);

    if (uniqueStatuses.length === 1 && uniqueStatuses[0] === "") {
      setModalShow(true);
      setModalType("SameStatusColName");
    } else if (uniqueStatuses.includes("")) {
      setModalShow(true);
      setModalType("DifferentStatus");
    } else {
      setModalShow(true);
      setModalType("Warning");
    }
  };

  const moveToNextColumn = async () => {
    if (selectedItems.length === 0) {
      return;
    }

    const sourceColumnId = selectedItems.reduce((acc, itemId) => {
      for (const [columnId, column] of Object.entries(columns)) {
        if (column.items.some((item) => item.id === itemId)) {
          if (acc && acc !== columnId) {
            setModalShow(true);
            setModalType("Error");
            console.log("Different columns. Error Modal");
            return null;
          }
          return columnId;
        }
      }
      return null;
    }, null);

    if (!sourceColumnId) {
      return;
    }

    const columnIds = Object.keys(columns);
    const currentIndex = columnIds.indexOf(sourceColumnId);
    const nextColumnId = columnIds[currentIndex + 1];

    if (!nextColumnId) {
      setModalShow(true);
      setModalType("Error");
      console.log("No next column. Error Modal");
      return;
    }

    const sourceColumn = columns[sourceColumnId];
    const nextColumn = columns[nextColumnId];

    const sourceItems = [...sourceColumn.items];
    const nextItems = [...nextColumn.items];

    const movedItems = selectedItems
      .map((itemId) => sourceItems.find((item) => item.id === itemId))
      .filter(Boolean);

    const allItemsFromSameColumn = movedItems.every(
      (item) => item.columnId === sourceColumn.id
    );

    if (!allItemsFromSameColumn) {
      setModalShow(true);
      setModalType("Error");
      console.log("Different columns. Error Modal");
      return;
    }

    const destinationColumnName =
      columnNameMappings[nextColumn.name] || nextColumn.name;
    console.log(movedItems);
    console.log(destinationColumnName);
    try {
      if (columnValidator(movedItems[0].status, destinationColumnName)) {
        const updateStatusResult = await updateStatus(
          movedItems,
          destinationColumnName
        );
        if (updateStatusResult.success) {
          movedItems.forEach((item) => {
            item.draftStatus = destinationColumnName;
          });
          sourceColumn.items = sourceItems.filter(
            (item) => !selectedItems.includes(item.id)
          );
          nextColumn.items = [...nextItems, ...movedItems];
          setColumns({
            ...columns,
            [sourceColumnId]: { ...sourceColumn },
            [nextColumnId]: { ...nextColumn },
          });
          setSelectedItems([]);
        }
      } else {
        setModalShow(true);
        setModalType("Error");
        return;
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      handleAxiosError(error);
    }
  };

  const handleShowDropWarning = () => {
    setModalType("DropWarning");
    console.log(modalType);
    setModalShow(true);
  };

  const handleClickDrop = async () => {
    let currentStatus;
    if (selectedItems.length === 0) {
      return;
    }

    const itemsToDrop = selectedItems
      .map((itemId) => {
        for (const [, column] of Object.entries(columns)) {
          const item = column.items.find((item) => item.id === itemId);
          if (item) {
            return item;
          }
        }
        return null;
      })
      .filter(Boolean);

    if (itemsToDrop.length === 0) {
      console.log("No items to drop");
      return;
    }

    const payload = {
      data: itemsToDrop.map((item) => {
        currentStatus = item.status;

        return {
          talentId: item.talentId,
          status: "Failed",
          firstName: item.firstName,
          currentStatus: currentStatus,
          email: item.email,
          prevUpdatedAt: item.updatedAt,
        };
      }),

      clientEmail: clientEmail,
      jobId: itemsToDrop[0].jobId,
      jobTitle: itemsToDrop[0].jobTitle,
      companyName: companyName,
      hiringManagerEmail: clientData.hiringManager?.email,
    };

    try {
      const response = await axios.patch(
        `${devBase}/api/ats/candidate-list`,
        payload,
        { headers }
      );

      if (response.status === 200) {
        setModalShow(true);
        setModalType("DropSuccess");

        const updatedColumns = { ...columns };
        selectedItems.forEach((itemId) => {
          for (const [columnId, column] of Object.entries(updatedColumns)) {
            const updatedItems = column.items.filter(
              (item) => item.id !== itemId
            );
            updatedColumns[columnId] = { ...column, items: updatedItems };
          }
        });
        setColumns(updatedColumns);

        const updatedStats = { ...stats };

        if (currentStatus !== "New Applicant") {
          updatedStats.inProgressLength = Math.max(
            0,
            stats.inProgressLength - selectedItems.length
          );
        }

        updatedStats.failedLength = stats.failedLength + selectedItems.length;

        statistics(updatedStats);

        console.log("Success ", response);
      }
    } catch (error) {
      console.error("Error saving job status: ", error);
      handleAxiosError(error);
    }
  };

  const handleDropdownSelect = (item, selectedTransition) => {
    // console.log("Selected dropdown value:", item, selectedTransition);

    if (selectedTransition && selectedItems.length !== 0) {
      moveItemsToColumn(selectedTransition);
    } else {
      moveOneItemToColumn(item, selectedTransition);
    }
  };

  const moveOneItemToColumn = async (item, selectedTransition) => {
    console.log(item, selectedTransition);
    const destinationColumnName =
      columnNameMappings[selectedTransition] || selectedTransition;
    const updatedItem = {
      ...item,
      draftStatus:
        item.status === destinationColumnName ? "" : destinationColumnName,
    };
    console.log(updatedItem);

    try {
      const updateStatusResult = await updateStatus(
        [updatedItem],
        destinationColumnName
      );

      if (updateStatusResult.success) {
        const sourceColumnId = getColumnIdForItem(item);
        const destinationColumnId = getDestinationColumnId(selectedTransition);

        if (!sourceColumnId || !destinationColumnId) {
          return;
        }

        const sourceColumn = columns[sourceColumnId];
        const destinationColumn = columns[destinationColumnId];

        const sourceItems = [...sourceColumn.items];
        const destinationItems = [...destinationColumn.items];

        sourceColumn.items = sourceItems.filter(
          (columnItem) => columnItem.id !== item.id
        );
        destinationColumn.items = [...destinationItems, updatedItem];

        setColumns({
          ...columns,
          [sourceColumnId]: { ...sourceColumn },
          [destinationColumnId]: { ...destinationColumn },
        });
      } else {
        // Handle updateStatus failure
        setModalShow(true);
        setModalType("Error");
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      handleAxiosError(error);
    }
  };

  const getColumnIdForItem = (item) => {
    for (const [columnId, column] of Object.entries(columns)) {
      if (column.items.some((columnItem) => columnItem.id === item.id)) {
        return columnId;
      }
    }
    return null;
  };

  const getDestinationColumnId = (destinationColumnName) => {
    for (const [columnId, column] of Object.entries(columns)) {
      if (column.name === destinationColumnName) {
        return columnId;
      }
    }
    return null;
  };

  const moveItemsToColumn = async (destinationColumnName) => {
    const sourceColumnId = selectedItems.reduce((acc, itemId) => {
      for (const [columnId, column] of Object.entries(columns)) {
        if (column.items.some((item) => item.id === itemId)) {
          if (acc && acc !== columnId) {
            setModalShow(true);
            setModalType("Error");
            console.log("Different columns. Error Modal");
            return null;
          }
          return columnId;
        }
      }
      return null;
    }, null);

    if (!sourceColumnId) {
      return;
    }

    const columnIds = Object.keys(columns);
    const currentIndex = columnIds.indexOf(sourceColumnId);
    const destinationColumnId = columnIds.find(
      (columnId) => columns[columnId].name === destinationColumnName
    );

    if (!destinationColumnId) {
      setModalShow(true);
      setModalType("Error");
      console.log("Destination column not found. Error Modal");
      return;
    }

    const sourceColumn = columns[sourceColumnId];
    const destinationColumn = columns[destinationColumnId];

    const sourceItems = [...sourceColumn.items];
    const destinationItems = [...destinationColumn.items];

    const movedItems = selectedItems
      .map((itemId) => sourceItems.find((item) => item.id === itemId))
      .filter(Boolean);

    const allItemsFromSameColumn = movedItems.every(
      (item) => item.columnId === sourceColumn.id
    );

    if (!allItemsFromSameColumn) {
      setModalShow(true);
      setModalType("Error");
      console.log("Different columns. Error Modal");
      return;
    }
    const destinationName =
      columnNameMappings[destinationColumnName] || destinationColumnName;

    const updatedItems = movedItems.map((item) => ({
      ...item,
      draftStatus: item.status === destinationName ? "" : destinationName,
    }));

    console.log(destinationName);
    console.log("Updated Items:", updatedItems);

    try {
      const updateStatusResult = await updateStatus(
        updatedItems,
        destinationName
      );
      if (updateStatusResult.success) {
        sourceColumn.items = sourceItems.filter(
          (item) => !selectedItems.includes(item.id)
        );
        destinationColumn.items = [...destinationItems, ...updatedItems];
        setColumns({
          ...columns,
          [sourceColumnId]: { ...sourceColumn },
          [destinationColumnId]: { ...destinationColumn },
        });
        setSelectedItems([]);
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      handleAxiosError(error);
    }
  };

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const saveJobStatus = async () => {
    if (isSaveInProgress) {
      return;
    }

    if (selectedItems.length === 0) {
      return;
    }

    setIsSaveInProgress(true);

    const itemsToSave = selectedItems
      .map((itemId) => {
        for (const [, column] of Object.entries(columns)) {
          const item = column.items.find((item) => item.id === itemId);
          if (item) {
            return item;
          }
        }
        return null;
      })
      .filter(Boolean);

    if (itemsToSave.length === 0) {
      return;
    }

    const nonEmptyDraftStatusItems = itemsToSave.filter(
      (item) => item.draftStatus && item.draftStatus.trim() !== ""
    );

    if (nonEmptyDraftStatusItems.length === 0) {
      console.log("No items with non-empty draftStatus to save");
      return;
    }

    const payload = {
      data: nonEmptyDraftStatusItems.map((item) => ({
        talentId: item.talentId,
        status: columnNameMappings[item.draftStatus] || item.draftStatus,
        firstName: item.firstName,
        email: item.email,
        currentStatus: item.status,
        prevUpdatedAt: item.updatedAt,
      })),
      clientEmail: clientEmail,
      jobId: nonEmptyDraftStatusItems[0].jobId,
      jobTitle: nonEmptyDraftStatusItems[0].jobTitle,
      companyName: companyName,
      hiringManagerEmail: clientData.hiringManager?.email,
    };

    console.log("==========================payload saveJobStatus: ", payload);

    try {
      const response = await axios.patch(
        `${devBase}/api/ats/candidate-list`,
        payload,
        { headers }
      );

      if (response.status === 200) {
        const destinationColumnName = nonEmptyDraftStatusItems[0].draftStatus;

        if (
          nonEmptyDraftStatusItems[0].status &&
          nonEmptyDraftStatusItems[0].status !== destinationColumnName
        ) {
          setModalShow(true);
          setModalType("Success");
        }

        const updatedColumns = { ...columns };
        nonEmptyDraftStatusItems.forEach((item) => {
          for (const [columnId, column] of Object.entries(updatedColumns)) {
            const updatedItems = column.items.map((columnItem) => {
              if (columnItem.id === item.id) {
                const updatedItem = {
                  ...columnItem,
                  draftStatus: "",
                  status: nonEmptyDraftStatusItems[0].draftStatus,
                  updatedAt: response.data.result.newUpdatedAt[0].updatedAt, // Update updatedAt
                };

                if (updatedItem.history && updatedItem.history.previousStatus) {
                  const newStatusUpdate = {
                    updatedBy: updatedItem.clientId,
                    updatedAt: new Date().toString(),
                    status: updatedItem.status,
                    statusType: "submit",
                  };

                  updatedItem.history.previousStatus.push(newStatusUpdate);
                } else {
                  updatedItem.history = {
                    createdAt: new Date().toString(),
                    jobId: updatedItem.jobId,
                    previousStatus: [
                      {
                        updatedBy: updatedItem.clientId,
                        updatedAt: new Date().toString(),
                        status: updatedItem.status,
                        statusType: "submit",
                      },
                    ],
                    clientId: updatedItem.clientId,
                    talentId: updatedItem.talentId,
                  };
                }

                return updatedItem;
              } else {
                return columnItem;
              }
            });

            updatedColumns[columnId] = { ...column, items: updatedItems };
          }
        });

        setColumns(updatedColumns);

        payload.data.forEach((item) => {
          if (item.status === "Hired") {
            statistics({
              ...stats,
              inProgressLength: stats.inProgressLength - payload.data.length,
              hiredLength: stats.hiredLength + payload.data.length,
            });
          } else if (item.status === "Failed") {
            statistics({
              ...stats,
              inProgressLength: stats.inProgressLength - payload.data.length,
              failedLength: stats.failedLength + payload.data.length,
            });
          } else if (item.status === "Screening") {
            statistics({
              ...stats,
              inProgressLength: stats.inProgressLength + payload.data.length,
            });
          }
        });

        console.log("Success ", response);
      }
      setSelectedItems([]);
    } catch (error) {
      setColumns(columnsFromBackend);
      if (error.response.status === 400) {
        setModalShow(true);
        setModalType("attemptModify");
      }

      console.error("Error saving job status: ", error);
      handleAxiosError(error);
    } finally {
      setIsSaveInProgress(false);
    }
  };

  const [isDraftHired, setIsDraftHired] = useState("");

  const handleCheckDraftHired = () => {
    const uniqueStatuses = Array.from(
      new Set(
        selectedItems.map((itemId) => {
          const item = Object.values(columns)
            .flatMap((column) => column.items)
            .find((columnItem) => columnItem.id === itemId);
          return item ? item.draftStatus.trim() : null;
        })
      )
    );

    const draggedItemDraftStatus = uniqueStatuses.join(", ");
    console.log("Unique draggedItems statuses:", draggedItemDraftStatus);
    setIsDraftHired(draggedItemDraftStatus);
  };

  useEffect(() => {
    handleCheckDraftHired();
  }, [selectedItems, columns]);

  console.log("selectedItems:", selectedItems);
  console.log(newStatus);
  console.log(modalType);
  return (
    <div className="dragAndDrop">
      <div
        className={`floating-btn__dnd ${
          selectedItems.length === 0 ? "d-none" : "d-block"
        }`}
      >
        <ButtonGroup size="sm">
          <Button className="ps-5" onClick={showWarningModal}>
            <i className="fa-solid fa-download"></i>
            <br />
            Submit
          </Button>
          <Button
            onClick={() => {
              moveToNextColumn();
            }}
            hidden={isDraftHired === "Hired"}
          >
            <i className="fa-solid fa-up-down-left-right"></i>
            <br />
            Move
          </Button>
          <Button onClick={handleShowDropWarning}>
            <i className="fa-solid fa-trash"></i>
            <br />
            Drop
          </Button>
          <Button className="text-white pe-5">
            <div className="floating-btn_circlelabel">
              {selectedItems.length}
            </div>
          </Button>
        </ButtonGroup>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.entries(columns).map(([columnId, column], index) => (
          <Column
            key={columnId}
            columnId={columnId}
            column={column}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            count={column.count}
            onDropdownSelect={handleDropdownSelect}
            jobDetails={clientData}
          />
        ))}
      </DragDropContext>
      <CustomModal
        show={modalShow}
        modalMessage={
          modalType === "Success"
            ? "Status updated successfully"
            : modalType === "SameStatusColName"
            ? "Cannot save same status"
            : modalType === "DifferentStatus"
            ? "Cannot submit different status"
            : modalType === "Warning"
            ? `Confirm moving applicant to ${
                columnNameMappingsRev[newStatus] || newStatus
              }? Press 'OK' to continue.`
            : modalType === "Error"
            ? `This candidate cannot be moved to ${invStatusValue}. Press 'OK' to continue.`
            : modalType === "DropWarning"
            ? "Are you sure you want to drop this candidate?"
            : modalType === "DropSuccess"
            ? "Candidate dropped successfully"
            : modalType === "attemptModify"
            ? "This record is being updated by another user. Please try again later."
            : "Something Went Wrong"
        }
        modalType={
          modalType === "Success" || modalType === "DropSuccess"
            ? "Success"
            : modalType === "Warning" || modalType === "DropWarning"
            ? "Warning"
            : modalType === "500"
            ? "Error"
            : modalType === "Error" ||
              modalType === "SameStatusColName" ||
              modalType === "DifferentStatus" ||
              modalType === "attemptModify"
            ? "Oops!"
            : ""
        }
        hasOption={true}
        onHide={() => setModalShow(false)}
        buttonName="OK"
        onClick={
          modalType === "Success" ||
          modalType === "500" ||
          modalType === "Error" ||
          modalType === "DropSuccess" ||
          modalType === "SameStatusColName" ||
          modalType === "DifferentStatus"
            ? () => {
                setModalShow(false);
                setSelectedItems([]);
              }
            : modalType === "Warning"
            ? () => {
                saveJobStatus();
              }
            : modalType === "DropWarning"
            ? () => {
                handleClickDrop();
                console.log("drop");
              }
            : modalType === "attemptModify"
            ? () => {
                window.location.reload();
              }
            : console.log("first")
        }
      />
    </div>
  );
};

export default DragAndDrop;
