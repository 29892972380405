import React from "react";

const Reminders = () => {
  return (
    <div>
      <ol>
        <li>You can only use your email address to register once.</li>
        <li>
          Always use a professional email address or your company-issued email
          address.
        </li>
        <li>
          Keep your email passwords secure. Using a combination of 8 characters
          at minimum is recommended. Combine alphabet, numbers and special
          characters such as !, #, @, &, *. etc.
        </li>
        <li>
          Make sure you logout of your email account after using a public
          computer or another person’s device. This will ensure that only you
          has access to your personal files, details, OTPs
        </li>
        <li>
          If a personal email address is used, make sure that you nominate at
          least 1 registered mobile number for your personal email address. This
          will help you retrieve your password in case you forget your email’s
          password.
        </li>
        <li>Never share your passwords and OTP to anyone.</li>
        <li>
          In case of slow connection or unavailability of pages, kindly try to
          perform the following :
        </li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            <a
              href="https://www.wikihow.com/Allow-Pop%E2%80%93ups"
              target="_blank"
              rel="noopener noreferrer"
            >
              Disable pop up blockers and Enable pop ups from nxt.com.ph
            </a>
          </li>
          <li>
            Clear your cache/browsing history and ensure that you use the latest
            browser versions.
          </li>
          <ol style={{ listStyleType: "lower-roman" }}>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95589"
                target="_blank"
                rel="noreferrer"
              >
                Google chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/en-au/guide/safari/sfri47acf5d6/mac"
                target="_blank"
                rel="noreferrer"
              >
                Safari on Mac
              </a>
            </li>
            <li>
              <a
                href="https://support.microsoft.com/en-au/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4"
                target="_blank"
                rel="noreferrer"
              >
                Microsoft edge
              </a>
            </li>
            <li>
              <a
                href="https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox"
                target="_blank"
                rel="noreferrer"
              >
                Mozilla firefox
              </a>
            </li>
          </ol>
        </ol>
        <p className="mt-5">
          Do you have suggestions or comments? Please contact us by submitting
          this{" "}
          <a
            href="https://www.nxt.com.ph/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            form
          </a>
          .
        </p>
      </ol>
    </div>
  );
};

export default Reminders;
