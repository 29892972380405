import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import "../../../assets/scss/Client/Layout/ApplicantList/FilterList.scss";
import GlobalText from "../../../assets/global/GlobalText.json";
import moment from "moment";
import cities from "../../../assets/global/cities.json";

const FilterList = () => {
  const [locationFilter, setlocationFilter] = useState("");
  const [appliedDateFilter, setappliedDateFilter] = useState("");
  const [statusFilter, setstatusFilter] = useState("");
  const [applyFrom, setapplyFrom] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");

  const experienceOptions = [
    "Less than 1 year",
    "1 to 2 years",
    "3 to 5 years",
    "6 to 10 years",
    "More than 10 years",
  ];

  const resetFilter = () => {
    setlocationFilter("");
    setappliedDateFilter("");
    setstatusFilter("");
    setapplyFrom("");
    setExperienceFilter("");
  };

  const handleThrowOnChange = () => {
    const applyFromFormat = moment(applyFrom).format("MM/DD/YYYY");
    const payload = {
      location: locationFilter,
      appliedDate: appliedDateFilter,
      status: statusFilter,
      applyFrom: applyFromFormat === "Invalid date" ? null : applyFromFormat,
      experience: experienceFilter,
    };

    const event = new CustomEvent("onApplicationListFilter", {
      detail: payload,
    });
    document.dispatchEvent(event);
  };

  useEffect(() => {
    handleThrowOnChange();
  }, [
    locationFilter,
    appliedDateFilter,
    statusFilter,
    applyFrom,
    experienceFilter,
  ]);

  useEffect(() => {
    document.removeEventListener("onApplicationListSearch", resetFilter);
    document.addEventListener("onApplicationListSearch", resetFilter);
  }, []);

  return (
    <div className="filter-list-container">
      <h6>FILTER LIST</h6>
      <hr className="line-seperator" />
      <div className="input-groups">
        <FormGroup className="experience-group">
          <Label>Experience</Label>
          <Input
            type="select"
            value={experienceFilter}
            onChange={(event) => setExperienceFilter(event.target.value)}
          >
            <option value="" disabled>
              Select to choose
            </option>
            {experienceOptions.map((option, key) => (
              <option value={option} key={key}>
                {option}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="location-group">
          <Label>Location</Label>
          <Input
            type="select"
            value={locationFilter}
            onChange={(event) => setlocationFilter(event.target.value)}
          >
            <option disabled value="">
              Select to Choose
            </option>
            {cities.map((option, key) => (
              <option value={option} key={key}>
                {option}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="apply-from-group">
          <Label>Apply From</Label>
          <Input
            type="date"
            key={applyFrom === "" ? "empty" : "filled"}
            value={applyFrom}
            onChange={(event) => setapplyFrom(event.target.value)}
          />
        </FormGroup>
        <FormGroup className="applied-date-group">
          <Label>Applied Date</Label>
          <Input
            type="select"
            value={appliedDateFilter}
            onChange={(event) => setappliedDateFilter(event.target.value)}
          >
            {GlobalText.appliedDate.map((option, index) => (
              <option
                key={index}
                disabled={option.disabled}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="application-status-group">
          <Label>Application Status</Label>
          <Input
            type="select"
            value={statusFilter}
            onChange={(event) => setstatusFilter(event.target.value)}
          >
            {GlobalText.status.map((option, index) => (
              <option
                key={index}
                disabled={option.disabled}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
      <div className="reset-btn">
        <Button className="update-btn" onClick={resetFilter}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default FilterList;
