import React from "react";
import "../../assets/scss/Gigster/GigsterActivate.scss";
import { Col, Container, Row } from "react-bootstrap";

const GigsterActivate = () => {
  return (
    <div className="gigster__activate">
      <Container>
        <div className="position-relative" style={{ height: "100vh" }}>
          <div class="position-absolute top-50 start-50 translate-middle w-75">
            <Row>
              <Col md={6}>
                <h3 className="mb-4">
                  Authorize gigster to use your NXT account?
                </h3>
                <h4>This application will be able to:</h4>
                <ul className="mb-5">
                  <li>Pull your profile details</li>
                  <li>Pull your contact details</li>
                  <li>Access you photo</li>
                  <li>Access your Key Skills</li>
                </ul>

                <button className="btn btn-auth py-2 px-5 me-2">
                  Authorize App
                </button>
                <button className="btn btn-cancel py-2 px-5">Cancel</button>
              </Col>
              <Col md={6}>
                <img
                  src={require(`../../assets/images/Gigster/Logo/gig2.png`)}
                  className="img-fluid mb-5"
                />
                <img
                  src={require(`../../assets/images/Gigster/Logo/nxt-logo.png`)}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GigsterActivate;
