import React, { useEffect, useState } from "react";
import moment from "moment/";
import "../../assets/scss/SuperAdmin/LayoutScss/SuperAdminNavbar.scss";

const SuperAdminNavbar = ({ name }) => {
  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const dateObj = moment();
      const formattedDateTime = dateObj.format("MMMM DD, YYYY - h:mm A");
      setCurrentDateTime(formattedDateTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="superAdminNavbar">
      <h4>
        Today is: <span>{currentDateTime}</span>
      </h4>
      <h5>
        Welcome Back! <span>SampleName{name}</span>
      </h5>
    </div>
  );
};

export default SuperAdminNavbar;
