import {
  faBox,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../../assets/scss/Page/Admin/Subscription/_subscriptionCard.scss";
const SubscriptionInformationCard = ({
  totalHired,
  totalForInterview,
  totalJobPost,
}) => {
  return (
    <div className="subscriptionInformationCard">
      <div className="totalJobPost">
        <div className="totalJobPostIcon">
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <div className="totalJobPostInformation">
          <h1>{totalJobPost} 000</h1>
          <p>Total Job Post</p>
        </div>
      </div>
      <div className="totalForInterview">
        <div className="totalForInterviewIcon">
          <FontAwesomeIcon icon={faBox} />
        </div>
        <div className="totalForInterviewInformation">
          <h1>{totalForInterview} 000</h1>
          <p>Total For Interview</p>
        </div>
      </div>
      <div className="totalHired">
        <div className="totalHiredIcon">
          <FontAwesomeIcon icon={faBox} />
        </div>
        <div className="totalHiredInformation">
          <h1>{totalHired} 000</h1>
          <p>Total Hired</p>
        </div>
      </div>
      <div className="totalHired">
        <div className="totalHiredIcon">
          <FontAwesomeIcon icon={faBox} />
        </div>
        <div className="totalHiredInformation">
          <h1>{totalHired}000</h1>
          <p>Total Hired</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInformationCard;
