import React, { ForwardRefRenderFunction, useState } from "react";
import "../../assets/scss/Components/Form/_customPassword.scss";
import ErrorMessage from "./ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const CustomPassword = (
  {
    name,
    required,
    labelOptional,
    placeholder,
    label,
    errors,
    type,
    inputClassname,
    className,
    defaultValue,
    onClick,
    classNameLabel,
    readOnly,
    value,
    ...props
  },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`customPassword ${className}`}>
      <label className={classNameLabel}>
        {label}{" "}
        {labelOptional && (
          <span className="label-optional">{labelOptional}</span>
        )}
        {required && <span className="required-field">*</span>}
      </label>
      <div className="passwordContainer">
        <input
          name={name}
          placeholder={placeholder}
          ref={ref}
          {...props}
          type={showPassword ? "text" : "password"}
          className={inputClassname}
          defaultValue={defaultValue}
          disabled={readOnly}
          onClick={onClick}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEye : faEyeSlash}
          onClick={togglePasswordVisibility}
          className="icon"
        />
      </div>

      {errors && errors[name] && <ErrorMessage label={errors[name].message} />}
    </div>
  );
};

export default React.forwardRef(CustomPassword);
