import React, { useState } from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/DocumentsCert.scss";
import { Button, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
const DocumentsCert = () => {
  const [certificateList, setCertificateList] = useState({
    title: "Coca Cola - Accountant ",
    startDate: "05/25/2022",
    endDate: "06/25/2023",
  });

  return (
    <div className="st-documents-cert-container">
      <div className="header-container">
        <h6>DOCUMENTS (CERTIFICATES, ETC.)</h6>
        <Button>
          <FontAwesomeIcon icon={faPencil} className="pencil-icon" /> Add
          Certificate
        </Button>
      </div>

      <hr className="line-seperator" />

      <div className="cert-list">
        <Label>{certificateList.title}</Label>
        <p>
          Time Period: {certificateList.startDate} to {certificateList.endDate}
        </p>
        <div className="remove-icon-container">
          <div className="remove-work-container">
            <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
            <span>Remove</span>
          </div>

          <Button>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="view-attachment-btn"
            />{" "}
            View Attachment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsCert;
