import { useState } from "react";
import { Message } from "../../Utilities/Reusable/Message/Message";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../Utilities/Reusable/Message/errorMessage";
import Config from "../../Config/config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useAuthToken } from "../../Utilities/Auth/Token";
import { LogoutAndClearProfile } from "../../services/Actions/logoutAction";

export const useMessageHandler = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const [status, setStatus] = useState(null);
  const token = useAuthToken();
  const navigate = useNavigate();

  const getErrorCodeMessage = (errorCode) => {
    return errorMessages[errorCode] || "Something went wrong.";
  };

  const getMessage = (category, action, statusCode) => {
    const specificMessage = Message[category]?.[action]?.[statusCode];

    const fallbackMessages = {
      500: "Something went wrong. Please try again later.",
      403: "You'll be logged out due to inactivity.",
      401: "You'll be logged out due to inactivity.",
    };

    return (
      specificMessage || fallbackMessages[statusCode] || "Something went wrong."
    );
  };

  const getMessageType = (statusCode) => {
    if (statusCode >= 200 && statusCode < 300) return "Success";
    if (statusCode >= 300 && statusCode < 400) return "Warning";
    if (statusCode >= 400) return "Error";
    return "Error";
  };

  const handleResponse = (response, category, action) => {
    const statusCode = response.status;
    const message = getMessage(category, action, statusCode);
    const type = getMessageType(statusCode);

    setModalMessage(message);
    setMessageType(type);
    setShowModal(true);
    setShowLogoutButton(false);
  };

  const handleError = (error, category, action) => {
    const statusCode = error.response?.status;
    const errorCode = error.response?.data?.message;

    // Check for specific error codes first
    if (errorCode && errorMessages[errorCode]) {
      const message = getErrorCodeMessage(errorCode);
      setModalMessage(message);
      setMessageType("Error");

      if (errorCode === "MGU-003" || errorCode === "MGU-000") {
        setShowLogoutButton(true);
      } else {
        setShowLogoutButton(false);
      }
      setShowModal(true);
      return;
    }

    if (statusCode === 401 || statusCode === 403) {
      const message =
        getMessage(category, action, statusCode) ||
        "Unauthorized access. Please log in again.";
      setModalMessage(message);
      setMessageType("Error");
      setShowLogoutButton(true);
      setShowModal(true);
      return;
    }

    const message =
      getMessage(category, action, statusCode) || "Something went wrong.";
    const type = getMessageType(statusCode);
    setStatus(statusCode);
    setModalMessage(message);
    setMessageType(type);
    setShowLogoutButton(false);
    setShowModal(true);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${Config.nxtApi}/api/talent/logout`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const FB = window.FB;
      if (FB) {
        console.log("Logging out from Facebook...");
        FB.logout(function (response) {
          console.log("User logged out from Facebook");
        });
      }

      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      dispatch(LogoutAndClearProfile());
      localStorage.clear();
      sessionStorage.clear();
      console.log(error);
      navigate("/");
    }

    closeModal();
  };

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
    setMessageType("");
    setShowLogoutButton(false);
  };

  return {
    showModal,
    modalMessage,
    messageType,
    showLogoutButton,
    status,
    handleResponse,
    handleError,
    handleLogout,
    closeModal,
  };
};
