import {
  faDollarSign,
  faUsers,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../../assets/scss/UIStyle/Card/CardWithIconStyle.scss";

const CardWithIcon = ({ data }) => {
  return (
    <div className="customCardWithIcon">
      {data.map((value, index) => {
        return (
          <div key={index} className={`cardWithIcon ${value.className}`}>
            <div className={`cardIconContainer ${value.className}`}>
              <FontAwesomeIcon icon={value.fontIcon} className="icon" />
            </div>
            <div className="cardTextContainer">
              <h1>{value.number}</h1>
              <p>{value.label}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardWithIcon;
