import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
const SocialMedia = () => {
  return (
    <div
      className="social-media"
      style={{
        display: "flex",
        gap: "10px",
        // alignItems: "center",
        // justifyContent: "center",
      }}
    >
      <a
        href="https://www.facebook.com/profile.php?id=100086798423561"
        className="facebook"
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="icon"
          style={{
            display: "flex",
            background: "#FFFFFF",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "100%",
          }}
        >
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ fontSize: "23px", marginLeft: "2px", color: "#3B5998" }}
          />
        </div>
      </a>
      <a
        href="https://www.instagram.com/acquiro.ph/"
        target="_blank"
        className="instagram"
        rel="noreferrer"
      >
        <div
          className="icon"
          style={{
            display: "flex",
            background: "#FFFFFF",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "100%",
          }}
        >
          <FontAwesomeIcon
            icon={faInstagram}
            style={{ fontSize: "23px", color: "#8A5A4E" }}
          />
        </div>
      </a>
      <a
        href="https://www.linkedin.com/company/89732942"
        target="_blank"
        className="linkedin"
        rel="noreferrer"
      >
        <div
          className="icon"
          style={{
            display: "flex",
            background: "#FFFFFF",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "100%",
          }}
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ fontSize: "23px", color: "#007BB6" }}
          />
        </div>
      </a>
      <a
        href="https://www.tiktok.com/@acquiroph"
        target="_blank"
        className="tiktok"
        rel="noreferrer"
      >
        <div
          className="icon"
          style={{
            display: "flex",
            background: "#FFFFFF",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "100%",
          }}
        >
          <FontAwesomeIcon
            icon={faTiktok}
            style={{ fontSize: "23px", color: "#3B5998" }}
          />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
