import {
  faCheck,
  faCopy,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { InlineShareButtons } from "sharethis-reactjs";
import CustomModal from "../Modal";
import FormModal from "../Modal/FormModal";

// const [show, setShow] = useState(false);
// const handleShow = () => setShow(true);

const popover = ({ url, subject, body, title, onShareSuccess, hasEmail }) => (
  <Popover id="popover-basic" style={{ width: "auto", zIndex: "1" }}>
    {/* <Popover.Header></Popover.Header> */}
    <Popover.Body>
      <div
        style={{
          display: "flex",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        {/* <InlineShareButtons
          config={{
            alignment: "center", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            labels: null, // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              "whatsapp",
              "linkedin",
              "messenger",
              "facebook",
              "twitter",
              "gmail",
              "email",
              "email",
            ],
            padding: 11, // padding within buttons (INTEGER)
            radius: "100%", // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40,
            // OPTIONAL PARAMETERS
            url: "https://dev-client.acquiro.com.ph/jd/07fbe935-5a13-4ca8-a0fd-d7e158e8e960", // (defaults to current url)
            image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
            description: "custom text", // (defaults to og:description or twitter:description)
            title: subject, // (defaults to og:title or twitter:title)
            message: body, // (only for email sharing)
            subject: "custom email subject", // (only for email sharing)
            username: "custom twitter handle", // (only for twitter sharing)
          }}
        /> */}
        {/*  */}
        {hasEmail && <EmailIcon size={30} round={true} />}
        <FacebookShareButton url={url}>
          <FacebookIcon size={30} round={true} onClick={onShareSuccess} />
        </FacebookShareButton>
        <FacebookMessengerShareButton
          url={url}
          title={subject}
          appId="242548685409116"
        >
          <FacebookMessengerIcon
            size={30}
            round={true}
            onClick={onShareSuccess}
          />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={url} title={subject} separator={`\n`}>
          <WhatsappIcon size={30} round={true} onClick={onShareSuccess} />
        </WhatsappShareButton>
        {/* <TelegramShareButton url={url} title={subject}>
          <TelegramIcon size={30} round={true} />
        </TelegramShareButton> */}
        <TwitterShareButton url={url} title={subject}>
          <TwitterIcon size={30} round={true} onClick={onShareSuccess} />
        </TwitterShareButton>
        <ViberShareButton url={url} title={subject} separator={`\n`}>
          <ViberIcon size={30} round={true} onClick={onShareSuccess} />
        </ViberShareButton>
        <ShareCopyLink onClick={onShareSuccess} url={url} />
        {/* <FormModal show={show} /> */}
      </div>
    </Popover.Body>
  </Popover>
);

function ShareCopyLink(url) {
  const [isCopied, setIsCopied] = useState(false);
  // console.log(url.url);
  const copyToClipboard = () => {
    const linkToCopy = url.url;

    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000); // Revert back to default after 5 seconds
      })
      .catch((error) => console.error("Could not copy link: ", error));
  };

  return (
    <>
      <FontAwesomeIcon
        icon={isCopied ? faCheck : faCopy}
        size="lg"
        onClick={copyToClipboard}
        style={{
          width: "15px",
          margin: "none",
          background: isCopied ? "green" : "grey",
          color: "white",
          fontSize: "15px",
          padding: "7px",
          borderRadius: "100%",
          cursor: "pointer",
        }}
      />
    </>
  );
}

const ShareBtn = ({ url, subject, body, title, hasEmail }) => {
  // const shareUrlElement = <a href={url}>{url}</a>;
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover({
          url,
          subject,
          body,
          title,
          hasEmail,
        })}
      >
        <FontAwesomeIcon icon={faShareNodes} />
      </OverlayTrigger>
    </>
  );
};

export default ShareBtn;
