import React from "react";
import { useNavigate, useLocation } from "react-router";

export const CustomNavigate = () => {
  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State", sendState);

  const navigate = useNavigate();

  const goToLandingPage = () => {
    navigate("/");
  };

  const goToAboutPage = () => {
    navigate("/about");
  };

  const goToContactUs = () => {
    navigate("/contact-us");
  };

  const goToFAQ = () => {
    navigate("/faq");
  };

  const goToRegistrationClient = (price_planName) => {
    console.log(sendState);
    console.log(price_planName);
    if (!price_planName) {
      console.log("first");
      navigate("/client/registration", {
        state: { sendClientState: sendState },
      });
    } else {
      console.log("firstdsa");
      navigate(`/client/registration/?feature=${price_planName}`, {
        state: { sendClientState: sendState },
      });
    }
  };

  const goToRegistrationTalent = () => {
    navigate("/talent/registration", {
      state: { sendClientState: sendState },
    });
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return {
    goToRegistrationClient,
    goToRegistrationTalent,
    goToLandingPage,
    goToAboutPage,
    goToContactUs,
    goToFAQ,
    goToForgotPassword,
  };
};
