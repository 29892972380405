import React from "react";
import "../assets/scss/layout/_footer.scss";
import { Container } from "reactstrap";

import SocialMedia from "../components/SocialMedia";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();

  const handleClickJoblist = () => {
    navigate("/talent/job-list");
  };
  return (
    <div style={{ color: "#FFFFFF" }}>
      <div style={{ background: "#004276" }} className="foot-container-outer">
        <Container className="foot-container">
          <section className="foot-next-logo">
            <img
              alt="nxt-logo"
              src={require("../assets/images/nxt-logo.png")}
              style={{
                width: "168px",
                height: "60px",
              }}
            />
            <p>
              Acquiro is an end-to-end workforce solutions company that <br />{" "}
              connects top talents and businesses of all sizes through our{" "}
              <br />
              advanced tech platform.
            </p>
            <SocialMedia />
          </section>
          <section className="foot-quick-links">
            <h3>Quicklinks</h3>
            <div className="foot-quick-links-cont">
              <ul className="foot-quick-links-list">
                <li>
                  <a
                    href="https://www.acquiro.com.ph/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.acquiro.com.ph/our-expertise/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Our Expertise
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.acquiro.com.ph/careers-and-culture/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers and Culture
                  </a>
                </li>
              </ul>
              <ul className="foot-quick-links-list">
                <li>
                  <a
                    href="https://www.acquiro.com.ph/careers-and-culture/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    About Acquiro
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.acquiro.com.ph/newsroom/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Newsroom
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section className="foot-find-jobs">
            <h3>Find Jobs</h3>
            <ul className="foot-joblist">
              <li>
                <a
                  className="custom-link"
                  onClick={handleClickJoblist}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  Job List
                </a>
              </li>
            </ul>
          </section>
        </Container>
      </div>
      <div className="foot-copyright" style={{ background: "#002848" }}>
        <Container>
          <p>
            © Copyright 2023 by{" "}
            <a
              href="https://www.acquiro.com.ph"
              target="_blank"
              rel="noreferrer"
            >
              Acquiro
            </a>
            . All rights reserved.
          </p>
          <p>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/privacy-policy");
              }}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/terms-and-condition");
              }}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            |{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/data-deletion");
              }}
              target="_blank"
              rel="noreferrer"
            >
              Data Deletion
            </a>{" "}
            |{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/cookie-policy");
              }}
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </p>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
