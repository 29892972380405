import axios from "axios";
import Config from "../Config/config";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAI } from "../services/Actions/aiAction";
import { useSelector } from "react-redux";
import moment from "moment";

export const useChatAssistant = () => {
  const userInfo = useSelector((state) => state.reducer.user?.currentUser);
  const aiInfo = useSelector((state) => state.reducer.aimlInfo?.aiml);
  const dispatch = useDispatch();

  const [initiateChatData, setInitiateChatData] = useState(null);
  const [chatMessageData, setChatMassageData] = useState(null);
  const [errorChatMessage, setErrorChatMessage] = useState(false);
  const [loadChatMessage, setLoadChatMessage] = useState(false);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const initiateChat = async () => {
    setInitiateChatData("load");
    setLoadAttempts(0);
    const initiateReqPayload = {
      userId: userInfo.userId,
      roleId: "",
      userType:
        userInfo.userType === "applicant" ? "talent" : userInfo.userType,
      platform: "NXT",
    };

    try {
      const response = await axios.post(
        `${Config.ai}/api/chat/initiate`,
        initiateReqPayload,
        { withCredentials: true }
      );
      console.log("chatAssistantResponse : ", response);

      dispatch(
        getAI({
          sessionId: response.data.sessionId,
          msgCount: 25,
          date: moment().toISOString(),
          error: null,
        })
      );
      setInitiateChatData("done");
    } catch (error) {
      setInitiateChatData("error");
      console.log("Error fetching data:", error);
    }
  };

  const [error15s, setError15s] = useState(false);
  const sendMessageChatAssistant = async (chatText) => {
    setError15s("");
    setLoadChatMessage(true);

    const chatReqPayload = {
      sessionId: aiInfo.sessionId,
      dialogueType: "register",
      createdAt: moment().toISOString(),
      content: {
        text: chatText.trim(),
        isRichText: true,
      },
    };

    const controller = new AbortController();
    const data = {
      content: {
        text: "I’m sorry, I’m having trouble processing your inquiry. Please try asking again or type a new command.",
      },
      dialogueType: "response",
      isRichText: false,
    };

    const timeout = setTimeout(() => {
      controller.abort();
      setChatMassageData(data);
      setLoadChatMessage(false);
    }, 30000);

    try {
      const response = await axios.post(
        `${Config.ai}/api/chat/response`,
        chatReqPayload,
        {
          withCredentials: true,
          signal: controller.signal,
        }
      );

      clearTimeout(timeout);
      setLoadChatMessage(false);
      setChatMassageData(response.data);
      console.log(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request aborted due to timeout");
      } else {
        console.log(error);
      }
      setLoadChatMessage(false);
    }
  };

  const [chatHistoryErr, setChatHistoryErr] = useState(null);
  const getChatHistory = async () => {
    try {
      const response = await axios.get(`${Config.ai}/api/chat/history`, {
        withCredentials: true,
      });

      console.log(response.data);

      setChatMassageData(response.data);
    } catch (error) {
      console.log(error);
      console.log(
        error?.response?.data.includes(
          "Procedure or function 'GetChatSession' expects parameter '@ChatID', which was not supplied."
        )
      );
      if (
        error?.response?.data.includes(
          "Procedure or function 'GetChatSession' expects parameter '@ChatID', which was not supplied."
        )
      ) {
        setChatHistoryErr("noCookies");
      } else {
        setChatHistoryErr("500"); // Set a generic error message
      }
    }
  };

  return {
    initiateChat,
    initiateChatData,
    sendMessageChatAssistant,
    chatMessageData,
    errorChatMessage,
    loadChatMessage,
    getChatHistory,
    chatHistoryErr,
    error15s,
  };
};
