import React, { useEffect, useState } from "react";
// import Text from "./Content/Text";
import Footer from "../../LandingPage/Components/Footer";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import Image from "../../../components/UI/Image";
import CustomContainer from "../../../layouts/Container/CustomContainer";
import { TabTitle } from "../../../Utilities/FunctionTitle";
import ScrollButton from "../../../components/UI/Button/ScrollToTop";
import { useNavigate } from "react-router-dom";

const TermsAndCondition = () => {
  TabTitle("NXT | Terms and Condition");
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  return (
    <div>
      <LandingNavbar />
      <Image
        className="full-width-height"
        src={require("../../../assets/images/Background/Outside/termsCondition.png")}
      />
      <CustomContainer className="containerWhiteBg">
        {/* <Text /> */}
        <div className="container">
          <div className="privacyPolicy">
            <h1>Terms and Conditions</h1>
            <h2>TERMS AND CONDITIONS – NXT Website</h2>
            <p>
              This document sets forth the terms and conditions ("Terms")
              governing the use of the job portal website ("Website") operated
              by Acquiro Solutions and Tech Inc. ("Acquiro"). By accessing and
              utilizing the Website, you agree to comply with these Terms.
              Similarly, these Terms shall apply to you if you have created an
              account with us whether as an applicant or as an employer/hirer,
              have, or have access to an employer account with us, post or
              attempt to post an advertisement or any job listings, have
              utilized or attempt to utilize any of our products and services.
            </p>
            <br />
            <h2>When you use our Platform NXT</h2>
            <p>
              By using NXT, you acknowledge and agree that NXT collects,
              records, processes, analyzes, and stores any activities you take
              on the Site, and any interactions and communications you have
              with, on, or through the Site.
              <br />
              <br />
              Occasionally, NXT tests improvements and updates to our Site and
              services, which may affect various aspects of the Site such as the
              ad delivery system, audience, ad performance, ad placement,
              profiles, resumes, resume search, job search, recommendations,
              alerts, or formatting. You agree that NXT may conduct such tests
              without notice to you. NXT’s services and their availability may
              differ by location, and you should check the Site in your location
              to see which services are available to you.
              <br />
              <br />
              Your continued access and use of NXT is governed by these Terms.
              Therefore, use of the same means you give consent to the
              collection, processing, recording, data profiling, and storing of
              your personal information and communications. Should you disagree
              with any aspect of these Terms, please stop using or refrain from
              accessing the Website.
            </p>
            <br />
            <h2>1. User Accounts</h2>
            <p>
              1.1. To post job listings or submit job applications on the
              Website, users must establish an account by furnishing accurate
              and complete information. You consent for the use of the personal
              information you provided and is also responsible for safeguarding
              the confidentiality of your account details and for all actions
              conducted under your account.
              <br />
              <br />
              1.2. Users must be at least 18 years of age to create an account
              on the Website.
            </p>
            <br />
            <h2>2. Job Listings</h2>
            <p>
              2.1. The Website permits both individuals and companies to post
              job listings, subject to Acquiro's review and approval. All job
              listings must be true, accurate, lawfully acquired, and devoid of
              discriminatory content.
              <br />
              <br />
              2.2. Job listings must not contain or endorse any unlawful,
              illegally obtained, harmful, or inappropriate material, including
              but not limited to discriminatory language, explicit content, or
              deceptive information.
              <br />
              <br />
              2.3. Acquiro reserves the right to remove or reject any job
              listing that contravenes our guidelines or applicable laws and
              regulations.
            </p>
            <br />
            <h2>3. Application Process</h2>
            <p>
              3.1. Individuals may submit job applications via the Website,
              following the application instructions detailed within each job
              listing.
              <br />
              <br />
              3.2. Companies, organizations, and individuals are accountable for
              reviewing and responding to applications promptly. Failure to do
              so may impact your account status adversely.
            </p>
            <br />
            <h2>4. Privacy</h2>
            <p>
              Acquiro collects and processes personal information in compliance
              with the Data Privacy Law of 2012 and in accord with our Privacy
              Policy, accessible on the Website. By utilizing the Website, you
              consent to the collection, processing, and utilization of your
              personal information as outlined in the Privacy Policy.{" "}
              <a href="/privacy-policy">https://nxt.com.ph/privacy-policy</a>
            </p>
            <br />
            <h2>5. Intellectual Property</h2>
            <p>
              5.1. All contents on the Website, including but not limited to
              text, graphics, logos, images, and software, are safeguarded by
              copyright and other intellectual property laws. Users are
              prohibited from utilizing, reproducing, or distributing such
              content without written permission from Acquiro Solutions and Tech
              Inc.
              <br />
              <br />
              5.2 Acquiro owns all intellectual property rights associated with
              this Website and other associated apps. It will continue to retain
              all such rights despite allowing you to use and utilize its
              products and services.
            </p>
            <br />
            <h2>6. Dispute Resolution</h2>
            <p>
              Any disputes arising from or relating to the use of the Website
              will be governed by Philippine laws. Both parties agree to submit
              to the exclusive jurisdiction of Philippine courts for the
              resolution of any disputes.
            </p>
            <br />
            <h2>7. Limitation of Liability</h2>
            <p>
              7.1. While we strive to provide accurate and reliable information
              on the Website, we do not guarantee the accuracy, completeness, or
              reliability of any content.
              <br />
              <br />
              7.2. Acquiro shall not be held responsible for any loss, damage,
              or inconvenience arising from the use of the Website or the
              information presented therein.
            </p>
            <br />
            <h2>8. In-Line Ads</h2>
            <p>
              By using the Website, you agree to the display of inline ads and
              other advertisements inside the website. These ads may be tailored
              to your interests based on data collected during your use of the
              Website.
            </p>
            <br />
            <h2>9. Data Collection</h2>
            <p>
              Acquiro may collect and process data, including but not limited to
              browsing history and user preferences, to optimize the ad
              experience and for other legitimate business purposes. Please
              refer to our Privacy Policy for more details on data collection
              processing and utilization.
            </p>
            <br />
            <h2>10. Use of the NXT Services for Employers/Hirers</h2>
            <p>
              10.1 Employers/hirers who have registered accounts through the NXT
              website can freely utilize our Free tier while fully complying
              with the terms and conditions provided herein. Furthermore, upon
              subscription, you will unlock access to advanced features detailed
              on the subscription page.
            </p>
            <br />
            <h2>11. Users’ Warranties and Representations</h2>
            <p>
              You warrant and represent that:
              <br />
              <br />
              11.1 you have the legal capacity to agree to these Terms and
              Conditions and perform any obligations herein;
              <br />
              <br />
              11.2 you comply with all the applicable laws and regulations of
              the Philippines and applicable company policies, rules and
              regulations;
              <br />
              <br /> 11.3 you will not use our products and services in
              violation of the rights of any party; and
              <br />
              <br />
              11.4 you will not use our products and services to facilitate any
              crime.
            </p>
            <br />
            <h2>12. Site Rules /Prohibitions</h2>
            <p>
              12.1 By utilizing our Site and services, you agree to abide by
              these Site Rules and any additional rules or policies published on
              the Site. Violating our rules, any term and/or condition
              constitutes a material breach of this Agreement and unauthorized
              usage of the Site.
              <br />
              <br />
              Do not directly or indirectly:
              <br />
              <br />
              <ul>
                <li>
                  Access the Site other than through the web or app interface
                  publicly provided by NXT, or otherwise access systems without
                  express authorization.
                </li>
                <li>
                  Provide any false, fake, or fictitious information including
                  user name and information.
                </li>
                <li>
                  Provide any code or data with the intent or effect of
                  damaging, disrupting, disabling, harming, impairing,
                  interfering with, intercepting, expropriating, or otherwise
                  impeding, the Site or any data, program, system, equipment, or
                  communication, or that would cause a violation of any
                  applicable law.
                </li>
                <li>
                  Access any data, especially personal data, without consent of
                  the person and company.
                </li>
                <li>Bypass any limitations or suspensions of functionality.</li>
                <li>Interfere with others’ use of the Site.</li>
                <li>
                  Modify, adapt, translate, reformat, resell, frame, or reverse
                  engineer any portion of the Site.
                </li>
                <li>
                  Remove any copyright, trademark, or other proprietary rights
                  notices.
                </li>
              </ul>
              <br />
              You agree not to use the Site to:
              <br />
              <br />
              <ul>
                <li>
                  Harm anyone or violate the rights of anyone, including the
                  right of publicity, right of privacy, or intellectual property
                  rights.
                </li>
                <li>Provide false information.</li>
                <li>
                  Transmit or store biometric information or biometric
                  identifiers.
                </li>
                <li>Promote or encourage illegal activity.</li>
                <li>
                  Promote pyramid schemes, multi-level marketing (MLM) programs,
                  jobs that require payment to start, or any topics we consider
                  detrimental to our users and the Company.
                </li>
                <li>
                  Send unsolicited commercial messages or any other unlawful
                  communications.
                </li>
                <li>
                  Imitate or impersonate another person, or create false
                  accounts.
                </li>
                <li>
                  Send messages to users who have asked not to be contacted.
                </li>
                <li>
                  Send text messages or make calls for a purpose not related to
                  hiring for or applying to your and any Job Listing.
                </li>
                <li>Sell personal data.</li>
              </ul>
              <br />
              12.2 We retain the right to monitor all Site activity and withhold
              any potentially malicious, spam, fraudulent, or unwanted messages
              or communications, as well as any attachments, scripts, macros, or
              code, at our discretion. You acknowledge and agree that NXT
              determines what constitutes spam and acceptable and relevant data
              and may use Site data for this purpose.
              <br />
              <br />
              12.3 We may interact with users to investigate potential rule
              violations and may share gathered information with law enforcement
              or third parties. The Site must not be used for commercial
              purposes, and you are prohibited from crawling, scraping, data
              mining, reproducing, selling, or exploiting any part of it without
              prior written permission from Acquiro. Additionally, you may not
              use any automated systems to violate NXT's rules and regulations,
              terms and conditions and agree to adhere to all data privacy laws,
              intellectual property laws and regulations.
            </p>
            <br />
            <h2>13. Applicant Tracking System</h2>
            <p>
              Our Applicant Tracking System (ATS) requires strict adherence to
              confidentiality, authorized use, data protection, accuracy,
              compliance, and security measures. Users must maintain
              confidentiality and use the ATS solely for managing job
              applications. We prioritize data protection and accuracy while
              ensuring compliance with laws, implementing rules and regulations.
              Users are responsible for safeguarding login credentials.
              Violations may lead to access termination.
            </p>
            <br />
            <h2>14. Modification of Terms</h2>
            <p>
              14.1. Acquiro retains the right to modify, amend, supplement or
              update these Terms at its discretion. Any modifications will be
              effective upon publication on the Website. Continued use of the
              Website after changes to the Terms signifies your acceptance of
              those modifications.
              <br />
              <br />
              14.2 By utilizing the Website, you acknowledge that you have read,
              understood, and agreed to these Terms. For inquiries or concerns,
              please reach out to us at{" "}
              <a className="text-underline">privacy@nxt.com.ph</a>
              <br />
              Date of Last Revision: February 28, 2024
            </p>
            <br />
            <p>
              Acquiro Solutions and Tech Inc. <br />
              15F ASTICOM, W5th Building, 5th Ave. Bonifacio Global City,
              Taguig, Philippines
              <br />
              Website:{" "}
              <a className="text-underline">
                https://www.nxt.com.ph, https://www.acquiro.com.ph
              </a>
            </p>
          </div>
        </div>
      </CustomContainer>
      <Footer />
      {showScrollButton && <ScrollButton />}
    </div>
  );
};

export default TermsAndCondition;
