import React, { useEffect, useState } from "react";
import "../../assets/scss/Client/Layout/ApplicantList/JoblistStatistics.scss";
import axios from "axios";
import { useAuthToken } from "../../Utilities/Auth/Token";
import Config from "../../Config/config";
const SidebarJoblistStatistics = () => {
  const token = useAuthToken();
  const getJob = JSON.parse(localStorage.getItem("jobList"));
  const [data, setData] = useState({
    applied: 0,
  });

  const getStats = async () => {
    try {
      const apiKey = process.env.REACT_APP_CLIENT_API;

      const getStats = await axios
        .get(
          `${Config.nxtApi}/api/client/jobs/${getJob.jobId}/applicants/count`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setData(response.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="sidebar-js-container">
      <div className="js-header">
        <h5>{getJob.jobTitle}</h5>
      </div>
      <div className="statistics-content">
        <div className="stats-section">
          <p>Applied</p>
          <p>{data.applied || 0}</p>
        </div>
        {/* <div className="stats-section">
          <p>Endorsed</p>
          <p>{data.endorsed || 0}</p>
        </div>
        <div className="stats-section">
          <p>Visit</p>
          <p>{data.visit || 0}</p>
        </div> */}
      </div>
    </div>
  );
};

export default SidebarJoblistStatistics;
