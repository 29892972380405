import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { logout } from "../services/Actions/userSlice";
import { useDispatch } from "react-redux";

export const useTokenExpiration = () => {
  const token = useSelector(
    (state) => state.reducer.user.currentUser?.refreshToken
  );
  const expiresIn = useSelector(
    (state) => state.reducer.user.currentUser?.expiresIn
  );

  const isTokenExpired = () => {
    if (!token || !expiresIn) return true;
    const now = Math.floor(Date.now() / 1000);
    console.log("now", now);
    return expiresIn < now;
  };

  return isTokenExpired();
};

export const ProtectRoutesTalent = () => {
  const token = useSelector(
    (state) => state.reducer.user.currentUser?.refreshToken
  );
  const isAuth = useSelector((state) => state.reducer.user.isAuthenticated);
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const pubId = useSelector((state) => state.reducer.user.currentUser?.pubId);

  if (token && userType === "applicant" && isAuth) {
    return <Outlet />;
  } else if (token && userType === "applicant" && pubId) {
    return <Navigate to={`/talent/job-list-details/${pubId}`} />;
  } else if ((!token || !isAuth) && userType !== "applicant") {
    return <Navigate to="/" />;
  } else {
    return <Navigate to="/talent/profile" />;
  }
};

export const ProtectRoutesClient = () => {
  const token = useSelector(
    (state) => state.reducer.user.currentUser?.refreshToken
  );
  const isAuth = useSelector((state) => state.reducer.user.isAuthenticated);
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  if (token && userType === "client" && isAuth) {
    return <Outlet />;
  } else if ((!token || !isAuth) && userType !== "client") {
    return <Navigate to="/" />;
  } else {
    return <Navigate to="/client/company-profile" />;
  }
};

export const ProtectRoutesAdmin = () => {
  const dispatch = useDispatch();
  const token = useSelector(
    (state) => state.reducer.user.currentUser?.refreshToken
  );
  const isAuth = useSelector((state) => state.reducer.user.isAuthenticated);
  const userType = useSelector(
    (state) => state.reducer.user.currentUser?.userType
  );

  const isExpired = useTokenExpiration();

  if (token && isAuth && !isExpired) {
    return <Outlet />;
  } else if (isExpired || ((!token || !isAuth) && userType !== "admin")) {
    dispatch(logout());
    return <Navigate to="/admin/login" />;
  } else {
    return <Navigate to="/admin/dashboard" />;
  }
};
