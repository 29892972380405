import { useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

export const CreatableSingle = ({
  name,
  label,
  defaultValue,
  isDisabled,
  options,
}) => {
  const { setValue, register } = useFormContext();
  return (
    <div className="mb-4">
      <label>{label}</label>
      <CreatableSelect
        isClearable
        name={name}
        defaultValue={defaultValue}
        onChange={(newValue) => setValue(name, newValue)}
        ref={register()}
        isDisabled={isDisabled}
        options={options}
      />
    </div>
  );
};
