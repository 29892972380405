import { useState } from "react";
import { Message } from "../../Utilities/Reusable/Message/Message";

const useErrorHandler = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getErrorMessage = (category, action, statusCode) => {
    const specificMessage = Message[category]?.[action]?.[statusCode];

    const fallbackMessages = {
      500: "Something went wrong. Please try again later.",
      403: "You do not have permission to perform this action.",
      400: "Invalid request. Please check your input and try again.",
      // Add other common fallback messages as needed
    };

    return (
      specificMessage || fallbackMessages[statusCode] || "Something Went Wrong"
    );
  };

  const handleError = (error, category, action) => {
    const statusCode = error.response?.status;
    const message = getErrorMessage(category, action, statusCode);
    setErrorMessage(message);
    setShowErrorModal(true);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setErrorMessage("");
  };

  return {
    showErrorModal,
    errorMessage,
    handleError,
    closeErrorModal,
  };
};

export default useErrorHandler;
