import React from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
// import Sidebar from "../../layouts/Sidebar";
// import Footer from "../../layouts/Footer";
import Banner from "../../components/Banner";
import "../../assets/scss/Client/Page/Subscription.scss";
// import Subsciption from "../../components/LayoutClient/Subscription/Subscription";
// import FooterClient from "../../layouts/FooterClient";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import SubsConfig from "../../components/LayoutClient/Subscription/SubsConfig";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../LandingPage/Components/Footer";

const SubscriptionConfiguration = () => {
  TabTitle("NXT | Subscription");
  const location = useLocation();
  const typeOfSub = location.state?.typeOfSub;
  const subsData = location.state?.subsData;
  const selectedPlan = location.state?.selectedPlan;
  console.log("conf", selectedPlan, subsData, typeOfSub);
  window.scrollTo(0, 0);
  return (
    <div className="subsription-background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="banner">
        <Banner />
      </div>

      <Container className="body-container">
        {/* <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div> */}

        <div className="content-container">
          <SubsConfig
            typeOfSub={typeOfSub}
            subsData={subsData}
            selectedPlan={selectedPlan}
          />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default SubscriptionConfiguration;
