import React from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_workHistory.scss";
import moment from "moment";

const WorkHistory = ({ data }) => {
  console.log("work history", data);
  if (!data || data.length === 0) {
    return (
      <div>
        <TitleLine title="Work History"></TitleLine>
        <p>No work history found</p>
      </div>
    );
  }

  return (
    <div>
      <TitleLine title="Work History"></TitleLine>
      <div className="work_history">
        <div className="work_history_content">
          {data?.map((val, key) => (
            <div className="certificate_content" key={key}>
              <h5>{val?.companyName}</h5>
              <p>Level: {val?.jobLevel}</p>
              <p>
                Time period:{" "}
                {moment(val?.experienceDate.startDate).format("YYYY-MM-DD")} to{" "}
                {moment(val?.experienceDate.endDate).format("YYYY-MM-DD")}
              </p>
              <hr className="line-seperator" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkHistory;
