import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z, ZodType } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import "../../../../assets/scss/Page/Outer/Login/_loginAdmin.scss";
import axios from "axios";
import { useNavigate } from "react-router";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../Config/config";
import CustomMessage from "../../../../components/Form/ErrorMessage";
import CustomButton from "../../../../components/UI/CustomButton";
import Image from "../../../../components/UI/Image";
import CustomModal from "../../../../components/UI/Modal";
import CustomInput from "../../../../components/Form/CustomInput";
import CustomPassword from "../../../../components/Form/CustomPassword";
import { RequestMethod } from "../../../../Utilities/Reusable/Method/RequestMethod";

const ContainerIndex = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const schema = z.object({
    email: z.string().nonempty("Email is required").email(),
    password: z.string().nonempty("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const submitData = async (data) => {
    // handleModalClose();
    try {
      const response = await axios.post(
        `${Config.admin}/api/v1/admin/auth/login`,
        {
          ...data,
        }
      );

      // const response = await RequestMethod(
      //   "post",
      //   `admin`,
      //   "/api/v1/admin/auth/login",
      //   { ...data },
      //   null,
      //   null,
      //   null
      // );

      const refreshToken = response.data.data.token;
      localStorage.setItem("refreshToken", refreshToken);
      setModalShow(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    setModalShow(false);
    navigate("/admin/dashboard");
  };

  return (
    <div className="adminLogin">
      <div className="adminLoginForm">
        <div className="section">
          <div>
            <h1>Welcome back</h1>
            <p>We’re so excited to see you again!</p>
          </div>
          <form onSubmit={handleSubmit(submitData)}>
            <CustomInput
              errors={errors}
              label="Email Address"
              type="email"
              {...register("email")}
              placeholder="Email Address"
              className="formInputGroup"
            />
            <CustomPassword
              errors={errors}
              label="Password"
              {...register("password")}
              placeholder="Password"
              className="formInputGroup"
            />
            <div className="forgotPassButton">
              <CustomButton
                name="Log In"
                type="submit"
                className="violetNoRadius"
              />
            </div>
          </form>
        </div>
        <div className="sectionImage">
          <Image
            className="loginAdminNXTImage"
            src={require("../../../../assets/images/Logo/Brand/nxtDefault.png")}
          />
          <Image
            className="loginAdminACQImage"
            src={require("../../../../assets/images/Logo/Brand/poweredByDefault.png")}
          />
        </div>
      </div>
      <CustomModal
        buttonName="Go to dashboard"
        show={modalShow}
        onHide={() => handleModalClose()}
        onClick={() => handleModalClose()}
        color="green"
        icon={faCheck}
        modalMessage="You have successfully logged in"
        modalType="Success"
      />
    </div>
  );
};

export default ContainerIndex;
