import React from "react";
import CustomTabs from "../../../components/UI/Tabs/Tabs";
import SubscriptionContent from "./TabsContent/Subscription/SubscriptionContent";
import MemberContent from "./TabsContent/Member/MemberContent";
import AdminContent from "./TabsContent/Admin/AdminContent";

const SubscriptionTabs = () => {
  const activePlans = ["ATS Plan 1"]; // Example array of active plans
  const isFirstTime = false; // Example isFirstTime flag

  const tabsData = [
    {
      eventKey: "member",
      title: "Members",
      content: <MemberContent />,
    },
    {
      eventKey: "admin",
      title: "Admins",
      content: <AdminContent />,
    },
    {
      eventKey: "subscription",
      title: "Subscription",
      content: (
        <SubscriptionContent
          activePlans={activePlans}
          isFirstTime={isFirstTime}
        />
      ),
    },
  ];
  return <div>{CustomTabs(tabsData)}</div>;
};

export default SubscriptionTabs;
