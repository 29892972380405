import React from "react";
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Empowering = () => {
  const stars = Array.from({ length: 4 }, (v, i) => (
    <>
      <span key={i}>
        <FontAwesomeIcon icon={faStar} />
      </span>
    </>
  ));

  return (
    <div className="empowering-subs py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>Empowering employers and businesses across industries</h5>
            <p>
              Acquiro’s latest innovation, NXT - a talent platform that
              understands the challenge businesses face when searching for top
              talents. NXT is designed to bridge the gap between employers and
              jobseekers seamlessly.
            </p>
          </div>
          <div className="col-md-6 row text-center">
            <div className="col-md-6 py-2 position-relative">
              <div className="empowering-subs__box">
                <p className="position-absolute top-50 start-50 translate-middle">
                  Unlimited Job Posting
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2 position-relative">
              <div className="empowering-subs__box">
                <p className="position-absolute top-50 start-50 translate-middle">
                  Effortless Talent Connection
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2 position-relative">
              <div className="empowering-subs__box">
                <p className="position-absolute top-50 start-50 translate-middle">
                  User-Friendly Interface
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2 position-relative">
              <div className="empowering-subs__box">
                <p className="position-absolute top-50 start-50 translate-middle">
                  Streamlined Talent Sourcing
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center py-5">
            <h5>Valued by businesses, cherished by people.</h5>
            <div className="row py-5">
              <div className="col-md-3">
                <div className="box-rate">
                  <div className="box-rate__number">4.5/5</div>
                  <div className="box-rate__star">
                    {stars}
                    <span>
                      <FontAwesomeIcon icon={faStarHalfStroke} />
                    </span>
                  </div>
                  <div className="border-bottom w-50 mx-auto my-2"></div>
                  <div className="box-rate__logo">
                    <img
                      className="img-fluid"
                      src={require("../../../assets/images/LandingPage/TrackingSystem/absi-logo.png")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="box-rate">
                  <div className="box-rate__number">4.5/5</div>
                  <div className="box-rate__star">
                    {stars}
                    <span>
                      <FontAwesomeIcon icon={faStarHalfStroke} />
                    </span>
                  </div>
                  <div className="border-bottom w-50 mx-auto my-2"></div>
                  <div className="box-rate__logo">
                    <img
                      className="img-fluid"
                      src={require("../../../assets/images/LandingPage/TrackingSystem/finsi-logo.png")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="box-rate">
                  <div className="box-rate__number">4.5/5</div>
                  <div className="box-rate__star">
                    {stars}
                    <span>
                      <FontAwesomeIcon icon={faStarHalfStroke} />
                    </span>
                  </div>
                  <div className="border-bottom w-50 mx-auto my-2"></div>
                  <div className="box-rate__logo">
                    <img
                      className="img-fluid"
                      src={require("../../../assets/images/LandingPage/TrackingSystem/acquiro-logo.png")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="box-rate">
                  <div className="box-rate__number">4.5/5</div>
                  <div className="box-rate__star">
                    {stars}
                    <span>
                      <FontAwesomeIcon icon={faStarHalfStroke} />
                    </span>
                  </div>
                  <div className="border-bottom w-50 mx-auto my-2"></div>
                  <div className="box-rate__logo">
                    <img
                      className="img-fluid"
                      src={require("../../../assets/images/LandingPage/TrackingSystem/asticom-logo.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empowering;
