import React from "react";

const ClientSignup = () => {
  return (
    <div>
      <p>
        Welcome to <span className="brandLink">nxt.com.ph</span>, one of the
        leading platforms dedicated to bridging the gap between jobseekers and
        businesses. Take advantage of our established end-to-end workforce
        solutions. Your NXT best talents are just one sign up away.
      </p>
      <p>
        <span className="brandLink">What can nxt.com.ph do for you?</span>
      </p>
      <ul>
        <li>Easily create your client account</li>
        <li>
          Create new and meaningful experiences today by connecting to talented
          professionals for your business
        </li>
        <li>
          Innovative technology and user-friendly interface for your talent
          pooling requirements
        </li>
        <li>Highest security measures to protect your data</li>
      </ul>

      <p>
        Register as a Client for free! Go to{" "}
        <a href="/client/registration"> www.nxt.com.ph/client/registration</a>
      </p>
      <p>To set up SSO using Google or Facebook:</p>
      <ol>
        <li>Click on the "Get Started" button on our website.</li>
        <li>Choose Google or Facebook</li>
        <li>Enter your SSO credentials and click "Continue."</li>
        <li>You will now be logged in to the NXT application.</li>
      </ol>
      <p>Sign up by Providing Email Address and Password</p>
      <ol>
        <li>Click on the "Get Started" button on our website.</li>
        <li>Enter your email address and password in the respective fields.</li>
        <br />
        <p>Email Address validation</p>
        <ul style={{ listStyle: "none" }}>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> After entering your
            email address, a One Time Pin (OTP) will be send to your email
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> Check you inbox and
            copy the OTP sent by NXT
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> Enter you OTP to the
            sign up page to verify your email
          </li>
          <br />
          <p>
            In case you do not see the email from your inbox, kindly check your
            spam folder. In the event that you accidentally deleted the
            validation email, please check your email’s trash.{" "}
          </p>
        </ul>
        <p>Resetting Your Password</p>
        <ul style={{ listStyle: "none" }}>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i>
            You'll be redirected to the password reset page.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i>
            Provide your email address registered on your NXT account.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i>
            You'll receive an email with a password reset link.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i>
            Click the link to set a new password.
          </li>
        </ul>
        <p>Your new password should meet the following criteria:</p>
        <ul>
          <li>At least one lowercase letter</li>
          <li>At least one uppercase letter</li>
          <li>At least one number</li>
          <li>At least one special character</li>
          <li>Minimum length of 8 characters</li>
        </ul>
      </ol>
      <p>Tips on email address:</p>
      <ul>
        <li>
          Use your personal email address or your company-issued email address.
          Applicants are more likely to trust job postings with company-issued
          email addresses.
        </li>
        <li>
          Recovery number and email address. Ensure that you have included your
          recovery phone number and recovery email address in your personal
          email address so you will be able to retrieve your access in case you
          forget your email address’s password.
        </li>
        <li>
          Every time you use a public computer, ensure that you logout of your
          email account and ensure that you never save your password. If
          possible, clear cache or or clear history from the used browser
          accessing your email account.
        </li>
        <li>
          First impressions matter. Always use a professional email address
          during your job applications.{" "}
        </li>
      </ul>
      {/* <ol>
        <li>Click on the Sign up as ‘Client’ button.</li>
        <li>
          Fill in your Company Name, Company Size, Company Address, Industry,
          Country, First Name, Last Name, Birthday, Address, Phone Number, Email
          Address, Country, Region, How you learned about NXT and accept Privacy
          Policy. Click on ‘Submit’, then ‘Confirm’.
        </li>
      </ol> */}
    </div>
  );
};

export default ClientSignup;
