import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

import Banner from "../../components/Banner";
import "../../assets/scss/Client/Page/Subscription.scss";
import SubscriptionCardContainer from "../../components/LayoutClient/Subscription/Subscription";

import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import ActiveSubscription from "../../components/LayoutClient/Subscription/ActiveSubscription";
import { useSubscription } from "../../hook/useSubscription";
import { useSelector } from "react-redux";
import { List } from "react-content-loader";
import Footer from "../LandingPage/Components/Footer";

const SubscriptionPage = () => {
  TabTitle("NXT | Subscription");

  const clientId = useSelector(
    (state) => state.reducer.companyProfile?.currentCompanyProfile?.clientId
  );

  const { checkSubscription, checkSubsData, checkSubsError, isLoading } =
    useSubscription();

  console.log(checkSubsData);

  // const [subsData, setSubsData] = useState(checkSubsData);
  const handleCheckSubscription = async () => {
    await checkSubscription(clientId);
  };

  useEffect(() => {
    handleCheckSubscription();
  }, []);

  // useEffect(() => {
  //   setSubsData(checkSubsData); // Update subsData when checkSubsData changes
  // }, [checkSubsData]);

  // console.log(subsData);
  return (
    <div className="subsription-background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="banner">
        <Banner />
      </div>

      <Container className="body-container">
        <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div>

        <div className="content-container">
          {isLoading ? (
            <List />
          ) : (
            <>
              {checkSubsData && (
                <>
                  <ActiveSubscription subsData={checkSubsData} />
                  <SubscriptionCardContainer subsData={checkSubsData} />
                </>
              )}
            </>
          )}
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default SubscriptionPage;
