import React, { useEffect, useState } from "react";
import DropdownFilter from "../../../Dropdown/DropdownFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import CustomInput from "../../../../Form/CustomInput";
import CreatableSelect from "react-select/creatable";
import { useTalent } from "../../../../../hook/useTalent";

const Company = ({ companyFilter }) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => setShowFilter(!showFilter);

  const handleFilter = (selectedOption) => {
    if (selectedOption) {
      companyFilter(selectedOption.value);
    } else {
      companyFilter("");
    }
  };

  const { getCompanies, listCompanyData } = useTalent();

  const transformedOptions = listCompanyData.map((company) => ({
    label: company.companyName,
    value: company.companyName,
  }));

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div className="filter--container">
      <div className="filter--container--title">
        <label>Company</label>
        <FontAwesomeIcon
          icon={showFilter ? faChevronDown : faChevronRight}
          className="filter--container--icon"
          onClick={handleShowFilter}
        />
      </div>
      {showFilter && (
        <CreatableSelect
          isClearable
          options={transformedOptions}
          onChange={handleFilter}
          placeholder="Select Option"
        />
      )}
    </div>
  );
};

export default Company;
