import axios from "axios";
import React from "react";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import Config from "../../../../../Config/config";

export default function GraphJobPosted(graphData) {
  console.log("empty data", graphData?.graphData);

  const checkCount = () => {
    if (graphData?.graphData?.closedJobs?.count === 1) {
      console.log("hello count ko 1");
    } else {
      console.log("hello count ko more than 1");
    }
  };

  checkCount();
  return (
    <div style={{ width: "100%", height: 120 }}>
      <ResponsiveContainer>
        <AreaChart
          width={200}
          height={60}
          data={graphData?.graphData?.totalJobs?.sortedArr}
          margin={{
            top: 5,
            right: 0,
            left: 0,
          }}
        >
          <Tooltip />
          <XAxis dataKey="name" />

          {/* <Legend /> */}
          <Area
            type="monotone"
            dataKey="jobPost"
            stroke="#2DCD7A"
            fill="#41E590"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
