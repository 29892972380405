import React from "react";
import LandingNavbar from "../../LandingPage/Components/LandingNavbar";
import ProductHero from "./ProductHero";
import ProductHomeBody from "./ProductHomeBody";
import Footer from "../../LandingPage/Components/Footer";

const ProductHome = () => {
  return (
    <>
      <LandingNavbar />
      <ProductHero />
      <ProductHomeBody />
      <Footer />
    </>
  );
};

export default ProductHome;
