import axios from "axios";
import Config from "../../Config/config";
import { useSelector } from "react-redux";

export const PostData = async (url, payload, token = null) => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  try {
    const response = await axios.post(`${Config.nxtApi}/${url}`, payload, {
      headers,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return error.response.data;
    } else {
      console.error("Network error:", error.message);
      return { error: "Network error" };
    }
  }
};
