import axios from "axios";
import React, { useState } from "react";
import "../assets/scss/SecretPage.scss";
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
  Table,
} from "reactstrap";
import Config from "../Config/config";
const SecretReferralPage = () => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const [responseData, setResponseData] = useState(null);

  const fetchDataFromAPI = () => {
    setLoading(true);
    setLoadModal(true);

    axios
      .get(`${Config.nxtApi}/api/reports/referrals?key=${value}`)
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
        setLoadModal(false);
      })
      .catch((error) => {
        setResponseData(null);
        console.error("Error fetching data:", error);
        setLoading(false);
        setLoadModal(false);
      });
  };

  return (
    <div className="secretPage">
      <div className="tableContainer">
        <div className="inputFieldContainer">
          <div>
            <h1 className="code">Code: {value ? value : null}</h1>
          </div>
          <div>
            <Input type="text" value={value} onChange={handleInputChange} />
            <Button onClick={fetchDataFromAPI}>Search</Button>
          </div>
        </div>
        <h1 className="tableLabel">Summary</h1>
        <div className="tableDetailsContainer">
          <Table className="table" responsive="md">
            <thead className="titleTable">
              <tr>
                <th>Referral Code</th>
                <th>Verified</th>
                <th>Unverified</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              {responseData && responseData.summary.length > 0 ? (
                <>
                  {responseData.summary.map((dataItem) => (
                    <tr className="bodyInside">
                      <td>{dataItem.referralCode}</td>
                      <td>{dataItem.verified}</td>
                      <td>{dataItem.unverified}</td>
                      <td>{dataItem.total}</td>{" "}
                    </tr>
                  ))}
                </>
              ) : null}
            </tbody>
          </Table>
          {(responseData && responseData.summary.length === 0) ||
            (responseData === null && (
              <Alert color="secondary" className="alertMessage">
                No data found.
              </Alert>
            ))}
        </div>
        <h1 className="tableLabel">Details</h1>
        <div className="tableDetailsContainer">
          <Table className="tableDetails" responsive="md">
            <thead className="titleTable">
              <tr>
                <th>Referral Code</th>
                <th>Date Registered</th>
                <th>Verified</th>
                <th>Date Verified</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody className="bodyTable">
              {responseData && responseData.summary.length > 0 ? (
                <>
                  {responseData.details.map((details) => {
                    return (
                      <tr className="bodyInside">
                        <td>{details.referralCode}</td>
                        <td>{details.dateRegistered}</td>
                        <td>{details.isVerified ? "Yes" : "No"}</td>
                        <td>{details.dateVerified || "-"}</td>
                        <td>{details.type}</td>
                      </tr>
                    );
                  })}
                </>
              ) : null}
            </tbody>
          </Table>
          {(responseData && responseData.summary.length === 0) ||
            (responseData === null && (
              <Alert color="secondary" className="alertMessage">
                No data found.
              </Alert>
            ))}
        </div>
      </div>
      <Modal isOpen={loadModal} centered>
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "70px 0",
            backgroundColor: "transparent",
          }}
        >
          <Spinner
            color="primary"
            style={{
              height: "9rem",
              width: "9rem",
              marginBottom: "50px",
            }}
          ></Spinner>
          <h1 style={{ fontWeight: "900" }}>Loading</h1>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SecretReferralPage;
