import React, { useState } from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

import Banner from "../../components/Banner";
import "../../assets/scss/Client/Page/TalentList.scss";
import TalentLists from "../../components/LayoutClient/TalentList/TalentList";
import SidebarJobList from "../../layouts/Client/SidebarJobList";
import BannerBreadcrumb from "../../layouts/Client/BannerBreadcrumb";
import SearchFilter from "../../components/LayoutClient/TalentList/SearchFilter";
import TalentListSearchFilter from "../../components/LayoutClient/TalentList/TalentListSearchFilter";

import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import Footer from "../LandingPage/Components/Footer";

const TalentList = () => {
  const [searchValue, setSearchValue] = useState("");
  TabTitle("NXT | Talent List");
  return (
    <div className="background-imagePost-talent">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="jobposting-banner">
        <BannerBreadcrumb />
      </div>
      <div className="search-container">
        <TalentListSearchFilter searchValue={setSearchValue} />
      </div>
      <Container className="jobposting-body">
        <div className="talent-list-container">
          <TalentLists searchValue={searchValue} />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default TalentList;
