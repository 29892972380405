import {
  faBookmark,
  faLocationPin,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Input, Label, Table } from "reactstrap";
import { List } from "react-content-loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../assets/scss/Talent/Layout/AppliedJob/AppliedJob.scss";
import { useAuthToken } from "../../../Utilities/Auth/Token";
import Config from "../../../Config/config";

const AppliedJobTable = () => {
  const token = useAuthToken();
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_TALENT_API;
  const [isLoading, setIsLoading] = useState(false);
  const [isLastData, setIsLastData] = useState(false);
  const [lastKey, setLastKey] = useState(null);
  const [jobsData, setJobData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [payload, setPayload] = useState({
    numRow: 10,
    filterKeys: [
      {
        attribute: "allFields",
        value: "",
      },
    ],
  });

  const processPayload = () => {
    const copiedPayload = { ...payload };
    if (!copiedPayload.lastKey) {
      delete copiedPayload.lastKey;
    }
    return copiedPayload;
  };

  const loadData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setIsLoading(true);

    axios
      .post(
        `${Config.nxtApi}/api/talent/myapplication`,
        processPayload(),
        config
      )
      .then((response) => {
        const newData = [...jobsData, ...response.data.data];
        setJobData(newData);

        setIsLastData(!response.data.lastKey);
        setLastKey(response.data.lastKey);
        setIsButtonDisabled(!response.data.lastKey);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching application list:", error);
      });
  };

  const handleSearchChange = async (event) => {
    setJobData([]);
    setLastKey(null);
    setIsLastData(false);
    setPayload({
      ...payload,
      filterKeys: [
        {
          attribute: "allFields",
          value: event.target.value,
        },
      ],
      lastKey: null,
    });
  };

  const handleLoadMore = async () => {
    setPayload({
      ...payload,
      lastKey,
    });
  };

  useEffect(() => {
    loadData();
  }, [payload]);

  return (
    <div className="appliedjobtable-container">
      <div className="header">
        <h1>{jobsData.size} APPLIED JOB</h1>
        <hr className="line" />
      </div>
      <div className="show-search">
        <div className="show-group">
          <Label>Search</Label>
          <Input type="select">
            <option>{payload.numRow}</option>
          </Input>
          <Label>entries</Label>
        </div>
        <div className="search-group">
          <Label>Search</Label>
          <Input type="text" onChange={handleSearchChange} />
        </div>
      </div>
      <div className="job-table-container">
        <Table className="job-table">
          <thead>
            <tr>
              <th>
                <Label>Job Title</Label>
              </th>

              <th>
                <FontAwesomeIcon icon={faSort} className="sort-icon" />
                <Label>Company</Label>
              </th>
              <th>
                <FontAwesomeIcon icon={faSort} className="sort-icon" />
                <Label>Date</Label>
              </th>
              <th>
                <FontAwesomeIcon icon={faSort} className="sort-icon" />
                <Label>Status</Label>
              </th>
              <th>
                <FontAwesomeIcon icon={faSort} className="sort-icon" />
                <Label>Calendar</Label>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="5" className="loader">
                  <List />
                </td>
              </tr>
            ) : jobsData.size === 0 ? (
              <tr>
                <td colSpan="5" className="no-data">
                  No data available.
                </td>
              </tr>
            ) : (
              Array.from(jobsData).map((job, index) => (
                <tr key={job.jobId + "_" + job.talentId + "_" + index}>
                  <td>
                    <div className="jobtitle-container">
                      <Label
                        className="job-title"
                        onClick={() => {
                          navigate(`/talent/job-list-details/${job.jobId}`);
                          const obj = {
                            job_id: job.ref_job_id,
                            id: job.jobId,
                            createdAt: job.createdAt,
                          };

                          localStorage.setItem(
                            "jobPayload",
                            JSON.stringify(obj)
                          );
                        }}
                      >
                        {job.jobTitle}
                      </Label>
                      <div className="location-container">
                        <div>
                          <FontAwesomeIcon
                            icon={faLocationPin}
                            className="icon"
                          />
                          <Label>
                            {!job.officeAddress ? "N/A" : job.officeAddress}
                          </Label>
                        </div>
                        {/* <div>
                          <FontAwesomeIcon icon={faBookmark} className="icon" />
                          <Label>{job.workArrangement}</Label>
                        </div> */}
                      </div>
                    </div>
                  </td>
                  <td>{job.companyName}</td>
                  <td>{job.dateApplied}</td>
                  <td>{job.status}</td>
                  <td>{job.calendar}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <div className="entries-pagination-container">
        <Button onClick={() => handleLoadMore()} disabled={isButtonDisabled}>
          {isLastData ? "No More to Load" : "Load More"}
        </Button>
      </div>
    </div>
  );
};

export default AppliedJobTable;
