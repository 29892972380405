import React, { useEffect, useRef, useState } from "react";
import LandingNavbar from "../../../LandingPage/Components/LandingNavbar";
import Image from "../../../../components/UI/Image";
import CustomContainer from "../../../../layouts/Container/CustomContainer";
import Jobs from "./Content/Jobs";
import Footer from "../../../LandingPage/Components/Footer";
import { List } from "react-content-loader";
import { TabTitle } from "../../../../Utilities/FunctionTitle";
import axios from "axios";
import BannerJobList from "./Content/BannerJobList";
import SearchFilter from "./Content/SearchFilter";
import { Container, Button } from "reactstrap";
import Config from "../../../../Config/config";
import { useLocation, useParams } from "react-router-dom";
import { useAuthToken } from "../../../../Utilities/Auth/Token";

const Joblist = () => {
  const token = useAuthToken();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSearchValue = queryParams.get("search");
  TabTitle("NXT | Job List");

  const apiKey = process.env.REACT_APP_TALENT_API;
  const [searchValue, setSearchValue] = useState(initialSearchValue || "");
  const [jobList, setJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastData, setIsLastData] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const lastKeyRef = useRef(null);
  const jobListContainerRef = useRef(null);
  const [totalLength, setTotalLength] = useState(""); // Add totalLength state
  const [displayedJobs, setDisplayedJobs] = useState(10); // Initial number of displayed jobs

  const fetchData = async (value = "") => {
    setIsLoading(true);
    setIsLastData(false);
    setIsButtonDisabled(false);
    try {
      const payload = {
        numRow: 10,
        sortKeys: "desc",
        filterKeys: [
          {
            attribute: "allFields",
            value: value,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${Config.nxtApi}/api/jobs`,
        payload
        // config
      );
      // const response = await axios.post(`${apiKey}/job-list`, payload, config);
      const fetchedJobList = response.data.data;
      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      setJobList(fetchedJobList);
      setTotalLength(response.data.total);
      setIsLastData(newLastKey === null);
      setIsButtonDisabled(newLastKey === null);
    } catch (error) {
      console.error("Error fetching job list:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(searchValue);
  }, [searchValue]);

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const loadMoreData = async () => {
    setIsLoading(true);

    try {
      const payload = {
        numRow: 10,
        sortKeys: "desc",
        lastKey: lastKeyRef.current,
        filterKeys: [
          {
            attribute: "allFields",
            value: searchValue,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // const response = await axios.post(`${apiKey}/job-list`, payload, config);
      const response = await axios.post(
        `${Config.nxtApi}/api/jobs`,
        payload
        // config
      );
      const fetchedJobList = response.data.data;
      const newLastKey = response.data.lastKey;
      lastKeyRef.current = newLastKey;

      setJobList((prevJobList) => [...prevJobList, ...fetchedJobList]);
      setIsLastData(newLastKey === null);
      setIsButtonDisabled(newLastKey === null);
    } catch (error) {
      console.error("Error fetching job list:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (searchValue === "") {
      fetchData();
    }
  }, [searchValue]);

  useEffect(() => {
    if (jobListContainerRef.current) {
      jobListContainerRef.current.scrollTop =
        jobListContainerRef.current.scrollHeight;
    }
  }, [jobList]);

  const handleClearSearch = () => {
    setSearchValue("");
  };

  return (
    <div className="background-imagePost-talent-list">
      <div className="header-fixed">
        <LandingNavbar />
      </div>
      <div className="jobposting-banner">
        <BannerJobList />
      </div>
      <div className="search-container">
        <SearchFilter
          onSearch={handleSearch}
          isLoading={isLoading}
          onClear={handleClearSearch}
          initialSearchValue={initialSearchValue} // Pass initialSearchValue prop
        />
      </div>
      <Container className="jobposting-body">
        <div className="talent-list-container" ref={jobListContainerRef}>
          {isLoading ? (
            <List />
          ) : (
            <>
              {jobList.length > 0 ? (
                <Jobs
                  joblist={jobList}
                  totalLength={totalLength}
                  loadMoreData={loadMoreData}
                  isLoading={isLoading}
                />
              ) : (
                <div>No jobs found.</div>
              )}
              <div className="joblist-load-more">
                <Button
                  onClick={loadMoreData}
                  disabled={isButtonDisabled}
                  className="load-more-button"
                >
                  {isLastData ? "No more to load" : "Load More"}
                </Button>
              </div>
            </>
          )}
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Joblist;
