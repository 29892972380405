import React, { useEffect, useState } from "react";
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { useAuthToken } from "../../../../../Utilities/Auth/Token";
import axios from "axios";
import Config from "../../../../../Config/config";

export default function GraphTalentApplied(graphData) {
  return (
    <div style={{ width: "100%", height: 120 }}>
      <ResponsiveContainer>
        <AreaChart
          width={200}
          height={60}
          data={graphData?.graphData?.openJobs?.sortedArr}
          margin={{
            top: 5,
            right: 0,
            left: 0,
          }}
        >
          <Tooltip />
          <XAxis dataKey="name" />
          <Area
            type="monotone"
            dataKey="jobPost"
            stroke="#7E72F2"
            fill="#B7B1F7"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
