import React from "react";
import { Container, Row } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";
import SubsSelectContent from "../../../../../../components/LayoutClient/Subscription/SubsSelect/SubsSelectContent";
import { useLocation } from "react-router-dom";
const AddOnSelect = ({ selectedPlan, subsData }) => {
  return (
    <div className="subsciption-container subscription-container__mod">
      <Container>
        <div className="mb-5 stepper">
          <Stepper
            activeColor="#8046FA"
            defaultColor="#EFF0F6"
            completeColor="#8046FA"
            defaultBarColor="#EFF0F6"
            steps={[
              { title: "Select Subscription" },
              { title: "Configure Subscription" },
              { title: "Checkout" },
            ]}
            activeStep={0}
          />
        </div>
        <h2 className="text-center pb-5">Select Subscription</h2>
        <Row>
          <div className="d-flex justify-content-center">
            <SubsSelectContent
              selectedPlan={selectedPlan}
              subsData={subsData}
            />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AddOnSelect;
