import React, { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { z, ZodType } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import "../../../../../assets/scss/Page/Outer/Registration/_emailRegistration.scss";
import Config from "../../../../../Config/config";
import axios from "axios";
import CustomInput from "../../../../../components/Form/CustomInput";
import CustomCheckbox from "../../../../../components/Form/CustomCheckbox";
import CustomButton from "../../../../../components/UI/CustomButton";
import CustomPassword from "../../../../../components/Form/CustomPassword";
import CustomMessage from "../../../../../components/Form/ErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import BottomButton from "../../../../../components/UI/Button/BottomButton";
import { RequestMethod } from "../../../../../Utilities/Reusable/Method/RequestMethod";
import { SubscriptionFunction } from "../../../../../Utilities/Reusable/Function/Subscription/Subscription";

const CreateAccount = ({ onSubmit, onPrivacyPolicyCheck }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [message, setMessage] = useState("");

  const location = useLocation();
  const sendState = location.state?.sendClientState;
  console.log("State create account", sendState);
  console.log("Current URL:", location.pathname);

  // const handleClickSignUpClient = () => {
  //   navigate("/login-client", {
  //     state: { sendState: "client" },
  //   });
  // };
  // const handleClickSignUpTalent = () => {
  //   navigate("/login-talent", {
  //     state: { sendState: "talent" },
  //   });
  // };

  const schema = z
    .object({
      email: z.string().nonempty("Email is required").email(),
      password: z
        .string()
        .nonempty("Password is required")
        .min(12, "Password must have a minimum of 12 characters")
        .max(16, "Password must not exceed 16 characters in length")
        .regex(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-._@$#+=!%*?&])[A-Za-z\d\-._@$#+=!%*?)(&]{12,16}$/,
          "Atleast one capital, atleast one lower, atleast one number , atleast one symbol"
        ),
      confirmPassword: z.string().nonempty("Confirm Password is required"),
      priv: z.literal(true, {
        errorMap: () => ({
          message: "You must agree to privacy policy to submit the form",
        }),
      }),
      terms: z.literal(true, {
        errorMap: () => ({
          message: "You must agree to terms and conditions to submit the form",
        }),
      }),
    })
    .refine((value) => value.confirmPassword === value.password, {
      message: "Password do not match",
      path: ["confirmPassword"],
    });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const submitEmailPassword = async (data) => {
    setMessage("");
    setIsBtnLoading(true);
    const { checkbox, email, ...requestData } = data;
    try {
      // const response = await axios.post(
      //   `${Config.nxtApi}/api/user/email/signup`,
      //   {
      //     ...requestData,
      //     email,
      //   }
      // );

      const response = await RequestMethod(
        "post",
        "nxtApi",
        "/api/user/email/signup",
        {
          ...requestData,
          email,
        }
      );
      triggerSendCode(email, response.data.id, response.data.reg_type);
    } catch (error) {
      console.log("Error fetching data:", error.status, error.data, error);
      setMessage(error.data?.message);
      setIsBtnLoading(false);
      console.log(error);
    }
  };

  const triggerSendCode = async (email, id, regType) => {
    try {
      const sendCode = await axios.post(
        `${Config.nxtApi}/api/user/email/signup/sendcode`,
        {
          email,
        }
      );
      setIsBtnLoading(false);

      onSubmit(email, id, regType);
    } catch (error) {
      if (error.response.data.message.message === "Has valid code") {
        onSubmit(email, id, regType);
      } else {
        setMessage(error.response.data.message);
      }
    }
  };

  const handleClickLanding = () => {
    navigate("/");
  };
  const [isPrivChecked, setisPrivChecked] = useState(false);
  // const [isTermsChecked, setisTermsChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  // console.log(isTermsChecked);s

  const handleTermsCheckboxChange = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const handlePrivCheckboxChange = () => {
    setisPrivChecked(!isPrivChecked);
  };

  useEffect(() => {
    if (isPrivChecked && isTermsChecked) {
      onPrivacyPolicyCheck(true);
    } else {
      onPrivacyPolicyCheck(false);
    }
  }, [isPrivChecked, isTermsChecked]);

  const { getURLSubsPlanPrice, price_planName } = SubscriptionFunction();
  useEffect(() => {
    getURLSubsPlanPrice();
  }, []);

  console.log(price_planName);

  return (
    <div>
      <BottomButton />
      <form onSubmit={handleSubmit(submitEmailPassword)}>
        <div className="loginTitleLeftWithIcon">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon"
            onClick={handleClickLanding}
          />
          <h1>Create Account</h1>
        </div>

        <CustomInput
          errors={errors}
          label="Email Address"
          type="email"
          {...register("email")}
          placeholder="Email Address"
          className="formInputGroup"
          onClick={() => setMessage("")}
        />

        <CustomPassword
          errors={errors}
          label="Password"
          {...register("password")}
          placeholder="Password"
          className="formInputGroup"
        />

        <CustomPassword
          errors={errors}
          label="Confirm Password"
          {...register("confirmPassword")}
          placeholder="Confirm Password"
          className="formInputGroup"
        />

        <CustomCheckbox
          type="checkbox"
          errors={errors}
          link="/privacy-policy"
          linkName="Privacy Policy"
          {...register("priv")}
          label="Accept"
          errorClass={errors.priv ? "error-border" : ""}
          // labelAfter="and Terms"
          onChange={handlePrivCheckboxChange}
          linkClassName="noUnderline"
        />

        <CustomCheckbox
          type="checkbox"
          errors={errors}
          link="/terms-and-condition"
          linkName="Terms and Conditions"
          {...register("terms")}
          label="Accept"
          onChange={handleTermsCheckboxChange}
          errorClass={errors.terms ? "error-border" : ""}
          // labelAfter="and Terms"
          linkClassName="noUnderline"
        />
        {message && <CustomMessage label={message} />}
        <div className="buttonCenter">
          {location.pathname.includes("/client/registration") ? (
            <CustomButton
              type="submit"
              name="Submit"
              className="customLRButton"
              // onClick={handleClickSignUpClient}
              isLoading={isBtnLoading}
            />
          ) : (
            <CustomButton
              type="submit"
              name="Submit"
              className="customLRButton"
              // onClick={handleClickSignUpTalent}
              isLoading={isBtnLoading}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
