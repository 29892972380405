import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/scss/NotFound.scss";
import { TabTitle } from "../../../Utilities/FunctionTitle";

function NotFound() {
  TabTitle("Acquiro | Not Found");
  return (
    <div className="not-found">
      <img src={require("../../../assets/images/nxt-logo.png")}></img>
      <h1>404 - ERROR</h1>
      <h1>Page not found</h1>
      {/* <p>We are sorry, but the page you requested was not found.</p> */}
      <div>
        <button>
          <Link className="btn-label" to="/">
            Back to Landing Page
          </Link>
        </button>
      </div>
      {/* <h4>
        © Copyright 2023 by {""}
        <a
          href="https://www.acquiro.com.ph"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "white" }}
        >
          Acquiro
        </a>
        . All rights reserved.
      </h4> */}
    </div>
  );
}

export default NotFound;
