import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../assets/scss/Talent/Layout/JobList/JobListSidebar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Config from "../../../../Config/config";
import logo from "../../../../assets/images/Icon/iconBuilding.png";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
const JobListSidebar = ({ jobDetails }) => {
  console.log(jobDetails);
  return (
    <div className="joblistsidebar-container">
      {" "}
      <div className="image-container">
        <img
          crossOrigin="anonymous"
          src={`${
            Config.nxtApi
          }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
            jobDetails?.jobDetails?.clientId
          )}&thumbNail=yes`}
          alt="logo"
          onError={(e) => {
            e.target.src = logo;
            e.target.classList.add("errorImg");
          }}
        />
      </div>
      <h1>{jobDetails?.jobDetails?.companyName}</h1>
      {/* <p>
        Asticom Group of Companies is the parent company to four subsidiaries:
        ABSI, HCX, and FINSI that serve the IT-BSO, Digital Solutions and HR,
        Engineering, and Logistics industries, respectively. Through our
        business solutions and services, we have created numerous opportunities
        for businesses and Filipinos.
      </p> */}
      <section>
        <h1>Location</h1>
        <p>
          {jobDetails?.jobDetails?.officeAddress}
          {/* , {jobDetails.city} */}
        </p>
      </section>
      <section>
        <h1>Salary</h1>
        <p>
          ₱ {jobDetails?.jobDetails?.minSalary} - ₱{" "}
          {jobDetails?.jobDetails?.maxSalary}{" "}
        </p>
      </section>
      <section>
        <h1>Job Type</h1>
        <p>{jobDetails?.jobDetails?.jobType} </p>
      </section>
      {/* <section>
        <h1>Website</h1>
        <p>
          15/F W5th Building, Bonifacio Global City, Taguig City, Philippines
        </p>
      </section> */}
    </div>
  );
};

export default JobListSidebar;
