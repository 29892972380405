import axios from "axios";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Config from "../../../Config/config";
import { List } from "react-content-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import "../../../assets/scss/LandingPage/_mostRecentJob.scss";
import moment from "moment";
import CustomButton from "../../../components/UI/CustomButton";
import { useNavigate } from "react-router";
import logo from "../../../assets/images/Icon/iconBuilding.png";
const MostRecentJob = () => {
  const navigate = useNavigate();
  const [recentJobList, setRecentJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getMostRecentJob = async () => {
    try {
      const response = await axios.get(`${Config.nxtApi}/api/jobs/job/recent`);
      setRecentJobList(response.data);
      setIsLoading(false);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMostRecentJob();
  }, []);

  const handleClickTitle = (job_id, id) => {
    console.log(job_id, id);
    navigate(`/jd/${job_id}`);

    const obj = {
      job_id: job_id,
      id: id,
    };

    localStorage.setItem("jobPayload", JSON.stringify(obj));
  };
  return (
    <div className="mostRecentJob">
      <Container>
        <div className="mostRecentJobTitle">
          <h1>Explore the Latest Job Vacancies</h1>
        </div>
        <div className="mostRecentJobList">
          {isLoading ? (
            <List />
          ) : (
            <>
              {recentJobList.map((val) => {
                return (
                  <div className="container" key={val.id}>
                    <div className="imgSection">
                      <img
                        crossOrigin="anonymous"
                        src={`${
                          Config.nxtApi
                        }/api/jobs/job/company/logo?clientId=${encodeURIComponent(
                          val.clientId
                        )}&thumbNail=yes`}
                        alt="logo"
                        onError={(e) => {
                          e.target.src = logo;
                          e.target.classList.add("errorImg");
                        }}
                      />
                    </div>
                    <div className="detailsSection">
                      <h1
                        onClick={() => {
                          handleClickTitle(val.job_id, val.id);
                        }}
                      >
                        {val.jobTitle}
                      </h1>
                      <h2>{val.companyName}</h2>
                      <div className="cityWorkArrangement">
                        <div>
                          <FontAwesomeIcon
                            icon={faLocationPin}
                            className="icon"
                          />{" "}
                          <p>{val.city}</p>
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            className="icon"
                          />
                          <p>{val.workArrangement}</p>
                        </div>
                      </div>
                      <div className="calendar">
                        <FontAwesomeIcon
                          icon={faCalendarDay}
                          className="icon"
                        />
                        <p>{moment(val.postingDate.startDate).format("LL")}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="btnContainer">
                <CustomButton
                  name="View All Jobs"
                  className="roundedVioletBorderBtn"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default MostRecentJob;
