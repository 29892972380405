import React from "react";
import { Container } from "reactstrap";
import { TabTitle } from "../../Utilities/FunctionTitle";
import Header from "../../layouts/Header";
// import Sidebar from "../../layouts/Sidebar";
// import Footer from "../../layouts/Footer";
import Banner from "../../components/Banner";
import "../../assets/scss/Client/Page/Subscription.scss";
// import Subsciption from "../../components/LayoutClient/Subscription/Subscription";
// import FooterClient from "../../layouts/FooterClient";
import ClientAnalytics from "../../Utilities/Analytics/ClientAnalytics";
import SubsSelect from "../../components/LayoutClient/Subscription/SubsSelect";
import Footer from "../LandingPage/Components/Footer";
const SubscriptionSelect = () => {
  TabTitle("NXT | Subscription");
  return (
    <div className="subsription-background-imagePost">
      <ClientAnalytics />
      <div className="header-fixed">
        <Header />
      </div>
      <div className="banner">
        <Banner />
      </div>

      <Container className="body-container">
        {/* <div>
          <div className="sidebar-container">
            <Sidebar />
          </div>
        </div> */}

        <div className="content-container">
          <SubsSelect />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default SubscriptionSelect;
