import React, { useState } from "react";
import { Col, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthToken } from "../../../../Utilities/Auth/Token";
import { Config } from "aws-sdk";
import axios from "axios";
import CustomModal from "../../../UI/Modal";
import { modalSubsReminder } from "../../../../Utilities/Reusable/Function/Subscription/ModalMessage/ModalMessage";
import PremiumDetails from "./Details/PremiumDetails";
import { SubscriptionFunction } from "../../../../Utilities/Reusable/Function/Subscription/Subscription";
import plans from "../../../../Data/subscription/subscription.json";
import addOns from "../../../../Data/subscription/AddOns.json";
import PlanAmount from "./Details/PlanAmount";
import bobbie from "../../../../assets/images/Logo/Brand/bobbie.png";
const SubsCardList = ({ subsData }) => {
  const { existingTransaction, expiredFreeTrial, subscribedToFreeTrial } =
    subsData;
  const location = useLocation();
  const navigate = useNavigate();

  const premiumPlans = addOns.filter(
    (plan) => plan.planName === "ats" || plan.planName === "ats+ai"
  );
  const aiPlan = addOns.find((plan) => plan.planName === "ai");

  const [plansOrAddOns, setPlansOrAddOns] = useState(() => {
    if (subsData[0].activeSubscription.current === "elite") {
      return [];
    } else if (subsData[0].activeSubscription.current === "premium") {
      return premiumPlans;
    } else if (subsData[0].activeSubscription.current === "premium+") {
      return [aiPlan];
    } else {
      return plans;
    }
  });

  // const token = useAuthToken();

  const [show, setShow] = useState(null);
  const [modalType, setModalType] = useState("");
  const modalInfo = modalSubsReminder(modalType);

  return (
    <>
      <div className="card__wrap--subscription__container">
        {plansOrAddOns.length === 0 ? (
          <strong>
            <p className="m-5">Max reached</p>
          </strong>
        ) : (
          plansOrAddOns.map((val, key) => {
            return (
              <div
                className={`container--subs__card ${
                  val.highlight && "recommended-border"
                } card-shadow`}
              >
                {val.highlight && (
                  <div className="recommended--details">
                    <h1>
                      <img
                        src={require("../../../../assets/images/hiresmarter.png")}
                        className="img-fluid"
                      />
                    </h1>
                    {/* <img src={bobbie} /> */}
                  </div>
                )}
                <Card
                  className={`bg-white p-4 ${val.highlight && "pt-2"}`}
                  style={{
                    boxShadow: "none",
                    marginBottom: `${val.highlight && "0"}`,
                    overflow: "hidden",
                  }}
                >
                  <PlanAmount planName={val.planNameTxt} amount={val.amount} />
                  <Button
                    variant=""
                    className={`btn-${
                      subsData[0].activeSubscription.pendingPlan ===
                        val.planName ||
                      subsData[0].addOn.pendingPlan === val.planName
                        ? "blue"
                        : "green"
                    } py-2 my-2`}
                    onClick={() =>
                      navigate("/client/subscription-select", {
                        state: { subsSelectDetails: val, subsData: subsData },
                      })
                    }
                    disabled={
                      subsData[0].activeSubscription.pendingPlan ||
                      (subsData[0].addOn.current?.includes(val.planName) ??
                        false) ||
                      subsData[0].addOn.pendingPlan ||
                      (subsData[0].addOn.current?.includes("ats+ai") ?? false)
                    }
                  >
                    {subsData[0].addOn.pendingPlan === val.planName ||
                    subsData[0].activeSubscription.pendingPlan === val.planName
                      ? "PENDING SUBSCRIPTION"
                      : "SUBSCRIBE NOW"}
                  </Button>
                </Card>{" "}
                <PremiumDetails
                  details={val.description}
                  highlight={val.highlight}
                />
              </div>
            );
          })
        )}
      </div>
      <CustomModal
        show={show}
        modalMessage={modalInfo?.message}
        modalType={modalInfo?.errorType}
        buttonName={modalType === "existSubs" ? "OK" : ""}
        onClick={() => {
          setShow(false);
        }}
      />
    </>
  );
};

export default SubsCardList;
