import React from "react";
import { Col, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PlanAmount from "../../../../../../components/LayoutClient/Subscription/SubscriptionCard/Details/PlanAmount";
import PremiumDetails from "../../../../../../components/LayoutClient/Subscription/SubscriptionCard/Details/PremiumDetails";
import "../../../../../../assets/scss/Client/Layout/Subscription/Subsciption.scss";
const ActiveCard = ({ selectedPlan }) => {
  console.log(selectedPlan);
  const navigate = useNavigate();
  return (
    <div>
      <div className={`container--subs__card card-shadow`}>
        <Card className="container--subs__card card-shadow bg-white p-4">
          <PlanAmount
            planName={selectedPlan?.planNameTxt}
            amount={selectedPlan?.amount}
          />
          <Button
            variant=""
            className="btn-blue d-block p-3 border-0 mb-5"
            // onClick={() =>
            //   navigate("/client/subscription-config", {
            //     state: { selectedPlan: selectedPlan },
            //   })
            // }
            disabled
          >
            ACTIVE SUBSCRIPTION
          </Button>
        </Card>
        <PremiumDetails
          details={selectedPlan?.description}
          highlight={selectedPlan?.highlight}
        />
      </div>
    </div>
  );
};

export default ActiveCard;
