import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";
import "../../assets/scss/layout/Container/_customContainer.scss";

const CustomContainer = ({ className, backgroundImage, children }) => {
  const backgroundStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
    zIndex: "9",
  };

  return (
    <div className={`customContainer ${className}`} style={backgroundStyle}>
      {className === "adminNavBg" ? (
        <>{children}</>
      ) : (
        <Container>{children}</Container>
      )}
    </div>
  );
};

export default CustomContainer;
