import React, { useEffect } from "react";
import { Container } from "reactstrap";
import "../../../assets/scss/LandingPage/LandingFooter.scss";

import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {" "}
      <div className="landing-footer">
        <Container>
          <div className="footer-body">
            <div className="logo-container">
              <img
                alt="nxt-img"
                src={require(`../../../assets/images/LandingPage/nxtWhite.png`)}
                className="nxt-img"
              />
              <img
                alt="nxt-img"
                src={require(`../../../assets/images/LandingPage/poweredby.png`)}
                className="nxt-img"
              />
              {/* <div className="social-media-container">
              <a href="https://www.facebook.com/profile.php?id=100086798423561">
                <img
                  src={require(`../../../assets/images/LandingPage/SocialMediaIcons/facebook_icon.png`)}
                  alt="nxt.com.ph"
                />
              </a>
              <a href="https://www.instagram.com/acquiro.ph/">
                <img
                  src={require(`../../../assets/images/LandingPage/SocialMediaIcons/instagram_icon.png`)}
                  alt="nxt.com.ph"
                />
              </a>
              <a href="https://www.linkedin.com/company/89732942">
                <img
                  src={require(`../../../assets/images/LandingPage/SocialMediaIcons/linkedin_icon.png`)}
                  alt="nxt.com.ph"
                />
              </a>
              <a href="https://www.tiktok.com/@acquiroph">
                <img
                  src={require(`../../../assets/images/LandingPage/SocialMediaIcons/tiktoks_icon.png`)}
                  alt="nxt.com.ph"
                />
              </a>
              <img
                className="npc"
                src={require("../../../assets/images/LandingPage/TrackingSystem/npc.png")}
              />
            </div> */}
            </div>
            <div className="links-container mb-5 mb-sm-5 mb-md-0">
              <div className="link-list w-100 row">
                <div className="col">
                  <ul>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Home
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/about")}
                    >
                      About NXT
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/product")}
                    >
                      Product
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/pricing")}
                    >
                      Pricing
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li className="p-2" type="button">
                      <a href="/#searchJob">Search Job</a>
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/client/registration")}
                    >
                      Post a Job
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/contact-us")}
                    >
                      Contact Us
                    </li>
                    <li
                      className="p-2"
                      type="button"
                      onClick={() => navigate("/faq")}
                    >
                      FAQs
                    </li>
                  </ul>
                </div>

                {/* <a href="/#top-companies">Top Hiring Companies</a>
              <a href="/#about">About NXT</a>
              <a href="/#career">MeNXT Career Tips!</a>
              <a href="/contact-us">Contact Us</a> */}
                {/* <a href="#postjob">Self-starters Podcast</a> */}
              </div>

              {/* <div className="copyright-container">
              <div className="arr-text">
                All rights reserved 2023
                <br />
                <span
                  onClick={() => navigate("/privacy-policy")}
                  style={{ cursor: "pointer" }}
                >
                  Privacy Policy
                </span>{" "}
                |{" "}
                <span
                  onClick={() => navigate("/terms-and-condition")}
                  style={{ cursor: "pointer" }}
                >
                  Terms and Conditions
                </span>{" "}
                |{" "}
                <span
                  onClick={() => navigate("/data-deletion")}
                  style={{ cursor: "pointer" }}
                >
                  Data Deletion
                </span>{" "}
                |{" "}
                <span
                  onClick={() => navigate("/cookie-policy")}
                  style={{ cursor: "pointer" }}
                >
                  Cookie Policy
                </span>{" "}
              </div>
              <br />
            </div> */}
            </div>
            <div className="position-relative d-none d-sm-none d-md-none d-lg-block">
              <div className="position-absolute bottom-0 start-0">
                <div className="social-media-container">
                  <a href="https://www.facebook.com/nextexperiencetoday/">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/facebook_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a>
                  {/* <a href="https://www.instagram.com/acquiro.ph/">
                  <img
                    src={require(`../../../assets/images/LandingPage/SocialMediaIcons/instagram_icon.png`)}
                    alt="nxt.com.ph"
                  />
                </a> */}
                  {/* <a href="https://www.linkedin.com/company/89732942">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/linkedin_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a> */}
                  <a href="https://www.tiktok.com/@nextexperiencetoday">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/tiktoks_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a>
                  {/* <img
                  className="npc"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/npc.png")}
                /> */}
                </div>
              </div>
            </div>
            <div className="position-relative d-block d-sm-block d-md-block d-lg-none">
              <div className="position-absolute bottom-0 start-50 translate-middle-x">
                <div className="social-media-container">
                  <a href="https://www.facebook.com/nextexperiencetoday/">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/facebook_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a>
                  {/* <a href="https://www.instagram.com/acquiro.ph/">
                  <img
                    src={require(`../../../assets/images/LandingPage/SocialMediaIcons/instagram_icon.png`)}
                    alt="nxt.com.ph"
                  />
                </a> */}
                  {/* <a href="https://www.linkedin.com/company/89732942">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/linkedin_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a> */}
                  <a href="https://www.tiktok.com/@nextexperiencetoday">
                    <img
                      src={require(`../../../assets/images/LandingPage/SocialMediaIcons/tiktoks_icon.png`)}
                      alt="nxt.com.ph"
                    />
                  </a>
                  {/* <img
                  className="npc"
                  src={require("../../../assets/images/LandingPage/TrackingSystem/npc.png")}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="all-reserved p-4">
        All rights reserved 2023
        <br />
        <span
          onClick={() => navigate("/privacy-policy")}
          style={{ cursor: "pointer" }}
        >
          Privacy Policy
        </span>{" "}
        |{" "}
        <span
          onClick={() => navigate("/terms-and-condition")}
          style={{ cursor: "pointer" }}
        >
          Terms and Conditions
        </span>{" "}
        |{" "}
        <span
          onClick={() => navigate("/data-deletion")}
          style={{ cursor: "pointer" }}
        >
          Data Deletion
        </span>{" "}
        |{" "}
        <span
          onClick={() => navigate("/cookie-policy")}
          style={{ cursor: "pointer" }}
        >
          Cookie Policy
        </span>{" "}
      </div>
    </>
  );
};

export default Footer;
