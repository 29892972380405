import React from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_recentHistory.scss";
import moment from "moment";

const RecentHistory = ({ data, client }) => {
  // console.log(data);
  return (
    <div>
      <TitleLine title="Recent History"></TitleLine>
      <div className="view_resume_history">
        <div className="view_resume_history_content">
          {data && data.length > 0 ? (
            data.map((val, key) => (
              <div className="certificate_content" key={key}>
                <h5>{val?.content}</h5>
                <p>{moment(val?.updatedAt).format("YYYY-MM-DD, h:mm:ss a")}</p>
                <hr className="line-separator" />
              </div>
            ))
          ) : (
            <p>No recent history</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentHistory;
