import React, { useEffect, useState } from "react";
import TitleLine from "../../../../../components/UI/Title/TitleLine";
import "../../../../../assets/scss/Page/Admin/ATS/_viewResumeHistory.scss";
import axios from "axios";

const RemarksNote = ({ data }) => {
  if (!data || !data.notes || data.notes.length === 0) {
    return (
      <div>
        <TitleLine title="Remarks/Note"></TitleLine>
        <p>No remarks found</p>
      </div>
    );
  }

  const { client, notes } = data;

  return (
    <div>
      <TitleLine title="Remarks/Note"></TitleLine>

      <div className="view_resume_history">
        <div className="view_resume_history_content">
          {data?.notes
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((val, key) => {
              return (
                <div key={key}>
                  <p>
                    <span>
                      {val?.name} {/* Assuming lastName is not needed */}
                    </span>
                  </p>
                  <p>{val.content}</p>
                  <hr className="line-seperator" />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RemarksNote;
