import React from "react";
import "../../../../assets/scss/Client/Layout/TalentList/SelectTalent/WorkHistory.scss";
import { Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
const WorkHistory = () => {
  const workHistoryList = [
    {
      title: "Coca Cola - Accountant ",
      level: "05/15/2022",
      startDate: "05/25/2022",
      endDate: "06/25/2023",
    },
    {
      title: "Sprite - Accountant ",
      level: "05/15/2022",
      startDate: "08/15/2054",
    },
  ];
  return (
    <div className="st-work-history-container">
      <h6>WORK HISTORY</h6>
      <hr className="line-seperator" />

      {workHistoryList?.map((val) => {
        return (
          <div className="work-history-list">
            <Label>{val.title}</Label>
            <p>Level: {val.level}</p>
            <p>
              Time Period: {val.startDate} to {val.endDate}
            </p>
            <div className="remove-icon-container">
              <div className="remove-work-container">
                <FontAwesomeIcon icon={faTrash} className="remove-work" />{" "}
                <span>Remove</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkHistory;
