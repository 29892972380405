import React from "react";

const TalentProfileLogin = () => {
  return (
    <div>
      <p>
        Now that you have validated your email account, below are the steps on
        how to access your NXT Talent profile.
      </p>
      <p>Login using email address:</p>
      <ol>
        <li>
          Open your browser and type in the address bar:{" "}
          <a href="/talent/login">www.nxt.com.ph/talent/login</a>
        </li>
        <li>Type in your registered email address and click on ‘Login’</li>
        <li>
          If you haven't set a password or trying to retrieve your password,
          click "Forgot Password."
        </li>
      </ol>
      <div className="ps-4">
        <p>Resetting Your Password</p>
        <ul style={{ listStyle: "none" }}>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> You'll be redirected to
            the password reset page.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> Provide your email
            address registered on your NXT account.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> You'll receive an email
            with a password reset link.
          </li>
          <li>
            <i class="fa-solid fa-arrow-right me-2"></i> Click the link to set a
            new password.
          </li>
        </ul>
        <p>Your new password should meet the following criteria:</p>
        <ul className="ms-3">
          <li>At least one lowercase letter</li>
          <li>At least one uppercase letter</li>
          <li>At least one number</li>
          <li>At least one special character</li>
          <li>Minimum length of 8 characters</li>
        </ul>
      </div>
      <p>Login using SSO</p>
      <ol>
        <li>Click on the Google or Facebook icon</li>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li>
            Google
            <ul style={{ listStyleType: "lower-roman" }}>
              <li>
                For Google, if multiple Google accounts are logged into the
                system, the user will choose the account that was previously
                created a NXT profile
              </li>
              <li>Enter Google credentials</li>
              <li>
                Once logged in, you should be able to see your account’s profile
                page.
              </li>
            </ul>
          </li>
          <li>
            Facebook
            <ul style={{ listStyleType: "lower-roman" }}>
              <li>
                For Facebook, if Facebook account is logged into the system, the
                user will be proceed directly to the NXT page. If not, user
                would need to enter their Facebook credentials
              </li>
              <li>
                Once logged in, you should be able to see your account’s profile
                page.
              </li>
            </ul>
          </li>
        </ul>
      </ol>
    </div>
  );
};

export default TalentProfileLogin;
