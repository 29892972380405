import React from "react";

const UpdateApplicationStatus = () => {
  return (
    <div>
      <p>
        NXT revolutionizes the connection between job seekers and businesses,
        offering new and exciting experiences for all stakeholders. NXT offers a
        seamless and rewarding experience in finding the NXT talent for your
        organization.
      </p>
      <ol>
        <li>Go to applicant’s profile</li>
        <li>Go to ‘Application Status’ section</li>
        <li>Select the desired new status from the dropdown list</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>For screening</li>
          <li>Failed Paper Screening</li>
          <li>For Interview</li>
          <li>Failed Interview</li>
          <li>Job Offer</li>
          <li>Hired</li>
        </ol>
        <li>Select preferred means of communication</li>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>Google meet</li>
          <li>Zoom</li>
          <li>Viber</li>
          <li>Phone</li>
        </ol>
        <li>Click on ‘Save’</li>
        <li>
          The applicant will receive a real time update via email that there is
          a change in the application status.
        </li>
      </ol>
    </div>
  );
};

export default UpdateApplicationStatus;
