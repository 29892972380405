import React from "react";

import CustomButton from "../../Button";
import { Button, Input, Table } from "reactstrap";
import "../../../../assets/scss/UIStyle/Table/SuperAdmin/GroupTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExpand,
  faPenSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const AddAdminTable = () => {
  const data = [
    {
      email: "cqmanga@acquiro.com.ph",
      firstName: "Parent Group",
      lastName: "Parent Group",
      group: "Admin",
    },
    {
      email: "cqmanga@acquiro.com.ph",
      firstName: "Parent Group",
      lastName: "Parent Group",
      group: "Admin",
    },
    {
      email: "cqmanga@acquiro.com.ph",
      firstName: "Parent Group",
      lastName: "Parent Group",
      group: "Admin",
    },
    {
      email: "cqmanga@acquiro.com.ph",
      firstName: "Parent Group",
      lastName: "Parent Group",
      group: "Admin",
    },
    {
      email: "cqmanga@acquiro.com.ph",
      firstName: "Parent Group",
      lastName: "Parent Group",
      group: "Admin",
    },
  ];

  const handleAddGroup = () => {
    // console.log("Click");
  };

  return (
    <div className="groupTable addAdminTable">
      <div className="buttonContainer">
        <Input type="text" placeholder="Enter Keyword" />
      </div>
      <div className="tableContainer">
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Group</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((val) => {
              return (
                <tr>
                  <td className="contentText">{val.email}</td>
                  <td className="contentText">{val.firstName}</td>
                  <td className="contentText">{val.lastName}</td>
                  <td className="contentText">{val.group}</td>
                  <td className="actionButtons">
                    <FontAwesomeIcon icon={faTrash} className="iconTrash" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AddAdminTable;
