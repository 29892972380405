import React from "react";
import "../../../assets/scss/UIStyle/Card/CompanyListStyle.scss";
const CompanyList = ({ firstData, secondData }) => {
  return (
    <div className="companyJobPostedList">
      <div className="jobPostedList">
        {firstData.map((value, index) => {
          return (
            <div key={index} className="jobPostedListInner">
              <div className="jobPostedListcontent">
                <div className="jobPostedListImgContainer">
                  <img src={value.img} alt="img"></img>
                </div>
                <div className="cardTextContainer">
                  <h1>{value.jobName}</h1>
                  <p>{value.company}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="companyList">
        {secondData.map((value, index) => {
          return (
            <div key={index} className="companyListInner">
              <div className="companyListcontent">
                <div className="companyListImgContainer">
                  <img src={value.img} alt="img"></img>
                </div>
                <h1>{value.company}</h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyList;
