import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { localDevBase } from "../../../../../services/API/localDevBase";
import { useAuthToken, useRoleKey } from "../../../../../Utilities/Auth/Token";

const ManageJobFormPagination = ({
  jobData,
  onPageChange,
  columnsPerPage,
  count,
  jobNextData,
  dataFromSelection,
  cursorData,
  manageJobPaginationError,
}) => {
  const devBase = localDevBase();
  const token = useAuthToken();
  const [currentPage, setCurrentPage] = useState(1);
  const [cursor, setCursor] = useState();
  const cursorRef = useRef(cursorData);
  console.log(cursorData);
  const totalPages = Math.ceil(count / columnsPerPage);
  const code = useRoleKey();

  const headers = {
    Authorization: `Bearer ${token}`,
    ...(code && { rolekey: code }),
  };

  const startIndex = (currentPage - 1) * columnsPerPage + 1;
  let endIndex;
  //  = (Math.min(startIndex + columnsPerPage - 1, count));

  if (startIndex < 9) {
    endIndex = Math.min(startIndex + columnsPerPage - 1, count);
  } else {
    endIndex = Math.min(startIndex + columnsPerPage - 1);
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [count, columnsPerPage]);

  const handlePageChangePrevious = async (page) => {
    console.log(page);
    if (page === 1) {
      try {
        jobNextData(null);
        setCursor(null);
        if (page === 1) {
          cursorRef.current = cursorData;
        } else {
          cursorRef.current = null;
        }
        setCurrentPage(page);
        onPageChange(page);

        const response = await axios.get(`${devBase}/api/ats/manage-job`, {
          params: {
            filter: dataFromSelection,
          },
          headers,
        });

        console.log(cursorRef.current);
        if (response?.data?.result?.cursor) {
          cursorRef.current = response.data.result.cursor;
        }
        jobNextData(response);
        setCursor(response);
      } catch (error) {
        manageJobPaginationError(error);
      }
    } else {
      try {
        jobNextData(null);
        setCursor(null);
        if (page === 1) {
          cursorRef.current = cursorData;
        } else {
          cursorRef.current = null;
        }
        setCurrentPage(page);
        onPageChange(page);

        const response = await axios.get(`${devBase}/api/ats/manage-job`, {
          params: {
            cursor: cursorRef.current || cursorData,
            filter: dataFromSelection,
          },
          headers,
        });

        console.log(cursorRef.current);
        if (response?.data?.result?.cursor) {
          cursorRef.current = response.data.result.cursor;
        }
        jobNextData(response);
        setCursor(response);
      } catch (error) {
        manageJobPaginationError(error);
      }
    }
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    onPageChange(page);

    if (page === 1) {
      try {
        const response = await axios.get(`${devBase}/api/ats/manage-job`, {
          params: {
            filter: dataFromSelection,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            ...(code && { rolekey: code }),
          },
        });

        console.log(response);
        if (response?.data?.result?.cursor) {
          cursorRef.current = response.data.result.cursor;
        }
        jobNextData(response);
        setCursor(response);
      } catch (error) {
        manageJobPaginationError(error);
      }
    } else {
      try {
        const response = await axios.get(`${devBase}/api/ats/manage-job`, {
          params: {
            cursor: cursorRef.current || cursorData,
            filter: dataFromSelection,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            ...(code && { rolekey: code }),
          },
        });

        console.log(response);
        if (response?.data?.result?.cursor) {
          cursorRef.current = response.data.result.cursor;
        }
        jobNextData(response);
        setCursor(response);
      } catch (error) {
        manageJobPaginationError(error);
      }
    }
  };

  return (
    <div className="d-flex">
      <div className="align-middle">
        <small>
          Showing {startIndex} to {endIndex} of {count} entries
        </small>
      </div>
      <ButtonToolbar
        aria-label="Toolbar with button groups"
        className="ms-auto"
      >
        <Button
          className="me-2"
          disabled={currentPage === 1}
          onClick={() => handlePageChangePrevious(currentPage - 1)}
        >
          Previous
        </Button>
        <ButtonGroup className="me-2" aria-label="Second group">
          <Button style={{ cursor: "text" }}>{currentPage}</Button>
        </ButtonGroup>
        {count >= 10 ? (
          <>
            <Button
              disabled={cursor?.data?.result?.cursor === null}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={true}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </>
        )}
      </ButtonToolbar>
    </div>
  );
};

export default ManageJobFormPagination;
